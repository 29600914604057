<template>
    <div class="container mx-auto px-4 ">
        <div class="w-full overflow-hidden">
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <!-- Main slide -->
            <div class="relative lg:col-span-2 overflow-hidden rounded-lg aspect-w-16 aspect-h-9" >
                <transition name="fade" mode="out-in">
                <div
                    :key="currentIndex"
                    class="absolute inset-0 w-full h-full"
                >
                    <img
                    :src="banners[currentIndex].storage_image_url"
                    :alt="banners[currentIndex]?.title ?? 'Image'"
                    class="w-full h-full object-cover"
                    sizes="(min-width: 48rem) 66vw, 100vw"
                                    />
                    <div v-if="banners[currentIndex]?.title || banners[currentIndex]?.description" class="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-50 text-white hidden sm:block">
                      <h2 v-if="banners[currentIndex]?.title" class="text-xl font-bold">{{ banners[currentIndex].title }}</h2>
                      <p v-if="banners[currentIndex]?.description">{{ banners[currentIndex].description }}</p>
                    </div>
                    <a class="absolute inset-0" v-if="banners[currentIndex]?.link" :href="banners[currentIndex].link"></a>
                </div>
                </transition>
            </div>
    
            <!-- Preview banners -->
            <div class="hidden lg:grid grid-cols-1 gap-4">
                <div
                  v-for="i in 2"
                  :key="getPreviewIndex(i)"
                  @click="setSlide(getPreviewIndex(i))"
                  class="relative aspect-w-16 aspect-h-9 overflow-hidden rounded-lg cursor-pointer"
                >
                <img
                  :src="banners[getPreviewIndex(i)].storage_image_url"
                  :alt="banners[getPreviewIndex(i)]?.title ?? 'Image'"
                  class="w-full h-full object-cover"
                  sizes="33vw"
                />
                
                </div>
            </div>
            </div>
        </div>
        <div class="mt-12 lg:mt-16 mb-4 flex justify-center flex-wrap gap-4">
            <Link :href="route('tournaments.index', {status: 'upcoming'})"
                  class="transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center btn-gradient-blue text-white font-semibold text-sm px-8 py-3 rounded-full cursor-pointer">
                <i class="fas fa-arrow-circle-right mr-2"></i> Find Tournaments
            </Link>
            <Link :href="route('matchfinder.index')"
                class="transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center btn-black-trans text-white font-semibold text-sm px-8 py-3 rounded-full cursor-pointer border-2 border-blue-500">
            <i class="fas fa-gamepad mr-2"></i> Find Matches
            </Link>
            <Link :href="route('leaderboard.index')"
                class="transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center btn-black-trans text-white font-semibold text-sm px-8 py-3 rounded-full cursor-pointer border-2 border-blue-500">
            <i class="fas fa-crown mr-2"></i> Leaderboards
            </Link>
            <Link v-if="!props.user" :href="route('login')"
               class="transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center btn-black-trans text-white font-semibold text-sm px-8 py-3 rounded-full cursor-pointer border-2 border-blue-500">
                <i class="mr-2 fas fa-user-circle"></i> Sign In
            </Link>
        </div>

        <div class="text-gray-600 flex justify-center text-xs font-medium">
            <div>
                Guaranteed Withdrawals
                <span class="text-xl ml-1 mr-2">&middot;</span> Secure Systems
                <span class="text-xl ml-1 mr-2">&middot;</span> Regular Events
            </div>
        </div>
    </div>
  </template>
  
  <script setup>
    import { ref, onMounted, onUnmounted } from 'vue'
    import { Link } from "@inertiajs/vue3";

    const props = defineProps({
      'user': {
          type: Object,
      },
      'banners': {
          type: Array,
          required: true,
      }
  })

  const currentIndex = ref(0)
  let intervalId = null

  const getPreviewIndex = (offset) => {
    return (currentIndex.value + offset) % props.banners.length
  }

  const nextSlide = () => {
    currentIndex.value = (currentIndex.value + 1) % props.banners.length
  }

  const setSlide = (index) => {
    currentIndex.value = index 
  }

  onMounted(() => {
    intervalId = setInterval(nextSlide, 10000)
  })

  onUnmounted(() => {
    if (intervalId) clearInterval(intervalId)
  })
</script>
  
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
  
  
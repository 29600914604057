<script setup>

import PaginationLink from "./PaginationLink.vue";

const props = defineProps({
    'links': Array,
});

const pageNumberLinks = props.links.filter(link => parseInt(link.label) || link.label === '...');
const nextAndPreviousLinks = props.links.filter(link => !parseInt(link.label) && link.label !== '...');

if (nextAndPreviousLinks.length !== 2) {
    throw new Error("PaginationLinks.vue: Expected exactly 2 next/previous links, got " + nextAndPreviousLinks.length);
}

</script>

<template>
    <div v-if="links.length > 3">
        <div class="flex flex-wrap -mb-1 justify-between py-2 px-2 border-t-2 border-b-2 border-blue-600 rounded-2xl mx-2">
            <PaginationLink :link="nextAndPreviousLinks[0]" />

            <div
                class="flex flex-wrap justify-center gap-1"
            >
                <PaginationLink
                    v-for="(link, key) in pageNumberLinks"
                    :key="key"
                    :link="link"
                />
            </div>

            <PaginationLink :link="nextAndPreviousLinks[1]" />

<!--            <template v-for="(link, key) in links" :key="key">

                <PaginationLink :link="link" />
                <div
                    v-if="link.url === null"
                    class="inline-flex items-center px-2 py-2 text-sm font-medium leading-5 text-gray-500 bg-blue-800 border border-gray-800 cursor-default rounded-l-md"
                    v-html="link.label"
                />

                <Link
                    v-else
                    class="relative inline-flex items-center px-2 py-2 text-sm leading-5 text-gray-300 transition duration-150 ease-in-out bg-blue-800 border border-gray-800 rounded-l-md hover:text-gray-200 focus:z-10 focus:outline-none focus:shadow-outline-blue active:bg-gray-100 active:text-gray-800"
                    :class="{ 'font-bold': link.active, 'text-white': link.active }"
                    :href="link.url"
                    v-html="link.label"
                    preserve-scroll
                />
            </template>-->
        </div>
    </div>
</template>

<style scoped>

</style>

<template>
    <div class="relative w-full overflow-hidden mb-8">
      <div class="overflow-x-auto whitespace-nowrap -mx-4">
        <div class="inline-flex gap-4 px-8 md:px-16">
          <Link :href="route('leagues.matches.show', { league: match.league.id, leagueMatch: match.id })" 
            v-for="match in props.leagueSchedule" :key="'league_match_' + match.id" class="w-72 bg-gray-800 rounded-lg overflow-hidden shrink-0">
            
            <div class="p-3 bg-gray-700 text-gray-300 text-sm space-y-2">
              <span class="block">{{ formatDate(match.match_start) }}</span>
            </div>
            
            <div class="p-4 flex flex-col gap-2">
              <div class="flex items-center justify-between">
                <div class="flex items-center gap-3">
                  <img :src="match.team_one.storage_avatar" :alt="match.team_one.name + '\'s avatar'" class="w-8 h-8 bg-gray-700 rounded-full flex items-center justify-center">
                  <span class="text-gray-100 font-medium">{{ match.team_one.name }}</span>
                </div>
                <span v-if="match.team_one.id === match.winning_team_id" class="text-green-400 text-sm">●</span>
              </div>

              <span class="text-xs text-left pl-2 vs-text">vs.</span>

              <div class="flex items-center justify-between">
                <div class="flex items-center gap-3">
                  <img :src="match.team_two.storage_avatar" :alt="match.team_two.name + '\'s avatar'" class="w-8 h-8 bg-gray-700 rounded-full flex items-center justify-center">
                  <span class="text-gray-100 font-medium">{{ match.team_two.name }}</span>
                </div>
                <span v-if="match.team_two.id === match.winning_team_id" class="text-green-400 text-sm">●</span>
              </div>
            </div>
          </Link>
       
        </div>
      </div>
    </div>
</template>
  
<script setup>
import { Link } from '@inertiajs/vue3';

 const props = defineProps({
      'leagueSchedule': {
          type: Array,
          required: true,
      },
  })

  const formatDate = (date) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', };
      return new Date(date).toLocaleDateString(undefined, options);
  };

</script>
  
<style scoped>
  .overflow-x-auto {
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .overflow-x-auto::-webkit-scrollbar {
    display: none;
  }

  .text-wrap {
    text-wrap: wrap;
  }

  .vs-text {
    color: #0087f7;
  }
</style>
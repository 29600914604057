<template>
    <div id="main" class="text-white py-6">
        <div class="container mx-auto">
            <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Teams', link: route('teams.index')},
                {name: team.name, link: route('teams.show', team.id)},
                {name: 'Management'}
            ]"/>
            <div class="flex flex-col gap-y-6 mt-4 md:flex-row md:justify-between">
                <div class="flex gap-4">
                    <div class="flex items-center flex-shrink-0">
                        <img class="w-16 mt-1 rounded-full lg:w-32 aspect-h-1" :src="team.storage_avatar"
                             :alt="`${team.name}'s avatar`">
                    </div>
                    <div class="leading-tight">
                        <div class="flex items-center">
                            <h2 class="text-4xl font-extrabold break-all text-white">
                                {{ team.name }}
                            </h2>
                            <img v-if="team.is_elite" class="w-16 mx-auto" :src="eliteImage" alt="Elite logo">
                        </div>
                        <div class="font-bold text-gray-600 uppercase text-xxs">
                            Created: {{ formattedDate(team.created_at) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TeamStatsOptions :team-id="team.id"
                          :win-ratio="winRatio"
                          :total-wins="totalWins"
                          :total-losses="totalLosses"
                          :total-cash-earned="totalCashEarned"
                          :team-views="team.team_views"
                          :is-captain="isCaptain"
                          :user-belongs-to-team="userBelongsToTeam"
                          :is-owner="isOwner"/>
        <div class="container mx-auto">
            <div class="flex flex-wrap -mx-4 mb-6 mt-10">
                <div class="w-6/12 px-4">
                    <div class="text-lg font-bold">
                        <i class="fas fa-envelope-open-text text-orange-500 mr-1 mb-4"></i>Invite Players
                    </div>
                    <player-search :team="team"></player-search>
                </div>

                <div class="w-6/12 px-4">
                    <div class="text-lg font-bold">
                        <i class="fas fa-users text-blue-500 mr-1"></i>Team Roster
                    </div>
                    <div class="mt-2">
                        <div v-for="member in team.members" :key="member.id" class="w-full overflow-x-auto">
                            <TeamRosterMemberCard :member="member"></TeamRosterMemberCard>
                        </div>
                    </div>
                    <div class="text-lg font-bold mt-4">
                        <i class="fas fa-envelope-open-text text-blue-500 mr-1"></i>Team Invitations
                    </div>
                    <TeamInviteList :invited-users="team.invitations.map(invite => invite.user)"></TeamInviteList>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import PlayerSearch from '../../../components/PlayerSearch.vue';
import TeamInviteList from '../../../components/TeamInviteList.vue';
import {usePage} from "@inertiajs/vue3";
import Breadcrumbs from "../../../InertiaComponents/Navigation/Breadcrumbs.vue";
import TeamStatsOptions from "../../../components/TeamStatsOptions.vue";
import {computed, ref} from "vue";
import TeamRosterMemberCard from "../../../components/TeamRosterMemberCard.vue";

const {
    team,
    winRatio,
    totalWins,
    totalMatches,
    totalCashEarned,
    isCaptain,
    userBelongsToTeam,
    isOwner

} = usePage().props;

const totalLosses = computed(() => totalMatches - totalWins);
const eliteImage = ref('/images/elite.svg');

const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
};
</script>

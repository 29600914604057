<script setup>

import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import TournamentBracketTeamDisplay from "./TournamentBracketTeamDisplay.vue";
import TournamentBracketTeamTbdDisplay from "./TournamentBracketTeamTbdDisplay.vue";
import {routeFromPage} from "../../routes.js";

const page = usePage();

const route = routeFromPage(page);

const props = defineProps({
    tournament: {
        type: Object,
        required: true,
    },
});

const matches = computed(() => props.tournament.matches);

const groupedMatches = computed(() => Map.groupBy(matches.value, (match) => match.round));

const linkAddr = (match) => {
    if (match.team_one && match.team_two) {
        return route('tournament.match.show', {
            tournament: props.tournament.slug,
            match: match.id
        });
    }
    return '#';
}

function generateRoundName(roundNumber, totalRounds) {
    if (roundNumber === totalRounds) {
        return 'Grand Finals';
    }
    if (roundNumber === totalRounds - 1) {
        return 'Semi-Finals';
    }
    if (roundNumber === totalRounds - 2) {
        return 'Quarter-Finals';
    }
    return `Round ${roundNumber}`;
}

const winningTeam = (match) => {
    if (!match || !match.winner_id) return null;
    if (match.winner_id === match.team_one.id) {
        return match.team_one;
    }
    if (match.winner_id === match.team_two.id) {
        return match.team_two;
    }
    return null;
}

const finalMatch = computed(() => {
    return matches.value.find(match => match.round === groupedMatches.value.size);
});

</script>

<template>
    <div class="flex overflow-x-auto" style="max-width: 100%">
        <div
            v-for="([key, matchRound], index) in groupedMatches"
            :key="key"
            class="mr-4 flex flex-col justify-around bracket-col match-round pt-10">
            <div
                class="flex-1 flex flex-col justify-around match-wrapper"
                :class="{'finals-match-wrapper': index === groupedMatches.size - 1}"
                v-for="(match, matchIndex) in matchRound"
                :key="match.id"
            >
                <a
                    :href="linkAddr(match)"
                    class="flex flex-col justify-between relative my-2 tournament-match"
                >
                    <h3 v-if="matchIndex === 0" class="text-lg text-center mb-2 font-semibold absolute -top-8 w-full">
                        {{ generateRoundName(key, groupedMatches.size) }}
                    </h3>
                    <div class="bg-blue-800 rounded-lg">
                        <TournamentBracketTeamDisplay
                            :team="match.team_one"
                            :match="match"
                            />

                        <p class="text-center font-semibold text-xxs text-blue-400 -my-1">
                            VS
                        </p>

                        <TournamentBracketTeamDisplay
                            :team="match.team_two"
                            :match="match"
                            />
                    </div>
                </a>
            </div>
        </div>

        <div class="-ml-4 mr-4 flex flex-col justify-around bracket-col match-round pt-10">
            <div
                class="flex flex-col justify-between relative my-2 tournament-champion"
            >
                <div class="flex-1 flex flex-col justify-around match-wrapper">
                    <h3 class="text-lg text-center mb-2 font-semibold absolute -top-8 w-full">
                        Champion
                    </h3>

                    <div class="bg-blue-800 rounded-lg">
                        <TournamentBracketTeamDisplay
                            v-if="finalMatch && !!finalMatch.winner_id"
                            :team="winningTeam(finalMatch)"
                            :match="finalMatch"
                        />

                        <TournamentBracketTeamTbdDisplay v-if="finalMatch && !finalMatch.winner_id" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

.bracket-col {
    min-width: 200px;
    margin-right: 35px;
}

.tournament-match:before {
    content: "";
    display: block;
    width: 8%;
    height: 100%;
    position: absolute;
    border-top: 2px solid #0082f5;
    top: 50%;
    left: -10%;
}

.tournament-champion:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-top: 2px solid #0082f5;
    top: 50%;
    left: -10%;
}

.match-wrapper {
    position: relative
}

.match-round > .match-wrapper:nth-child(odd):after {
    content: "";
    display: block;
    width: 8%;
    height: 100%;
    position: absolute;
    border-top: 2px solid #0082f5;
    border-bottom: 2px solid #0082f5;
    top: 50%;
    left: 100%;
    border-right: 2px solid #0082f5;
}

.finals-match-wrapper:after {
    display: none !important;
}

</style>

<script setup>

import {computed} from "vue";
import {usePage} from "@inertiajs/vue3";
import {Link} from "@inertiajs/vue3";
import CreditsBalanceSummary from "../Account/CreditsBalanceSummary.vue";
import AccountDropdown from "./AccountDropdown.vue";
import FundsSummary from "../Account/FundsSummary.vue";
import NavMobileMenuDropdown from "./NavMobileMenuDropdown.vue";

const page = usePage();
const user = computed(() => page.props.auth?.user)
const appName = computed(() => page.props.appName)
const unreadNotifications = computed(() => page.props.notifications.unread);

</script>

<template>
    <div id="nav" class="relative z-50 bg-blue-800">
        <div class="mx-auto">
            <div class="flex items-center justify-between w-full">
                <div class="flex justify-between flex-1 py-4">
                    <div class="flex">
                        <NavMobileMenuDropdown/>
                        <div class="relative flex items-center text-2xl font-bold text-white uppercase lg:block items mr-4 md:ml-4 h-12 xl:h-16 flex-shrink-0">
                            <Link href="/" class="h-full">
<!--                                                           <img class="w-full h-full object-contain" src="{{ asset('images/logo3.svg') }}"-->
<!--                                                                                                 alt="{{ config('app.name') }} logo">-->

                                <img class="w-full h-full object-contain" src="/images/logobeta.png"
                                     :alt="`${appName} logo`">
                            </Link>
                        </div>
                        <div class="hidden lg:flex lg:items-center gap-3 md:gap-4 xl:gap-6 mr-4">
<!--                            <Link :href="route('game.index')"-->
<!--                                  class="text-xs xl:text-md font-semibold tracking-wide text-gray-400 uppercase hover:text-gray-300 whitespace-nowrap">Games</Link>-->
                            <Link :href="route('tournaments.index', {'status':'upcoming'})"
                               class="text-xs xl:text-md font-semibold tracking-wide text-gray-400 uppercase hover:text-gray-300 whitespace-nowrap">Tournaments</Link>
                            <Link :href="route('matchfinder.index')"
                               class="text-xs xl:text-md font-semibold tracking-wide text-gray-400 uppercase hover:text-gray-300 whitespace-nowrap">Match Finder</Link>
<!--                            <Link :href="route('leagues.index')"-->
<!--                               class="text-xs xl:text-md font-semibold tracking-wide text-gray-400 uppercase hover:text-gray-300 whitespace-nowrap">Leagues</Link>-->
                            <!--                            <Link :href="route('store.index')"-->
<!--                                  class="text-xs xl:text-md font-semibold tracking-wide text-gray-400 uppercase hover:text-gray-300 whitespace-nowrap">Clubs</Link>-->

                            <Link
                                :href="route('leaderboard.index')"
                                class="text-xs xl:text-md font-semibold tracking-wide text-gray-400 uppercase hover:text-gray-300 whitespace-nowrap"
                            >
                                Leaderboards
                            </Link>

                            <Link :href="route('store.index')"
                               class="text-xs xl:text-md font-semibold tracking-wide text-gray-400 uppercase hover:text-gray-300 whitespace-nowrap">Store</Link>
                            <a :href="route('search.index')"
                               class="text-xs xl:text-md font-semibold tracking-wide text-gray-400 uppercase hover:text-gray-300 whitespace-nowrap">
                                <i class="fa-solid fa-search"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="flex items-center mr-4 gap-2">
                    <template v-if="!user">
                        <Link :href="route('register')"
                           class="text-xs font-bold tracking-wide text-white lg:text-sm border-2 border-blue-500 px-3 py-2 rounded-full">
                            Join Free
                        </Link>
                        <Link :href="route('login')"
                           class="px-4 py-2 text-sm font-bold text-white btn-gradient-blue rounded-full">
                            <i class="mr-2 fas fa-user-circle"></i>Sign In
                        </Link>
                    </template>

                    <template v-else>
                        <div class="flex gap-3 mr-2 xl:mr-6 items-center">
                            <div class="hidden xl:inline-block flex-1 whitespace-nowrap">
                                <Link :href="route('user.friend-hub.create')" class="flex font-bold text-white text-xxs lg:text-sm bg-blue-500 px-2 lg:px-4 py-1 lg:py-2 rounded-full btn-gradient-blue transition duration-300 ease-in-out transform hover:scale-105">
                                    Refer a Friend
                                </Link>
                            </div>
                            <div class="hidden xl:inline-block flex-1 whitespace-nowrap">
                                <a :href="route('bank.deposit.create', {'m': 'dc'})" class="flex font-bold text-white text-xxs lg:text-sm bg-blue-500 px-2 lg:px-4 py-1 lg:py-2 rounded-full btn-gradient-blue transition duration-300 ease-in-out transform hover:scale-105">
                                    Add Money
                                </a>
                            </div>
                            <CreditsBalanceSummary/>
                            <FundsSummary/>
                        </div>
                        <div class="xl:mr-3">
                            <a :href="route('notification.index')" class="relative">
                                <div
                                    class="absolute w-5 h-5 font-bold text-center text-white bg-blue-500 border-2 border-blue-800 rounded-full text-xs"
                                    style="right:2px; left: 10px; top: -10px;">
                                    {{ unreadNotifications.length }}
                                </div>
                                <i class="fas fa-bell text-xl text-gray-400"
                                    :class="{ 'text-yellow-300': !!unreadNotifications.length, 'shake': !!unreadNotifications.length }"
                                ></i>
                            </a>
                        </div>

                        <AccountDropdown :user='user'/>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

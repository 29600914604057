<script setup>

import {Link, usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import {numberWithCommas} from "../../Services/utils.js";

const page = usePage();

const props = defineProps({
    'classes': {
        type: String,
        default: 'px-4'
    },
    'userCount': {
        type: Number,
        required: true
    },
    'appName': {
        type: String,
        required: true
    },
    'hideText': {
        type: Boolean,
        required: false,
        default: false,
    }
})

const formattedUserCount = computed(() => numberWithCommas(props.userCount));
const user = computed(() => page.props.auth.user);

</script>

<template>
    <div :class="`z-10 ${classes}`">
        <div v-if="!hideText" class="mb-10">
<!--            <h1 class="text-white text-5xl font-extrabold leading-tight mb-6">-->
<!--                Harnessing the Power of <span class="text-blue-500">AI</span>-->
<!--            </h1>-->
<!--            <div class="text-gray-400 font-medium text-2xl mb-6">-->
<!--                Coming soon!-->
<!--            </div>-->

            <h1 class="text-white text-4xl sm:text-5xl font-extrabold leading-tight mb-6">
                <span class="text-orange-500">$2,000</span> ELO Leaderboards are <span class="text-blue-500">LIVE</span>
            </h1>
            <div class="text-gray-400 font-medium text-xl sm:text-2xl mb-6">
                When you play an XP or Wager match, you'll climb our $2K Leaderboards
            </div>
            <div class="text-gray-400 font-medium mb-6 text-lg">
                Over
                <span class="text-gray-200 font-semibold">{{ formattedUserCount }}</span>
                are playing tournaments on <span class="text-gray-300 font-semibold">{{ appName }}</span>
                <div>Join us now! Start winning, start earning.</div>
            </div>
        </div>
        <div v-else class="mb-10">

        </div>
        <div class="mb-2 flex justify-center flex-wrap gap-2">
            <Link :href="route('tournaments.index', {status: 'upcoming'})"
                  class="transition duration-300 ease-in-out transform hover:scale-105 inline-block btn-gradient-blue text-white font-semibold text-sm px-8 py-3 rounded-full">
                <i class="fas fa-arrow-circle-right mr-2"></i> Find Tournaments
            </Link>
            <Link :href="route('matchfinder.index')"
               class="transition duration-300 ease-in-out transform hover:scale-105 inline-block btn-gradient-blue text-white font-semibold text-sm px-8 py-3 rounded-full">
                <i class="fas fa-gamepad mr-2"></i> Find Matches
            </Link>
            <Link :href="route('leaderboard.index')"
               class="transition duration-300 ease-in-out transform hover:scale-105 inline-block btn-gradient-blue text-white font-semibold text-sm px-8 py-3 rounded-full">
                <i class="fas fa-crown mr-2"></i> Leaderboards
            </Link>
        </div>

        <div v-if="!user" class="mb-4">
            <a :href="route('login')"
               class="inline-block btn-black-trans text-white font-semibold text-sm px-8 py-2 border-2 border-blue-500 rounded-full">
                <i class="mr-2 fas fa-user-circle"></i> Sign In
            </a>
        </div>

        <div class="text-gray-600 text-xs font-medium">
            <div>
                Guaranteed Withdrawals
                <span class="text-xl ml-1 mr-2">&middot;</span> Secure Systems
                <span class="text-xl ml-1 mr-2">&middot;</span> Regular Events
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>

import {usePage, Link} from "@inertiajs/vue3";
import {computed} from "vue";
import {format, formatDistanceToNowStrict} from "date-fns";
import {numberWithCommas} from "../../../Services/utils.js";
import SocialMediaButton from "../../../InertiaComponents/UI/SocialMediaButton.vue";
import GamingAccountButton from "../../../InertiaComponents/GamingAccount/GamingAccountButton.vue";
import TitledContainer from "../../../InertiaComponents/UI/TitledContainer.vue";
import TrophyCount from "../../../InertiaComponents/Trophies/TrophyCount.vue";
import AboutUserWidget from "../../../InertiaComponents/UserProfile/AboutUserWidget.vue";
import UserAchievementsWidget from "../../../InertiaComponents/UserProfile/UserAchievementsWidget.vue";
import UserArenaRecordWidget from "../../../InertiaComponents/UserProfile/UserArenaRecordWidget.vue";
import UserRankWidget from "../../../InertiaComponents/UserProfile/UserRankWidget.vue";
import FriendCard from "../../../InertiaComponents/UserProfile/FriendCard.vue";
import Friends from "../../../InertiaComponents/UserProfile/Friends.vue";
import EarnedTrophyCard from "./Partials/EarnedTrophyCard.vue";
import UserAchievement from "../../../InertiaComponents/UserProfile/UserAchievement.vue";
import Tabs from "../../../InertiaComponents/UI/Tabs.vue";

const page = usePage();

const props = defineProps({
    'user': {
        type: Object,
        required: true
    },
    'friendsCount': {
        type: Number,
        required: true
    },
    'friends': {
        type: Array,
        required: true
    },
    'isFriendsWithUser': {
        type: Boolean,
        required: true
    },
    'hasRequestedAsFriend': {
        type: Boolean,
        required: true
    },
    'tournamentCount': {
        type: Number,
        required: true
    },
    'trophyCount': {
        type: Number,
        required: true
    },
    'trophyLogs': {
        type: Array,
        required: true,
    },
    'achievements': {
        type: Array,
        required: true
    },
    'leaderboardInfo': {
        type: Object,
        required: true
    },
    'mostPlayedGames': {
        type: Array,
        required: true
    }
});

const authUser = computed(() => page.props.auth.user);

const userDescription = computed(() => {
    if (props.user.elite_membership) {
        return 'Elite Member';
    }

    return 'ESHUB Member';
});

const friendsCount = computed(() => {
    return props.friendsCount;
});

const canUpgradeMembership = computed(() => {
    return authUser.id === props.user.id && !props.user.elite_membership;
});

const tabs = [
    {
        name: 'Summary',
        slotName: 'summary'
    },
    {
        name: 'Friends',
        slotName: 'friends'
    },
    {
        name: 'Trophy Case',
        slotName: 'trophy-case'
    },
    {
        name: 'Achievements',
        slotName: 'achievements'
    }
];

</script>

<template>
    <div>
        <div class="container px-4 mx-auto text-white md:px-0">
            <div class="lg:justify-between lg:flex">
                <div class="items-center md:flex gap-x-8">
                    <img class="object-cover w-40 h-40 mt-1 rounded-full"
                         :src="user.storage_avatar"
                         alt="{{ user.username }}"
                    >

                    <div class="space-y-4">
                        <div>
                            <p class="mb-2 text-sm text-gray-300">
                                {{ userDescription }} <span v-if="canUpgradeMembership"> - <Link
                                :href="route('store.index')" class="text-blue-400">Upgrade to Elite?</Link></span>
                            </p>
                            <div class="flex items-center">
                                <h1 class="text-4xl font-extrabold text-white text-40">
                                    {{ user.username }}
                                </h1>

                                <img v-if="user.elite_membership"
                                     class="w-16 aspect-h-16"
                                     :src="'/images/elite.svg'"
                                     alt="Elite logo">
                            </div>
                            <Link :href="route('user.show', {'user': user})" class="text-xl text-blue-300">
                                &#64;{{ user.slug }}
                            </Link>

                            <div class="flex gap-4 mt-4">
                                <div>
                                    <p class="text-sm text-gray-300">
                                        Joined:
                                    </p>
                                    <p class="ml-2">
                                        {{ format(new Date(user.created_at), 'LLL do, yyyy') }}
                                        ({{ formatDistanceToNowStrict(new Date(user.created_at)) }} ago)
                                    </p>
                                </div>
                            </div>
                        </div>

                        <!--                        @include('modules.profile.partials.game_profiles')-->
                    </div>


                </div>
                <!--                @include('modules.profile.partials.friends')-->


                <div class="flex gap-2">


                    <TitledContainer v-if="!!user.gaming_accounts" title="Gaming Accounts:">
                        <div class="flex flex-col gap-2">
                            <!-- Steam -->
                            <GamingAccountButton
                                v-if="!!user.gaming_accounts.steam"
                                :gaming-account-address="user.gaming_accounts.steam"
                                gaming-account-icon="fa-brands fa-steam"
                                gaming-account-icon-color="gray-700"
                            />

                            <!-- PSN -->
                            <GamingAccountButton
                                v-if="!!user.gaming_accounts.psn"
                                :gaming-account-address="user.gaming_accounts.psn"
                                gaming-account-icon="fa-brands fa-playstation"
                                gaming-account-icon-color="blue-500"
                            />

                            <!-- XBL -->
                            <GamingAccountButton
                                v-if="!!user.gaming_accounts.xbl"
                                :gaming-account-address="user.gaming_accounts.xbl"
                                gaming-account-icon="fa-brands fa-xbox"
                                gaming-account-icon-color="green-600"
                            />
                        </div>
                    </TitledContainer>

                    <Friends
                        :friends-count="friendsCount"
                        :is-friends-with="isFriendsWithUser"
                        :is-you="user.id === authUser.id"
                        :user="user"
                        :has-requested-as-friend="hasRequestedAsFriend"
                    />
                </div>
            </div>
            <!--            @include('modules.profile.partials.stats')-->

            <div class="sm:flex justify-between gap-2 my-2">
                <TitledContainer title="Your Stats:" classes="flex-1">
                    <div class="flex gap-2 flex-wrap">
                        <div class="flex items-center px-6 py-3 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i class="mr-4 text-blue-500 fas fa-users text-xl"></i>
                            <div>
                                <div class="text-xs text-gray-300">Friends</div>
                                <div class="text-lg font-bold">{{ numberWithCommas(friendsCount) }}</div>
                            </div>
                        </div>
                        <div
                            class="flex items-center px-6 py-3 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i class="mr-4 text-white fas fa-eye text-xl"></i>
                            <div>
                                <div class="text-xs text-gray-300">Views</div>
                                <div class="text-lg font-bold">{{ user.profile_views }}</div>
                            </div>
                        </div>
                        <div
                            class="flex items-center px-6 py-3 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i class="mr-4 text-green-500 fas fa-dollar-sign text-xl"></i>
                            <div>
                                <div class="text-xs text-gray-300">Cash Earned</div>
                                <div class="text-lg font-bold">${{ numberWithCommas(user.total_earnings) }}</div>
                            </div>
                        </div>
                        <div
                            class="flex items-center px-6 py-3 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i class="mr-4 text-blue-500 fas fa-crosshairs text-xl"></i>
                            <div>
                                <div class="text-xs text-gray-300">XP</div>
                                <div class="text-lg font-bold">{{ numberWithCommas(user.xp) }}</div>
                            </div>
                        </div>
                    </div>
                </TitledContainer>

                <TitledContainer title="Your Trophies:" side="right">
                    <div class="flex gap-x-2">
                        <TrophyCount type="diamond" :count="user.elite_trophies"/>
                        <TrophyCount type="gold" :count="user.gold_trophies"/>
                        <TrophyCount type="silver" :count="user.silver_trophies"/>
                        <TrophyCount type="bronze" :count="user.bronze_trophies"/>
                    </div>
                </TitledContainer>
            </div>
        </div>

        <div class="container mx-auto text-white">

            <Tabs
                :tabs="tabs"
            >
                <template v-slot:summary>
                    <div class="flex gap-4 p-4">
                        <div class="max-w-xs flex flex-col gap-4">
                            <UserRankWidget :user="user" :leaderboard-ranks="leaderboardInfo"/>
                            <AboutUserWidget :user="user"
                                             :tournament-count="tournamentCount"
                                             :trophy-count="trophyCount"
                            />
                            <div v-if="!!user.socials" class="my-2 p-4 border-2 border-blue-600 rounded-lg">
                                <h3 class="text-sm -mt-7 text-gray-300 mb-2">
                                    Socials:
                                </h3>

                                <div class="">
                                    <SocialMediaButton
                                        v-if="!!user.socials.twitter"
                                        social-media-color="blue-300"
                                        social-media-icon="fa-brands fa-x-twitter"
                                        social-media-name="X/Twitter"
                                        social-url-prefix="https://www.x.com"
                                        :social-media-address="user.socials.twitter"
                                    />

                                    <SocialMediaButton
                                        v-if="!!user.socials.instagram"
                                        social-media-color="pink-500"
                                        social-media-icon="fa-brands fa-instagram"
                                        social-media-name="Instagram"
                                        social-url-prefix="https://www.instagram.com"
                                        :social-media-address="user.socials.instagram"
                                    />

                                    <SocialMediaButton
                                        v-if="!!user.socials.facebook"
                                        social-media-color="blue-500"
                                        social-media-icon="fa-brands fa-facebook"
                                        social-media-name="Facebook"
                                        social-url-prefix="https://www.facebook.com"
                                        :social-media-address="user.socials.facebook"
                                    />

                                    <SocialMediaButton
                                        v-if="!!user.socials.twitch"
                                        social-media-color="purple-500"
                                        social-media-icon="fa-brands fa-twitch"
                                        social-media-name="Twitch"
                                        social-url-prefix="https://www.twitch.tv"
                                        :social-media-address="user.socials.twitch"
                                    />

                                    <SocialMediaButton
                                        v-if="!!user.socials.youtube"
                                        social-media-color="red-500"
                                        social-media-icon="fa-brands fa-youtube"
                                        social-media-name="Youtube"
                                        social-url-prefix="https://www.youtube.com"
                                        :social-media-address="user.socials.youtube"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="flex-1 flex flex-col gap-4">
                            <UserArenaRecordWidget :most-played-games="mostPlayedGames" :user="user"/>
                            <UserAchievementsWidget :user="user" :achievements="achievements"/>
                        </div>
                    </div>
                </template>

                <template v-slot:friends>
                    <div class="text-white bg-blue-800 border border-blue-700 rounded-lg p-4">
                        <h3 class="text-2xl font-semibold">
                            Friends <span class="font-light text-xl text-gray-400">{{ friendsCount }}</span>
                        </h3>

                        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                            <Link
                                v-for="friend in friends" :key="friend.id"
                                :href="route('user.show', {user: friend})"
                            >
                                <FriendCard :friend="friend"/>
                            </Link>
                        </div>
                    </div>
                </template>

                <template v-slot:trophy-case>
                    <div class="text-white bg-blue-800 border border-blue-700 rounded-lg p-4">
                        <h3 class="text-2xl font-semibold mb-4">
                            Trophies <span class="font-light text-xl text-gray-400">{{ trophyCount }}</span>
                        </h3>

                        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                            <EarnedTrophyCard
                                v-for="trophyLog in trophyLogs" :key="trophyLog.id"
                                :trophy-log="trophyLog"
                            />
                        </div>
                    </div>
                </template>

                <template v-slot:achievements>
                    <div class="text-white bg-blue-800 border border-blue-700 rounded-lg p-4">
                        <div class="flex items-center justify-between px-6 py-4 text-sm font-semibold bg-blue-700 ">
                            <h3 class="text-2xl font-semibold mb-4">
                                Achievements <span class="ml-2 text-xl text-gray-500">{{ achievements.length }}</span>
                            </h3>
                        </div>
                        <div v-if="!!achievements && achievements.length > 0" class="grid lg:grid-cols-4 p-4 gap-4">
                            <UserAchievement v-for="achievement in achievements" :key="achievement.id" :achievement="achievement" />
                        </div>

                        <div v-else class="grid py-10 space-y-6 place-items-center">
                            <i class="text-4xl fas fa-trophy text-blue-light"></i>

                            <div class="space-y-3 text-center">
                                <p class="text-xl font-semibold">
                                    No achievements
                                </p>
                                <p class="text-sm text-gray-off">
                                    It looks like <span class="text-gray-300">{{ user.username }}</span> hasn't earned any achievements yet.
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
            </Tabs>
        </div>
    </div>
</template>

<style scoped>

</style>

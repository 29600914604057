<template>
    <div>
        <div class="mb-2 text-lg font-bold text-white">
            <i :class="iconClass" class="mr-1"></i>{{ title }}
        </div>
        <div class="w-full mb-4">
            <div class="mb-6">
                <div class="w-full overflow-x-auto">
                    <div class="text-xs text-white bg-blue-800 border border-blue-700 rounded w-max lg:w-full">
                        <tabs>
                            <tab icon="mr-1" name="Matches" :selected="true">
                                <table class="w-full">
                                    <thead>
                                    <tr class="font-semibold text-gray-600 bg-gray-900 text-xxs">
                                        <th class="w-1/6 px-6 pt-5 pb-2 text-left border-r border-blue-700">Opponent</th>
                                        <th class="w-1/6 px-6 pt-5 pb-2 text-left border-r border-blue-700">State</th>
                                        <th class="w-1/6 px-3 pt-5 pb-2 text-left border-r border-blue-700">Wager Amount</th>
                                        <th class="w-1/6 px-6 pt-5 pb-2 text-left border-r border-blue-700">Format</th>
                                        <th class="w-1/6 px-6 pt-5 pb-2 text-left border-r border-blue-700">Date</th>
                                        <th class="w-1/6 px-6 pt-5 pb-2 text-left border-r border-blue-700">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody class="font-extrabold text-white text-xs">
                                    <template v-for="item in items" :key="item.id">
                                        <tr v-if="item.teamTwo || item.teamOne" class="text-center">
                                            <td class="w-1/6 flex items-center px-2 py-2 py-3 border-r border-blue-700">
                                                <img class="w-8 h-8 mr-3 rounded-full" :src="item.teamTwo ? item.teamTwo.storage_avatar : item.teamOne.storage_avatar" :alt="`${item.teamTwo ? item.teamTwo.name : item.teamOne.name}'s avatar`">
                                                {{ item.teamTwo ? item.teamTwo.name : item.teamOne.name || 'Disbanded' }}
                                            </td>
                                            <td class="w-1/6 px-2 border-r border-blue-700">
                                                <span v-if="item.disputed">Disputed</span>
                                                <span v-else-if="item.state === 'CHALLENGE'">CHALLENGE</span>
                                                <span v-else>In-Progress</span>
                                            </td>
                                            <td class="w-1/6 px-2 border-r border-blue-700">${{ item.wager_amount }}</td>
                                            <td class="w-1/6 px-2 border-r border-blue-700">{{ item.team_size }} v {{ item.team_size }}</td>
                                            <td class="w-1/6 px-2 border-r border-blue-700"><i class="mr-2 text-xs text-white fas fa-clock"></i>{{ formattedDate(item.expiry_date) }}</td>
                                            <td class="w-1/6 px-2 text-blue-500 border-r border-blue-700">
                                                <a v-if="userBelongsToTeam" :href="route('challenge.show', item.id)">View Match</a>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr v-if="items.length === 0" class="text-center">
                                        <td class="flex items-center px-2 py-3" colspan="6">No {{ emptyMessage }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </tab>
                        </tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

const props = defineProps({
    title: String,
    iconClass: String,
    items: Array,
    emptyMessage: String,
    userBelongsToTeam: Boolean
});

const formattedDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString('en-US', options);
};
</script>


export class TeamsApi {
    async get(id) {
        return await axios.get(`/api/teams?teamId=${id}`);
    }

    async create(data) {
        return await axios.post('/api/teams', data);
    }
}

export class WagerChallengesApi {
    async get(id) {
        return await axios.get(`/api/challenge/${id}`);
    }

    async create(data) {
        return await axios.post(`/api/challenge`, data);
    }
}

export class Api {
    static teams() {
        return new TeamsApi();
    }

    static wagerChallenges() {
        return new WagerChallengesApi();
    }
}


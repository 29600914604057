<script setup>

import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import NewsPost from "../../InertiaComponents/News/NewsPost.vue";
import {createExcerpt} from "../../Services/utils.js";
import {computed} from "vue";

const props = defineProps({
    post: {
        type: Object,
        required: true
    },
});

const backgroundImg = computed(() => {
    if (props.post.featured_image) {
        return props.post.featured_image;
    }

    return null;
});

</script>

<template>
    <div>
        <div id="bg" class="-mt-12 game-header-img z-1 bg-cover"
             :style="{'background': `url('${backgroundImg}')`, 'background-size': 'cover', 'background-position': 'center'}"
        >
        </div>

        <div class="container md:max-w-3xl xl:max-w-4xl mx-auto">
            <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'News', link: '/news'},
                {name: createExcerpt(post.title, 5)},
            ]" />

            <NewsPost :post="post" />
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>

import {useSlots} from "vue";

defineProps({
    'label': {
        type: String,
        default: ''
    },
    'hideIfNoContent': {
        type: Boolean,
        default: false
    }
});

const slots = useSlots();

</script>

<template>
    <div class="leading-3 mb-1" v-show="!hideIfNoContent || !slots">
        <div class="font-bold text-gray-400 text-xxs">{{ label }}</div>
        <div class="text-sm font-bold">
            <slot></slot>
        </div>
    </div>
</template>

<style scoped>

</style>

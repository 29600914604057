<template>
    <div id="main" class="text-white px-4 md:px-0 py-4">
        <div class="mx-auto container">
            <div id="breadcrumbs" class="text-xs mb-5">
                Home <span class="ml-1 mr-1 text-gray-600 font-extrabold">></span> User <span class="ml-1 mr-1 text-gray-600 font-extrabold">></span> <span class="ml-1 mr-1 text-gray-600">Settings</span>
            </div>

            <div class="flex flex-wrap justify-between">
                <Navigation />

                <div class="w-full md:w-10/12 px-4 md:px-6 mt-8 md:mt-0 border-l border-gray-800">

                    <h2 class="text-2xl font-bold">Security Settings</h2>
                    <h3 class="font-bold text-sm mb-2">Your Details</h3>
                    <hr class="border-b-1 border-gray-800 py-2">

                    <form @submit.prevent="submit">
                        <div class="grid grid-cols-1 mt-6 gap-x-6 gap-y-6 sm:grid-cols-6">
                            <div class="col-span-full">
                                <label for="paypal_address" class="py-1 text-xs text-gray-600">Paypal Address</label>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <input v-model="form.paypal_address" name="paypal_address" type="email"
                                           placeholder="Paypal Address"
                                           class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500">
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="first_name" class="py-1 text-xs text-gray-600">First name</label>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <input v-model="form.first_name" name="first_name" type="text"
                                           placeholder="First name"
                                           class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500">
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="last_name" class="py-1 text-xs text-gray-600">Last name</label>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <input v-model="form.last_name" name="last_name" type="text"
                                           placeholder="Last name"
                                           class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500">
                                </div>
                            </div>

                            <div class="col-span-full">
                                <label for="phone" class="py-1 text-xs text-gray-600">Phone</label>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <input v-model="form.phone" name="phone" type="text"
                                           placeholder="Phone number"
                                           class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500">
                                </div>
                            </div>

                            <div class="col-span-full">
                                <label for="address" class="py-1 text-xs text-gray-600">Address</label>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <input v-model="form.address" name="address" type="text"
                                           placeholder="Address"
                                           class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500">
                                </div>
                            </div>

                            <div class="sm:col-span-2 sm:col-start-1">
                                <label for="city" class="py-1 text-xs text-gray-600">City</label>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <input v-model="form.city" name="city" type="text"
                                           placeholder="City"
                                           class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500">
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="zipcode" class="py-1 text-xs text-gray-600">Postal code</label>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <input v-model="form.zipcode" name="zipcode" type="text"
                                           placeholder="Postal code"
                                           class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500">
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="country_code" class="py-1 text-xs text-gray-600">Country</label>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <select v-model="form.country_code" name="country_code"
                                            class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                                        <option v-for="(country, countryCode) in countries" :key="countryCode" :value="countryCode">
                                            {{ country }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-span-full">
                                <label for="currency" class="py-1 text-xs text-gray-600">Currency</label>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <select v-model="form.currency" name="currency"
                                            class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                                        <option v-for="currency in currencies" :key="currency" :value="currency">
                                            {{ currency }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="sm:col-span-2">
                                <label for="state_code" class="py-1 text-xs text-gray-600">State</label>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <select v-model="form.state_code" name="state_code"
                                            class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                                        <option v-for="(state, stateCode) in states" :key="stateCode" :value="stateCode">
                                            {{ props.user.state_code === stateCode ? 'selected' : '' }}
                                            {{ state }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-span-full">
                                <button type="submit"
                                        class="btn-gradient-blue px-16 py-3 rounded uppercase text-xxs float-left font-semibold text-right inline-block">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import {usePage} from '@inertiajs/vue3';
import Navigation from '../../../components/SettingsNav.vue';


const {props} = usePage();

const form = useForm({
    paypal_address: props.user.paypal_address || '',
    first_name: props.user.first_name || '',
    last_name: props.user.last_name || '',
    phone: props.user.phone || '',
    address: props.user.address || '',
    city: props.user.city || '',
    zipcode: props.user.zipcode || '',
    country_code: props.user.country_code || '',
    currency: props.user.currency || '',
    state_code: props.user.state_code || '',
});

const countries = props.countries;
const states = props.states;
const currencies = props.currencies;

const successMessage = ref('');
const errorMessage = ref('');

const submit = () => {
    form.post(route('settings.security.update'), {
        onSuccess: () => {
            successMessage.value = 'Successfully updated your security settings';
            errorMessage.value = '';
        },
        onError: (err) => {
            errorMessage.value = 'Failed to update security settings';
            successMessage.value = '';
        }
    });
};

const currencyLabel = (currency) => {
    return `${currency.name} - ${currency.description}`;
};
</script>

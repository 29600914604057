<script setup>

import {Link, useForm, usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import {formatDistanceToNow} from "date-fns";
import Tabs from "../../../components/partials/Tabs.vue";
import Tab from "../../../components/partials/Tab.vue";
import TournamentRegisterButton from "../../../components/TournamentRegisterButton.vue";
import TournamentBracket from "../../../InertiaComponents/Tournament/TournamentBracket.vue";
import Breadcrumbs from "../../../InertiaComponents/Navigation/Breadcrumbs.vue";
import Header from "../../../InertiaComponents/Clubs/Header.vue";

const page = usePage();
const user = computed(() => page.props.auth.user);
const tournament = computed(() => page.props.tournament);
const enteredTeam = computed(() => page.props.enteredTeam);
const teams = computed(() => page.props.teams);
const userTeams = computed(() => page.props.userTeams);
const club = computed(() => page.props.club);

const backgroundImg = computed(() => {
    const tournament = page.props.tournament;
    if (!!tournament.storage_custom_tournament_banner_url) {
        return tournament.storage_custom_tournament_banner_url;
    }

    if (!!tournament.game) {
        return tournament.game.storage_header_url;
    }

    return null;
});

const leaveTournamentForm = useForm({
    team_id: enteredTeam?.value?.id
});

const leaveTournamentPressed = () => {
    if (!enteredTeam.value) {
        console.error("No user teams found - cannot leave tournament");
        return;
    }

    axios.post('/api/tournaments/' + tournament.value.slug + '/leave', leaveTournamentForm, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((resp) => {
        window.location.href = "/tournaments/" + tournament.value.slug;
    }).catch((err) => {
        leaveTournamentForm.errors = err.response.data.errors;
    });
}

</script>

<template>
    <div class="w-full -mt-12">
        <Header/>
        <div id="bg" class="-mt-12 game-header-img z-1" :style="{
                background:
                    'url(\'' + tournament.game.storage_header_url + '\')'
            }"></div>
        <div id="main" class="relative px-4 py-20 text-white lg:px-0">
            <div class="container mx-auto">
                <div class="flex gap-2 justify-end">
                    <a href="/store#membership"
                       v-if="tournament.elite_only"
                       class="inline-flex items-center float-right gap-2 px-8 py-3 mt-3 mb-4 text-xs font-semibold text-center uppercase rounded-full btn-gradient-blue">
                        <span>Buy Elite</span>
                    </a>
                </div>

                <Breadcrumbs :breadcrumbs="[
                    {name: 'Home', link: '/'},
                    {name: 'Tournaments', link: route('club-tournaments.index', club.slug)},
                    {name: tournament.name}
                ]" classes="mb-2"/>

                <div class="flex flex-col items-center justify-between w-full mx-2 sm:flex-row sm:mx-0">
                    <div class="flex lg:w-3/4">
                        <div
                            class="flex items-center self-center flex-shrink-0 hidden px-2 py-2 mr-2 bg-blue-900 rounded-full sm:block">
                            <img :src="tournament.game.storage_icon_url" class="w-8 h-8" alt="">
                        </div>
                        <div class="flex flex-col items-center gap-4 text-4xl font-extrabold">
                            <div class="inline-block">
                                {{ tournament.name }}

                            </div>
                            <p
                                class="leading-normal lg:hidden self-start px-3 py-1 rounded-lg text-white uppercase font-semibold text-xxs text-center"
                                :class="tournament.platform.color"
                            >
                                <i
                                    class="text-white font-medium mr-1"
                                    :class="tournament.platform.icon"
                                >
                                </i>
                                {{ tournament.platform.name }}
                            </p>
                        </div>
                    </div>

                    <div v-if="!!user">
                        <div class="mb-1 text-center">
                            <span
                                class="text-xs font-bold text-white"
                                v-if="new Date(tournament.tournament_starts).getTime() > new Date().getTime()"
                            >
                                Begins In:
                                <span class="text-blue-500">
                                        {{ formatDistanceToNow(tournament.tournament_starts) }}
                                </span>
                            </span>
                        </div>

                        <div v-if="!tournament.bracket_generated">
                            <div v-if="!enteredTeam">
                                <div v-if="new Date(tournament.registration_opens).getTime() < new Date().getTime()">
                                    <TournamentRegisterButton :price="tournament.entry_fee"
                                                              :teams="userTeams"
                                                              :tournament="tournament"/>
                                </div>
                                <div v-else>
                                    <span class="text-xs font-bold text-white">Registration opens in:
                                        <span class="text-blue-500">
                                            {{ formatDistanceToNow(tournament.registration_opens) }}
                                        </span>
                                    </span>
                                </div>

                            </div>
                            <div v-else>
                                <div
                                    v-if="user.id === enteredTeam.owner_id && new Date(tournament.tournament_starts).getTime() > new Date().getTime() && !tournament.bracket_generated">
                                    <form @submit.prevent="leaveTournamentPressed" method="POST">
                                        <input type="hidden" name="team_id" :value="enteredTeam.id">
                                        <button type="submit"
                                                class="relative inline-flex items-center self-end px-6 py-3 text-xs font-semibold bg-blue-500 rounded-lg btn-gradient-blue">
                                            Leave Tournament
                                        </button>
                                    </form>
                                </div>
                                <div v-else>
                                    <a
                                        class="relative inline-flex items-center self-end px-6 py-3 text-xs font-semibold bg-blue-500 rounded-lg btn-gradient-blue"
                                        :href="route('teams.show', enteredTeam.id)">
                                        <i class="mr-2 fas fa-eye"></i>
                                        View Team
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex flex-col py-4 mt-5 bg-blue-800 border border-gray-800 sm:flex-row"
                :class="{ 'mb-5': tournament.warning_text, 'mb-10': !tournament.warning_text }"
            >

                <div class="container flex flex-col justify-between mx-auto sm:flex-row">
                    <div class="flex flex-col sm:flex-row">
                        <Link
                            :href="route('game.platform.show', [tournament.platform.slug, tournament.game.slug])"
                            id="stat-box"
                            class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i
                                class="text-white text-xl rounded-lg mr-4 fas fa-gamepad"
                            ></i>
                            <div>
                                <div class="text-sm font-bold">{{ tournament.game.name }}</div>
                                <div class="text-xs text-gray-400">Game</div>
                            </div>
                        </Link>

                        <div id="stat-box"
                             class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i
                                class="text-white text-xl px-1 py-1 rounded-lg mr-4"
                                :class="`${tournament.platform.icon} ${tournament.platform.color}`"
                            ></i>
                            <div>
                                <div class="text-sm font-bold">{{ tournament.platform.name }}</div>
                                <div class="text-xs text-gray-400">Platform</div>
                            </div>
                        </div>

                        <div id="stat-box"
                             class="flex items-center inline-block px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full ">
                            <i class="mr-4 text-purple-600 fa-solid fa-trophy text-xl"></i>
                            <div>
                                <div class="text-sm font-bold">
                                    <span class="font-medium mr-0.5">$</span>{{
                                        (Math.round(tournament.total_prize_pool * 100) / 100).toFixed(2)
                                    }}
                                </div>
                                <div class="text-xs text-gray-400">Prize Awarded</div>
                            </div>
                        </div>
                        <div id="stat-box"
                             class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i class="mr-4 text-blue-500 fas fa-clock text-xl"></i>
                            <div>
                                <div class="text-sm font-bold">
                                    {{ tournament.user_based_tournament_starts }}
                                </div>
                                <div class="text-xs text-gray-400">Tournament Start</div>
                            </div>
                        </div>
                        <div id="stat-box"
                             class="flex items-center px-6 py-2 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i class="mr-4 text-green-bright fas fa-calendar-check text-xl"></i>
                            <div>
                                <div class="text-sm font-bold">
                                    {{ tournament.user_based_registration_closes }}
                                </div>
                                <div class="text-xs text-gray-400">Registration Closes</div>
                            </div>
                        </div>
                        <div id="stat-box"
                             class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i class="mr-4 text-orange-500 fas fa-globe text-xl"></i>
                            <div>
                                <div class="text-sm font-bold">{{ tournament.region.name }}</div>
                                <div class="text-xs text-gray-400">Hosting Region</div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center mt-4 sm:mt-0">
                        <div class="mr-4">
                            <div class="text-xs font-bold">Teams Registered</div>
                            <div class="text-2xl font-semibold text-center flex justify-center gap-0.5">
                                <span class="text-blue-500">
                                    {{ tournament.total_registered_teams }}
                                </span>
                                <span class="text-blue-500">
                                    /
                                </span>
                                <span>
                                    {{ tournament.bracket_size }}
                                </span>
                            </div>
                        </div>
                        <img
                            v-for="team in tournament.teams.slice(0, 5)"
                            :key="team.id"
                            class="w-12 h-12 -mr-4 border-4 border-blue-700 rounded-full"
                            :src="team.storage_avatar" alt="">

                        <div
                            v-if="tournament.teams.length > 5"
                            class="flex items-center justify-center w-12 h-12 -mr-4 text-xs font-semibold border-4 border-blue-700 rounded-full btn-gradient-blue">
                            +{{ tournament.teams.length - 5 }}
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="tournament.warning_text"
                class="container mx-auto mb-5"
            >
                <div
                    class="flex flex-row px-4 py-3 my-2 text-base bg-red-500 border-t-4 border-red-900 rounded-b shadow-md">
                    <svg class="w-6 h-6 mr-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path
                            d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                    </svg>
                    <div>
                        <p class="font-bold">Warning!</p>
                        <ul>
                            <li class="ml-5 text-sm list-disc">
                                {{ tournament.warning_text }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="container mx-auto mt-5 mb-1">
                <div class="flex flex-wrap -mx-4">
                    <div class="w-full px-4">
                        <div
                            v-if="tournament.finished"
                            class="px-4 py-3 my-2 bg-blue-500 border-t-4 border-gray-900 rounded-b shadow-md text-teal-darkest"
                            role="alert">
                            <div class="flex">
                                <svg class="w-6 h-6 mr-4 text-white" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                                </svg>
                                <div>
                                    <p class="text-sm font-bold">Information</p>
                                    <p class="text-xs">This tournament has finished and has been archived.</p>
                                </div>
                            </div>
                        </div>
                        <div class="text-white bg-blue-600 border border-gray-800 rounded-lg">

                            <div class="items-center justify-between text-sm ">
                                <div class="flex w-full px-6">

                                    <Tabs>
                                        <Tab icon="fas fa-info-circle mr-1 " name="Info" :selected="true">
                                            <div id="main" class="flex w-full px-0 py-4">
                                                <div class="flex flex-wrap w-full">
                                                    <div
                                                        class="flex flex-col items-center justify-center w-full py-8 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12">
                                                        <div class="-mb-8 text-4xl font-bold">1st</div>
                                                        <img src="/images/trophies/gold-trophy.svg"
                                                             class="w-32 h-32">
                                                        <div class="-mt-8 text-xl font-bold text-gray-400">
                                                            ${{ tournament.prize_amount }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="flex flex-col items-center justify-center w-full py-8 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12">
                                                        <div class="-mb-8 text-4xl font-bold">2nd</div>
                                                        <img src="/images/trophies/silver-trophy.svg"
                                                             class="w-32 h-32">
                                                        <div
                                                            v-if="tournament.second_place"
                                                            class="-mt-8 text-xl font-bold text-gray-500"
                                                        >
                                                            ${{ tournament.second_place }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="flex flex-col items-center justify-center w-full py-8 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12">
                                                        <div class="-mb-8 text-4xl font-bold">3rd</div>
                                                        <img src="/images/trophies/bronze-trophy.svg"
                                                             class="w-32 h-32">
                                                        <div
                                                            v-if="tournament.third_place"
                                                            class="-mt-8 text-xl font-bold text-gray-500"
                                                        >
                                                            ${{ tournament.third_place }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500"><i
                                                            class="text-gray-500 fas fa-globe-europe"></i></div>
                                                        <div class="text-xxs">Hosting Region</div>
                                                        <div class="text-lg">{{ tournament.region.name }}</div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500"><i
                                                            class="text-gray-500 fas fa-gamepad"></i></div>
                                                        <div class="text-xxs">Game</div>
                                                        <a class="text-lg"
                                                           :href="route('game.platform.show', [tournament.platform.slug, tournament.game.slug])">
                                                            {{ tournament.game ? tournament.game.name : '' }}
                                                        </a>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500"><i
                                                            class="text-gray-500 fas fa-coins"></i></div>
                                                        <div class="text-xxs">Prize Pool</div>
                                                        <div class="text-lg">${{ tournament.total_prize_pool }}</div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-500"><i
                                                            class="text-gray-500 fas fa-stopwatch"></i></div>
                                                        <div class="text-xxs">Tournament Start</div>
                                                        <div class="text-lg">
                                                            {{ tournament.user_based_tournament_starts }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-400 -ml-3">
                                                            <i class="text-gray-400 fas fa-user-ninja"></i>
                                                            <span class="ml-2 text-sm">Format</span>
                                                        </div>
                                                        <div class="text-xl ml-3">{{ tournament.format }} v
                                                            {{ tournament.format }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-400 -ml-3">
                                                            <i class="text-gray-400 fas fa-coins"></i>
                                                            <span class="ml-2 text-sm">Entry Fee</span>
                                                        </div>
                                                        <div class="text-xl ml-3">{{ tournament.entry_fee }} Credits
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-400">
                                                            <i class="text-gray-400 fas fa-hand-rock"></i>
                                                            <span class="ml-2 text-sm">Best Of</span>
                                                        </div>
                                                        <div class="text-xl">{{ tournament.best_of }}</div>
                                                    </div>
                                                    <div
                                                        class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                        <div class="pt-1 text-xl text-gray-400">
                                                            <i
                                                                class="text-gray-400"
                                                                :class="tournament.platform.icon"
                                                            ></i>
                                                            <span class="ml-2 text-sm">Platform</span>
                                                        </div>
                                                        <div class="text-xl">{{ tournament.platform.name }}</div>
                                                    </div>
                                                    <template v-if="tournament.game.crossplay_options">
                                                        <div
                                                            class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                            <div class="pt-1 text-xl text-gray-500"><i
                                                                class="text-gray-500 fa-solid fa-shuffle"></i></div>
                                                            <div class="text-xxs">PC Players</div>
                                                            <div class="text-lg">
                                                                {{ tournament.pc_players ? 'Allowed' : 'Disallowed' }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                            <div class="pt-1 text-xl text-gray-500"><i
                                                                class="text-gray-500 fa-solid fa-shuffle"></i></div>
                                                            <div class="text-xxs">Input Type</div>
                                                            <div class="text-lg">{{ tournament.input_option }}</div>
                                                        </div>
                                                        <div
                                                            class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                            <div class="pt-1 text-xl text-gray-500"><i
                                                                class="text-gray-500 fa-solid fa-shuffle"></i></div>
                                                            <div class="text-xxs">PC Stream Required</div>
                                                            <div class="text-lg">
                                                                {{ tournament.pc_stream_required ? 'Yes' : 'No' }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12">
                                                            <div class="pt-1 text-xl text-gray-500"><i
                                                                class="text-gray-500 fa-solid fa-shuffle"></i></div>
                                                            <div class="text-xxs">Monitorcam Required</div>
                                                            <div class="text-lg">
                                                                {{ tournament.moss_required ? 'Yes' : 'No' }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab icon="fas fa-headset mr-1" name="Brackets">
                                            <div id="main" class="px-0 py-4">
                                                <div
                                                    v-if="tournament.bracket_generated && !tournament.bracket_hidden"
                                                    id="bracket"
                                                >
                                                    <TournamentBracket :tournament="tournament"/>
                                                </div>
                                                <div
                                                    v-else
                                                    class="flex flex-col items-center justify-center h-56"
                                                >
                                                    <div class="mb-4 text-2xl font-bold text-center"><i
                                                        class="text-3xl text-blue-500 fas fa-hourglass-half spin-medium"
                                                        style="font-size:3.4rem;"></i></div>
                                                    <div class="text-2xl font-bold text-center">
                                                        Pending Bracket Generation
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab icon="fas fa-users mr-1" name="Teams">
                                            <div id="main" class="px-0 py-4">
                                                <div id="participants" class="flex flex-wrap w-full">
                                                    <div
                                                        v-if="tournament.bracket_generated && !tournament.bracket_hidden"
                                                        v-for="team in teams"
                                                    >
                                                        <a
                                                            :key="team.id"
                                                            :href="route('teams.show', team.id)"
                                                            v-if="team.name !== 'BYE'"
                                                            class="w-full px-2 mb-3 sm:w-3/12"
                                                        >
                                                            <div
                                                                class="flex px-4 py-8 text-white bg-blue-800 border border-blue-700 rounded">
                                                                <div>
                                                                    <img :src="team.storage_avatar"
                                                                         :alt="team.name"
                                                                         class="w-12 h-12 mr-6 border-2 border-blue-900 rounded-lg">
                                                                </div>
                                                                <div>
                                                                    <div class="font-bold text-white font-sm">
                                                                        {{ team.name }}
                                                                    </div>
                                                                    <div class="text-gray-600 text-xxs">Created:
                                                                        {{ team.created_at }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div v-else>
                                                        <a
                                                            v-if="enteredTeam"
                                                            :href="route('teams.show', enteredTeam.id)"
                                                            class="w-3/12 px-2 mb-3"
                                                        >
                                                            <div
                                                                class="flex px-4 py-8 text-white bg-blue-800 border border-blue-700 rounded">
                                                                <div>
                                                                    <img :src="enteredTeam.storage_avatar"
                                                                         :alt="enteredTeam.name"
                                                                         class="w-12 h-12 mr-6 border-2 border-blue-900 rounded-lg">
                                                                </div>
                                                                <div>
                                                                    <div class="font-bold text-white font-sm">
                                                                        {{ enteredTeam.name }}
                                                                    </div>
                                                                    <div class="text-gray-600 text-xxs">Created:
                                                                        {{ enteredTeam.created_at }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <span
                                                            v-else
                                                            class="font-semibold"
                                                        >
                                                            Teams are not visible until the bracket has been generated.
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab icon="fas fa-clipboard-list mr-1" name="Rules">
                                            <div id="main" class="px-0 py-4">
                                                <div
                                                    v-if="tournament.ruleset && tournament.ruleset.content"
                                                    id="rules"
                                                    class="text-md"
                                                    v-html="tournament.ruleset.content"
                                                >
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

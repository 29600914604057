<script setup>

import TitledContainer from "../UI/TitledContainer.vue";
import {useForm} from "@inertiajs/vue3";

const props = defineProps({
    friendsCount: {
        type: Number,
        required: true
    },
    isFriendsWith: {
        type: Boolean,
        required: true
    },
    user: {
        type: Object,
        required: true
    },
    isYou: {
        type: Boolean,
        required: true
    },
    'hasRequestedAsFriend': {
        type: Boolean,
        required: true
    }
});

const form = useForm({

});

function deleteFriend() {
    form.post(route('friend.delete', {'user': props.user.slug}));
}

function addFriend() {
    form.post(route('friendrequest.create', {'user': props.user.slug}));
}

</script>

<template>
    <div class="flex flex-col gap-y-4 md:items-center justify-between md:gap-x-5">
        <TitledContainer title="Friends & Followers" class="flex flex-col">
            <p class="text-xl font-bold text-center text-blue">
                {{ friendsCount }}
            </p>
        </TitledContainer>
        <div v-if="isYou">
            <a :href="route('user.settings')">
                <div
                    class="flex items-center justify-center px-6 py-4 text-base font-bold rounded-lg gap-x-2 btn-gradient-blue sm:mt-0">
                    <i class="text-base fas fa-cog"></i>Edit profile
                </div>
            </a>
        </div>
        <form
            v-else-if="isFriendsWith"
            @submit.prevent="deleteFriend"
        >
            <button type="submit"
                    class="justify-center px-6 py-4 mt-4 mr-4 text-xs font-bold text-white bg-red-500 border border-gray-700 rounded-lg sm:mt-0">
                <i class="mr-4 text-sm text-white fas fa-user-friends"></i>Delete friend
            </button>
        </form>
        <button
            v-else-if="hasRequestedAsFriend"
            type="submit"
            class="flex items-center justify-center px-6 py-4 text-xs font-bold text-white uppercase bg-blue-500 border border-gray-700 rounded-lg gap-x-2 sm:mt-0"
        >
            <i class="text-base text-white fas fa-users"></i>Friend Request Sent
        </button>
        <form v-else @submit.prevent="addFriend">
            <button type="submit"
                    class="flex items-center justify-center px-6 py-4 text-xs font-bold text-white uppercase bg-blue-500 border border-gray-700 rounded-lg gap-x-2 sm:mt-0">
                <i class="text-base text-white fas fa-users"></i>Add friend
            </button>
        </form>

<!--        @if (auth()->user()->id !== $user->id)-->
<!--        {{&#45;&#45;                <div class="px-6 py-4 text-xs font-medium font-semibold border border-gray-700 rounded-lg justify-centermr-4"> &#45;&#45;}}-->
<!--        {{&#45;&#45;                    <i class="mr-4 text-sm text-blue-500 fas fa-envelope"></i>Message &#45;&#45;}}-->
<!--        {{&#45;&#45;                </div> &#45;&#45;}}-->
<!--        {{&#45;&#45;                <div class="px-6 py-4 font-medium font-semibold rounded-lg justify-centertext-xs btn-gradient-blue"> &#45;&#45;}}-->
<!--        {{&#45;&#45;                    <i class="mr-4 text-sm fas fa-fist-raised"></i>Challenge &#45;&#45;}}-->
<!--        {{&#45;&#45;                </div> &#45;&#45;}}-->
<!--        @else-->

<!--        @endif-->
    </div>

</template>

<style scoped>

</style>

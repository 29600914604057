<template>
    <div class="container mb-4">
        <div class="row">
            <div class="col">
                <div class="bread">
                    Home <span class="text-grey bread-divider">></span>
                    Teams <span class="text-grey bread-divider">></span>
                    <span class="text-grey"> Team Finder</span>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-between">
            <h2 class="campton-bold d-inline-block">Team Finder</h2>
            <div>
                <button @click="showModal" class="btn btn-primary border-0 campton-bold px-4 text-sm" style="padding-top: 6px;">
                    <i aria-hidden="true" class="fas fa-search mr-2"></i>Join Team Finder
                </button>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-3">
                <h4>Filters</h4>
                <div class="form-group">
                    <label for="gameFilter">Games</label>
                    <select id="gameFilter" v-model="filters.game" class="form-control">
                        <option>All Games</option>
                        <option>CSGO</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="countryFilter">Country</label>
                    <select id="countryFilter" v-model="filters.country" class="form-control">
                        <option>England</option>
                        <option>Fucked</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </select>
                </div>
            </div>
            <div class="col-md-9">
                <div v-for="request in filteredRequests" :key="request.id" class="w-100 mb-3 request-card">
                    <div class="d-flex w-100">
                        <div class="flex-1 w-100">
                            <div class="px-3 py-3">
                                <div class="flex align-items-center">
                                    <div class="flex">
                                        <div class="flex justify-content-start">
                                            <div class="flex-1">
                                                <div>{{ request.user.username }}</div>
                                                <span class="text-grey">
                                                    <div>
                                                        <span style="font-size:11px;">Created <span>{{ request.created_at }}</span></span><span> · </span><span>{{ request.user.xp }} XP</span>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 py-3 border-top">
                                <div class="flex align-items-center">
                                    <div class="flex-1">
                                        <div class="flex justify-content-start">
                                            <div class="flex-1">
                                                <div>{{ request.message }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <modal name="new-teamfinder" height="auto">
            <div class="modal-header">
                <div class="col">
                    Find A Team
                    <div class="text-grey text-sm open-sans">Fill in your details to find a team</div>
                </div>
            </div>
            <div class="modal-body">
                <form @submit.prevent="submitForm">
                    <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="game">Game</label>
                                <select v-model="form.game_id" class="form-control" id="game" name="game_id">
                                    <option value="1">CSGO</option>
                                    <option value="2">League Of Legends</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="language">Communication Language</label>
                                <select v-model="form.language" class="form-control" id="language" name="language">
                                    <option>English</option>
                                    <option>Turkish</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="message">Comment</label>
                                <textarea v-model="form.message" class="form-control" id="message" name="message" rows="3" placeholder="Enter a comment here describing the sort of team you're looking for"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button type="submit" class="btn btn-primary w-100 open-sans fw-800 text-xs border-0">
                                CREATE REQUEST
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </modal>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { usePage, useForm } from '@inertiajs/vue3';

const { props } = usePage();
const teamFindRequests = ref([...props.teamFindRequests]);
const filters = ref({ game: 'All Games', country: 'England' });

const form = useForm({
    game_id: '',
    language: '',
    message: ''
});

const showModal = () => {
    this.$modal.show('new-teamfinder');
};

const submitForm = () => {
    form.post(route('teamfinder.store'), {
        onSuccess: () => {
            this.$modal.hide('new-teamfinder');
        },
    });
};

const filteredRequests = computed(() => {
    return teamFindRequests.value.filter(request => {
        return (filters.value.game === 'All Games' || request.game_name === filters.value.game) &&
            (filters.value.country === 'All Countries' || request.country === filters.value.country);
    });
});
</script>

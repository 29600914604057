<script setup>

import Header from "../InertiaComponents/Navigation/Header.vue";
import Footer from "../InertiaComponents/Navigation/Footer.vue";
import Banner from "../InertiaComponents/Alert/Banner.vue";
import {ModalsContainer} from "vue-final-modal";
import Toast from "primevue/toast";
import ActiveMatchBanner from "../InertiaComponents/Navigation/ActiveMatchBanner.vue";
import {computed} from "vue";
import {Head, usePage} from "@inertiajs/vue3";

const page = usePage();

const seoSettings = computed(() => {
    return {
        title: page.props.seo?.settings?.title,
        description: page.props.seo?.settings?.description,
        overallHtml: page.props.seo?.settings?.overallHtml
    }
})

</script>

<template>
    <div class="w-full min-h-screen m-0 bg-gradient-blue-black font-test flex flex-col">
        <Head>
<!--            <title>{{ seoSettings.title }}</title>-->
<!--            <meta head-key="description" name="description" :content="seoSettings.description" />-->
            <template v-html="seoSettings.overallHtml">

            </template>
        </Head>

        <ActiveMatchBanner />

        <div>
            <Header/>
            <Banner/>
        </div>

        <main class="relative flex-1 py-12">
            <transition name="fade" mode="out-in">
                <slot></slot>
            </transition>
        </main>

        <ModalsContainer />

        <Footer/>

        <Toast position="bottom-right" />
    </div>
</template>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease, transform 0.5s ease;
    }

    .fade-enter-from, .fade-leave-to {
        opacity: 0;
        transform: translateY(-20px);
    }
</style>

<script setup>

import { defineProps, computed } from 'vue';

const props = defineProps({
    'title': {
        type: String,
        required: true
    },
    'side': {
        type: String,
        required: false,
        default: 'left'
    },
    'classes': {
        type: String,
        required: false,
        default: ''
    }
});

const textSideClass = computed(() => {
    if (props.side === 'left') {
        return 'text-left';
    }

    if (props.side === 'right') {
        return 'text-right';
    }

    return '';
});

</script>

<template>
    <div :class="`p-4 mt-2 border-2 border-blue-600 rounded-lg ${classes}`">
        <h3 :class="`text-sm text-gray-300 -mt-7 mb-2 ${textSideClass}`">
            {{ title }}
        </h3>

        <slot></slot>
    </div>
</template>

<style scoped>

</style>

<script setup>

import {computed, ref} from "vue";
import {usePage} from "@inertiajs/vue3";
import Tooltip from "../UI/Tooltip.vue";
import {formatCurrency} from "../../Services/utils.js";

const page = usePage();
const user = computed(() => page.props.auth?.user);

const showTooltip = ref(false);

const withdrawableAmountTooltipContent = computed(() => {
    return `$${user.value.withdrawable_balance} can be withdrawn.`
})

</script>

<template>
    <div class="hidden sm:inline-flex whitespace-nowrap relative sm:items-center sm:gap-2" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
        <i class="text-sm lg:text-xl text-gray-400 fas fa-dollar-sign"></i> <span class="font-bold text-white text-sm lg:text-md xl:text-lg">{{ formatCurrency(user.balance) }}</span>
        <Tooltip :show="showTooltip" title="Your Funds" :content="withdrawableAmountTooltipContent" />
    </div>
</template>

<style scoped>

</style>

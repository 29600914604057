<script setup>

import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import PageTitle from "../../InertiaComponents/UI/PageTitle.vue";
import {Link, usePage} from "@inertiajs/vue3";
import TeamLeaderboard from "../../InertiaComponents/Leaderboard/TeamLeaderboard.vue";
import UiButton from "../../InertiaComponents/UI/UiButton.vue";

const page = usePage();

const props = defineProps({
    teams: {
        type: Object,
        required: true
    },
    username: {
        type: String,
        required: true
    },
    activeFilter: {
        type: String,
        required: true,
    },
    activePeriodFilter: {
        type: String,
        required: true,
    },
    activeGameFilter: {
        type: String,
        required: true,
    },
    leaderboard: {
        type: Object,
        required: true,
    },
    period: {
        type: Object,
        required: true,
    },
    availableLeaderboards: {
        type: Array,
        required: true,
    },
    game: {
        type: Object,
        required: false,
    },
    games: {
        type: Array,
        required: true,
    },
    yourTeamRank: {
        type: Object,
        required: false,
    },
    activeTeamFilter: {
        type: String,
        required: true,
    },
    ranksForUserTeams: {
        type: Array,
        required: true,
    },
});

</script>

<template>
    <div>
        <div class="container mx-auto text-white">
            <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Leaderboards', link: '/leaderboard'},
                {name: 'Team Leaderboards'}
            ]"/>

            <PageTitle title="Team Leaderboards"/>

            <div class="mt-4 mb-6 flex gap-2">
                <UiButton :href="route('leaderboard.index')" ghost pulse>
                    <i aria-hidden="true" class="text-sm fas fa-users"></i> User Leaderboards
                </UiButton>

                <UiButton>
                    <i aria-hidden="true" class="text-sm fas fa-users"></i> Team Leaderboards
                </UiButton>
            </div>

            <TeamLeaderboard
                :teams="teams"
                :leaderboard="leaderboard"
                :auth-user="page.props.authUser"
                :active-filter="activeFilter"
                :ranks-for-user-teams="ranksForUserTeams"
                :active-game-filter="activeGameFilter"
                :active-team-filter="activeTeamFilter"
                :active-period-filter="activePeriodFilter"
                :period="period"
                :game="game"
                :games="games"
                :your-team-rank="yourTeamRank"
                :available-leaderboards="availableLeaderboards"
                :username="username"
            />
        </div>
    </div>
</template>

<style scoped>

</style>

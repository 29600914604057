<script setup>

import {Link} from "@inertiajs/vue3";

const props = defineProps({
    team: {
        type: Object,
        required: true
    },
    teamActivityLog: {
        type: Array,
        required: true
    },
})

const matchLogOpponent = (log) => {
    if (log.team_one_id !== props.team.id) {
        return log.team_one;
    }

    if (log.team_two_id !== props.team.id) {
        return log.team_two;
    }

    return null;
};

const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const options = {year: 'numeric', month: 'long', day: 'numeric'};
    return date.toLocaleDateString('en-US', options);
};

</script>

<template>
    <table class="w-full">
        <thead>
        <tr class="font-semibold text-gray-600 bg-gray-900 text-xxs">
            <th class="px-6 pt-5 pb-2 text-left border-r border-blue-700">
                Opponent
            </th>
            <th class="px-6 pt-5 pb-2 text-left border-r border-blue-700">
                State
            </th>
            <th class="px-3 pt-5 pb-2 text-left border-r border-blue-700">XP
                Earned
            </th>
            <th class="px-6 pt-5 pb-2 text-left border-r border-blue-700">
                Winnings
            </th>
            <th class="px-3 pt-5 pb-2 text-left border-r border-blue-700">
                Outcome
            </th>
            <th class="px-6 pt-5 pb-2 text-left border-r border-blue-700">
                Date
            </th>
            <th class="px-6 pt-5 pb-2 text-left border-r border-blue-700">
                Action
            </th>
        </tr>
        </thead>
        <tbody class="font-extrabold text-white text-xs">
        <tr v-for="log in teamActivityLog" :key="log.id"
            class="text-center">
            <td class="flex items-center px-2 py-3 border-r border-blue-700">
                <Link :href="route('teams.show', matchLogOpponent(log).id)">
                    <img class="w-8 h-8 mr-3 rounded-full inline-block"
                         :src="matchLogOpponent(log).storage_avatar"
                         :alt="`${matchLogOpponent(log).name}'s avatar`">
                    <span>{{ matchLogOpponent(log).name || 'Disbanded' }}</span>
                </Link>
            </td>
            <td class="px-2 border-r border-blue-700 capitalize">
                {{ log.match_state }}
            </td>
            <td class="px-2 border-r border-blue-700">
                {{ log.winning_team_id === team.id ? log.xp_earned : 0 }}
            </td>
            <td class="px-2 border-r border-blue-700">
                ${{ log.winning_team_id === team.id ? log.cash_earned : '0.00' }}
            </td>
            <td class="px-2 text-gray-600 border-r border-blue-700">
                <span
                    :class="{'text-green-500': log.winning_team_id === team.id, 'text-red-500': log.winning_team_id !== team.id}">
                    {{ log.winning_team_id === team.id ? 'Win' : 'Loss' }}
                </span>
            </td>
            <td class="px-2 border-r border-blue-700">
                <i class="mr-2 text-xs text-white fas fa-clock"></i>
                {{ formattedDate(log.match_date) }}
            </td>
            <td class="px-2 text-blue-500 border-r border-blue-700">
                <a v-if="log.match_type === 'wager'"
                   :href="route('challenge.show', log.match_id)">
                    View Match
                </a>
            </td>
        </tr>
        <tr v-if="teamActivityLog.length === 0" class="text-center">
            <td class="flex items-center px-2 py-3">Team has no recent
                matches
            </td>
            <td class="px-2"></td>
            <td class="px-2"></td>
            <td class="px-2"></td>
            <td class="px-2 text-gray-600"></td>
            <td class="px-2"></td>
        </tr>
        </tbody>
    </table>
</template>

<style scoped>

</style>

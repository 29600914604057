<script setup>

import {Link} from "@inertiajs/vue3";
import {format} from "date-fns/format";
import {defineProps, computed} from "vue";

const props = defineProps({
    newsPost: {
        type: Object,
        required: true
    },
    game: {
        type: Object,
        default: null
    }
});

const featuredImage = computed(() => {
    if (!!props.newsPost.featured_image) {
        return props.newsPost.featured_image;
    }

    if (!!props.game) {
        return props.game.storage_image_url;
    }

    return '/images/eshub_news.jpeg';
});

const featuredImageCaption = computed(() => {
    if (!!props.newsPost.featured_image_caption) {
        return props.newsPost.featured_image_caption;
    }

    if (!!props.game) {
        return `Icon for game ${props.game.name}`;
    }

    return `Image for article (${props.newsPost.title})`;
});

const imageObjectFit = computed(() => {
    if (!!props.newsPost.featured_image_caption || !!props.game) {
        return 'object-cover';
    }

    return 'object-cover';
});

</script>

<template>
    <Link :href="route('news-posts.show', {slug: newsPost.slug})" class="flex transform transition hover:scale-105 group">
        <div class="bg-blue-600 rounded-lg shadow-xl flex-1 flex flex-col justify-between text-white">
            <div>
                <div class="relative mb-2 overflow-hidden rounded-lg">
                    <img
                        v-if="featuredImage"
                        class="rounded-lg w-full object-center lg:h-52 transform transition group-hover:scale-110"
                        :class="imageObjectFit"
                        :src="featuredImage"
                        :alt="featuredImageCaption"
                    >
                    <div v-if="!!game" class="absolute top-0 right-0">
                        <div class="flex flex-col gap-2 items-center justify-center">
                            <img :src="game.storage_icon_url" :alt="`${game.name} icon`" class="w-20">
                        </div>
                    </div>
                </div>

                <h2 class="text-md font-bold mt-2 px-2">
                    {{ newsPost.title }}
                </h2>
            </div>

            <div class="my-3 px-2">
                <p v-if="!!newsPost.summary">
                    {{ newsPost.summary }}
                </p>
                <p v-else>
                    Click to find out more info!
                </p>
            </div>

            <div class="pb-4 px-2">
                <p class="my-0">
                    By <span class="font-semibold text-blue-400">{{ newsPost?.user?.name }}</span>
                </p>

                <p class="text-sm text-gray-300 my-0">
                    {{ newsPost.read_time }} &mdash; {{ format(newsPost.published_at, 'MMM d, yyyy') }}
                </p>
            </div>
        </div>
    </Link>
</template>

<style scoped>

</style>

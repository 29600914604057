<script setup>

import {Link, useForm, usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import {now} from "@vue/devtools-api";
import {formatDistance, formatDistanceToNow} from "date-fns";
import PaginationLinks from "../../../InertiaComponents/Pagination/PaginationLinks.vue";
import PlatformIcon from "../../../InertiaComponents/Platform/PlatformIcon.vue";

const page = usePage();
const props = defineProps({
    matches: Array,
    classes: String,
    buttons: Object
});
const matches = computed(() => page.props.matches);
const user = computed(() => page.props.auth.user);

const acceptOrCancelForm = useForm({

})

const isPartOfTeam = (team) => {
    return !!user && !!user.teams && user.teams.some(t => t.id === team.id);
}

</script>

<template>
    <div class="rounded-lg overflow-x-auto overflow-y-hidden">
        <div
            :class="`w-max lg:w-full bg-blue-600 text-white rounded-lg border border-gray-800 pb-4`">
            <div id="wager-header" class="flex items-center justify-between px-6 text-xs">

<!--                @if (isset($buttons))-->
<!--                @foreach ($buttons as $label => $link)-->
<!--                <a href="{{ $link }}"-->
<!--                   class="px-3 py-1 font-medium font-semibold rounded-lg btn-gradient-blue text-xxs">{{ $label }}</a>-->
<!--                @endforeach-->
<!--                @endif-->
            </div>




            <div class="p-4">
                <table id="challenges-table">
                    <thead>
                    <tr>
                        <th class="dimension-title">Game</th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Game Mode
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Region
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Best Of
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Team Size
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Wager
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Expires In
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Actions
                            </p>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="match in matches.data"
                        :key="match.id"
                    >
                        <td class="flex items-center gap-4 pl-3 py-2">
                            <div class="flex items-center gap-2">
                                <img
                                    :src="match.game.storage_icon_url"
                                    alt=""
                                    class="w-8 h-8 object-contain rounded-md"
                                >
                                <PlatformIcon class="w-8 h-8 rounded-md" :platform="match.platform" />
                            </div>
                            <div class="font-semibold text-gray-300 text-sm">{{ match.game.name }}</div>
                        </td>

                        <td class="text-gray-400 text-xs">
                            <p class="text-center">
                                {{ match.game_mode.name }}
                            </p>
                        </td>

                        <td class="text-gray-400 text-xs">
                            <p class="text-center">
                                {{ match.region.name }}
                            </p>
                        </td>

                        <td class="text-gray-400 text-xs">
                            <p class="font-semibold text-center">
                                {{ match.best_of }}
                            </p>
                        </td>

                        <td class="text-gray-400 text-xs">
                            <p class="font-semibold text-center">{{ match.team_size }} v {{ match.team_size }}</p>
                        </td>

                        <td class="text-gray-400">
                            <div class="text-center">
                                <p class="inline-block px-4 py-2 font-semibold text-center uppercase bg-gray-800 rounded-full text-xs">
                                    <i class="mr-1 text-orange-500 fa-solid fa-trophy" aria-hidden="true"></i>
                                    $ {{ match.wager_amount }}
                                </p>
                            </div>
                        </td>

                        <td class="text-gray-400 text-xs">
                            <div class="text-center">
                                <div v-if="!match.available_now" class="capitalize">
                                    {{ formatDistanceToNow(new Date(match.expiry_date), { includeSeconds: true, addSuffix: true }) }}
                                </div>
                                <div class="inline-flex items-center gap-1 px-4 py-2 font-semibold text-white rounded-lg btn-gradient-blue" v-else>
                                    <div class="relative flex items-center w-2 h-2">
                                        <span class="absolute inline-flex w-full h-full rounded-full opacity-75 bg-blue-300 animate-ping"></span>

                                        <span class="relative inline-flex w-2 h-2 rounded-full bg-blue-300"></span>
                                    </div>

                                    <p class="text-xxs">
                                        Available Now
                                    </p>
                                </div>
                            </div>
                        </td>

                        <td class="text-gray-400 text-xs" v-if="!!user">
                            <div class="text-center">
                                <div v-if="isPartOfTeam(match.team_one)">
                                    <form method="POST" @submit.prevent="acceptOrCancelForm.post(route('challenge.cancel.store', match.id))" v-if="match.canManage">
                                        <button type="submit"
                                                class="inline-block px-6 py-2 mr-2 font-medium font-semibold uppercase bg-gray-600 border border-gray-600 rounded-lg text-xxs">
                                            Cancel
                                        </button>
                                    </form>
                                </div>
                                <a
                                    v-else
                                    :href="route('challenge.accept.show', match.id)"
                                    class="inline-block px-6 py-2 font-medium font-semibold uppercase rounded-lg btn-gradient-blue text-xxs"
                                >
                                    <div class="text-white">
                                        <i class="mr-1 fas fa-crown"></i> Accept
                                    </div>
                                </a>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="!matches || matches.total === 0">
                <div class="flex justify-center items-center px-6 py-4 w-12/12">
                    <div class="font-semibold text-white text-sm">There are no matches available.</div>
                </div>
            </div>

            <div class="text-center">
                <Link
                    :href="route('matchfinder.index', {'status': 'upcoming'})"
                    class="inline-flex items-center gap-2 text-sm btn-gradient-blue font-semibold px-4 py-2 rounded-lg transition transform scale-100 hover:scale-105 duration-200 ease-in-out"
                >
                    <i class="fas fa-gamepad text-lg">
                    </i>
                    <span>
                        Find More Challenges <span v-if="matches.total > matches.data.length">({{ matches.total - matches.data.length}})</span>
                    </span>
                </Link>
            </div>
        </div>
    </div>
</template>

<style scoped>
#challenges-table {
    @apply w-full p-2;
}

#challenges-table th {
    @apply bg-blue-800 text-gray-400 text-left text-xs pl-4 py-2 uppercase font-medium;
}

#challenges-table th:first-of-type {
    @apply rounded-l-lg;
}

#challenges-table th:last-of-type {
    @apply rounded-r-lg;
}

#challenges-table td {
    @apply text-left pl-4;
}
</style>

import {createSSRApp, createApp, h} from 'vue'
import { createInertiaApp, router } from '@inertiajs/vue3'
import MainLayout from "./Layouts/MainLayout.vue";
import { createVfm } from 'vue-final-modal'

import 'vue-final-modal/style.css'

import './bootstrap.js';

import Form from "./helpers/form";
window.Form = Form;

import PrimeVue from "primevue/config";
// import 'primevue/resources/themes/lara-dark-blue/theme.css'
import Aura from '@primevue/themes/aura';
import Nora from '@primevue/themes/nora';
import Lara from '@primevue/themes/lara';

import ToastService from 'primevue/toastservice';
import {routeFromPage} from "./routes.js";
import {definePreset} from "@primevue/themes";

// import vueComponents from "./vueComponents.js";

const app = createInertiaApp({
    resolve: async (name) => {
        const pages = (await import.meta.glob('./Pages/**/*.vue', { eager: true }))
        const page = pages[`./Pages/${name}.vue`] || pages[`./Pages/${name}/Index.vue`];
        page.default.layout = page.default.layout || MainLayout;
        return pages[`./Pages/${name}.vue`]
    },
    setup({ el, App, props, plugin }) {
        const route = routeFromPage(props.initialPage);

        const app = createSSRApp({ render: () => h(App, props) })
            .use(plugin).mixin({
                methods: {
                    route,
                },
            });

        const vfm = createVfm();
        app.use(vfm);

        app.use(PrimeVue, {
            theme: {
                preset: definePreset(Aura, {
                    semantic: {
                        primary: {
                            50: '{blue.50}',
                            100: '{blue.100}',
                            200: '{blue.200}',
                            300: '{blue.300}',
                            400: '{blue.400}',
                            500: '{blue.500}',
                            600: '{blue.600}',
                            700: '{blue.700}',
                            800: '{blue.800}',
                            900: '{blue.900}',
                            950: '{blue.950}'
                        },
                        colorScheme: {
                            light: {
                                primary: {
                                    color: '{slate.950}',
                                    inverseColor: '#ffffff',
                                    hoverColor: '{slate.900}',
                                    activeColor: '{slate.800}'
                                },
                                highlight: {
                                    background: '{slate.950}',
                                    focusBackground: '{slate.700}',
                                    color: '{blue.500}',
                                    focusColor: '#ffffff'
                                }
                            },
                            dark: {
                                primary: {
                                    color: '{blue.400}',
                                    inverseColor: '{slate.950}',
                                    hoverColor: '{slate.100}',
                                    activeColor: '{slate.200}'
                                },
                                highlight: {
                                    background: 'rgba(250,250,250,0.16)',
                                    focusBackground: 'rgba(250, 250, 250, .24)',
                                    color: 'rgba(255,255,255,.87)',
                                    focusColor: 'rgba(255,255,255,.87)'
                                }
                            }
                        }
                    }
                })
            }
        });
        app.use(ToastService);

        app.mount(el);
    }
});

if (process.env.NODE_ENV === "production") {
    router.on("navigate", (event) => {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag("event", "page_view", {
            page_location: event.detail.page.url,
            page_title: event.target.title,
        });
    });
}

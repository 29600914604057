<script setup>

import {computed} from 'vue';

const props = defineProps({
    'propertyName': {
        type: String,
        required: true
    },
    'errors': {
        type: Object,
        required: true
    },
    'classes': {
        type: String,
        default: ''
    }
});

const hasError = computed(() => {
    return props.errors[props.propertyName] !== undefined;
});

const renderedErrorText = computed(() => {
    if (typeof(props.errors[props.propertyName]) === 'string') {
        return props.errors[props.propertyName];
    }

    if (Array.isArray(props.errors[props.propertyName])) {
        return props.errors[props.propertyName].join(', ');
    }

    return '';
});

</script>

<template>
    <div v-if="hasError" class="text-red-500" :class="classes">
        {{ renderedErrorText }}
    </div>
</template>

<style scoped>

</style>

<script setup>
import {ref, onMounted, onUnmounted, computed} from "vue";
import {format, formatDistanceToNow} from "date-fns";
import {Link} from "@inertiajs/vue3";
import TournamentTopPrizeLabel from "./TournamentTopPrizeLabel.vue";
import TournamentCreditFeeLabel from "./TournamentCreditFeeLabel.vue";
import TournamentBasicDetailsLabel from "./TournamentBasicDetailsLabel.vue";
import pluralize from "pluralize";
import {numberWithCommas} from "../../Services/utils.js";

const props = defineProps(['tournament']);
const tournament = ref(props.tournament);
const threshold = 1800000; // 30 minutes in milliseconds

const countdownRegistration = ref('');
const showCountdownRegistration = ref(false);

const countdownTournamentStarts = ref('');
const showCountdownTournamentStarts = ref(false);


const calculateRegistrationCountdown = () => {
    const now = new Date();
    const registrationEnds = new Date(tournament.value.user_based_registration_closes);
    const difference = registrationEnds - now;

    if (difference <= threshold) {
        showCountdownRegistration.value = true;

        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        countdownRegistration.value = `${hours > 0 ? hours + 'h ' : ''}${minutes}m ${seconds}s`;
        return;
    }
    showCountdownRegistration.value = false;
};

const calculateTournamentStartCountdown = () => {
    const now = new Date();
    const tournamentStarts = new Date(tournament.value.user_based_tournament_starts);
    const difference = tournamentStarts - now;

    if (difference <= threshold) {
        showCountdownTournamentStarts.value = true;

        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        countdownTournamentStarts.value = `${hours > 0 ? hours + 'h ' : ''}${minutes}m ${seconds}s`;
        return;
    }
    showCountdownTournamentStarts.value = false;
};

const thumbnailImageUrl = computed(() => {
    if (!!props.tournament.storage_thumbnail_image_url) {
        return props.tournament.storage_thumbnail_image_url;
    }

    return tournament.value.game.storage_background_url;
});


let interval = null;

onMounted(() => {
    calculateRegistrationCountdown();
    calculateTournamentStartCountdown();
    interval = setInterval(() => {
        calculateRegistrationCountdown();
        calculateTournamentStartCountdown();
    }, 1000);
});

onUnmounted(() => {
    clearInterval(interval);
});
</script>

<template>
    <Link :href="route('tournaments.show', tournament.slug)"
          class="px-2 py-2 overflow-hidden transition duration-200 ease-in-out transform hover:scale-105 w-full flex flex-col justify-between">

        <div class="elite-banner" v-if="!!tournament.elite_only">
            Elite Only
        </div>

        <div
            class="relative text-white bg-blue-600 rounded-lg overflow-hidden shadow-xl flex-1 flex flex-col justify-between">
            <div class="relative flex flex-col justify-between gap-3 flex-1">
                <div class="relative">
                    <img
                        :src="thumbnailImageUrl"
                        class="rounded-t-lg w-full object-contain"
                        :alt="`${tournament.game.name}'s avatar`">

                    <div
                        v-if="!!tournament.elite_only && tournament.is_published"
                        class="absolute top-2 left-2 bg-blue-800 text-xxs flex items-center px-1 py-1 font-semibold text-center rounded-full bg-opacity-90">
                        <img class="w-8 h-8 mx-auto object-cover" src="/images/elite.svg" alt="Elite logo">
                    </div>

                    <div v-if="!tournament.is_published" class="absolute top-2 left-2 bg-blue-800 text-lg text-yellow-500 border-2 border-yellow-500 flex items-center px-1 py-1 font-semibold text-center rounded-full bg-opacity-90">
                        Not Live
                    </div>

                    <div
                        class="absolute font-semibold text-right"
                        :class="{'right-4': !!tournament.elite_only, 'top-3': !!tournament.elite_only, 'top-2': !tournament.elite_only, 'right-2': !tournament.elite_only}"
                    >
                        <div
                            class="inline-flex items-center px-2 py-1 rounded-lg shadow-lg mb-3 sm:mb-4"
                            :class="`${tournament.platform.color}`"
                        >
                            <i :class="`text-lg sm:text-2xl ${tournament.platform.icon}`"></i>
                        </div>

                        <TournamentTopPrizeLabel :tournament="tournament" classes="md:hidden"/>
                    </div>

                    <div class="absolute bottom-2 left-2">
                        <TournamentBasicDetailsLabel :tournament="tournament" classes="md:hidden"/>
                        <TournamentCreditFeeLabel :tournament="tournament" classes="hidden md:inline-flex"/>
                    </div>

                    <div class="absolute bottom-2 font-semibold"
                         :class="{'right-4': !!tournament.elite_only, 'right-2': !tournament.elite_only}"
                    >
                        <TournamentCreditFeeLabel :tournament="tournament" classes="md:hidden"/>
                        <TournamentTopPrizeLabel :tournament="tournament" classes="hidden md:block"/>
                    </div>
                </div>

                <div class="px-4 flex flex-col justify-around flex-1">
                    <div class="flex justify-between mb-1 font-bold text-gray-400 uppercase text-xxs">
                        <div>
                            <!-- Tournament Finished Status -->
                            <div v-if="tournament.finished">
                                {{ format(tournament.tournament_starts, 'MMM d, h:mm bbb') }} -
                                <i class="fa fa-lock text-yellow-500"></i>
                                Ended
                            </div>

                            <!-- Tournament Not Started & Bracket Not Generated -->
                            <div
                                v-else-if="new Date(tournament.tournament_starts).getTime() > new Date().getTime() && !tournament.bracket_generated">
                                <div v-if="showCountdownTournamentStarts">
                                    Tournament Starts in <span
                                    class="text-blue-400 font-bold">{{ countdownTournamentStarts }}</span>
                                </div>
                                <div v-else>
                                    {{ format(tournament.user_based_tournament_starts, 'MMM d, h:mm bbb') }} -
                                    <span class="text-blue-300">
                                            {{ formatDistanceToNow(tournament.tournament_starts, {addSuffix: true}) }}
                                        </span>
                                </div>
                            </div>

                            <!-- Tournament Started & Bracket Not Generated -->
                            <div
                                v-else-if="new Date(tournament.tournament_starts).getTime() > new Date().getTime() && new Date(tournament.registration_closes).getTime() < new Date().getTime() && !tournament.bracket_generated">
                                <i class="fa fa-hourglass text-blue-500"></i>
                                Pending Bracket Creation
                            </div>

                            <!-- Tournament In Progress Status -->
                            <div
                                v-else-if="(!tournament.finished && new Date(tournament.tournament_starts).getTime() < new Date().getTime()) && tournament.bracket_generated">
                                {{ format(tournament.tournament_starts, 'MMM d, h:mm bbb') }} -
                                <span class="text-blue-300">In Progress</span>
                            </div>
                        </div>
                        <div class="flex">
                            <span class="ml-3">{{ tournament.region.name }}</span>
                        </div>
                    </div>
                    <div class="md:text-left mb-1">
                        <div class="text-md font-semibold text-white"> {{ tournament.name }}</div>
                    </div>
                    <div class="flex justify-between">
                        <div>
                            <div class="mb-1 font-bold text-gray-400 text-xxs">Prize Pool</div>
                            <div class="text-xs font-bold"><i class="mr-1 text-orange-500 fa-solid fa-trophy"></i> $
                                {{ numberWithCommas((tournament.total_prize_pool).toFixed(2)) }}
                            </div>
                        </div>
                        <div>
                            <div class="mb-1 font-bold text-gray-400 text-xxs">Team Size</div>
                            <div class="text-xs font-bold"><i class="mr-1 text-indigo-600 fas fa-dice"></i>
                                {{ tournament.max_team_size }} v {{ tournament.max_team_size }}
                            </div>
                        </div>
                        <div>
                            <div class="mb-1 font-bold text-gray-400 text-xxs">Entry Fee</div>
                            <div class="text-xs font-bold">
                                <i class="mr-1 text-green-bright fas fa-coins"></i>

                                <span v-if="tournament.entry_fee > 0">
                                    {{ tournament.entry_fee }} {{ pluralize('Credit', tournament.entry_fee) }}
                                </span>
                                <span v-else>
                                    Free Entry
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row px-4 py-2 " style="min-height: 56px;">
                <div class="flex -space-x-3">
                    <img
                        v-for="team in tournament.teams.slice(0, 5)"
                        class="w-10 h-10 border-4 border-blue-700 rounded-full object-contain"
                        :src="team.storage_avatar"
                        :alt="`${team.name}'s avatar`"
                    >
                </div>
                <div class="flex items-center ml-8 font-bold text-gray-400 text-xxs">
                    {{ tournament.total_registered_teams }} Registered
                </div>
            </div>

            <!-- Tournament Owners -->
            <div v-if="tournament.owners && !!tournament.owners.length">
                <div class="flex flex-row px-4 py-2 text-xs font-semibold text-white">
                    <div class="pt-2">Tournament hosted By:</div>
                    <div v-for="owner in tournament.owners.slice(0, 5)" class="flex flex-row -space-x-3">
                        <img
                            class="w-8 h-8 border-4 border-blue-700 rounded-full object-contain"
                            :src="owner.user.storage_avatar"
                            :alt="`${owner.user.username}'s avatar`"
                        >
                        <div class="pl-3 pt-2 text-ellipsis">{{ owner.user.username }}</div>
                    </div>
                </div>
            </div>

            <!-- Registration Countdown -->
            <div
                v-if="new Date(tournament.tournament_starts).getTime() > new Date().getTime() && new Date(tournament.registration_closes).getTime() > new Date().getTime()"
                class="flex justify-between px-4 mb-1 font-bold text-gray-400 uppercase text-xxs">
                <div v-if="showCountdownRegistration">
                    Registration Closes in <span class="countdown">{{ countdownRegistration }}</span>
                </div>
                <div v-else>
                    Registration Closes <span class="countdown">{{
                        formatDistanceToNow(tournament.registration_closes, {addSuffix: true})
                    }}</span>
                </div>
            </div>
            <div v-else class="flex justify-between px-4 mb-1 font-bold text-gray-400 uppercase text-xxs">
                Registration Closed
            </div>
        </div>
    </Link>
</template>

<style scoped>
.countdown {
    @apply text-blue-400 rounded-lg font-bold;
}
</style>

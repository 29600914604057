<script setup>
import { Link } from '@inertiajs/vue3';
import { format } from 'date-fns';
import { computed } from 'vue';

const props = defineProps({
    leagueMatches: Array,
    league: Object
});

// Group league matches by group and date
const groupedMatches = computed(() => {
    const groups = {};
    props.leagueMatches.forEach(match => {
        const groupName = match.league_group ? match.league_group.name : 'No Group';
        const date = format(new Date(match.match_start), 'yyyy-MM-dd');
        if (!groups[groupName]) {
            groups[groupName] = {};
        }
        if (!groups[groupName][date]) {
            groups[groupName][date] = [];
        }
        groups[groupName][date].push(match);
    });

    const sortedGroups = {};
    Object.keys(groups).forEach(groupName => {
        const dates = Object.keys(groups[groupName]).sort((a, b) => new Date(b) - new Date(a));
        sortedGroups[groupName] = {};
        dates.forEach(date => {
            sortedGroups[groupName][date] = groups[groupName][date];
        });
    });

    return sortedGroups;
});

// Format date for display
const formatDate = (date) => {
    return format(new Date(date), 'dd/MM/yyyy');
};
</script>

<template>
    <div id="matches-tab" class="mt-6 md:mt-12">
        <div id="matches" class="grid grid-cols-1 gap-4">
            <div v-for="(dates, group) in groupedMatches" :key="group" class="mb-8">
                <h2 class="text-2xl font-bold mb-4 underline">{{ group }}</h2>
                <div v-for="(matches, date) in dates" :key="date" class="mb-4">
                    <h3 class="text-xl font-bold mb-2">{{ formatDate(date) }}</h3>
                    <div v-for="match in matches" :key="match.id"
                         class="mx-1/2 overflow-x-auto bg-gray-800 border border-gray-700 p-4 my-2 rounded-lg">
                        <Link :href="route('leagues.matches.show', { league: league.id, leagueMatch: match.id })">
                            <div class="flex items-center justify-between">
                                <div class="flex items-center justify-start w-1/3">
                                    <img :src="match.team_one.storage_avatar" alt="Team One Avatar"
                                         class="w-8 h-8 rounded-full mr-2">
                                    <span>
                                        {{ match.team_one.name }}
                                    </span>
                                    <span v-if="match.winning_team_id === match.team_one.id"
                                          class="text-green-500 mx-2">(W)</span>
                                    <span v-if="match.winning_team_id !== match.team_one.id && (match.winning_team_id === match.team_two.id)" class="text-red-500 mx-2">(L)</span>
                                    <span v-if="(match.winning_team_id !== match.team_one.id) && (match.winning_team_id !== match.team_two.id)" class="text-red-500 mx-2"></span>
                                </div>
                                <div class="flex items-center justify-center w-1/3">
                                    <div class="text-2xl font-bold">V</div>
                                </div>
                                <div class="flex items-center justify-end w-1/3">
                                    <span v-if="match.winning_team_id === match.team_two.id"
                                          class="text-green-500 mx-2">(W)</span>
                                    <span v-if="match.winning_team_id !== match.team_two.id && (match.winning_team_id === match.team_one.id)" class="text-red-500 mx-2">(L)</span>
                                    <span v-if="(match.winning_team_id !== match.team_one.id) && (match.winning_team_id !== match.team_one.id)" class="text-red-500 mx-2"></span>
                                    <span>
                                        {{ match.team_two.name }}
                                    </span>
                                    <img :src="match.team_two.storage_avatar" alt="Team Two Avatar"
                                         class="w-8 h-8 rounded-full ml-2">
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
tr:nth-child(even) {
    @apply bg-gray-800;
}

tr:nth-child(odd) {
    @apply bg-gray-900;
}

th {
    @apply bg-blue-600 px-6 pt-2 pb-2 text-left border-b border-gray-700;
}
</style>

<script setup>

import {formatCurrency, numberWithCommas} from "../../Services/utils.js";
import {Link} from '@inertiajs/vue3';
import {computed} from "vue";

const props = defineProps({
    users: {
        type: Object,
        required: true,
    },
    leaderboard: {
        type: Object,
        required: true,
    },
    authUser: {
        type: Object,
        required: true,
    },
    showRank: {
        type: Boolean,
        required: false,
        default: true,
    },
});

function calculateUserPosition(index) {
    const page = props.users.current_page;
    const perPage = props.users.per_page;

    return (page - 1) * perPage + index + 1;
}

const currentUserUsername = computed(() => {
    if (!props.authUser) {
        return '';
    }

    return props.authUser.username;
})

</script>

<template>
    <div class="overflow-x-auto">
        <table
            v-if="users.total > 0"
            class="w-full rounded-lg overflow-hidden"
        >
            <thead>
            <tr class="text-xs font-semibold text-gray-400 bg-blue-800">
                <th v-if="showRank" class="px-6 pt-4 pb-2 text-left max-w-[20px]">
                    Rank
                </th>
                <th class="px-6 pt-4 pb-2 text-left">
                    User
                </th>
                <th class="px-6 pt-4 pb-2 text-left">
                    <p class="text-center">
                        Socials
                    </p>
                </th>
                <th class="px-6 pt-4 pb-2 text-left">
                    <p class="text-center">
                        XP

                        <i v-if="leaderboard.field === 'xp'" class="fa-solid fa-chevron-down"></i>
                    </p>
                </th>
                <th class="px-6 pt-4 pb-2 text-left">
                    <p class="text-center">
                        Earnings

                        <i v-if="leaderboard.field === 'total_earnings'" class="fa-solid fa-chevron-down"></i>
                    </p>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(userRank, index) in users.data"
                :key="userRank.id"
                :class="{
                'bg-blue-800' : index % 2 === 0,
                'bg-blue-700' : index % 2 !== 0,
                'border-blue-500 border-2': userRank.user.username === currentUserUsername,
            }"
            >
                <td v-if="showRank">
                    <div :class="{
                    'from-yellow-500 via-transparent to-transparent' : calculateUserPosition(index) === 1,
                    'from-gray-300 via-transparent to-transparent' : calculateUserPosition(index) === 2,
                    'from-orange-500 via-transparent to-transparent' : calculateUserPosition(index) === 3,
                    'border-transparent' : calculateUserPosition(index) > 3,
                    'px-6 py-6 whitespace-nowrap bg-gradient-to-r': true,
                }">
                    <span :class="{
                        'gold-rank' : calculateUserPosition(index) === 1,
                        'silver-rank' : calculateUserPosition(index) === 2,
                        'bronze-rank' : calculateUserPosition(index) === 3,
                        'bg-transparent' : calculateUserPosition(index) > 3,
                        'px-6 py-4 whitespace-nowrap rounded-full shadow-lg': true,
                    }">
                        <span v-if="calculateUserPosition(index) > 3">
                            {{ calculateUserPosition(index) }}
                        </span>
<!--                        <img src="/images/leaderboard/rank-markers/gold.png" class="w-16">-->
                    </span>
                    </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                        <div class="flex-shrink-0 w-8 h-8">
                            <img class="object-cover w-8 h-8 rounded-full"
                                 :src="userRank.user.storage_avatar"
                                 :alt="`${userRank.user.username}'s avatar`">
                        </div>
                        <Link :href="route('user.show', [userRank.user.slug])" class="ml-4">
                            <div class="text-xs sm:text-sm font-medium leading-5 text-blue-400">
                                {{ userRank.user.username }}
                                <span v-if="userRank.user.username === currentUserUsername"
                                      class="text-blue-500 font-semibold text-xs sm:text-sm">
                                (You)
                            </span>
                            </div>
                        </Link>
                    </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                    <div v-if="!!userRank.user.socials" class="flex gap-1 justify-center">
                        <a v-if="!!userRank.user.socials.instagram"
                           :href="`https://www.instagram.com/${userRank.user.socials.instagram}`">
                            <i class="mr-1 text-sm sm:text-lg fab fa-instagram instagram-blue"></i>
                        </a>
                        <a v-if="!!userRank.user.socials.twitter"
                           :href="`https://x.com/${userRank.user.socials.twitter}`">
                            <i class="mr-1 text-sm sm:text-lg fab fa-twitter twitter-color"></i>
                        </a>
                        <a v-if="!!userRank.user.socials.facebook"
                           :href="`https://www.facebook.com/${userRank.user.socials.facebook}`">
                            <i class="mr-1 text-sm sm:text-lg fab fa-facebook facebook-blue"></i>
                        </a>
                        <a v-if="!!userRank.user.socials.twitch"
                           :href="`https://www.twitch.tv/${userRank.user.socials.twitch}`">
                            <i class="mr-1 text-sm sm:text-lg fab fa-twitch twitch-purple"></i>
                        </a>
                        <div v-if="!!userRank.user.socials.discord" class="flex items-center justify-start">
                            <i class="mr-1 text-sm sm:text-lg fab fa-discord discord-color"></i>
                            <p class="text-xs">{{ userRank.user.socials.discord }}</p>
                        </div>
                        <a v-if="!!userRank.user.socials.youtube"
                           :href="`https://www.youtube.com/${userRank.user.socials.youtube}`">
                            <i class="mr-1 text-sm sm:text-lg fab fa-youtube youtube-color"></i>
                        </a>
                    </div>
                </td>

                <td class="px-6 py-4">
                    <div
                        class="px-3 py-1 mr-2 font-semibold rounded-full w-full text-md text-center">
                        <!--                                        {{ numberWithCommas(user.xp) }} <span class="font-light">(Level {{ user.rank.level }} - </span> {{ user.rank.name }} <span class="font-light">)</span>-->
                        <span v-if="!!userRank.xp_change">
                            <span v-if="!!userRank.xp_change" class="text-md">
                                <i :class="{'fas mr-2': true,
                                 'fa-arrow-up text-green-500': userRank.xp_change > 0,
                                    'fa-arrow-down text-red-500': userRank.xp_change < 0}"></i>
                                <i class="text-blue-500 fas fa-crosshairs text-sm sm:text-xl" aria-hidden="true"></i>
                                {{ numberWithCommas(userRank.xp_change) }}
                            </span>

                            <span class="text-sm ml-2">
                                (Total:
                                {{ numberWithCommas(userRank.xp) }})
                            </span>
                        </span>

                        <p v-else class="text-sm sm:text-md text-center">
                            <i class="mr-1 text-blue-500 fas fa-crosshairs text-md sm:text-xl" aria-hidden="true"></i>
                            {{ numberWithCommas(userRank.xp) }}
                        </p>
                    </div>
                </td>

                <td class="px-6 py-4">
                    <div
                        class="px-3 py-1 mr-2 font-semibold text-center rounded-full w-full text-sm sm:text-md">

                        <span v-if="!!parseFloat(userRank.earnings_change)">
                            <span class="text-md">
                                <i :class="{'fas mr-2': true,
                                 'fa-arrow-up text-green-500': parseFloat(userRank.earnings_change) > 0,
                                    'fa-arrow-down text-red-500': parseFloat(userRank.earnings_change) < 0}"></i>
                                <i class="text-green-500 fas fa-dollar-sign text-sm sm:text-lg -mr-1" aria-hidden="true"></i>
                                {{ formatCurrency(userRank.earnings_change) }}
                            </span>

                            <span class="text-sm ml-2">
                                (Total:
                                ${{ formatCurrency(userRank.earnings) }})
                            </span>
                        </span>

                        <p v-else>
                            <i class="text-green-500 fas fa-dollar-sign text-sm sm:text-lg -mr-1" aria-hidden="true"></i>
                            {{ formatCurrency(userRank.earnings) }}
                        </p>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
table th {
    @apply bg-blue-800 text-gray-400 text-xs pl-4 py-2 uppercase font-medium px-6 pt-4 pb-2 text-left;
}

.gold-rank {
    background-image: url('/images/leaderboard/rank-markers/gold.png');
    background-position: center;
    background-size: contain
}

.silver-rank {
    background-image: url('/images/leaderboard/rank-markers/silver.png');
    background-position: center;
    background-size: contain
}

.bronze-rank {
    background-image: url('/images/leaderboard/rank-markers/bronze.png');
    background-position: center;
    background-size: contain
}
</style>

<script setup>
import {usePage} from '@inertiajs/vue3';
import {computed} from 'vue';
import Header from '../../InertiaComponents/Clubs/Header.vue';

const {props} = usePage();
const {club, auth} = props;

const heroBackground = computed(() => {
    return club.hero_url ? 'custom-hero-background' : 'default-hero-background';
});

const heroStyle = computed(() => {
    return club.hero_url ? {backgroundImage: `url(${club.hero_url})`} : {};
});

</script>

<style scoped>
.default-hero-background {
    @apply text-white py-48 text-center bg-cover bg-center;
}

.custom-hero-background {
    @apply py-72 max-h-screen min-h-screen text-center bg-cover bg-center -mt-32 z-0;
}

.hero-section.default-hero-background {
    background: linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d);
    @apply bg-opacity-60;
}

.hero-content {
    @apply flex flex-col items-center;
}

.club-name {
    @apply flex items-center mb-5 gap-1;
}

.club-logo {
    @apply w-24 h-24 object-cover rounded-full border-2 border-white mx-auto;
}

.club-details {
    @apply py-8 mx-auto max-w-2xl;
}

.club-sections {
    @apply bg-blue-800 p-6 mb-5 rounded-lg shadow-md;
}

.club-sections h2 {
    @apply text-2xl font-semibold mb-2;
}

.club-details ul {
    @apply list-none p-0;
}

.club-details li {
    @apply mb-2;
}

.club-details strong {
    @apply font-bold;
}
</style>

<template>
    <div class="-mt-12">
        <Header/>
        <section :class="heroBackground" class="" :style="heroStyle">
            <div class="hero-content">
                <div class="club-name">
                    <img :src="club.logo_url" alt="Club Logo" class="club-logo"/>
                    <h1 class="text-5xl font-bold mb-5">{{ club.name }}</h1>
                </div>
                <p class="text-xl mb-5">{{ club.description }}</p>

            </div>
        </section>

        <div class="grid grid-cols-3 m-4 gap-3">

            <!-- Club Games Section -->
            <section class="club-sections py-8 mx-auto max-w-2xl">

            </section>

            <!-- Club Tournaments Section -->
            <section class="club-sections py-8 mx-auto max-w-2xl">

            </section>

            <!-- Club Members Section -->
            <section class="club-sections py-8 mx-auto max-w-2xl">

            </section>

            <!-- Club Gallery Section -->
            <section class="club-sections py-8 mx-auto max-w-2xl">

            </section>

            <!-- Club News Section -->
            <section class="club-sections py-8 mx-auto max-w-2xl">

            </section>

            <!-- Store Section -->
            <section class="club-sections py-8 mx-auto max-w-2xl">

            </section>
        </div>
    </div>
</template>

<script setup>

</script>

<template>
    <footer class="w-full relative px-4 pt-10 pb-6 bg-white md:px-4">
        <div class="container mx-auto">
            <div class="flex flex-wrap">
                <div class="w-2/4 mb-2 md:w-1/4 md:mb-0 mt-4">
                    <div class="mb-6 text-sm font-bold text-black uppercase">
                        Games
                    </div>

                    <ul class="text-sm font-semibold text-gray-800 flex flex-col gap-3">
                        <li>
                            <a href="/game/playstation-4/call-of-duty-black-ops-3">
                                Call of Duty: Black Ops 3
                            </a>
                        </li>
                        <li>
                            <a href="/game/playstation-4/call-of-duty-black-ops-4">
                                Call of Duty: Black Ops 4
                            </a>
                        </li>
                        <li>
                            <a href="/game/cross-platform/call-of-duty-cold-war">
                                Call Of Duty: Cold War
                            </a>
                        </li>
                        <li>
                            <a href="/game/cross-platform/call-of-duty-modern-warfare-ii">
                                Call of Duty: Modern Warfare II
                            </a>
                        </li>
                        <li>
                            <a href="/game/playstation-5/call-of-duty-mwr">
                                Call of Duty: MWR
                            </a>
                        </li>
                        <li>
                            <a href="/game/cross-platform/call-of-duty-warzone">
                                Call Of Duty: Warzone 2
                            </a>
                        </li>
                        <li>
                            <a href="/game/cross-platform/ea-fc24">
                                EA FC24
                            </a>
                        </li>

                        <li>
                            <a href="/game/cross-platform/fall-guys">
                                Fall Guys
                            </a>
                        </li>
                        <li>
                            <a href="/game/cross-platform/xdefiant">
                                XDefiant
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="w-2/4 mb-2 md:w-1/4 md:mb-0 mt-4">
                    <div class="mb-6 text-sm font-bold text-black uppercase">
                        Esports Hub
                    </div>

                    <ul class="text-sm font-semibold text-gray-800 flex flex-col gap-3">
                        <li>
                            <a :href="route('leaderboard.index')">
                                Leaderboards
                            </a>
                        </li>
                        <li>
                            <a :href="route('leagues.index')">
                                Leagues
                            </a>
                        </li>
                        <li>
                            <a :href="route('game.index')">
                                Games
                            </a>
                        </li>
                        <li>
                            <a :href="route('support-creator.index')">
                                Support A Creator
                            </a>
                        </li>
                        <li>
                            <a :href="route('creator.application.index')">
                                Apply to be a Creator
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="w-2/4 mb-2 md:w-1/4 md:mb-0 mt-4">
                    <div class="mb-6 text-sm font-bold text-black uppercase">
                        Support
                    </div>

                    <ul class="text-sm font-semibold text-gray-800 flex flex-col gap-3">
                        <li>
                            <a :href="route('terms.index')">
                                Terms Of Service
                            </a>
                        </li>
                        <li>
                            <a :href="route('privacy-policy.index')">
                                Privacy Policy
                            </a>
                        </li>
                        <li>
                            <a :href="route('contact.index')">
                                Contact Us
                            </a>
                        </li>
                        <li>
                            <a :href="route('tickets.index')">
                                Get Help
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="w-2/4 mb-2 md:w-1/4 md:mb-0 mt-4">
                    <div class="mb-6 text-sm font-bold text-black uppercase">
                        Socials
                    </div>

                    <ul class="text-sm font-semibold text-gray-800 flex flex-col gap-3">
                        <li>
                            <a href="https://twitter.com/TheEsportHub" class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                                <i class="fa-brands fa-square-x-twitter "></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.twitch.tv/theesportshub" class="text-blue-500 text-3xl hover:scale-105 transition-transform">

                                <i class="fa-brands fa-twitch"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mt-4">
                <h6 class="text-center text-xl font-semibold">
                    &copy; The Esports Hub Limited, {{ new Date().getFullYear() }}
                </h6>
            </div>
        </div>
    </footer>
</template>

<style scoped>

</style>

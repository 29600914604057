<script setup>
import { computed, ref } from 'vue';
import {usePage, useForm} from '@inertiajs/vue3';
import Header from "../../../InertiaComponents/Clubs/Header.vue";
import Select from "../../../InertiaComponents/Forms/Select.vue";
import ClubLeagueCard from "../../../InertiaComponents/Clubs/Leagues/LeagueCard.vue";
import Breadcrumbs from "../../../InertiaComponents/Navigation/Breadcrumbs.vue";
import PaginationStatsDisplay from "../../../InertiaComponents/Pagination/PaginationStatsDisplay.vue";
import PaginationLinks from "../../../InertiaComponents/Pagination/PaginationLinks.vue";

const { props } = usePage();
const club = computed(() => props.club);
const filters = ref(props.filters);

const leagues = computed(() => props.leagues);

const showFilters = ref(false);

const games = computed(() => props.games);
const regions = computed(() => props.regions);
const platforms = computed(() => props.platforms);
const statusOptions = computed(() => {
    return [
        {name: 'All', slug: 'all'},
        {name: 'In Progress', slug: 'in-progress'},
        {name: 'Upcoming', slug: 'upcoming'},
        {name: 'Ended', slug: 'ended'},
    ];
});

const regionOptions = computed(() => {
    return [
        {name: 'All', short_name: 'all'},
        ...regions.value.map(region => {
            return {
                name: region.name,
                short_name: region.short_name
            }
        })
    ];
});

const platformOptions = computed(() => {
    return [
        {label: 'All', value: 'all'},
        ...platforms.value.map(platform => {
            return {
                label: platform.name,
                value: platform.slug
            }
        })
    ];
});

const gameOptions = computed(() => {
    return [
        {name: 'All', slug: 'all'},
        ...games.value.map(game => {
            return {
                name: game.name,
                slug: game.slug
            }
        })
    ];
});



const selectedGame = computed(() => props.filters.game);
const selectedRegion = computed(() => props.filters.region);
const selectedPlatform = computed(() => props.filters.platform);
const selectedTeamSize = computed(() => props.filters.teamSize);
const selectedStatus = computed(() => props.filters.status);
const selectedSortBy = computed(() => props.filters.sortBy);

const filterForm = useForm({
    status: selectedStatus?.value?.slug || 'all',
    game: selectedGame?.value?.slug || 'all',
    region: selectedRegion?.value || 'all',
    platform: selectedPlatform?.value?.slug || 'all',
    teamSize: selectedTeamSize?.value || 'all',
    sortBy: selectedSortBy?.value || 'start_date_desc',
});

const onSubmitFilters = (clubSlug) => {
    filterForm.transform((data) => {
        return {
            status: data.status === 'all' ? undefined : data.status,
            game: data.game === 'all' ? undefined : data.game,
            platform: data.platform === 'all' ? undefined : data.platform,
            teamSize: data.teamSize === 'all' ? undefined : data.teamSize,
            sortBy: data.sortBy,
        };
    }).get(route('clubleagues.index', {club: clubSlug}), { preserveState: false, preserveScroll: true });

};

const resetFilter = () => {
    filterForm.status = 'all';
    filterForm.game = 'all';
    filterForm.region = 'all';
    filterForm.platform = 'all';
    filterForm.teamSize = 'all';
    filterForm.sortBy = 'start_date_desc';

    onSubmitFilters(club.value.slug);
};

</script>

<style scoped>
.main-div {
    @apply py-4 text-white;
}

.container {
    @apply mx-auto;
}

.title-text {
    @apply text-4xl font-bold;
}

.button-show-filters {
    @apply bg-blue-500 text-white p-2 rounded-lg;
}

.filters-container {
    @apply sticky top-0 py-2 overflow-y-auto max-h-screen bg-blue-600 shadow-2xl rounded-lg;
}

.filters-header {
    @apply text-xl text-center uppercase border-b-2 border-blue-500;
}

.filters-form {
    @apply px-2 py-1;
}

.form-select {
    @apply block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50;
}

.apply-button {
    @apply w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-400 shadow-md hover:shadow-xl;
}

.reset-button {
    @apply w-full bg-red-600 text-white p-2 rounded-lg mt-2 hover:bg-red-500;
}

.leagues-grid {
    @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4;
}
</style>

<template>
    <div class="main-div -mt-16">
        <Header/>
        <div class="main-div">
            <div class="container">
                <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Leagues'}
            ]" />
                <div class="flex items-center px-4 my-4">
                    <img :src="club.logo_url" alt="Organizer Avatar" class="w-12 h-12 rounded-full mr-2">
                    <div class="title-text">{{ club.name }}: Leagues</div>
                </div>
                <div class="flex md:hidden justify-center mb-4">
                    <button
                        @click="showFilters = !showFilters"
                        class="button-show-filters"
                    >
                        <span v-if="showFilters">Hide</span>
                        <span v-else>Show</span>
                        Filters
                    </button>
                </div>

                <div class="md:grid md:grid-cols-12 gap-2">
                    <div
                        class="col-span-6 md:col-span-3 lg:col-span-2"
                        :class="{ 'hidden md:block': !showFilters }"
                    >
                        <!-- Filter Section -->
                        <div class="filters-container">

                            <h4 class="filters-header">
                                Filters
                            </h4>
                            <form class="filters-form" @submit.prevent="onSubmitFilters(club.slug)">
                                <div class="mt-4">
                                    <label for="status" class="block text-sm font-medium text-white">Status</label>
                                    <Select
                                        :options="statusOptions"
                                        labelAttr="name"
                                        valueAttr="slug"
                                        v-model="filterForm.status"
                                        name="status"
                                        label="Status"
                                        :selected="selectedStatus"
                                    >
                                    </Select>
                                </div>

                                <div class="mt-4">
                                    <Select
                                        :options="gameOptions"
                                        labelAttr="name"
                                        valueAttr="slug"
                                        v-model="filterForm.game"
                                        name="game"
                                        label="Game"
                                        :selected="selectedGame"
                                    />

                                    <div v-if="filterForm.errors.game">
                                        <p class="text-red-500 text-xs italic">{{ filterForm.errors.game }}</p>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <Select
                                        :options="[
                                        {label: 'All', value: 'all'},
                                        {label: '1 v 1', value: '1v1'},
                                        {label: '2 v 2', value: '2v2'},
                                        {label: '3 v 3', value: '3v3'},
                                        {label: '4 v 4', value: '4v4'},
                                        {label: '6 v 6', value: '6v6'},
                                        {label: '8 v 8', value: '8v8'},
                                    ]"
                                        labelAttr="label"
                                        valueAttr="value"
                                        v-model="filterForm.teamSize"
                                        name="teamSize"
                                        label="Team Size"
                                    />

                                    <div v-if="filterForm.errors.teamSize">
                                        <p class="text-red-500 text-xs italic">{{ filterForm.errors.teamSize }}</p>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <Select
                                        :options="regionOptions"
                                        labelAttr="name"
                                        valueAttr="short_name"
                                        v-model="filterForm.region"
                                        name="region"
                                        label="Region"
                                    />

                                    <div v-if="filterForm.errors.region">
                                        <p class="text-red-500 text-xs italic">{{ filterForm.errors.region }}</p>
                                    </div>
                                </div>

                                <div class="mt-4">
                                    <Select
                                        :options="platformOptions"
                                        labelAttr="label"
                                        valueAttr="value"
                                        v-model="filterForm.platform"
                                        name="platform"
                                        label="Platform"
                                    />
                                </div>

                                <div class="mt-4">
                                    <button type="submit"
                                            class="apply-button">
                                        Apply Filters
                                    </button>

                                    <div>
                                        <button @click="resetFilter" type="button"
                                                class="reset-button">
                                            Reset Filters
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-span-6 md:col-span-9 lg:col-span-10">
                        <div class="sticky top-0 z-10 bg-blue-600 py-1 border-b-2 border-blue-500 rounded-r-full rounded-l-full md:rounded-l-none flex justify-center xl:justify-between items-center text-center">

                            <div class="flex-1 hidden xl:block">
                            </div>

                            <PaginationStatsDisplay :data="leagues" />

                            <div class="mr-8 flex-1 hidden xl:block">
                                <Select
                                    :options="[
                                            {label: 'Start Date - Latest First', value: 'start_date_desc'},
                                            {label: 'Start Date - Earliest First', value: 'start_date_asc'},
                                        ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.sortBy"
                                    name="sortBy"
                                    label="Sort By:"
                                    label-direction="left"
                                    box-size="xs"
                                />
                            </div>
                        </div>

                        <div
                            v-if="leagues && leagues.data.length === 0"
                            class="px-5 py-5 text-center flex-1 flex items-center justify-center"
                        >
                            <p class="text-xl">
                                No Leagues found with the selected filters.
                            </p>
                        </div>

                        <div v-else class="leagues-grid">
                            <ClubLeagueCard v-for="league in leagues.data" :key="league.id" :league="league"
                                        :club="club"/>
                        </div>

                        <PaginationLinks :links="leagues.links"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

import {route as ziggyRoute} from "ziggy-js";

export const routeFromPage = (page) => {
    const Ziggy = {
        // Pull the Ziggy config off of the props.
        ...page.props.ziggy,
        // Build the location, since there is
        // no window.location in Node.
        location: new URL(page.props.ziggy.url)
    }

    const route = (name, params, absolute, config) => {
        if (!config) {
            config = Ziggy;
        }
        return ziggyRoute(name, params, absolute, config);
    }

    return route;
}

<script setup>

import {useForm} from "@inertiajs/vue3";
import {useToast} from "primevue/usetoast";
import {computed} from "vue";
import InputErrorMessage from "../../UI/InputErrorMessage.vue";
import {Api} from "../../../Services/api.js";

const props = defineProps({
    game: Object,
    // // games: Array,
    // selectedGame: String,
});

const form = useForm({
    name: '',
    game_id: props?.game?.id || '',
});

const emit = defineEmits(['teamCreated'])

const toast = useToast();

const onFormSubmit = async () => {

    try {
        const resp = await Api.teams().create(form.data());
        toast.add({
            severity: 'success',
            summary: 'Team Created',
            detail: 'Your team has been created successfully',
            life: 5000
        });
        emit('teamCreated', resp.data.data);
    } catch (e) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error creating your team',
            life: 5000
        });

        form.errors = e.response.data.errors;
    }
}

const canPressSubmit = computed(() => {
    return form.name.length >= 4 && form.name.length <= 16;
});

</script>

<template>
    <div class="container mx-auto">
        <div class="flex justify-center items-center w-full h-full">
            <div class="w-full sm:w-6/12 px-4 mb-6">
                <div class="bg-blue-600 text-white rounded-lg border border-gray-800 px-8 sm:px-16 py-16">
                    <div id="inner" class="pb-6 border-gray-800 mb-4">
                        <div class="mb-6">
                            <div class="text-3xl font-extrabold mb-1">Create Your Team for {{ game.name }}</div>
                            <div class="text-gray-400 text-sm">
                                Challenge your competitors
                            </div>
                        </div>

                        <form @submit.prevent="onFormSubmit" method="POST">
                            <div id="form-group">
                                <div class="text-xs text-gray-600 py-1">Team Name</div>
                                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                    <input
                                        name="name"
                                        type="text"
                                        v-model="form.name"
                                        class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                                    />

                                    <div class="absolute right-1 top-1 text-xxs" :class="form.name.length > 16 ? 'text-red-400' : ''">
                                        <span>
                                            {{ form.name.length }}
                                        </span>
                                        / 16
                                    </div>
                                </div>

                                <InputErrorMessage :errors="form.errors" property-name="name" />
                            </div>

                            <input type="hidden" name="game_id" value="game.id">

                            <button
                                type="submit"
                                class="mt-3 btn-gradient-blue px-16 py-3 rounded uppercase text-xs w-full font-semibold text-center inline-block"
                                :class="{'cursor-not-allowed bg-blue-800': !canPressSubmit}"
                                :disabled="!canPressSubmit"
                            >
                                Create Team
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<template>
    <div id="main" class="px-4 py-4 text-white sm:px-0">
        <div class="container mx-auto">
            <Breadcrumbs
                :breadcrumbs="[
                    {name: 'Home', link: '/'},
                    {name: 'Bank'}
                ]" classes="md:-ml-4" />
            <div class="flex items-center my-4">
                <div class="text-4xl font-bold">My Bank</div>
            </div>
            <div class="flex flex-wrap w-full mb-8 -mx-4">
                <div class="relative w-full px-4 md:w-1/3">
                    <div class="relative px-6 py-6 overflow-hidden leading-snug bg-blue-600 border border-gray-800 rounded-lg shadow-lg ">
                        <div class="text-sm font-semibold text-gray-600">Balance</div>
                        <div class="text-3xl font-bold">${{ balance }}</div>
                        <div class="text-xs font-semibold text-blue-500">
                            <!-- TODO: Make use of the Link component -->
                            <a :href="route('bank.deposit.create', { m: 'dc' })">Deposit</a> /
                            <a :href="route('bank.withdrawal.create')">Withdraw</a>
                        </div>
                        <i aria-hidden="true" style="font-size:120px;" class="absolute top-0 right-0 -mx-2 text-gray-700 fas fa-piggy-bank"></i>
                    </div>
                </div>
                <div class="relative w-full px-4 mt-3 md:w-1/3 md:mt-0">
                    <div class="relative px-6 py-6 overflow-hidden leading-snug bg-blue-600 border border-gray-800 rounded-lg shadow-lg ">
                        <div class="text-sm font-semibold text-gray-600">Credits</div>
                        <div class="text-3xl font-bold">{{ credits }}</div>
                        <Link href="/store" class="text-xs font-semibold text-blue-500">Get Credits</Link> /
                        <!-- TODO: Make use of the Link component -->
                        <a :href="route('bank.credit-transfer.index')" class="text-xs font-semibold text-blue-500">Transfer Credits</a>
                        <i aria-hidden="true" style="font-size:120px;" class="absolute top-0 right-0 -mx-4 text-gray-700 fas fa-coins"></i>
                    </div>
                </div>
                <div class="relative w-full px-4 mt-3 md:w-1/3 md:mt-0">
                    <div class="relative px-6 py-6 overflow-hidden leading-snug bg-blue-600 border border-gray-800 rounded-lg shadow-lg ">
                        <div class="text-sm font-semibold text-gray-600">Membership</div>
                        <div v-if="eliteMembership !== null" class="text-3xl font-bold">Elite</div>
                        <div v-if="eliteMembership !== null" class="text-xs font-semibold text-blue-500">You're an Elite member</div>
                        <div v-else class="text-3xl font-bold">Regular</div>
                        <div v-else class="text-xs font-semibold text-blue-500">
                            <Link :href="route('store.index')">Get Elite</Link>
                        </div>
                        <i aria-hidden="true" style="font-size:120px;" class="absolute top-0 right-0 -mx-4 text-gray-700 fas fa-fire"></i>
                    </div>
                </div>
            </div>
            <div v-if="eliteMembership !== null" class="w-full">
                <div class="mt-4 mb-4 text-xs text-white bg-blue-600 border border-gray-800 rounded-lg">
                    <div class="border-b border-gray-800 tabs">
                        <ul class="flex">
                            <li class="px-3 py-4 mr-4 font-semibold border-b-2 border-blue-500">Elite Information</li>
                        </ul>

                        <div class="flex justify-between pt-3 pb-1 font-extrabold text-gray-600 bg-blue-800 text-xxs">
                            <div class="flex items-center w-3/12 px-6">Membership Start <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                            <div class="flex items-center justify-center w-3/12 px-6">Renewal Date <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                            <div class="flex items-center w-3/12 px-6">Actions <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                        </div>
                        <div class="flex items-center w-full py-4 font-semibold border-b border-gray-800">
                            <div class="flex items-center w-3/12 px-6">{{ eliteMembership.purchase_date ?? '' }}</div>
                            <div class="w-3/12 text-center">{{ eliteMembership.expiry_date ?? '' }}</div>
                            <div class="w-3/12" v-if="autorenewMembership">
                                <form :action="route('bank.cancel-autorenew')" method="POST">
                                    <input type="hidden" name="_token" :value="csrf">
                                    <button type="submit" class="px-2 py-2 font-semibold text-white rounded-lg btn-gradient-blue">
                                        Cancel Auto Renew
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full overflow-x-auto rounded-lg">
                <div class="mt-4 mb-4 text-xs text-white rounded-lg min-w-max bank-table-wrapper">
                    <Tabs>
                        <Tab name="Deposit Logs" :selected="true">
                            <div class="flex justify-between px-6 pt-3 pb-1 font-extrabold text-gray-600 bg-blue-800 text-xxs">
                                <div class="flex items-center w-2/12">Date <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                                <div class="flex items-center w-1/12">ID <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                                <div class="flex items-center w-2/12">Amount <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                                <div class="flex items-center w-2/12">Complete <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                            </div>
                            <div v-for="depositLog in depositLogs.data" :key="depositLog.id" class="flex items-center justify-between px-6 py-3 font-semibold bg-blue-600 border-b border-gray-800">
                                <div class="flex w-2/12">{{ format(depositLog.created_at, 'yyyy-MM-dd HH:mm:ss') }}</div>
                                <div class="w-1/12">{{ depositLog.id }}</div>
                                <div class="w-2/12">${{ formatCurrency(depositLog.deposit_amount) }}</div>
                                <div class="w-2/12">
                                    <i :class="depositLog.completed_payment ? 'text-green-500 fas fa-check-circle' : 'text-red-600 fas fa-times-circle'"></i>
                                    {{ depositLog.completed_payment ? 'Completed' : 'Pending' }}
                                </div>
                            </div>
                            <div class="mt-5">
                                <PaginationLinks :links="depositLogs.links" />
                            </div>
                        </Tab>
                        <Tab name="Withdrawal Logs">
                            <div class="flex justify-between px-6 pt-3 pb-1 font-extrabold text-gray-600 bg-blue-800 text-xxs">
                                <div class="flex items-center w-2/12">Date <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                                <div class="flex items-center w-1/12">ID <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                                <div class="flex items-center w-2/12">Amount <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                                <div class="flex items-center w-2/12">Complete <i aria-hidden="true" class="ml-1 text-gray-600 fas fa-sort"></i></div>
                            </div>
                            <div v-for="withdrawalLog in withdrawalLogs.data" :key="withdrawalLog.id" class="flex items-center justify-between px-6 py-3 font-semibold bg-blue-600 border-b border-gray-800">
                                <div class="flex w-2/12">{{ format(withdrawalLog.created_at, 'yyyy-MM-dd HH:mm:ss') }}</div>
                                <div class="w-1/12">{{ withdrawalLog.id }}</div>
                                <div class="w-2/12">${{ formatCurrency(withdrawalLog.withdrawal_amount) }}</div>
                                <div class="w-2/12">
                                    <i :class="withdrawalLog.complete ? 'text-green-500 fas fa-check-circle' : (withdrawalLog.cancelled_at ? 'text-red-500 fas fa-xmark' : 'text-yellow-400 fas fa-clock')"></i>
                                    {{ withdrawalLog.complete ? 'Completed' : (withdrawalLog.cancelled_at ? 'Cancelled' : 'Pending') }}
                                </div>
                                <div class="w-2/12" v-if="withdrawalLog.cancellable">
                                    <form :action="route('bank.withdrawal.cancel', withdrawalLog.id)" method="POST">
                                        <input type="hidden" name="_token" :value="csrf">
                                        <button class="btn-gradient-blue rounded-lg px-2 py-2 text-white font-semibold" type="submit">
                                            Cancel withdrawal
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div class="mt-5">
                                <PaginationLinks :links="withdrawalLogs.links" />
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <div class="w-full mb-4">
                <BankActivityLogTable :logs="balanceActivityLogs" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { usePage, Link } from '@inertiajs/vue3';
import BankActivityLogTable from '../../InertiaComponents/Bank/BankActivityLog.vue';
import PaginationLinks from "../../InertiaComponents/Pagination/PaginationLinks.vue";
import Tabs from '../../components/partials/Tabs.vue';
import Tab from '../../components/partials/Tab.vue';
import {format} from "date-fns";
import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";

const { props } = usePage();
const { depositLogs, withdrawalLogs, balanceActivityLogs, eliteMembership, csrf } = props;
const balance = computed(() => props.auth.user.balance);
const credits = computed(() => props.auth.user.credits);
const autorenewMembership = computed(() => props.auth.user.autorenew_membership);


const formatCurrency = (value) => {
    return Number(value).toFixed(2);
};

</script>

<script setup>

import {computed, ref} from "vue";

const props = defineProps({
    tabs: {
        type: Array,
        required: true,
    },
    defaultOpenTab: {
        type: String,
        required: false,
    },
});

if (!props.tabs || props.tabs.length <= 0) {
    throw new Error("Must provide tab names for Tabs to work!")
}

const defaultTab = computed(() => {
    if (props.defaultOpenTab) {
        return props.tabs.find(tab => tab.slotName === props.defaultOpenTab);
    }

    return props.tabs[0];
})

const activeTab = ref(defaultTab.value);

function tabClicked(tab) {
    activeTab.value = tab;
}

const activeTabClasses = 'border-blue-400 text-blue-400 font-semibold';
const inactiveTabClasses = 'border-transparent cursor-pointer text-gray-400 font-light hover:border-blue-400';

</script>

<template>
    <div>
        <slot name="header" :tab-names="tabs">
            <div class="flex items-center gap-4 bg-blue-600 px-6 pt-4 rounded-t-lg">
                <div
                    v-for="tabName in tabs" :key="tabName.slotName"
                    :class="`border-b-2 pb-2 text-xs transition-all ${activeTab.slotName === tabName.slotName ? activeTabClasses : inactiveTabClasses}`"
                    @click="tabClicked(tabName)"
                >
                    {{ tabName.name }}
                </div>
            </div>
        </slot>

        <div
            class="bg-blue-700 rounded-b-lg"
        >
            <slot :name="activeTab.slotName">
                No content for tab {{ activeTab.name }}
            </slot>
        </div>
    </div>
</template>

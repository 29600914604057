<script setup>

import {
    numberWithCommas,
    numberWithCommasNoDecimals,
    roundToTwoDecimalPlaces
} from "../../Services/utils.js";
import {Link} from '@inertiajs/vue3';

const props = defineProps({
    teams: {
        type: Object,
        required: true,
    },
    leaderboard: {
        type: Object,
        required: true,
    },
    authUser: {
        type: Object,
        required: true,
    },
    showRank: {
        type: Boolean,
        required: false,
        default: true,
    },
    activeFilter: {
        type: String,
        required: true,
    },
});

function calculateTeamPosition(index) {
    const page = props.teams.current_page;
    const perPage = props.teams.per_page;

    return (page - 1) * perPage + index + 1;
}

function isUserAMemberOfTeam(team) {
    return team.members.some(member => member.username === props.authUser.username);
}

// Returns 'owner' if the team's owner_id matches the user's ID
// and 'member' if the User is within the team's member records.
function userRoleInTeam(team) {
    if (team.owner_id === props.authUser.id) {
        return 'owner';
    }

    if (isUserAMemberOfTeam(team)) {
        return 'member';
    }

    return 'guest';
}

</script>

<template>
    <div class="overflow-x-auto">
        <table
            v-if="teams.total > 0"
            class="w-full rounded-lg overflow-hidden"
        >
            <thead>
            <tr class="text-xs font-semibold text-gray-400 bg-blue-800">
                <th v-if="showRank" class="px-6 pt-4 pb-2 text-left max-w-[20px]">
                    Rank
                </th>
                <th class="px-6 pt-4 pb-2 text-left">
                    Team
                </th>
                <!--            <th class="px-6 pt-4 pb-2 text-left">-->
                <!--                Socials-->
                <!--            </th>-->
                <th class="px-6 pt-4 pb-2 text-left">
                    <p class="text-center">
                        ELO Score

                        <i v-if="leaderboard.field === 'competitive_score'" class="fa-solid fa-chevron-down"></i>
                    </p>
                </th>
                <th class="px-6 pt-4 pb-2 text-left">
                    <p class="text-center">
                        Views

                        <i v-if="leaderboard.field === 'views'" class="fa-solid fa-chevron-down"></i>
                    </p>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(teamRank, index) in teams.data"
                :key="teamRank.id"
                :class="{
                'bg-blue-800' : index % 2 === 0,
                'bg-blue-700' : index % 2 !== 0,
                'border-blue-500 border-2': isUserAMemberOfTeam(teamRank.team),
            }"
            >
                <td v-if="showRank">
                    <div :class="{
                    'from-yellow-500 via-transparent to-transparent' : calculateTeamPosition(index) === 1,
                    'from-gray-300 via-transparent to-transparent' : calculateTeamPosition(index) === 2,
                    'from-orange-500 via-transparent to-transparent' : calculateTeamPosition(index) === 3,
                    'border-transparent' : calculateTeamPosition(index) > 3,
                    'px-6 py-6 whitespace-nowrap bg-gradient-to-r': true,
                }">
                    <span :class="{
                        'gold-rank' : calculateTeamPosition(index) === 1,
                        'silver-rank' : calculateTeamPosition(index) === 2,
                        'bronze-rank' : calculateTeamPosition(index) === 3,
                        'bg-transparent' : calculateTeamPosition(index) > 3,
                        'px-6 py-4 whitespace-nowrap rounded-full shadow-lg': true,
                    }">
                        <span v-if="calculateTeamPosition(index) > 3">
                            {{ calculateTeamPosition(index) }}
                        </span>
                    </span>
                    </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                        <div class="flex-shrink-0 w-8 h-8">
                            <img class="object-cover w-8 h-8 rounded-full"
                                 :src="teamRank.team.storage_avatar"
                                 :alt="`${teamRank.team.username}'s avatar`">
                        </div>
                        <Link :href="route('teams.show', [teamRank.team.id])" class="ml-4">
                            <div class="text-sm font-medium leading-5 text-blue-400">
                                {{ teamRank.team.name }}
                                <span v-if="userRoleInTeam(teamRank.team) === 'owner'"
                                      class="text-blue-400 font-semibold text-sm">
                                    (Owner)
                                </span>

                                <span v-else-if="userRoleInTeam(teamRank.team) === 'member'"
                                      class="text-green-500 font-semibold text-sm">
                                    (Member)
                                </span>
                            </div>

                            <!--                        <div class="text-blue-500 text-xs font-bold">-->
                            <!--                            <a :href="route('user.show', [teamRank.user.slug])">-->
                            <!--                                View Profile-->
                            <!--                            </a>-->
                            <!--                        </div>-->
                        </Link>
                    </div>
                </td>

                <!--            <td class="px-6 py-4 whitespace-nowrap">-->
                <!--                <div v-if="!!teamRank.user.socials" class="flex gap-1">-->
                <!--                    <a v-if="!!teamRank.user.socials.instagram"-->
                <!--                       :href="`https://www.instagram.com/${teamRank.user.socials.instagram}`">-->
                <!--                        <i class="mr-1 text-lg fab fa-instagram instagram-blue"></i>-->
                <!--                    </a>-->
                <!--                    <a v-if="!!teamRank.user.socials.twitter" :href="`https://x.com/${teamRank.user.socials.twitter}`">-->
                <!--                        <i class="mr-1 text-lg fab fa-twitter twitter-color"></i>-->
                <!--                    </a>-->
                <!--                    <a v-if="!!teamRank.user.socials.facebook"-->
                <!--                       :href="`https://www.facebook.com/${teamRank.user.socials.facebook}`">-->
                <!--                        <i class="mr-1 text-lg fab fa-facebook facebook-blue"></i>-->
                <!--                    </a>-->
                <!--                    <a v-if="!!teamRank.user.socials.twitch"-->
                <!--                       :href="`https://www.twitch.tv/${teamRank.user.socials.twitch}`">-->
                <!--                        <i class="mr-1 text-lg fab fa-twitch twitch-purple"></i>-->
                <!--                    </a>-->
                <!--                    <div v-if="!!teamRank.user.socials.discord" class="flex items-center justify-start">-->
                <!--                        <i class="mr-1 text-lg fab fa-discord discord-color"></i>-->
                <!--                        <p class="text-xs">{{ teamRank.user.socials.discord }}</p>-->
                <!--                    </div>-->
                <!--                    <a v-if="!!teamRank.user.socials.youtube"-->
                <!--                       :href="`https://www.youtube.com/${teamRank.user.socials.youtube}`">-->
                <!--                        <i class="mr-1 text-lg fab fa-youtube youtube-color"></i>-->
                <!--                    </a>-->
                <!--                </div>-->
                <!--            </td>-->

                <td class="px-6 py-4">
                    <div
                        class="px-3 py-1 mr-2 font-semibold text-center rounded-full w-full text-md">
                        <span v-if="!!teamRank.competitive_score_change">
                            <span v-if="!!teamRank.competitive_score_change" class="text-md">
                                <i :class="{'fas mr-2': true,
                                 'fa-arrow-up text-green-500': teamRank.competitive_score_change > 0,
                                    'fa-arrow-down text-red-500': teamRank.competitive_score_change < 0}"></i>
                                <i class="text-blue-500 fas fa-crosshairs text-xl" aria-hidden="true"></i>
                                {{ numberWithCommas(roundToTwoDecimalPlaces(teamRank.competitive_score_change)) }}
                            </span>

                            <span class="text-sm ml-2">
                                (Total:
                                {{ numberWithCommas(roundToTwoDecimalPlaces(teamRank.competitive_score)) }})
                            </span>
                        </span>

                        <p v-else class="text-md">
                            <i class="mr-1 text-blue-500 fas fa-crosshairs text-xl" aria-hidden="true"></i>
                            {{ numberWithCommas(roundToTwoDecimalPlaces(teamRank.competitive_score)) }}
                        </p>
                    </div>
                </td>

                <td class="px-6 py-4">
                    <div
                        class="px-3 py-1 mr-2 font-semibold text-center ounded-full w-full text-md">

                        <span v-if="!!parseFloat(teamRank.views_change)">
                            <span class="text-md">
                                <i :class="{'fas mr-2': true,
                                 'fa-arrow-up text-green-500': parseFloat(teamRank.views_change) > 0,
                                    'fa-arrow-down text-red-500': parseFloat(teamRank.views_change) < 0}"></i>
                                <i class="text-blue-500 fas fa-eye -mr-1" aria-hidden="true"></i>
                                {{ numberWithCommasNoDecimals(teamRank.views_change) }}
                            </span>

                            <span class="text-sm ml-2">
                                (Total:
                                {{ numberWithCommasNoDecimals(teamRank.views) }})
                            </span>
                        </span>

                        <p v-else>
                            <i class="text-blue-500 fas fa-eye mr-1" aria-hidden="true"></i>
                            {{ numberWithCommasNoDecimals(teamRank.views) }}
                        </p>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
table th {
    @apply bg-blue-800 text-gray-400 text-xs pl-4 py-2 uppercase font-medium px-6 pt-4 pb-2 text-left;
}

.gold-rank {
    background-image: url('/images/leaderboard/rank-markers/gold.png');
    background-position: center;
    background-size: contain
}

.silver-rank {
    background-image: url('/images/leaderboard/rank-markers/silver.png');
    background-position: center;
    background-size: contain
}

.bronze-rank {
    background-image: url('/images/leaderboard/rank-markers/bronze.png');
    background-position: center;
    background-size: contain
}
</style>

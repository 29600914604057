<template>
    <div id="main" class="px-4 py-4 text-white md:px-0">
        <div class="container mx-auto">
            <div id="breadcrumbs" class="mb-5 text-xs">
                Home <span class="ml-1 mr-1 font-extrabold text-gray-600">></span> User <span
                class="ml-1 mr-1 font-extrabold text-gray-600">></span> <span
                class="ml-1 mr-1 text-gray-600">Settings</span>
            </div>

            <div class="flex flex-wrap justify-between">
                <Navigation/>

                <div class="w-full px-4 mt-8 border-l border-gray-800 md:w-10/12 md:px-6 md:mt-0">

                    <h2 class="text-2xl font-bold">Personal Settings</h2>
                    <h3 class="mb-2 text-sm font-bold">Your Details</h3>
                    <hr class="py-2 border-gray-800 border-b-1">
                    <form @submit.prevent="submit">
                        <div id="form-group">
                            <div class="py-1 text-xs text-gray-600">Username</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <input disabled v-model="form.username" name="username" type="text"
                                       class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                            </div>
                        </div>
                        <div id="form-group">
                            <div class="py-1 text-xs text-gray-600">About You</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                <textarea v-model="form.about" name="about"
                          class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"></textarea>
                            </div>
                        </div>
                        <div id="form-group">
                            <div class="py-1 text-xs text-gray-600">Gender</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <select v-model="form.gender" name="gender"
                                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                    <option value="Prefer Not to Say">Prefer Not To Say</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                        </div>
                        <div id="form-group">
                            <div class="py-1 text-xs text-gray-600">Timezone</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <select v-model="form.timezone" name="timezone"
                                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                    <option value="" disabled selected class="text-gray-500">Timezone</option>
                                    <option v-for="timezone in timezones" :key="timezone" :value="timezone">
                                        {{ timezone }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div v-if="form.elite_membership" id="form-group">
                            <label for="avatar" class="py-1 text-xs text-gray-600">Avatar (leave open to keep current
                                avatar)</label>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <input name="avatar" type="file" accept="image/png,image/jpeg,image/gif"
                                       @change="handleAvatarChange"
                                       class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                            </div>
                        </div>

                        <div v-if="form.elite_membership" id="form-group">
                            <label for="banner" class="py-1 text-xs text-gray-600">Banner (leave open to keep current
                                banner)</label>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <input name="banner" type="file" accept="image/png,image/jpeg"
                                       @change="handleBannerChange"
                                       class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                            </div>
                        </div>

                        <button type="submit"
                                class="inline-block float-left px-16 py-3 mt-4 font-semibold text-right uppercase rounded btn-gradient-blue text-xxs">
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue';
import {useForm, usePage} from '@inertiajs/vue3';
import Navigation from '../../../components/SettingsNav.vue';


const page = usePage();
let props = page.props;

const form = useForm({
    username: props.auth.user.username,
    about: props.auth.user.about,
    gender: props.auth.user.gender,
    timezone: props.auth.user.timezone,
    elite_membership: props.auth.user.elite_membership,
    avatar: null,
    banner: null
});

const timezones = props.timezones;

const successMessage = ref('');
const errorMessage = ref('');

const handleAvatarChange = (event) => {
    form.avatar = event.target.files[0];
};

const handleBannerChange = (event) => {
    form.banner = event.target.files[0];
};

const submit = () => {
    form.post(route('settings.personal.update'), {
        onSuccess: (page) => {
            successMessage.value = 'Successfully updated your personal details settings';
            errorMessage.value = '';
        },
        onError: (err) => {
            errorMessage.value = 'Failed to update personal details settings';
            successMessage.value = '';
        }
    });
};

</script>

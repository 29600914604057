class GamerTag {
    constructor(name, accountFieldName) {
        this.name = name;
        this.accountFieldName = accountFieldName;
    }
}


// Provides the Gamer Tags for a given Game and Platform.
export function getTagsByGameAndPlatform(game, platform) {
    const platformTags = getTagsByPlatform(platform);

    if (game.name === 'Axie Infinity') {
        return [
            ...platformTags,
            new GamerTag('Ronin Wallet Address', 'ronin_wallet_address'),
        ];
    }

    const gameTags = getTagsByGame(game);

    return [
        ...platformTags,
        ...gameTags,
    ];
}

export function getTagsByGameAndAllPlatformsAssociated(game) {
    const gameTags = getTagsByGame(game);
    const platformTags = game.platforms.map(platform => getTagsByPlatform(platform)).flat();

    return [
        ...gameTags,
        ...platformTags,
    ];
}

export function getTagsByPlatform(platform) {
    if (platform.name === 'Playstation 4' || platform.name === 'Playstation 5') {
        return [
            new GamerTag('PlayStation Network', 'psn'),
        ];
    }

    if (platform.name === 'Xbox One') {
        return [
            new GamerTag('XBox Live', 'xbl'),
        ];
    }

    return [];
}

// Provides the Gamer Tags which are specific to a given Game.
export function getTagsByGame(game)
{
    if (game.name === "Fortnite") {
        return [
            new GamerTag('Epic Games ID', 'epic'),
        ];
    }

    if (game.name === "Call of Duty; Modern Warfare III (2023)") {
        return [
            new GamerTag('Activision ID', 'activision'),
        ];
    }

    if (game.name === "XDefiant") {
        return [
            new GamerTag('Ubisoft ID', 'ubisoft_id'),
        ];
    }

    if (game.name === "Fall Guys") {
        return [
            new GamerTag('Steam ID', 'steam'),
        ];
    }

    if (game.name === "EA FC24") {
        return [
            new GamerTag('PlayStation Network', 'psn'),
            new GamerTag('XBox Live', 'xbl'),
            new GamerTag("Steam ID", "steam"),
        ];
    }

    if (game.name === "Call of Duty: Vanguard") {
        return [
            new GamerTag('PlayStation Network', 'psn'),
            new GamerTag('XBox Live', 'xbl'),
            new GamerTag('Activision ID', 'activision'),
        ];
    }

    if (game.name === "Call of Duty: World War 2") {
        return [
            new GamerTag('PlayStation Network', 'psn'),
            new GamerTag('XBox Live', 'xbl'),
        ];
    }

    if (game.name === "Call of Duty: Black Ops 3") {
        return [
            new GamerTag('PlayStation Network', 'psn'),
            new GamerTag('XBox Live', 'xbl'),
        ];
    }

    if (game.name === "Call of Duty: Cold War") {
        return [
            new GamerTag('PlayStation Network', 'psn'),
            new GamerTag('XBox Live', 'xbl'),
            new GamerTag('Activision ID', 'activision'),
        ];
    }

    if (game.name === "Call of Duty: Warzone") {
        return [
            new GamerTag('PlayStation Network', 'psn'),
            new GamerTag('XBox Live', 'xbl'),
            new GamerTag('Activision ID', 'activision'),
        ];
    }

    if (game.name === "Call of Duty: Black Ops 4") {
        return [
            new GamerTag('PlayStation Network', 'psn'),
            new GamerTag('XBox Live', 'xbl'),
        ];
    }

    if (game.name === 'Apex Legends') {
        return [
            new GamerTag('Apex Legends ID', 'apex_legends_id'),
        ];
    }

    if (game.name === 'Call of Duty: Warzone' || game.name === 'Call of Duty: Cold War' || game.name === 'Call of Duty: Modern Warfare') {
        return [
            new GamerTag('Activision ID', 'activision'),
        ];
    }

    if (game.name === 'Rogue Company') {
        return [
            new GamerTag('Rogue ID', 'rogue_id'),
        ];
    }

    if (game.name === 'Valorant') {
        return [
            new GamerTag('Riot ID', 'riot_id'),
        ];
    }

    return [];
}

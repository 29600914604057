<template>
    <div class="container mx-auto">
        <div class="flex items-center justify-center w-full h-full">
            <div class="w-full px-4 mb-6 lg:w-6/12">
                <form @submit.prevent="submitForm" class="w-full">
                    <div class="p-8 text-white bg-blue-600 border border-gray-800 rounded-lg lg:p-16">
                        <div id="inner" class="pb-6 mb-4 border-gray-800">
                            <div class="mb-6">
                                <div class="mb-1 text-4xl font-extrabold">Reset all game records</div>
                                <div class="text-sm text-gray-600">
                                    Reset all your game records
                                </div>
                            </div>

                            <div class="mb-6">
                                <ul class="list-disc">
                                    <li v-for="userGameRecord in userGameRecords" :key="userGameRecord.id" class="inline-block w-full py-1 text-xs font-semibold">
                                        {{ userGameRecord.game_name }}
                                        {{ `(${userGameRecord.wins} wins, ${userGameRecord.losses} losses)` }}
                                    </li>
                                    <li v-if="userGameRecords.length === 0">No game records.</li>
                                </ul>
                            </div>

                            <button v-if="parseFloat(user.balance) >= parseFloat(price)"
                                    type="submit"
                                    @click="confirmReset"
                                    class="w-full p-3 mt-3 text-xs font-semibold text-center uppercase bg-red-500 rounded">
                                Reset all game records (${{ price }})
                            </button>
                            <div v-else>
                                <p class="mb-2 text-red-500 font-semibold">
                                    You do not have sufficient funds to buy - add money?
                                </p>
                                <a :href="route('bank.deposit.create', { m: 'dc' })"
                                              class="btn-gradient-blue px-16 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block">
                                    Add Money
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { usePage, useForm } from '@inertiajs/vue3';

const { props } = usePage();
const user = props.auth.user;
const price = ref(props.price || 0);

// Define userGameRecords as a ref and initialize it with props.userGameRecords
const userGameRecords = ref([...props.userGameRecords]);

const form = useForm({});

const confirmReset = () => {
    return confirm('Are you sure you want to reset all stats? This can not be undone!');
};

const successMessage = ref('');
const errorMessage = ref('');

const submitForm = () => {
    form.post(route('store.all-stats-reset.store'), {
        onSuccess: (page) => {
            successMessage.value = 'Successfully reset all game records';
            errorMessage.value = '';
            userGameRecords.value = page.props.userGameRecords;
        },
        onError: (err) => {
            errorMessage.value = 'Failed to reset all game records';
            successMessage.value = '';
        }
    });
};
</script>

<template>
    <div class="container mx-auto">
        <div class="flex justify-center items-center w-full h-full">
            <div class="w-6/12 px-4 mb-6">
                <div class="bg-blue-600 text-white rounded-lg border border-gray-800 px-16 py-16">
                    <div id="inner" class="pb-6 border-gray-800 mb-4">
                        <div class="mb-6">
                            <div class="text-4xl font-extrabold mb-1">Buy Credits</div>
                            <div class="text-gray-400 text-sm">
                                Buy credits for use on {{ appName }}
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="text-xs text-gray-600 py-1">Your Balance</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <input disabled
                                       type="text"
                                       name="balance"
                                       class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                                       :value="`$${user.balance}`"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="text-xs text-gray-600 py-1">Purchasing Credits</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <input disabled
                                       type="text"
                                       name="credits_amount"
                                       class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                                       :value="creditPack.credit_amount"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="text-xs text-gray-600 py-1">Price</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <input disabled
                                       type="text"
                                       name="price"
                                       class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                                       :value="`$${creditPack.price}`"
                                />
                            </div>
                        </div>

                        <form @submit.prevent="submitForm" class="w-full">
                            <input type="hidden" name="creditpack_id" :value="creditPack.id">
                            <button v-if="parseFloat(user.balance) >= parseFloat(creditPack.price)"
                                    type="submit"
                                    class="mt-3 btn-gradient-blue px-16 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block">
                                <span>Buy Now</span>
                            </button>
                            <div v-else>
                                <p class="mb-2 text-red-500 font-semibold">
                                    You do not have sufficient funds to buy - add money?
                                </p>
                                <a :href="route('bank.deposit.create', { m: 'dc' })"
                                              class="btn-gradient-blue px-16 py-3 rounded uppercase font-medium text-xs w-full text-center inline-block">
                                    Add Money
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { usePage, useForm } from '@inertiajs/vue3';

const { props } = usePage();
const creditPack = props.creditPack;
const user = props.auth.user;
const appName = ref(props.appName);

const form = useForm({
    creditpack_id: creditPack.id,
});

const successMessage = ref('');
const errorMessage = ref('');

const submitForm = () => {
    form.post(route('credits.checkout.store'), {
        onSuccess: () => {
            successMessage.value = 'Successfully purchased credits';
            errorMessage.value = '';
        },
        onError: (err) => {
            errorMessage.value = 'Failed to purchase credits';
            successMessage.value = '';
        }
    });
};
</script>

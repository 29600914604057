<template>
    <div class="container mx-auto">
        <div class="flex justify-center items-center w-full h-full">
            <div class="w-full sm:w-6/12 px-4 mb-6">
                <div class="bg-blue-600 text-white rounded-lg border border-gray-800 px-16 py-16">
                    <div id="inner" class="pb-6 border-gray-800 mb-4">
                        <div class="mb-6">
                            <div class="text-4xl font-extrabold mb-1">Support A Creator</div>
                            <div class="text-gray-600 text-sm">
                                Support your favourite creator by entering their creator code below.
                            </div>
                        </div>
                        <form @submit.prevent="validateForm" class="w-full">
                            <div class="form-group">
                                <div class="text-xs text-gray-600 py-1">Support A Creator Code</div>
                                <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                    <input
                                        id="creator-code"
                                        type="text"
                                        v-model="form.creator_code"
                                        @input="validateInput"
                                        class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                                    />
                                </div>
                                <div v-if="validationError" class="text-red-500 text-xs">
                                    {{ validationError }}
                                </div>
                            </div>
                            <button
                                class="mt-3 btn-gradient-blue text-white px-16 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block">
                                <span>Support</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref} from 'vue';
import {useForm} from '@inertiajs/vue3';
import Flash from "../../components/Flash.vue";

const form = useForm({
    creator_code: '',
});


const validationError = ref('');

const validateInput = () => {
    if (form.creator_code.trim() === '') {
        validationError.value = 'Creator code cannot be empty';
        return;
    }
    validationError.value = '';
};

const submitForm = () => {
    form.post(route('support-creator.store'));
};

const validateForm = () => {
    validateInput();
    if (validationError.value === '') {
        submitForm();
    }
};
</script>

v-for="(error, key) in flash.errors" :key="key">

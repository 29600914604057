<template>
    <div id="main" class="px-4 py-4 text-white md:px-0">
        <div class="container mx-auto">
            <div id="breadcrumbs" class="mb-5 text-xs">
                Home <span class="ml-1 mr-1 font-extrabold text-gray-600">></span> User <span class="ml-1 mr-1 font-extrabold text-gray-600">></span> <span class="ml-1 mr-1 text-gray-600">Settings</span>
            </div>

            <div class="flex flex-wrap justify-between">
                <Navigation />

                <div class="w-full px-4 mt-8 border-l border-gray-800 md:w-10/12 md:px-6 md:mt-0">
                    <h2 class="text-2xl font-bold">Account Settings</h2>
                    <h3 class="mb-2 text-sm font-bold">Email</h3>
                    <hr class="py-2 border-gray-800 border-b-1">
                    <form @submit.prevent="submit">
                        <div id="form-group">
                            <div class="py-1 text-xs text-gray-600">Email *</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <input v-model="form.email" type="text"
                                       class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                            </div>
                        </div>

                        <div id="form-group">
                            <div class="py-1 text-xs text-gray-600">New password (leave empty to keep current password)</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <input v-model="form.password" type="password" name="password"
                                       class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                            </div>
                        </div>

                        <div id="form-group">
                            <div class="py-1 text-xs text-gray-600">New password confirmation</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <input v-model="form.password_confirmation" type="password" name="password_confirmation"
                                       class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                            </div>
                        </div>

                        <div id="form-group">
                            <div class="py-1 text-xs text-gray-600">Current password</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <input v-model="form.current_password" type="password" name="current_password"
                                       class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                            </div>
                        </div>

                        <button type="submit"
                                class="inline-block float-left px-16 py-3 mt-4 font-medium text-right uppercase rounded btn-gradient-blue text-xxs">
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useForm, usePage } from '@inertiajs/vue3';
import Navigation from '../../../components/SettingsNav.vue';

const page = usePage();
let props = page.props

const form = useForm({
    email: props.auth.user.email,
    password: '',
    password_confirmation: '',
    current_password: ''
});

const successMessage = ref(props.flash.success || '');
const errorMessage = ref(props.flash.error || '');

const submit = () => {
    form.post(route('settings.account.update'), {
        onSuccess: () => {
            successMessage.value = 'Successfully updated your account settings';
            errorMessage.value = '';
            form.password = '';
            form.password_confirmation = '';
            form.current_password = '';
        },
        onError: (err) => {
            errorMessage.value = 'Failed to update account settings';
            successMessage.value = '';
        }
    });
};
</script>

<script setup>
import Breadcrumbs from "../../../InertiaComponents/Navigation/Breadcrumbs.vue";
import { formatDate } from "date-fns";
import {usePage, Link, router} from "@inertiajs/vue3";
const { props } = usePage();

const trophyLogs = props.trophyLogs;

const claimPrize = async (tournament, trophyLog) => {
    router.post(
        route('user.prize.claim', {
            tournament: tournament,
            trophyLog: trophyLog
        }),
        {},
        {
            onSuccess: () => {
                // remove the claimed trophy from the list
                const index = trophyLogs.findIndex(trophy => trophy.id === trophyLog);
                trophyLogs.splice(index, 1);
            },
        });
};

</script>

<template>
    <div id="main" class="py-6 text-white">
        <div class="container mx-auto">
            <!-- Breadcrumbs -->
            <Breadcrumbs :breadcrumbs="[
                    {name: 'Home', link: '/'},
                    {name: 'Tournaments', link: '/tournaments'},
                    {name: 'Prizes'},
                ]" classes="mb-2"/>

            <!-- Page Title -->
            <div class="flex items-center">
                <div class="text-4xl font-extrabold">My Prizes</div>
            </div>
        </div>

        <!-- Trophies Table -->
        <div class="container mx-auto mt-5 mb-10">
            <div class="flex flex-wrap mb-12 -mx-4">
                <div class="w-full px-4 mb-6">
                    <div class="text-white bg-blue-600 border border-gray-800 rounded-lg">
                        <div id="prizes-header" class="flex items-center justify-between px-6 py-4">
                            <h2 class="font-semibold">Your Tournament Trophies</h2>
                        </div>

                        <!-- Table Header -->
                        <div class="flex pt-3 pb-1 font-extrabold text-gray-50 bg-blue-800 text-xs">
                            <div class="table-columns w-3/12 ">Tournament</div>
                            <div class="table-columns w-2/12">Game</div>
                            <div class="table-columns w-2/12">Trophy</div>
                            <div class="table-columns w-3/12">Action</div>
                            <div class="table-columns w-2/12">Date Won</div>
                        </div>


                        <!-- Table Body -->
                        <div v-if="!!trophyLogs.length" v-for="trophy in trophyLogs" :key="trophy.id" class="flex bg-blue-800 border-b border-gray-700">
                            <!-- Tournament -->
                            <div class="table-columns w-3/12 px-2 py-2">
                                <Link :href="route('tournaments.show', {tournament: trophy.tournament.slug})" class="flex items-center">
                                        <img :src="trophy.tournament.storage_custom_tournament_banner_url" :alt="trophy.tournament.name" class="w-8 h-8 mr-2 rounded-full" />
                                        <div class="font-semibold text-white text-xs">{{ trophy.tournament.name }}</div>
                                </Link>
                            </div>

                            <!-- Tournament Game -->
                            <div class="table-columns w-2/12 px-2 py-2">
                                <div class="text-center">
                                    <div class="py-2 font-semibold uppercase bg-gray-800 rounded-full text-xs">
                                        {{ trophy.tournament.game.name }}
                                    </div>
                                </div>
                            </div>


                            <!-- Trophy -->
                            <div class="table-columns w-2/12 px-2 py-2">
                                <div class="text-center">
                                    <div class="py-1 font-semibold uppercase bg-gray-800 rounded-full text-xs">
                                        <div v-if="trophy.trophy_type === 'gold'" class="text-yellow-500"><i class="mr-1 font-medium text-yellow-500 fa-solid fa-trophy" aria-hidden="true"></i>Gold
                                        </div>
                                        <div v-else-if="trophy.trophy_type === 'silver'" class="text-gray-400"><i class="mr-1 font-medium text-gray-400 fa-solid fa-trophy" aria-hidden="true"></i>Silver
                                        </div>
                                        <div v-else-if="trophy.trophy_type === 'bronze'" class="text-orange-500"><i class="mr-1 font-medium text-orange-500 fa-solid fa-trophy" aria-hidden="true"></i>Bronze
                                        </div>
                                        <div v-else class="text-gray-400"><i class="mr-1 font-medium text-gray-400 fa-solid fa-trophy" aria-hidden="true"></i>Participation
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Action -->
                            <div class="table-columns w-3/12 px-2 py-2">
                                <button
                                    v-if="!trophy.claimed"
                                    @click="claimPrize(trophy.tournament.slug, trophy.id)"
                                    class="px-6 py-2 font-medium uppercase bg-blue-400 rounded-lg text-xs text-gray-50 hover:bg-blue-900"
                                >
                                    <i class="mr-2 fas fa-hands" aria-hidden="true"></i>Claim Prize
                                </button>
                                <span v-else class="text-gray-600">Already Claimed</span>
                            </div>

                            <!-- Date Won -->
                            <div class="table-columns w-2/12 px-2 py-2 font-semibold text-xs">
                                <i class="mr-1 text-blue-500 fas fa-clock" aria-hidden="true"></i>
                                {{ formatDate(new Date(trophy.created_at), 'MMM dd, yyyy') }}
                            </div>
                        </div>
                        <div v-else class="flex items-center justify-center py-4 text-gray-400">No prizes to claim.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    text-align: left;
    padding: 8px;
}

.table-columns {
    @apply flex items-center justify-start px-6;
}
</style>

<script setup>
import { Link, useForm, usePage, router } from "@inertiajs/vue3";
import { computed, ref, watch } from "vue";
import { formatDistanceToNow } from "date-fns";
import Tabs from "../../components/partials/Tabs.vue";
import Tab from "../../components/partials/Tab.vue";
import TournamentRegisterButton from "../../components/TournamentRegisterButton.vue";
import TournamentBracket from "../../InertiaComponents/Tournament/TournamentBracket.vue";
import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";

const props = defineProps({
    tournament: Object,
    twitchUrl: String,
    enteredTeam: Object,
    teams: Object,
    userTeams: Object,
    tournament_owners: Object,
    tournament_owners: Array
});

const tournament = computed(() => props.tournament);
const twitchUrl = computed(() => props.twitchEmbedUrl);
const enteredTeam = computed(() => props.enteredTeam);
const teams = computed(() => props.teams);
const userTeams = computed(() => props.userTeams);
const tournament_owners = props.tournament.owners;
const tournament_owner_user_ids = props.tournament.owners.map(owner => owner.user_id);

const page = usePage();
const user = computed(() => page.props.auth.user);

const isOwner = computed(() => {
    return user.value && tournament_owner_user_ids.includes(user.value.id);
});

const backgroundImg = computed(() => {
    const tournament = props.tournament;

    if (!!tournament.storage_custom_tournament_banner_url) {
        return tournament.storage_custom_tournament_banner_url;
    }

    if (!!tournament.game) {
        return tournament.game.storage_header_url;
    }

    return null;
});

const generateBracket = () => {
    router.post(route('tournaments.generate-bracket', 
        { tournament: tournament.value.slug })
    );
    // Refetch the data without reloading the page
    router.reload();
};

const resetBracket = () => {
    router.post(route('tournaments.reset-bracket', 
        { tournament: tournament.value.slug })
    );
    // Refetch the data without reloading the page
    router.reload();
};

const toggleBracketVisibility = () => {
    router.post(route('tournaments.toggle-bracket-visibility', 
        { tournament: tournament.value.slug }), 
    );
    // Refetch the data without reloading the page
    router.reload();
};

const disputedMatches = computed(() => {
    return props.tournament.matches.filter(match => match.disputed);
});

const nonDisputedMatches = computed(() => {
    const data = props.tournament.matches.filter(match => !match.disputed);
    return data
});

// State to toggle the non-disputed matches visibility
const showNonDisputed = ref(true);

const toggleNonDisputed = () => {
    showNonDisputed.value = !showNonDisputed.value;
};

// Create a forms object to hold useForm instances for each match
const forms = ref({});

watch(
    () => tournament.value.matches,
    (matches) => {
        const newForms = {};
        matches?.forEach(match => {
            if (forms.value[match.id]) {
                // Preserve existing form state
                newForms[match.id] = forms.value[match.id];
            } else {
                // Initialize new form
                newForms[match.id] = useForm({
                    winner_id: match.winner_id || null,
                    finished: match.finished || false,
                });
            }
        });
        forms.value = newForms;
    },
    { immediate: true, deep: true }
);

// Method to advance a match
const advanceMatch = (match, teamId) => {
    if (!teamId) {
        console.error('No team selected for advancement');
        return;
    }

    // Use window.confirm for a basic confirmation dialog
    const confirmation = window.confirm('Are you sure you want to advance this match?');

    if (confirmation) {
        // Proceed with advancing the match if the user confirms
        router.post(
            route('tournament.match.advance', {
                tournament: tournament.value.slug,
                match: match.id,
                team: teamId,
            }),
            {},
            {
                onSuccess: () => {
                    window.alert('The match has been successfully advanced.');
                    window.location.reload();
                },
                onError: (errors) => {
                    window.alert('Failed to advance the match. Please try again.');
                    console.error('Failed to advance match', errors);
                },
            }
        );
    }
};

// Method to reverse a match advancement
const reverseAdvancement = (match) => {
    const confirmation = window.confirm('Are you sure you want to reverse this match?');
    if (confirmation) {
        router.post(route('tournament.match.reverseAdvancement', {
            tournament: tournament.value.slug,
            match: match.id
        }), {}, {
            onSuccess: () => {
                window.location.reload();
            },
            onError: (errors) => {
                console.error('Failed to reverse match advancement', errors);
            }
        });
    }
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
    });
};

const teamLatestRounds = computed(() => {
    const teamRounds = {};
    props.tournament.matches.forEach(match => {
        if (match.team_one_id) {
            if (!teamRounds[match.team_one_id] || match.round > teamRounds[match.team_one_id]) {
                teamRounds[match.team_one_id] = match.round;
            }
        }
        if (match.team_two_id) {
            if (!teamRounds[match.team_two_id] || match.round > teamRounds[match.team_two_id]) {
                teamRounds[match.team_two_id] = match.round;
            }
        }
    });
    return teamRounds;
});

const teamsInLatestRound = (match) => {
    const team_one_latest_round = teamLatestRounds.value[match.team_one_id];
    const team_two_latest_round = teamLatestRounds.value[match.team_two_id];
    return (
        (!match.team_one_id || team_one_latest_round === match.round) &&
        (!match.team_two_id || team_two_latest_round === match.round)
    );
};

const teamsAreValid = (match) => {
    const teamOneValid = match.team_one?.name && match.team_one.name !== 'BYE' && match.team_one.name !== 'TBD';
    const teamTwoValid = match.team_two?.name && match.team_two.name !== 'BYE' && match.team_two.name !== 'TBD';
    return teamOneValid && teamTwoValid;
};

const teamMatchesByRound = computed(() => {
    const mapping = {};
    props.tournament.matches.forEach(match => {
        [match.team_one_id, match.team_two_id].forEach(teamId => {
            if (teamId) {
                if (!mapping[teamId]) {
                    mapping[teamId] = {};
                }
                mapping[teamId][match.round] = match;
            }
        });
    });
    return mapping;
});

const previousOpponentWasBye = (match, teamId) => {
    if (match.round === 1) {
        return false;
    }
    const previousRound = match.round - 1;
    const teamMatches = teamMatchesByRound.value[teamId];
    if (!teamMatches || !teamMatches[previousRound]) {
        return true;
    }
    const previousMatch = teamMatches[previousRound];
    const opponent =
        previousMatch.team_one_id === teamId ? previousMatch.team_two : previousMatch.team_one;
    if (!opponent || !opponent.name) {
        return true;
    }
    return opponent.name === 'BYE' || opponent.name === 'TBD';
};

const canReverseAdvancement = (match) => {
    const totalRounds = Math.ceil(Math.log2(tournament.value.bracket_size));

    if (match.round === 1 || match.round === totalRounds) {
        return false; // Cannot reverse in round 1 or final (as the prize will have been awarded)
    }

    if (!teamsInLatestRound(match) || !teamsAreValid(match)) {
        return false;
    }
    const teamOneId = match.team_one_id;
    const teamTwoId = match.team_two_id;
    const teamOneHadByeInPreviousRound = previousOpponentWasBye(match, teamOneId);
    const teamTwoHadByeInPreviousRound = previousOpponentWasBye(match, teamTwoId);

    if (match.round === tournament.value.bracket_size && match.finished) {
        return false;
    }

    return !teamOneHadByeInPreviousRound && !teamTwoHadByeInPreviousRound;
};

const timeUntilStart = computed(() => {
    return formatDistanceToNow(new Date(tournament.value.tournament_starts));
});

const calculateDaysSince = (dateString) => {
    const startDate = new Date(dateString);
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - startDate.getTime();
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
};

const determineAction = (match) => {
    const { team_one_reported_winner, team_two_reported_winner, start_date, team_one, team_two } = match;

    if (!team_one_reported_winner && !team_two_reported_winner) {
        const daysElapsed = calculateDaysSince(start_date);
        return `No result declared in ${daysElapsed} days`;
    }

    if (team_one_reported_winner && team_two_reported_winner) {
        return team_one_reported_winner === team_two_reported_winner
            ? `Winner: ${team_one_reported_winner === 1 ? team_one.name : team_two.name}`
            : 'Disputed';
    }

    if (team_one_reported_winner) {
        return `Pending ${team_two.name} declaration`;
    }

    if (team_two_reported_winner) {
        return `Pending ${team_one.name} declaration`;
    }

    return '';
};


const actionClass = (match) => {
    if (!match.team_one_reported_winner && !match.team_two_reported_winner) {
        return calculateDaysSince(match.start_date) > 3 ? 'bg-yellow-500 text-white' : 'bg-gray-500 text-white';
    }
    if (match.team_one_reported_winner && match.team_two_reported_winner) {
        return match.team_one_reported_winner === match.team_two_reported_winner
            ? 'bg-green-500 text-white'
            : 'bg-red-500 text-white';
    }
    return match.team_one_reported_winner ? 'bg-green-500 text-white' : 'bg-red-500 text-white';
};

const leaveTournamentForm = useForm({
    team_id: enteredTeam?.value?.id
});

const leaveTournamentPressed = () => {
    if (!enteredTeam.value) {
        console.error("No user teams found - cannot leave tournament");
        return;
    }

    axios.post('/api/tournaments/' + tournament.value.slug + '/leave', leaveTournamentForm, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((resp) => {
        window.location.href = "/tournaments/" + tournament.value.slug;
    }).catch((err) => {
        leaveTournamentForm.errors = err.response.data.errors;
    });
};
</script>



<template>
    <div>
        <!-- Hero Banner-->
        <div id="bg" class="tournament-game-header-img z-1"
             :style="{'background': `url('${backgroundImg}')`}"
        >
        </div>

        <!-- Tournament Quick Facts-->
        <div id="header" class="relative px-4 text-white lg:px-0">
            <div class="container mx-auto">
                <Breadcrumbs :breadcrumbs="[
                    {name: 'Home', link: '/'},
                    {name: 'Tournaments', link: '/tournaments'},
                    {name: tournament.name}
                ]" classes="mb-2"/>
                <div class="relative flex flex-row">
                    <div class="w-9/12 flex flex-wrap text-4xl font-extrabold">
                        <div class="col-span-1 row-span-2">
                            <div
                                class="items-center self-center hidden p-2 bg-blue-900 rounded-full border border-gray-700 sm:block z-20">
                                <img :src="tournament.game.storage_icon_url" class="w-16 h-16 lg:w-32 lg:h-32"
                                     alt="game-icon">
                            </div>
                        </div>

                        <div class="inline-block text-wrap mt-8 mx-2">
                            <div>
                                {{ tournament.name }}
                            </div>
                            <div
                                v-if="!tournament.finished && new Date(tournament.tournament_starts).getTime() > new Date().getTime()"
                                class="flex flex-row gap-6 mt-2">
                                <div class="text-2xl font-semibold">
                                    <i class="fa-solid fa-hourglass-start text-blue-400"></i> Tournament Starts in
                                    <span class="text-blue-400">{{ timeUntilStart }}</span>
                                </div>
                            </div>
                            <div
                                v-else-if="!tournament.finished && new Date(tournament.tournament_starts).getTime() < new Date().getTime()"
                                class="flex flex-row gap-6 mt-2">
                                <div class="text-2xl font-semibold">
                                    <i class="fa-solid fa-spinner text-blue-400"></i> Tournament
                                    <span class="text-blue-400">In Progress</span>
                                </div>
                            </div>
                            <div v-else-if="tournament.finished" class="flex flex-row gap-6 mt-2">
                                <div class="text-2xl font-semibold">
                                    <i class="fa-solid fa-lock text-yellow-400"></i> This Tournament has Finished
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--  Registration and Elite Purchase Options -->
        <div id="registration" class="flex flex-col px-4 text-white lg:px-0 relative">
            <!-- Registration Buttons -->
            <div class="container mx-auto mb-1">
                <div v-if="!!user">
                    <div v-if="!tournament.bracket_generated" class="flex flex-col sm:flex-row-reverse">
                        <div v-if="!enteredTeam" class="flex flex-col sm:flex-row gap-4">
                            <!-- Show if registration is open -->
                            <div v-if="new Date(tournament.tournament_starts).getTime() > new Date().getTime() &&
                                            new Date(tournament.registration_closes).getTime() > new Date().getTime() &&
                    ((tournament.bracket_size - tournament.teams.length != 0) && !tournament.bracket_generated) && !tournament.finished"
                                 class="stat-box">
                                Only <span class="text-blue-500 font-semibold">{{
                                    tournament.bracket_size - tournament.teams.length
                                }} </span> spaces remaining
                            </div>
                            <div v-if="!tournament.finished && new Date(tournament.registration_opens).getTime() < new Date().getTime() &&
                                            new Date(tournament.registration_closes).getTime() > new Date().getTime() &&
                                        (user.elite_membership || !tournament.elite_only)">
                                <TournamentRegisterButton :price="tournament.entry_fee"
                                                          :teams="userTeams"
                                                          :tournament="tournament"/>
                            </div>
                            <div v-if="tournament.elite_only && !user.elite_membership && new Date(tournament.tournament_starts).getTime() > new Date().getTime() &&
                                            new Date(tournament.registration_closes).getTime() > new Date().getTime()"
                                 class="mt-2">
                                This tournament requires an <span
                                class="text-blue-500 font-semibold">Elite Membership</span> to enter.
                            </div>
                            <div
                                v-if="tournament.elite_only && !user.elite_membership && new Date(tournament.registration_closes).getTime() > new Date().getTime()">
                                <a href="/store#membership"
                                   class="inline-block px-6 py-3 text-xs font-semibold text-center uppercase rounded-lg btn-gradient-blue animated-button">
                                    <span>Buy Elite</span>
                                </a>
                            </div>
                            <div v-if="new Date(tournament.registration_closes).getTime() < new Date().getTime()"
                                 class="stat-box">
                                <i class="fa fa-lock text-yellow-400 text-2xl"></i>
                                <span class="font-semibold text-white pl-1">Registration has now closed.</span>
                            </div>
                            <div v-if="new Date(tournament.registration_opens).getTime() > new Date().getTime()"
                            >
                                    <span class="stat-box">Registration Opens In {{
                                            formatDistanceToNow(tournament.registration_opens)
                                        }}</span>
                            </div>

                        </div>

                        <div v-else>
                            <div
                                v-if="user.id === enteredTeam.owner_id && new Date(tournament.tournament_starts).getTime() > new Date().getTime() && !tournament.bracket_generated">
                                <form @submit.prevent="leaveTournamentPressed">
                                    <input type="hidden" name="team_id" :value="enteredTeam.id">
                                    <button type="submit"
                                            class="relative inline-flex items-center self-end px-6 py-3 text-xs font-semibold bg-blue-500 rounded-lg btn-gradient-blue">
                                        Leave Tournament
                                    </button>
                                </form>
                            </div>
                            <div v-else>
                                <a
                                    class="relative inline-flex items-center self-end px-6 py-3 text-xs font-semibold bg-blue-500 rounded-lg btn-gradient-blue"
                                    :href="route('teams.show', enteredTeam.id)">
                                    <i class="mr-2 fas fa-eye"></i>
                                    View Team
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex items-center flex-wrap justify-end gap-2 pt-3">
                    <!-- Platform Box -->
                    <div id="stat-box"
                         class="stat-box relative">
                        <i class="text-white text-xl px-1 py-1 rounded-lg mr-2"
                           :class="`${tournament.platform.icon} ${tournament.platform.color}`"></i>
                        <div>
                            <div class="text-xs text-gray-400">Platform</div>
                            <div class="text-sm font-bold">{{ tournament.platform.name }}</div>
                        </div>
                    </div>

                    <!-- Game Mode Box -->
                    <div id="stat-box"
                         class="stat-box">
                        <i class="mr-2 text-blue-500 fas fa-gamepad text-xl"></i>
                        <div>
                            <div class="text-xs text-gray-400">Game Mode</div>
                            <div class="text-sm font-bold">{{ tournament.game_mode.name }}</div>
                        </div>
                    </div>

                    <!-- Tournament Start Box -->
                    <div id="stat-box"
                         class="stat-box">
                        <i class="mr-2 text-blue-500 fas fa-clock text-xl"></i>
                        <div>
                            <div class="text-xs text-gray-400">Tournament Start</div>
                            <div class="text-sm font-bold">{{ tournament.user_based_tournament_starts }}</div>
                        </div>
                    </div>

                    <!-- Registration Closes Box -->
                    <div id="stat-box"
                         class="stat-box">
                        <i class="mr-2 text-green-bright fas fa-calendar-check text-xl"></i>
                        <div>
                            <div class="text-xs text-gray-400">Registration Closes</div>
                            <div class="text-sm font-bold">{{ tournament.user_based_registration_closes }}</div>
                        </div>
                    </div>

                    <!-- Hosting Region Box -->
                    <div id="stat-box"
                         class="stat-box">
                        <i class="mr-2 text-orange-500 fas fa-globe text-xl"></i>
                        <div>
                            <div class="text-xs text-gray-400">Hosting Region</div>
                            <div v-if="tournament.region.name === 'Europe + North America East' "
                                 class="text-sm font-bold">Europe & NA East
                            </div>
                            <div v-else class="text-sm font-bold">{{ tournament.region.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Warning Message -->
        <div v-if="tournament.warning_text || tournament.rendered_warning_text_md" class="relative container mx-auto">
            <div
                class="flex flex-row px-4 py-3 my-2 text-base bg-red-600 border-t-4 border-red-900 rounded-b shadow-md">
                <svg class="w-6 h-6 mr-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                        d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                </svg>
                <div v-if="!tournament.rendered_warning_text_md">
                    <p class="font-bold">Warning!</p>
                    <ul>
                        <li class="ml-5 text-sm list-disc">
                            {{ tournament.warning_text }}
                        </li>
                    </ul>
                </div>
                <div v-else>
                    <p class="font-bold">Warning!</p>
                    <div v-html="tournament.rendered_warning_text_md" class="markdown-content">
                    </div>
                </div>
            </div>
        </div>

        <div id="main" class="flex flex-col relative px-4 text-white lg:px-0">
            <div class="container mx-auto mt-3 mb-1">
                <div class="flex flex-wrap -mx-4">
                    <div class="w-full px-4">
                        <div
                            v-if="tournament.finished"
                            class="px-4 py-3 my-2 bg-blue-500 border-t-4 border-gray-900 rounded-b shadow-md text-teal-darkest"
                            role="alert">
                            <div class="flex">
                                <svg class="w-6 h-6 mr-4 text-white" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                                </svg>
                                <div>
                                    <p class="text-sm font-bold">Information</p>
                                    <p class="text-xs">This tournament has finished and has been archived.</p>
                                </div>
                            </div>
                        </div>
                        <!-- Tabs Container -->
                        <div class="tabs-container relative">
                            <div class="items-center justify-between text-sm ">
                                <div class="flex w-full px-6">
                                    <Tabs>
                                        <Tab id="info" icon="fas fa-info-circle mr-1 " name="Info" href="#info">
                                            <!-- Trophies & Prizes -->
                                            <div class="flex flex-col sm:flex-row gap-2 w-full">
                                                <div
                                                    class="trophy-container border-4 border-yellow-500 rounded-lg shadow-lg">
                                                    <div class="text-xl font-bold text-gray-200">1st</div>
                                                    <img
                                                        src="/images/trophies/trophy_gold_icon_theesportshub.svg"
                                                        class="w-32 h-32">
                                                    <div class="text-2xl font-bold text-gray-200">
                                                        ${{
                                                            isNaN(tournament.prize_amount) ? tournament.prize_amount : Number(tournament.prize_amount).toFixed(2)
                                                        }}
                                                    </div>
                                                </div>

                                                <div
                                                    class="trophy-container border-4 border-gray-300 rounded-lg shadow-lg">
                                                    <div class="text-xl font-bold text-gray-200">2nd</div>

                                                    <img
                                                        src="/images/trophies/trophy_silver_icon_theesportshub.svg"
                                                        class="w-32 h-32">
                                                    <div v-if="tournament.second_place"
                                                         class="text-2xl font-bold text-gray-200">
                                                        ${{
                                                            isNaN(tournament.second_place) ? tournament.second_place : Number(tournament.second_place).toFixed(2)
                                                        }}
                                                    </div>
                                                </div>

                                                <div v-if="tournament.third_place > 0"
                                                    class="trophy-container border-4 border-yellow-700 rounded-lg shadow-lg">
                                                    <div class="text-xl font-bold text-gray-200">3rd</div>

                                                    <img
                                                        src="/images/trophies/trophy_bronze_icon_theesportshub.svg"
                                                        class="w-32 h-32">
                                                    <div v-if="tournament.third_place"
                                                         class="text-2xl font-bold text-gray-200">
                                                        ${{
                                                            isNaN(tournament.third_place) ? tournament.third_place : Number(tournament.third_place).toFixed(2)
                                                        }}
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Tournament Stats and Facts Cards -->
                                            <div class="flex flex-row gap-2">
                                                <div class="grid grid-cols-1 md:grid-cols-4 gap-2 w-full">
                                                    <div
                                                        class="stat-container">
                                                        <div class="text-xl text-gray-500"><i
                                                            class="text-gray-500 fas fa-gamepad"></i></div>
                                                        <div class="pt-1 text-gray-400">Game:</div>
                                                        <a class="text-lg"
                                                           :href="route('game.platform.show', [tournament.platform.slug, tournament.game.slug])">
                                                            {{ tournament.game ? tournament.game.name : '' }}
                                                        </a>
                                                    </div>
                                                    <div
                                                        class="stat-container">
                                                        <div class="text-xl text-gray-500"><i
                                                            class="text-gray-400 fas fa-user-ninja"></i></div>
                                                        <div class="pt-1 text-gray-400">Format:</div>
                                                        <div class="text-lg">{{ tournament.format }} v
                                                            {{ tournament.format }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="stat-container">
                                                        <div class="text-xl text-gray-500">
                                                            <i class="text-gray-400 fas fa-coins"></i>
                                                        </div>
                                                        <div class="pt-1 text-gray-400">Entry Fee:</div>
                                                        <div class="text-lg">{{ tournament.entry_fee }} Credits</div>
                                                    </div>
                                                    <div
                                                        class="stat-container">
                                                        <div class="text-xl text-gray-500">
                                                            <i class="text-gray-400 fas fa-hand-rock"></i>
                                                        </div>
                                                        <div class="pt-1 text-gray-400">Best Of:</div>
                                                        <div class="text-lg">{{ tournament.best_of }}</div>
                                                    </div>
                                                    <template v-if="tournament.game.crossplay_options">
                                                        <div
                                                            class="stat-container">
                                                            <div class="text-xl text-gray-500"><i
                                                                class="text-gray-500 fa-solid fa-shuffle"></i></div>
                                                            <div class="pt-1 text-gray-400">PC Players</div>
                                                            <div class="text-lg">
                                                                {{ tournament.pc_players ? 'Allowed' : 'Disallowed' }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="stat-container">
                                                            <div class="text-xl text-gray-500"><i
                                                                class="text-gray-500 fa-solid fa-shuffle"></i></div>
                                                            <div class="pt-1 text-gray-400">Input Type</div>
                                                            <div class="text-lg">{{ tournament.input_option }}</div>
                                                        </div>
                                                        <div
                                                            class="stat-container">
                                                            <div class="text-xl text-gray-500"><i
                                                                class="text-gray-500 fa-solid fa-shuffle"></i></div>
                                                            <div class="pt-1 text-gray-400">PC Stream Required</div>
                                                            <div class="text-lg">
                                                                {{ tournament.pc_stream_required ? 'Yes' : 'No' }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="stat-container">
                                                            <div class="text-xl text-gray-500"><i
                                                                class="text-gray-500 fa-solid fa-shuffle"></i></div>
                                                            <div class="pt-1 text-gray-400">Monitorcam Required</div>
                                                            <div class="text-lg">
                                                                {{ tournament.moss_required ? 'Yes' : 'No' }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>

                                            <!-- Get In Touch and Twitch Boxes -->
                                            <div class="flex flex-row gap-2">
                                                <div class="grid grid-cols-1 md:grid-cols-2 gap-2 w-full my-2">
                                                    <!-- Owner Details Box -->
                                                    <div class="host-details-container">
                                                        <div class="flex flex-row">
                                                            <div class="pt-1 text-xl text-gray-500"><i
                                                                class="text-gray-500 fas fa-user"></i></div>
                                                            <div class="text-lg pt-1 px-1">Host Details</div>
                                                        </div>
                                                        <template v-if="!!tournament.owners.length">
                                                            <div v-for="owner in tournament_owners" :key="owner.id">
                                                                <div id="details" class="text-md markdown-content"
                                                                     v-html="owner.details"></div>
                                                            </div>
                                                            <div class="my-4">
                                                                <div v-for="owner in tournament_owners" :key="owner.id"
                                                                     class="text-lg mb-2">
                                                                    <div class="text-sm">Host</div>
                                                                    <!-- TODO: Replace with Link once user page inertiafied -->
                                                                    <a :href="route('user.show', owner.user.slug)"
                                                                          class="flex flex-row">
                                                                        <img :src="owner.user.storage_avatar"
                                                                             class="w-8 h-8 rounded-full mr-1"
                                                                             alt="host_avatar">
                                                                        {{ owner.user.username }}
                                                                    </a>
                                                                    <!-- user links eg discord, user socials -->
                                                                    <div class="my-2">
                                                                        <div v-if="owner.discord">
                                                                            <div class="text-sm">Tournament Socials
                                                                            </div>
                                                                            <ul class="flex flex-row gap-2">
                                                                                <li v-if="owner.discord">
                                                                                    <a :href="owner.discord"
                                                                                       target="_blank"
                                                                                       rel="noopener noreferrer"
                                                                                       class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                                                                                        <i class="fab fa-discord"></i>
                                                                                    </a>
                                                                                </li>
                                                                                <li v-if="owner.facebook">
                                                                                    <a :href="owner.facebook"
                                                                                       target="_blank"
                                                                                       rel="noopener noreferrer"
                                                                                       class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                                                                                        <i class="fab fa-facebook"></i>
                                                                                    </a>
                                                                                </li>
                                                                                <li v-if="owner.x_social">
                                                                                    <a :href="owner.x_social"
                                                                                       target="_blank"
                                                                                       rel="noopener noreferrer"
                                                                                       class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                                                                                        <i class="fab fa-twitter"></i>
                                                                                    </a>
                                                                                </li>
                                                                                <li v-if="owner.youtube">
                                                                                    <a :href="owner.youtube"
                                                                                       target="_blank"
                                                                                       rel="noopener noreferrer"
                                                                                       class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                                                                                        <i class="fab fa-youtube"></i>
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div class="my-2">
                                                                        <div class="text-sm">Host Socials</div>
                                                                        <ul class="flex flex-row gap-2">
                                                                            <li v-if="owner.user.discord">
                                                                                <a :href="owner.user.discord"
                                                                                   target="_blank"
                                                                                   rel="noopener noreferrer"
                                                                                   class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                                                                                    <i class="fab fa-discord"></i>
                                                                                </a>
                                                                            </li>
                                                                            <template v-if="owner.user.socials">
                                                                                <li v-if="owner.user.socials.facebook">
                                                                                    <a :href="owner.user.socials.facebook"
                                                                                       target="_blank"
                                                                                       rel="noopener noreferrer"
                                                                                       class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                                                                                        <i class="fab fa-facebook"></i>
                                                                                    </a>
                                                                                </li>
                                                                                <li v-if="owner.user.socials.twitter">
                                                                                    <a :href="owner.user.socials.twitter"
                                                                                       target="_blank"
                                                                                       rel="noopener noreferrer"
                                                                                       class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                                                                                        <i class="fab fa-twitter"></i>
                                                                                    </a>
                                                                                </li>
                                                                                <li v-if="owner.user.socials.youtube">
                                                                                    <a :href="owner.user.socials.youtube"
                                                                                       target="_blank"
                                                                                       rel="noopener noreferrer"
                                                                                       class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                                                                                        <i class="fab fa-youtube"></i>
                                                                                    </a>
                                                                                </li>
                                                                            </template>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <div class="my-2">
                                                                <p>
                                                                    This is an official ESHUB tournament.

                                                                    If you have any questions or require match support, please use our live support system or discord.
                                                                </p>
                                                                <br>
                                                                <div>
                                                                    <a href="https://discord.gg/CKpv4gshPN"
                                                                       class="text-blue-500 text-3xl hover:scale-105 transition-transform"
                                                                       target="_blank" rel="noopener noreferrer">
                                                                        <div class="flex flex-row gap-3">
                                                                            <img src="/images/logo.png" class="w-auto h-12"
                                                                                 alt="eshub_logo">
                                                                            <div class="mt-2">
                                                                                <i class="fab fa-discord"></i>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <br>
                                                                <p>You can also reach out to us via our socials:</p>
                                                            </div>
                                                            <div>
                                                                <ul class="text-sm font-semibold text-gray-800 flex flex-row gap-3">
                                                                    <li>
                                                                        <a href="https://twitter.com/TheEsportHub"
                                                                           class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                                                                            <i class="fa-brands fa-square-x-twitter "></i>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a href="https://www.twitch.tv/theesportshub"
                                                                           class="text-blue-500 text-3xl hover:scale-105 transition-transform">

                                                                            <i class="fa-brands fa-twitch"></i>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </template>

                                                    </div>

                                                    <!-- Twitch Box -->
                                                    <div
                                                        class="twitch-container">
                                                        <div v-if="!!twitchUrl">
                                                            <iframe :src="twitchUrl" frameborder="0"
                                                                    allowfullscreen="true" scrolling="no" height="378"
                                                                    width="100%"
                                                                    class="rounded-lg"></iframe>
                                                        </div>
                                                        <div v-else class="flex flex-col gap-2 p-4">
                                                            <div class="flex flex-row gap-2">
                                                                <div class="pt-1 text-2xl text-gray-500"><i
                                                                    class="text-gray-500 fab fa-twitch"></i>
                                                                </div>
                                                                <span
                                                                    class="text-gray-50 text-lg pt-1 px-1">Twitch</span>
                                                            </div>
                                                            <div class="text-lg mx-auto mt-16">No Twitch Channel</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Tournament Description Box if description is null or empty string, then do not render-->
                                            <div v-if="tournament.description" class="mt-4 mx-48">
                                                <div id="description" class="text-md markdown-content"
                                                     v-html="tournament.description"></div>
                                            </div>
                                        </Tab>

                                        <Tab id="brackets" icon="fas fa-headset mr-1" name="Brackets" href="#brackets">
                                            <div id="main" class="px-0 py-4">
                                                <div v-if="!tournament.bracket_hidden && !!tournament.challonge_bracket_embed_code">
                                                    <iframe
                                                        :src="tournament.challonge_bracket_embed_code"
                                                        width="100%"
                                                        height="500"
                                                        frameborder="0"
                                                        scrolling="auto"
                                                        allowtransparency="true"
                                                    >
                                                    </iframe>
                                                </div>
                                                <div
                                                    v-else-if="tournament.bracket_generated && !tournament.bracket_hidden"
                                                    id="bracket"
                                                >
                                                    <TournamentBracket :tournament="tournament"/>
                                                </div>
                                                <div
                                                    v-else
                                                    class="flex flex-col items-center justify-center h-56"
                                                >
                                                    <div class="mb-4 text-2xl font-bold text-center"><i
                                                        class="text-3xl text-blue-500 fas fa-hourglass-half spin-medium"
                                                        style="font-size:3.4rem;"></i></div>
                                                    <div class="text-2xl font-bold text-center">
                                                        Pending Bracket Generation
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab id="teams" icon="fas fa-users mr-1" name="Teams" href="#teams">
                                            <!-- Teams -->
                                            <div class="container mx-auto text-center mb-12 py-4">
                                                <div v-if="isOwner || tournament.bracket_generated && !tournament.bracket_hidden"
                                                     class="grid grid-cols-1 sm:grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4 w-full">
                                                    <div v-for="team in tournament.teams" :key="team.id"
                                                         class="bg-blue-900 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1">
                                                        <Link :href="route('teams.show', team.id)"
                                                              class="p-6 flex flex-col items-center justify-center text-center space-y-4">
                                                            <img :src="team.storage_avatar"
                                                                 class="w-24 h-24 rounded-full" alt="team_badge">
                                                            <h2 class="text-center mt-2">{{ team.name }}</h2>
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <span class="font-semibold">
                                                        Teams are not visible until the bracket has been generated.
                                                    </span>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab id="rules" icon="fas fa-clipboard-list mr-1" name="Rules" href="#rules">
                                            <div id="main" class="px-0 py-4">
                                                <div
                                                    v-if="tournament.ruleset && tournament.ruleset.content"
                                                    id="rules"
                                                    class="text-md markdown-content"
                                                    v-html="tournament.ruleset.content"
                                                >
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab v-if="isOwner" id="admin" icon="fas fa-cogs mr-1" name="Admin" href="#admin">
                                            <div id="main" class="px-0 py-4">
                                                <div class="flex flex-row items-center justify-left">
                                                    <div v-if="!tournament.bracket_generated && !tournament.has_started">
                                                        <button @click="generateBracket"
                                                                class="px-4 py-2 my-2 bg-green-500 text-white rounded hover:bg-green-600">
                                                            Generate Bracket
                                                        </button>
                                                    </div>
                                                    <div v-else-if="!tournament.has_started">
                                                        <button @click="resetBracket"
                                                                class="px-4 py-2 my-2 bg-red-500 text-white rounded hover:bg-red-600">
                                                            Reset Bracket
                                                        </button>
                                                    </div>
                                                    <button  v-if="tournament.bracket_generated"
                                                        @click="toggleBracketVisibility"
                                                            :class="tournament.bracket_hidden ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'"
                                                            class="px-4 py-2 m-2 text-white rounded ">
                                                        {{
                                                            tournament.bracket_hidden ? 'Enable Teams/Bracket View' : 'Disable Teams/Bracket View'
                                                        }}
                                                    </button>
                                                    <!-- Button to go to the tournament.edit page -->
                                                    <Link :href="route('tournaments.edit', tournament.slug)"
                                                          class="px-4 py-2 m-2 bg-blue-500 text-white rounded hover:bg-blue-900">
                                                        Edit Tournament
                                                    </Link>
                                                </div>
                                                <!-- Admin Matches Container -->
                                                <div class="table-responsive overflow-x-auto bg-blue-900 rounded-lg">
                                                    <div class="shadow-lg p-4">
                                                        <!-- Header row with equal-width columns -->
                                                        <div class="grid grid-cols-9 gap-2 text-gray-400 py-2 font-bold"
                                                             style="min-width: 1440px;">
                                                            <span class="text-center">Match Status</span>
                                                            <span class="text-center">Start Date</span>
                                                            <span class="text-center">Round</span>
                                                            <span class="text-center">Disputed</span>
                                                            <span class="text-center">Team One</span>
                                                            <span class="text-center">Team Two</span>
                                                            <span class="text-center">Winner</span>
                                                            <span class="text-center">View Match</span>
                                                            <span class="text-center">Actions</span>
                                                        </div>

                                                        <!-- Disputed Matches -->
                                                        <div v-for="match in disputedMatches" :key="match.id" class="p-2 rounded-lg my-2">

                                                            <div v-if="match.team_one_reported_winner || match.team_two_reported_winner" class="mt-2 space-y-1">
                                                                <p v-if="match.team_one_reported_winner" class="text-red-500">
                                                                    Team {{ match.team_one.name }} reported themselves as 
                                                                    {{ match.team_one_reported_winner === match.team_one_id ? 'the winner' : 'the loser' }}.
                                                                </p>
                                                                <p v-if="match.team_two_reported_winner" class="text-red-500">
                                                                    Team {{ match.team_two.name }} reported themselves as 
                                                                    {{ match.team_two_reported_winner === match.team_two_id ? 'the winner' : 'the loser' }}.
                                                                </p>
                                                            </div>


                                                            <!-- Use match object directly -->
                                                            <div
                                                                  class="grid grid-cols-9 bg-blue-800 text-gray-300 gap-2 items-center"
                                                                  style="min-width: 1440px;">
                                                                <div class="relative group">
                                                                    <div
                                                                        :class="actionClass(match)"
                                                                        class="match-status rounded-lg px-2 py-1 overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
                                                                        :title="determineAction(match)"
                                                                    >
                                                                        {{ determineAction(match) }}
                                                                    </div>
                                                                    <div
                                                                        class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block bg-black text-white text-lg px-4 py-2 rounded shadow-lg whitespace-nowrap z-50"
                                                                    >
                                                                        {{ determineAction(match) }}
                                                                    </div>
                                                                </div>

                                                                <div class="text-center">{{
                                                                        formatDate(match.start_date)
                                                                    }}
                                                                </div>
                                                                <div class="text-center">{{ match.round }}</div>
                                                                <div class="text-center"><span
                                                                    class="text-red-500">Yes</span></div>

                                                                <!-- Team One -->
                                                                <div class="text-center truncate">
                                                                    {{ match.team_one?.name || 'BYE' }}
                                                                </div>

                                                                <!-- Team Two -->
                                                                <div class="text-center truncate">
                                                                    {{ match.team_two?.name || 'BYE' }}
                                                                </div>

                                                                <!-- Winner Dropdown -->
                                                                <div v-if="forms[match.id]" class="text-center">
                                                                    <select v-model="forms[match.id].winner_id"
                                                                            class="bg-blue-700 text-white rounded-lg"
                                                                            style="width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                                                        <option :value="match.team_one_id">
                                                                            {{ match.team_one?.name || 'TBD' }}
                                                                        </option>
                                                                        <option :value="match.team_two_id">
                                                                            {{ match.team_two?.name || 'TBD' }}
                                                                        </option>
                                                                        <option :value="null">TBD</option>
                                                                    </select>
                                                                </div>

                                                                <!-- View Match -->
                                                                <div
                                                                    class="text-center flex space-x-2 justify-center">
                                                                    <!-- TODO update to Link once inertiafied -->
                                                                    <a :href="route('tournament.match.show', { tournament: tournament.slug, match: match.id })"
                                                                       class="text-blue-500 hover:text-blue-100">
                                                                        <i class="fas fa-eye py-2 text-blue-500 hover:text-blue-100 cursor-pointer"></i>
                                                                    </a>
                                                                </div>

                                                                <!-- Actions -->
                                                                <div class="text-center flex flex-grow space-x-2 justify-center">
                                                                    <!-- Advance Match Button -->
                                                                    <button
                                                                        v-if="!match.finished && teamsAreValid(match)"
                                                                        @click="advanceMatch(match, forms[match.id].winner_id)"
                                                                        class="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                                                                    >
                                                                        Advance
                                                                    </button>

                                                                    <!-- Reverse Advancement Button -->
                                                                    <button
                                                                        v-if="canReverseAdvancement(match)"
                                                                        @click="reverseAdvancement(match)"
                                                                        class="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                                                    >
                                                                        Reverse
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Non-disputed Matches (Expandable) -->
                                                        <template v-if="showNonDisputed">
                                                            <div v-for="match in nonDisputedMatches" :key="match.id" class="rounded-lg my-1">
                                                                
                                                                <div v-if="match.team_one_reported_winner || match.team_two_reported_winner" class="mt-2 space-y-1">
                                                                    <p v-if="match.team_one_reported_winner" class="text-red-500">
                                                                        Team {{ match.team_one.name }} reported themselves as 
                                                                        {{ match.team_one_reported_winner === match.team_one_id ? 'the winner' : 'the loser' }}.
                                                                    </p>
                                                                    <p v-if="match.team_two_reported_winner" class="text-red-500">
                                                                        Team {{ match.team_two.name }} reported themselves as 
                                                                        {{ match.team_two_reported_winner === match.team_two_id ? 'the winner' : 'the loser' }}.
                                                                    </p>
                                                                </div>

                                                                <!-- Use match object directly -->
                                                                <div
                                                                      class="grid grid-cols-9 gap-2 p-2 bg-blue-800 text-gray-300 items-center"
                                                                      style="min-width: 1440px;">
                                                                    <div class="relative group">
                                                                        <div
                                                                            :class="actionClass(match)"
                                                                            class="match-status rounded-lg px-2 py-1 overflow-hidden overflow-ellipsis whitespace-nowrap cursor-pointer"
                                                                            :title="determineAction(match)"
                                                                        >
                                                                            {{ determineAction(match) }}
                                                                        </div>
                                                                        <div
                                                                            class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block bg-black text-white text-lg pl-8 py-2 rounded shadow-lg whitespace-nowrap z-50"
                                                                        >
                                                                            {{ determineAction(match) }}
                                                                        </div>
                                                                    </div>

                                                                    <div class="text-center">
                                                                        {{ formatDate(match.start_date) }}
                                                                    </div>
                                                                    <div class="text-center">{{ match.round }}</div>
                                                                    <div class="text-center"><span
                                                                        class="text-green-500">No</span></div>

                                                                    <!-- Team One -->
                                                                    <div class="text-center truncate">
                                                                        {{ match.team_one?.name || 'BYE' }}
                                                                    </div>

                                                                    <!-- Team Two -->
                                                                    <div class="text-center truncate">
                                                                        {{ match.team_two?.name || 'BYE' }}
                                                                    </div>


                                                                    <!-- Winner Dropdown -->
                                                                    <div v-if="forms[match.id]" class="text-center">
                                                                        <select v-model="forms[match.id].winner_id"
                                                                                class="bg-blue-700 text-white rounded-lg"
                                                                                style="width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                                                            <option :value="match.team_one_id">
                                                                                {{ match.team_one?.name || 'TBD' }}
                                                                            </option>
                                                                            <option :value="match.team_two_id">
                                                                                {{ match.team_two?.name || 'TBD' }}
                                                                            </option>
                                                                            <option :value="null">TBD</option>
                                                                        </select>
                                                                    </div>

                                                                    <!-- View Match -->
                                                                    <div
                                                                        class="text-center flex space-x-2 justify-center">
                                                                        <!-- TODO update to Link once inertiafied -->
                                                                        <a :href="route('tournament.match.show', { tournament: tournament.slug, match: match.id })"
                                                                           class="text-blue-500 hover:text-blue-100">
                                                                            <i class="fas fa-eye py-2 text-blue-500 hover:text-blue-100 cursor-pointer"></i>
                                                                        </a>
                                                                    </div>

                                                                    <!-- Actions -->
                                                                    <div
                                                                        class="text-center flex space-x-2 justify-center">
                                                                        <!-- Advance Match Button -->
                                                                        <button
                                                                            v-if="!match.finished && teamsAreValid(match)"
                                                                            @click="advanceMatch(match, forms[match.id].winner_id)"
                                                                            class="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                                                                        >
                                                                            Advance
                                                                        </button>

                                                                        <!-- Reverse Advancement Button -->
                                                                        <button
                                                                            v-if="canReverseAdvancement(match)"
                                                                            @click="reverseAdvancement(match)"
                                                                            class="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                                                                        >
                                                                            Reverse
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </template>

                                                        <!-- Toggle Button -->
                                                        <div class="mt-4 text-center">
                                                            <button @click="toggleNonDisputed"
                                                                    class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-800">
                                                                {{
                                                                    showNonDisputed ? 'Hide Non-disputed Matches' : 'Show Non-disputed Matches'
                                                                }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                    </Tabs>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

:deep(.markdown-content h1) {
    @apply text-4xl font-bold mb-4;
}

:deep(.markdown-content h2) {
    @apply text-2xl font-bold mb-3;
}

:deep(.markdown-content h3) {
    @apply text-xl font-bold mb-2;
}

:deep(.markdown-content p) {
    @apply mb-4;
}

:deep(.markdown-content ul) {
    @apply list-disc pl-6 mb-4;
}

:deep(.markdown-content ol) {
    @apply list-decimal pl-6 mb-4;
}

:deep(.markdown-content li) {
    @apply mb-2;
}

:deep(.markdown-content blockquote) {
    @apply border-l-4 border-blue-500 pl-4 italic mb-4;
}

:deep(.markdown-content code) {
    @apply bg-gray-800 text-gray-100 px-1 py-0.5 rounded;
}

:deep(.markdown-content a) {
    @apply text-blue-500 underline hover:text-blue-500 font-bold;
    transition: color 0.2s ease-in-out;
}

:deep(.markdown-content a[target="_blank"]) {
    @apply text-blue-400 underline hover:text-blue-500;
    transition: color 0.2s ease-in-out;
}

.stat-box {
    @apply flex gap-1 items-center px-3 py-3 leading-tight bg-blue-800 shadow-xl rounded-2xl bg-opacity-80;
}

.tabs-container {
    @apply text-white bg-blue-600 border border-gray-800 rounded-lg py-2;
}

.trophy-container {
    @apply flex flex-col items-center justify-center w-full py-8 my-2 bg-blue-900 rounded-lg;
}

.stat-container {
    @apply w-full p-4 flex flex-row gap-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg;
}

.host-details-container {
    @apply w-full p-4 font-semibold bg-blue-900 border border-blue-600 rounded-lg;
}

.twitch-container {
    @apply w-full font-semibold bg-blue-900 border border-blue-600 rounded-lg;
}

.match-status {
    @apply rounded-lg px-2 py-1 overflow-hidden overflow-ellipsis whitespace-nowrap;
}

.animated-button {
    animation: pulse 1.2s infinite;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.animated-button:hover {
    transform: scale(1.1) translateY(-3px);
    background-color: #2e30c1;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(192, 192, 192, 0.7);
    }
    70% {
        box-shadow: 0 0 10px 10px rgba(192, 192, 192, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(192, 192, 88, 0);
    }
}
</style>

<script setup>

import Breadcrumbs from "../../../InertiaComponents/Navigation/Breadcrumbs.vue";
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import {router, useForm, usePage} from "@inertiajs/vue3";
import PaginationLinks from "../../../InertiaComponents/Pagination/PaginationLinks.vue";
import MatchCard from "../../../InertiaComponents/WagerChallenges/Matches/MatchCardWithUnknownTeams.vue";
import PaginationStatsDisplay from "../../../InertiaComponents/Pagination/PaginationStatsDisplay.vue";
import Select from "../../../InertiaComponents/Forms/Select.vue";
import {Link} from '@inertiajs/vue3';
import StartChallengeButton from "../../../InertiaComponents/WagerChallenges/Matches/StartChallengeButton.vue";

const page = usePage();
const pendingChallenges = computed(() => page.props.pendingChallenges);
const matches = computed(() => page.props.matches);

const games = computed(() => page.props.games);
const regions = computed(() => page.props.regions);
const platforms = computed(() => page.props.platforms);

const showFilters = ref(false);

const now = ref(new Date());

const anyMatchesExpiredInPast = computed(() => {
    return matches.value.data.some(match => new Date(match.expiry_date).getTime() < now.value.getTime());
});

watch(anyMatchesExpiredInPast, (value) => {
    if (value) {
        router.reload({only: ['matches']});
    }
});

const interval = ref(null);
onMounted(() => {
    interval.value = setInterval(() => {
        now.value = new Date();
    }, 1000);
});

onUnmounted(() => {
    clearInterval(interval.value);
});


const gameOptions = computed(() => {
    return [
        {name: 'All', slug: 'all'},
        ...games.value.map(game => {
            return {
                name: game.name,
                slug: game.slug
            }
        })
    ];
});

const regionOptions = computed(() => {
    return [
        {name: 'All', short_name: 'all'},
        ...regions.value.map(region => {
            return {
                name: region.name,
                short_name: region.short_name
            }
        })
    ];
});

const platformOptions = computed(() => {
    return [
        {label: 'All', value: 'all'},
        ...platforms.value.map(platform => {
            return {
                label: platform.name,
                value: platform.slug
            }
        })
    ];
});

const selectedGame = computed(() => page.props.game);
const selectedRegion = computed(() => page.props.region);
const selectedPlatform = computed(() => page.props.platform);
const selectedWagerAmount = computed(() => page.props.wagerAmount);
const selectedTeamSize = computed(() => page.props.teamSize);
const selectedBestOf = computed(() => page.props.bestOf);
const selectedSortBy = computed(() => page.props.sortBy);

const filterForm = useForm({
    game: selectedGame?.value?.slug || 'all',
    region: selectedRegion?.value || 'all',
    platform: selectedPlatform?.value?.slug || 'all',
    wagerAmount: selectedWagerAmount?.value || 'all',
    teamSize: selectedTeamSize?.value || 'all',
    bestOf: selectedBestOf?.value || 'all',
    sortBy: selectedSortBy?.value || 'start_date_asc',
});

const onSubmitFilters = () => {
    filterForm
        .transform((data) => {
            if (data.game === 'all') {
                delete data.game;
            }

            if (data.region === 'all') {
                delete data.region;
            }

            if (data.platform === 'all') {
                delete data.platform;
            }

            if (data.wagerAmount === 'all') {
                delete data.wagerAmount;
            }

            if (data.teamSize === 'all') {
                delete data.teamSize;
            }

            if (data.bestOf === 'all') {
                delete data.bestOf;
            }

            if (data.sortBy === 'start_date_asc') {
                delete data.sortBy;
            }

            return data;
        })
        .get(route('matchfinder.index'), { preserveState: false, preserveScroll: true });
}




</script>

<template>
    <div id="main" class="text-white py-6 px-4 sm:px-0">
        <div class="mx-auto container">
            <!--            <div id="breadcrumbs" class="text-xs mb-1">-->
            <!--                Home <span class="ml-1 mr-1 text-gray-600 font-extrabold">></span> <span-->
            <!--                class="ml-1 mr-1 text-gray-600">Match finder</span>-->
            <!--            </div>-->
            <Breadcrumbs :breadcrumbs="[{
                'name': 'Home',
                'link': '/'
            }, {
                'name': 'Match finder',
            }]"/>

            <div class="flex items-center w-full justify-between">
                <div class="text-4xl font-extrabold">Match Finder</div>
                <StartChallengeButton />
            </div>

            <h2 class="text-md text-left my-2">
                Find XP or Wager matches to play against other players.
            </h2>
            <h3 class="text-md text-left mt-2 mb-4">
                Want to play a specific game? Or just find XP matches? Use the filters below to find matches.
            </h3>
        </div>

        <div class="flex md:hidden justify-center mb-4">
            <button
                @click="showFilters = !showFilters"
                class="bg-blue-500 text-white p-2 rounded-lg"
            >

                <span v-if="showFilters">Hide</span>
                <span v-else>Show</span>
                Filters
            </button>
        </div>

        <div class="container mx-auto mt-5 mb-10">
            <!--            @include('partials.success')-->
            <!--            @include('partials.info')-->
            <!--            @include('partials.error')-->
            <!--            @if(app('request')->input('msg') == 'success')-->
            <!--            <div class="bg-blue-500 border-t-4 border-gray-900 rounded-b text-teal-darkest px-4 py-3 shadow-md my-2" role="alert">-->
            <!--                <div class="flex">-->
            <!--                    <svg class="h-6 w-6 text-white mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>-->
            <!--                    <div class="text-white">-->
            <!--                        <p class="font-bold">Success</p>-->
            <!--                        <p class="text-sm">Successfully posted challenge!</p>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--            @endif-->

            <div class="block mb-5" v-if="pendingChallenges.length > 0">
                <div class="bg-blue-300 border-t-4 border-gray-900 rounded-b text-teal-darkest px-4 py-3 shadow-md my-2"
                     role="alert">
                    <div class="flex">
                        <svg class="h-6 w-6 text-white mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path
                                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                        </svg>
                        <div>
                            <p class="font-bold">You have {{ pendingChallenges.length }} pending challenge(s). View it
                                in the
                                <a :href="route('challenges.index')" class="underline">challenge center</a>.</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="md:grid md:grid-cols-12">
                <div
                    class="col-span-6 md:col-span-3 lg:col-span-2"
                    :class="{ 'hidden md:block': !showFilters }"
                >
                    <div class="sticky top-0 py-2 overflow-y-auto max-h-screen bg-blue-600 shadow-2xl rounded-lg">
                        <h4 class="text-xl text-center uppercase border-b-2 border-blue-500">
                            Filters
                        </h4>

                        <form class="px-2 py-1" @submit.prevent="onSubmitFilters">
                            <div class="mt-4">
                                <Select
                                    :options="gameOptions"
                                    labelAttr="name"
                                    valueAttr="slug"
                                    v-model="filterForm.game"
                                    name="game"
                                    label="Game"
                                    :selected="selectedGame"
                                />

                                <div v-if="filterForm.errors.game">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.game }}</p>
                                </div>
                            </div>

                            <div class="mt-4">
                                <Select
                                    :options="regionOptions"
                                    labelAttr="name"
                                    valueAttr="short_name"
                                    v-model="filterForm.region"
                                    name="region"
                                    label="Region"
                                />

                                <div v-if="filterForm.errors.region">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.region }}</p>
                                </div>
                            </div>

                            <div class="mt-4">
                                <Select
                                    :options="platformOptions"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.platform"
                                    name="platform"
                                    label="Platform"
                                />
                            </div>

                            <div class="mt-4">
                                <Select
                                    :options="[
                                        {label: 'All', value: 'all'},
                                        {label: 'XP Only ($0)', value: 'xp-only'},
                                        {label: '$0.01 - $5', value: 'up-to-5'},
                                        {label: '$5 - $10', value: '5-10'},
                                        {label: '$10 - $15', value: '10-15'},
                                        {label: '$15 - $20', value: '15-20'},
                                        {label: '$20 - $40', value: '20-40'},
                                        {label: '$40 - $80', value: '40-80'},
                                        {label: 'Over $80', value: 'over-80'},
                                    ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.wagerAmount"
                                    name="wager"
                                    label="Wager"
                                />

                                <div v-if="filterForm.errors.wagerAmount">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.wagerAmount }}</p>
                                </div>
                            </div>


                            <div class="mt-4">
                                <Select
                                    :options="[
                                        {label: 'All', value: 'all'},
                                        {label: '1 v 1', value: '1v1'},
                                        {label: '2 v 2', value: '2v2'},
                                        {label: '3 v 3', value: '3v3'},
                                        {label: '4 v 4', value: '4v4'},
                                        {label: '6 v 6', value: '6v6'},
                                        {label: '8 v 8', value: '8v8'},
                                    ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.teamSize"
                                    name="teamSize"
                                    label="Team Size"
                                />

                                <div v-if="filterForm.errors.teamSize">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.teamSize }}</p>
                                </div>
                            </div>

                            <div class="mt-4">
                                <Select
                                    :options="[
                                        {label: 'All', value: 'all'},
                                        {label: 'Best of 1', value: '1'},
                                        {label: 'Best of 3', value: '3'},
                                        {label: 'Best of 5', value: '5'},
                                    ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.bestOf"
                                    name="bestOf"
                                    label="Best Of"
                                />

                                <div v-if="filterForm.errors.teamSize">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.teamSize }}</p>
                                </div>
                            </div>

                            <div class="mt-4 xl:hidden">
                                <Select
                                    :options="[
                                            {label: 'Start Date - Soonest First', value: 'start_date_asc'},
                                            {label: 'Wager - High to Low', value: 'wager_high_to_low'},
                                            {label: 'Wager - Low to High', value: 'wager_low_to_high'},
                                        ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.sortBy"
                                    name="sortBy"
                                    label="Sort By:"
                                />
                            </div>

                            <div class="mt-4">
                                <button type="submit" class="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-400 shadow-md hover:shadow-xl">
                                    Apply Filters
                                </button>

                                <div v-if="filterForm.isDirty">
                                    <button @click="() => filterForm.reset()" type="button" class="w-full bg-red-600 text-white p-2 rounded-lg mt-2 hover:bg-red-500">
                                        Reset Filters
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-span-6 md:col-span-9 lg:col-span-10 relative flex flex-col">
                    <div class="sticky top-0 z-10 bg-blue-600 py-1 border-b-2 border-blue-500 rounded-r-full rounded-l-full md:rounded-l-none flex justify-center xl:justify-between items-center text-center">

                        <div class="flex-1 hidden xl:block">
                        </div>

                        <PaginationStatsDisplay :data="matches" />

                        <div class="mr-8 flex-1 hidden xl:block">
                            <Select
                                :options="[
                                            {label: 'Start Date - Soonest First', value: 'start_date_asc'},
                                            {label: 'Wager - High to Low', value: 'wager_high_to_low'},
                                            {label: 'Wager - Low to High', value: 'wager_low_to_high'},
                                        ]"
                                labelAttr="label"
                                valueAttr="value"
                                v-model="filterForm.sortBy"
                                name="sortBy"
                                label="Sort By:"
                                label-direction="left"
                                box-size="xs"
                            />
                        </div>
                    </div>

                    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 px-4 py-4">
                        <MatchCard
                            v-for="match in matches.data"
                            :key="match.id"
                            :match="match"
                        />
                    </div>

                    <div
                        v-if="matches && matches.data.length === 0"
                        class="px-5 py-5 text-center flex-1 flex items-center justify-center"
                    >
                        <div
                            class="rounded-b px-4 py-3 my-2 flex flex-col justify-center items-center"
                        >
                            <div class="flex px-2 py-2 rounded-md items-center gap-2">
                                <i class="fas fa-circle-info text-xl"></i>
                                <div>
                                    <p class="font-semibold">There aren't any active matches right now, start a challenge?</p>
                                </div>
                            </div>
                            <StartChallengeButton />
                        </div>
                    </div>

                    <PaginationLinks :links="matches.links"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

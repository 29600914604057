<script setup>
import {useForm, usePage, Link} from "@inertiajs/vue3";
import {computed, watch, ref} from "vue";
import TournamentCard from "../../InertiaComponents/Tournament/TournamentCard.vue";
import Select from "../../InertiaComponents/Forms/Select.vue";
import PaginationLinks from "../../InertiaComponents/Pagination/PaginationLinks.vue";
import PaginationStatsDisplay from "../../InertiaComponents/Pagination/PaginationStatsDisplay.vue";
import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import PageTitle from "../../InertiaComponents/UI/PageTitle.vue";

const page = usePage();
const props = page.props;
const tournaments = computed(() => props.tournaments);
const games = computed(() => props.games);
const regions = computed(() => props.regions);
const platforms = computed(() => props.platforms);

// Get the authenticated user
const user = computed(() => props.auth.user);

const selectedGame = computed(() => props.game);
const selectedRegion = computed(() => props.region);
const selectedPlatform = computed(() => props.platform);
const selectedStatus = computed(() => props.status);
const selectedPrize = computed(() => props.prize);
const selectedSecondPrize = computed(() => props.secondPrize);
const selectedThirdPrize = computed(() => props.thirdPrize);
const selectedElite = computed(() => props.elite);
const selectedSortBy = computed(() => props.sortBy);
const selectedOwnedByMe = computed(() => props.ownedByMe || 'all');
const selectedPublishedStatus = computed(() => props.publishedStatus || 'all');

const showFilters = ref(false);

const gameOptions = computed(() => {
    return [
        {name: 'All', slug: 'all'},
        ...games.value.map(game => ({
            name: game.name,
            slug: game.slug,
        })),
    ];
});

const regionOptions = computed(() => {
    return [
        {name: 'All', short_name: 'all'},
        ...regions.value.map(region => ({
            name: region.name,
            short_name: region.short_name,
        })),
    ];
});

const platformOptions = computed(() => {
    return [
        {label: 'All', value: 'all'},
        ...platforms.value.map(platform => ({
            label: platform.name,
            value: platform.slug,
        })),
    ];
});

const filterForm = useForm({
    game: selectedGame?.value?.slug || 'all',
    status: selectedStatus?.value || 'all',
    region: selectedRegion?.value?.short_name || 'all',
    platform: selectedPlatform?.value?.slug || 'all',
    topPrize: selectedPrize?.value || 'all',
    secondPrize: selectedSecondPrize?.value || 'all',
    thirdPrize: selectedThirdPrize?.value || 'all',
    elite: selectedElite?.value || 'all',
    sortBy: selectedSortBy?.value || 'start_date_asc',
    ownedByMe: selectedOwnedByMe?.value || 'all',
    publishedStatus: selectedPublishedStatus?.value || 'all',
});

const onSubmitFilters = () => {
    filterForm
        .transform(data => {
            if (data.game === 'all') delete data.game;
            if (data.region === 'all') delete data.region;
            if (data.platform === 'all') delete data.platform;
            if (data.topPrize === 'all') delete data.topPrize;
            if (data.secondPrize === 'all') delete data.secondPrize;
            if (data.thirdPrize === 'all') delete data.thirdPrize;
            if (data.elite === 'all') delete data.elite;
            if (data.status === 'all') delete data.status;
            if (data.sortBy === 'start_date_asc') delete data.sortBy;
            if (data.ownedByMe === 'all') delete data.ownedByMe;
            if (data.publishedStatus === 'all' || data.ownedByMe !== 'owned') {
                delete data.publishedStatus;
            }

            return data;
        })
        .get(route('tournaments.index'), {preserveState: false, preserveScroll: true});
};
</script>


<template>
    <div id="main" class="text-white py-4">
        <div class="container mx-auto">
            <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Tournaments'}
            ]"/>

            <!-- Title and Create Tournament Button -->
            <div class="sm:flex justify-between items-center mt-4">
                <!-- Page Title -->
                <PageTitle title="Tournament Hub"/>

                <!-- Create Tournament Button (visible only to logged-in users) -->
                <Link
                    :href="route('tournaments.create')"
                    class="px-4 sm:py-2 py-4 text-white font-semibold rounded-lg btn-gradient-blue transition duration-300 ease-in-out transform hover:scale-105"
                >
                    Create Tournament
                </Link>
            </div>

            <h2 class="text-md text-left my-2">
                Your one-stop shop for all upcoming tournaments, find the perfect tournament for you and your team.
            </h2>
            <h3 class="text-md text-left mt-2 mb-4">
                Want to find in-progress tournaments to keep track of your favourite teams? Check out the filters on the
                left.
            </h3>

            <div class="flex md:hidden justify-center mb-4">
                <button
                    @click="showFilters = !showFilters"
                    class="bg-blue-500 text-white p-2 rounded-lg"
                >

                    <span v-if="showFilters">Hide</span>
                    <span v-else>Show</span>
                    Filters
                </button>
            </div>

            <div class="md:grid md:grid-cols-12">
                <div
                    class="col-span-6 md:col-span-3 lg:col-span-2"
                    :class="{ 'hidden md:block': !showFilters }"
                >
                    <div class="sticky top-0 py-2 overflow-y-auto max-h-screen bg-blue-600 shadow-2xl rounded-lg">
                        <h4 class="text-xl text-center uppercase border-b-2 border-blue-500">
                            Filters
                        </h4>

                        <form class="px-2 py-1" @submit.prevent="onSubmitFilters">
                            <!-- Ownership Filter (visible only to logged-in users) -->
                            <div v-if="user" class="mt-4">
                                <Select
                                    :options="[
                                        {label: 'All', value: 'all'},
                                        {label: 'Owned By Me', value: 'owned'},
                                    ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.ownedByMe"
                                    name="ownedByMe"
                                    label="Ownership"
                                />

                                <div v-if="filterForm.errors.ownedByMe">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.ownedByMe }}</p>
                                </div>
                            </div>
                            <!-- Published Status Filter (visible only when viewing owned tournaments) -->
                            <div v-if="filterForm.ownedByMe === 'owned'" class="mt-4">
                                <Select
                                    :options="[
                                        {label: 'All', value: 'all'},
                                        {label: 'Live', value: 'published'},
                                        {label: 'Draft', value: 'unpublished'},
                                    ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.publishedStatus"
                                    name="publishedStatus"
                                    label="Live Status"
                                />

                                <div v-if="filterForm.errors.publishedStatus">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.publishedStatus }}</p>
                                </div>
                            </div>
                            <div class="mt-4">
                                <Select
                                    :options="gameOptions"
                                    labelAttr="name"
                                    valueAttr="slug"
                                    v-model="filterForm.game"
                                    name="game"
                                    label="Game"
                                    :selected="selectedGame"
                                />

                                <div v-if="filterForm.errors.game">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.game }}</p>
                                </div>
                            </div>

                            <div class="mt-4">
                                <Select
                                    :options="[
                                        {label: 'All', value: 'all'},
                                        {label: 'Upcoming', value: 'upcoming'},
                                        {label: 'In Progress', value: 'in-progress'},
                                        {label: 'Completed', value: 'completed'},
                                    ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.status"
                                    name="status"
                                    label="Status"
                                />

                                <div v-if="filterForm.errors.status">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.status }}</p>
                                </div>
                            </div>

                            <div class="mt-4">
                                <Select
                                    :options="regionOptions"
                                    labelAttr="name"
                                    valueAttr="short_name"
                                    v-model="filterForm.region"
                                    name="region"
                                    label="Region"
                                />

                                <div v-if="filterForm.errors.region">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.region }}</p>
                                </div>
                            </div>

                            <div class="mt-4">
                                <Select
                                    :options="[
                                        {label: 'All', value: 'all'},
                                        {label: 'Elite Only', value: 'elite'},
                                        {label: 'Non-Elite', value: 'non-elite'},
                                    ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.elite"
                                    name="elite"
                                    label="Elite"
                                />

                                <div v-if="filterForm.errors.topPrize">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.topPrize }}</p>
                                </div>
                            </div>

                            <div class="mt-4">
                                <Select
                                    :options="platformOptions"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.platform"
                                    name="platform"
                                    label="Platform"
                                />
                            </div>

                            <div class="mt-4">
                                <Select
                                    :options="[
                                        {label: 'All', value: 'all'},
                                        {label: 'Under $100', value: 'under-100'},
                                        {label: '$100 - $200', value: '100-200'},
                                        {label: '$200 - $300', value: '200-300'},
                                        {label: '$300 - $500', value: '300-500'},
                                        {label: 'Over $500', value: 'over-500'},
                                    ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.topPrize"
                                    name="prize"
                                    label="Top Prize"
                                />

                                <div v-if="filterForm.errors.topPrize">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.topPrize }}</p>
                                </div>
                            </div>


                            <div class="mt-4">
                                <Select
                                    :options="[
                                        {label: 'All', value: 'all'},
                                        {label: 'Under $100', value: 'under-100'},
                                        {label: '$100 - $200', value: '100-200'},
                                        {label: '$200 - $300', value: '200-300'},
                                        {label: '$300 - $500', value: '300-500'},
                                        {label: 'Over $500', value: 'over-500'},
                                    ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.secondPrize"
                                    name="secondPrize"
                                    label="Second Prize"
                                />

                                <div v-if="filterForm.errors.secondPrize">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.secondPrize }}</p>
                                </div>
                            </div>

                            <div class="mt-4">
                                <Select
                                    :options="[
                                        {label: 'All', value: 'all'},
                                        {label: 'Under $100', value: 'under-100'},
                                        {label: '$100 - $200', value: '100-200'},
                                        {label: '$200 - $300', value: '200-300'},
                                        {label: '$300 - $500', value: '300-500'},
                                        {label: 'Over $500', value: 'over-500'},
                                    ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.thirdPrize"
                                    name="thirdPrize"
                                    label="Third Prize"
                                />

                                <div v-if="filterForm.errors.thirdPrize">
                                    <p class="text-red-500 text-xs italic">{{ filterForm.errors.thirdPrize }}</p>
                                </div>
                            </div>

                            <div class="mt-4 xl:hidden">
                                <Select
                                    :options="[
                                            {label: 'Start Date - Soonest First', value: 'start_date_asc'},
                                            {label: 'Top Prize - High to Low', value: 'top_prize_high_to_low'},
                                            {label: 'Top Prize - Low to High', value: 'top_prize_low_to_high'},
                                        ]"
                                    labelAttr="label"
                                    valueAttr="value"
                                    v-model="filterForm.sortBy"
                                    name="sortBy"
                                    label="Sort By:"
                                />
                            </div>

                            <div class="mt-4">
                                <button type="submit"
                                        class="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-400 shadow-md hover:shadow-xl">
                                    Apply Filters
                                </button>

                                <div v-if="filterForm.isDirty">
                                    <button @click="() => filterForm.reset()" type="button"
                                            class="w-full bg-red-600 text-white p-2 rounded-lg mt-2 hover:bg-red-500">
                                        Reset Filters
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-span-6 md:col-span-9 lg:col-span-10 relative flex flex-col">
                    <div
                        class="sticky top-0 z-10 bg-blue-600 py-1 border-b-2 border-blue-500 rounded-r-full rounded-l-full md:rounded-l-none flex justify-center xl:justify-between items-center text-center">

                        <div class="flex-1 hidden xl:block">
                        </div>

                        <PaginationStatsDisplay :data="tournaments"/>

                        <div class="mr-8 flex-1 hidden xl:block">
                            <Select
                                :options="[
                                            {label: 'Start Date - Soonest First', value: 'start_date_asc'},
                                            {label: 'Top Prize - High to Low', value: 'top_prize_high_to_low'},
                                            {label: 'Top Prize - Low to High', value: 'top_prize_low_to_high'},
                                        ]"
                                labelAttr="label"
                                valueAttr="value"
                                v-model="filterForm.sortBy"
                                name="sortBy"
                                label="Sort By:"
                                label-direction="left"
                                box-size="xs"
                            />
                        </div>
                    </div>

                    <div
                        class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-1 px-4 py-4">
                        <TournamentCard
                            :key="tournament.id"
                            :tournament="tournament"
                            v-for="tournament in tournaments.data"
                        />
                    </div>

                    <PaginationLinks :links="tournaments.links"/>

                    <div
                        v-if="tournaments && tournaments.data.length === 0"
                        class="px-5 py-5 text-center flex-1 flex items-center justify-center"
                    >
                        <p class="text-xl">
                            No Tournaments found with the selected filters.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import {getSuffixForOrdinal} from "../../Services/utils.js";
import {computed} from "vue";
import TooltipWrapper from "../UI/TooltipWrapper.vue";

const props = defineProps({
    'user': {
        type: Object,
        required: true
    },
    leaderboardRanks: {
        type: Object,
        required: true
    }
})

const earningsLeaderboardRank = computed(() => {
    const leaderboardRanks = props.leaderboardRanks;
    return leaderboardRanks['earnings-all-time'] ? leaderboardRanks['earnings-all-time'].rank : null;
});

const xpLeaderboardRank = computed(() => {
    const leaderboardRanks = props.leaderboardRanks;
    return leaderboardRanks['xp-all-time'] ? leaderboardRanks['xp-all-time'].rank : null;
});

</script>

<template>
    <div :class="`overflow-hidden bg-blue-800 border border-blue-800 rounded-lg`">
        <div class="p-4 bg-blue-700">
            <p class="text-sm font-semibold">Rank</p>
        </div>

        <div class="p-4">
            <div class="flex flex-col gap-y-6">
                <div v-if="!!earningsLeaderboardRank" class="flex items-center gap-x-4">
                    <i class="text-xl fas fa-dollar text-green-500 w-4 block text-right"></i>
                    <div>
                        <p class="text-sm font-semibold text-gray-off">Earnings Leaderboard</p>
                        <p class="text-base font-bold uppercase">
                            {{ earningsLeaderboardRank }}<span class="text-xs">{{ getSuffixForOrdinal(earningsLeaderboardRank) }}</span>
                        </p>
                    </div>
                </div>
                <div v-else class="">
                    <TooltipWrapper title="Earnings Leaderboard - All Time" content="Win Wager matches and Tournaments to build your Earnings and your rank!">
                        <p class="text-sm font-semibold text-gray-off">Earnings Leaderboard</p>
                        <p class="text-base font-bold uppercase">
                            Not Ranked
                        </p>
                    </TooltipWrapper>
                </div>

                <div v-if="!!xpLeaderboardRank" class="flex items-center gap-x-4">
                    <i class="text-xl fas fa-crosshairs text-blue-400 w-4 block text-right"></i>
                    <div>
                        <p class="text-sm font-semibold text-gray-off">XP Leaderboard</p>
                        <p class="text-base font-bold uppercase">
                            {{ xpLeaderboardRank }}<span class="text-xs">{{ getSuffixForOrdinal(xpLeaderboardRank) }}</span>
                        </p>
                    </div>
                </div>
                <div v-else class="">
                    <TooltipWrapper title="XP Leaderboard - All Time" content="Win Wager matches and Tournaments to build your XP and your rank!">
                        <p class="text-sm font-semibold text-gray-off">XP Leaderboard</p>
                        <p class="text-base font-bold uppercase">
                            Not Ranked
                        </p>
                    </TooltipWrapper>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>

import {ref, computed} from 'vue';
import {usePage} from '@inertiajs/vue3';
import Tabs from '../../components/partials/Tabs.vue';
import Tab from '../../components/partials/Tab.vue';
import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import TeamStatsOptions from "../../components/TeamStatsOptions.vue";
import TeamRosterMemberCard from "../../components/TeamRosterMemberCard.vue";
import TeamMatchTable from "../../components/TeamMatchTable.vue";
import {Link} from '@inertiajs/vue3';
import TeamMatchHistoryTable from "../../InertiaComponents/Teams/TeamMatchHistoryTable.vue";

const {props} = usePage();
const {
    team,
    teamActivityLog,
    winRatio,
    totalMatches,
    totalWins,
    totalCashEarned,
    activeMatches,
    challengedMatches,
    isCaptain,
    showElo
} = props;

const user = computed(() => props.auth.user);

const eliteImage = ref('/images/elite.svg');

const totalLosses = computed(() => totalMatches - totalWins);

const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const options = {year: 'numeric', month: 'long', day: 'numeric'};
    return date.toLocaleDateString('en-US', options);
};

const userBelongsToTeam = computed(() => team.members.some(member => member.id === user.value.id));
const isOwner = computed(() => team.owner_id === user.value.id);

const matchLogOpponent = (log) => {
    if (log.team_one_id !== team.id) {
        return log.team_one;
    }

    if (log.team_two_id !== team.id) {
        return log.team_two;
    }

    return null;
};

</script>

<template>
    <div id="main" class="px-4 py-6 text-white sm:px-0">
        <div class="container mx-auto">
            <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Teams', link: route('teams.index')},
                {name: team.name},
            ]"/>

            <div class="flex flex-col gap-y-6 mt-4 md:flex-row md:justify-between">
                <div class="flex gap-4">
                    <div class="flex items-center flex-shrink-0">
                        <img class="w-16 mt-1 rounded-full lg:w-32 aspect-h-1" :src="team.storage_avatar"
                             :alt="`${team.name}'s avatar`">
                    </div>
                    <div class="leading-tight">
                        <div class="flex items-center">
                            <h2 class="text-4xl font-extrabold break-all text-white">
                                {{ team.name }}
                            </h2>
                            <img v-if="team.is_elite" class="w-16 mx-auto" :src="eliteImage" alt="Elite logo">
                        </div>
                        <div class="font-bold text-gray-600 uppercase text-xxs">
                            Created: {{ formattedDate(team.created_at) }}
                        </div>
                    </div>
                </div>
                <form v-if="!userBelongsToTeam" class="flex items-center" :action="route('challenges.create')"
                      method="GET">
                    <input type="hidden" name="challenged_team_id" :value="team.id">
                    <input type="hidden" name="action" value="challenge_team">
                    <button type="submit"
                            class="px-6 py-3 text-xs font-medium font-semibold rounded-lg btn-gradient-blue">
                        <i class="mr-4 text-sm fas fa-fist-raised"></i>Challenge
                    </button>
                </form>
            </div>
        </div>

        <TeamStatsOptions :team-id="team.id"
                          :win-ratio="winRatio"
                          :total-wins="totalWins"
                          :total-losses="totalLosses"
                          :total-cash-earned="totalCashEarned"
                          :team-views="team.team_views"
                          :is-captain="isCaptain"
                          :user-belongs-to-team="userBelongsToTeam"
                          :is-owner="isOwner"
                          :team="team"
                          :show-elo="showElo"
        />

        <div class="container mx-auto">
            <div class="flex flex-wrap mt-10 mb-6">
                <div class="w-full px-4 lg:w-5/12">
                    <div class="text-lg font-bold text-white"><i class="mr-1 text-blue-500 fas fa-users"></i>Team
                        Roster
                        <a v-if="isCaptain" :href="route('team.member.index', team.id)"
                           class="ml-2 text-blue-500 text-xxs">Add Members</a>
                    </div>
                    <div class="mt-2">
                        <div v-for="member in team.members" :key="member.id" class="w-full overflow-x-auto">
                            <TeamRosterMemberCard :member="member"/>
                        </div>
                    </div>
                </div>
                <div class="flex-row w-full px-4 mt-6 lg:w-7/12 lg:mt-0">
                    <TeamMatchTable
                        title="Challenge Requests"
                        iconClass="fas fa-shield-alt text-blue-500"
                        :items="challengedMatches"
                        emptyMessage="challenge requests"
                        :userBelongsToTeam="userBelongsToTeam"
                    ></TeamMatchTable>

                    <TeamMatchTable
                        title="Active Matches"
                        iconClass="fas fa-fire text-orange-500"
                        :items="activeMatches"
                        emptyMessage="active matches"
                        :userBelongsToTeam="userBelongsToTeam"
                    ></TeamMatchTable>
                    <div>
                        <div class="mb-2 text-lg font-bold text-white"><i
                            class="mr-1 text-orange-500 fas fa-fire"></i>Match
                            History
                        </div>
                        <div class="w-full mb-12">
                            <div class="w-full mb-6 overflow-x-auto">
                                <div
                                    class="text-xs text-white bg-blue-800 border border-blue-700 rounded w-max md:w-full">
                                    <tabs>
                                        <tab icon="mr-1" name="Recent Matches" :selected="true">
                                            <TeamMatchHistoryTable :team-activity-log="teamActivityLog.slice(0, 5)" :team="team" />
                                        </tab>
                                        <tab icon="mr-1" name="All Matches" :selected="false">
                                            <TeamMatchHistoryTable :team-activity-log="teamActivityLog" :team="team" />
                                        </tab>
                                    </tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import UserRankBadge from "./UserRankBadge.vue";

const props = defineProps({
    'user': {
        type: Object,
        required: true
    },
    'mostPlayedGames': {
        type: Array,
        required: true,
    }
})

function rankForGame(game) {
    if (!props.user.user_game_ranks || props.user.user_game_ranks.length <= 0) {
        return {
            image_url: '/images/user/ranks/novice.png',
            image_alt: 'Novice Rank',
        };
    }

    const ranks = props.user.user_game_ranks;
    return ranks.find(rank => rank.game_id === game.id);
}

</script>

<template>
    <div>
        <div class="text-white bg-blue-800 border border-blue-700 rounded-lg">
            <div class="flex items-center justify-between px-6 py-4 text-sm font-semibold bg-blue-700 ">
                <div>
                    Arena Record
                </div>
                <a :href="route('user.stats.show', {user: user})" class="hover:underline text-blue">
                    View Full Record<i class="ml-1 fas fa-arrow-right"></i>
                </a>
            </div>
            <div v-if="!!mostPlayedGames && mostPlayedGames.length > 0" class="w-full p-4 overflow-x-auto">
                <table class="w-full">
                    <thead class="text-left">
                        <tr class="text-sm text-gray-500 uppercase rounded-lg font-semibolds">
                            <th class="p-4 bg-blue-900 rounded-l-lg">Game</th>
                            <th class="p-4 text-center bg-blue-900">W</th>
                            <th class="p-4 text-center bg-blue-900">L</th>
                            <th class="p-4 text-center bg-blue-900 rounded-r-lg">Win Ratio</th>
                            <th class="p-4 text-center bg-blue-900 rounded-r-lg w-1/5">Rank</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(game, index) in mostPlayedGames">
                            <td :class="{'bg-blue-700': index % 2 === 0, 'flex items-center p-4 rounded-l-lg gap-x-4': true}">
                                <img class="flex-shrink-0 object-cover rounded-lg w-14 h-14"
                                     :src="game.game.storage_image_url"
                                     :alt="game.game.name">
                                <p class="text-lg font-bold">
                                    {{ game.game.name }}
                                </p>
                            </td>
                            <td :class="{'p-4 text-center': true, 'bg-blue-700': index % 2 === 0}">
                                <p class="text-base font-semibold">
                                    {{ game.wins }}
                                </p>
                            </td>
                            <td :class="{'p-4 text-center': true, 'bg-blue-700': index % 2 === 0}">
                                <p class="text-base font-semibold">
                                    {{ game.losses }}
                                </p>
                            </td>
                            <td :class="{'p-4 text-center rounded-r-lg': true, 'bg-blue-700': index % 2 === 0}">
                                <p class="text-base font-semibold text-blue-500">
                                    {{ game.win_percentage }}%
                                </p>
                            </td>
                            <td :class="{'p-4 text-center rounded-r-lg': true, 'bg-blue-700': index % 2 === 0}">
                                <p class="text-base font-semibold text-blue-500">
                                    <UserRankBadge :rank="rankForGame(game.game)" class="inline-block h-8" />
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-else class="grid py-10 space-y-6 place-items-center">
                <i class="text-4xl fas fa-star text-blue-light"></i>
                <div class="space-y-3 text-center">
                    <p class="text-xl font-semibold text-white">No matches played</p>
                    <p class="text-sm text-gray-off">
                        It looks like {{ user.username }} hasn't played any matches yet.
                    </p>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>

</style>

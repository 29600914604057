<script setup>

import {computed, onMounted, ref} from 'vue';
import {useForm, usePage} from "@inertiajs/vue3";
import OptionsChooser from "../../InertiaComponents/WagerChallenges/Creation/OptionsChooser.vue";
import GameChooser from "../../InertiaComponents/WagerChallenges/Creation/GameChooser.vue";
import Wizard from "../../InertiaComponents/UI/Wizard.vue";
import CreateTeam from "../../InertiaComponents/WagerChallenges/Creation/CreateTeam.vue";
import ProgressSpinner from "primevue/progressspinner";
import GameOn from "../../InertiaComponents/WagerChallenges/Creation/GameOn.vue";
import {Api} from "../../Services/api.js";
import {useToast} from "primevue/usetoast";

const page = usePage();
const toast = useToast();

const games = computed(() => {
    return page.props.games;
});

const regions = computed(() => {
    return page.props.regions;
});

const user = computed(() => {
    return page.props.auth.user;
});

const gamingAccounts = computed(() => {
  return user.value.gaming_accounts;
});

const gameNames = computed(() => {
    return games.value.map(game => {
        return {
            id: game.id,
            name: game.name
        };
    });
});

const gameOptions = computed(() => {
    return games.value.map(game => {
        return {
            label: game.name,
            value: game.slug
        };
    });
});

const form = useForm({
    gameSlug: gameOptions?.value.length > 0 ? gameOptions.value[0].value : '',
});

const selectedGame = computed(() => {
    return games.value.find(game => game.slug === form.gameSlug);
});

const teamsForGame = ref([]);
const createdTeamForGame = ref(null);

const isLoadingTeams = ref(false);

onMounted(() => {
    handleGameChange(form.gameSlug);
});

const handleGameChange = async (gameSlug) => {
    const game = games.value.find(game => game.slug === gameSlug);
    isLoadingTeams.value = true;
    const result = await fetch('/api/user/teams?gameId=' + game.id);

    const jsonResult = await result.json();

    isLoadingTeams.value = false;
    teamsForGame.value = jsonResult.data;
}


const progressOptionsWithoutCreatingTeam = [{
    label: "Pick Game",
    id: 'choose-game',
    stepNumber: 1
}, {
    label: "Choose Options",
    id: 'choose-options',
    stepNumber: 2
}, {
    label: "Game On",
    id: 'game-on',
    stepNumber: 3
}];

const progressOptionsWithCreatingTeam = [{
    label: "Pick Game",
    id: 'choose-game',
    stepNumber: 1
}, {
    label: "Create Team",
    id: 'create-team',
    stepNumber: 2
}, {
    label: "Choose Options",
    id: 'choose-options',
    stepNumber: 3
}, {
    label: "Game On",
    id: 'game-on',
    stepNumber: 4
}];

const progressOptions = computed(() => {
    if (isLoadingTeams.value) {
        return progressOptionsWithoutCreatingTeam;
    }

    if (!!teamsForGame.value && teamsForGame.value.length > 0) {
        return progressOptionsWithoutCreatingTeam;
    }

    return progressOptionsWithCreatingTeam;
});

const currentStep = ref('choose-game');

const createdChallenge = ref(null);

const handleGameSelected = async (game) => {
    console.log("Game selected", game);
    form.gameSlug = game.slug;
    await handleGameChange(game.slug);

    if (!!teamsForGame.value && teamsForGame.value.length > 0) {
        currentStep.value = 'choose-options';
    } else {
        currentStep.value = 'create-team';
    }
}

const handleTeamCreated = async (team) => {
    console.log("Team created", team);

    try {
        const resp = await Api.teams().get(team.id);
        const teamDetails = resp.data.data;
        console.log("Team changed", teamDetails);

        // selectablePlayers.value = teamDetails.members;
        createdTeamForGame.value = teamDetails;
        currentStep.value = 'choose-options';
    } catch (e) {
        console.error(e);
        toast.add({severity: 'error', summary: 'Error', detail: 'An error occurred while fetching team details', life: 5000});
    }
}

const handleChallengeCreated = async (challengeId) => {
    console.log("Challenge created", challengeId);
    currentStep.value = 'game-on';

    try {
        const resp = await Api.wagerChallenges().get(challengeId);
        createdChallenge.value = resp.data;
    } catch (e) {
        console.error(e);
        toast.add({severity: 'error', summary: 'Error', detail: 'An error occurred while fetching challenge details', life: 5000});
    }
}

const pages = [{
    label: "Page 1",
}];

</script>

<template>
    <div>
        <div class="container mx-auto">

            <Wizard :pages="progressOptions" :current-page="currentStep" :is-loading-marker-options="isLoadingTeams">
                <template #choose-game>
                    <GameChooser :games="games" :gaming-accounts="gamingAccounts" @game-chosen="handleGameSelected" />
                </template>

                <template #create-team>
                    <div>
                        <CreateTeam v-if="!!selectedGame" :game="selectedGame" @team-created="handleTeamCreated" />
                        <ProgressSpinner v-else />
                    </div>
                </template>

                <template #choose-options>
                    <OptionsChooser :teams="teamsForGame.length > 0 ? teamsForGame : [createdTeamForGame]" :game="selectedGame" :regions="regions" :gaming-account="gamingAccounts" @challenge-created="handleChallengeCreated" />
                </template>

                <template #game-on>
                    <div>
                        <GameOn v-if="!!createdChallenge" :challenge="createdChallenge" />
                        <ProgressSpinner v-else />
                    </div>
                </template>
            </Wizard>
        </div>
    </div>
</template>

<style scoped>

</style>

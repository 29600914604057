<script setup>

import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import PageTitle from "../../InertiaComponents/UI/PageTitle.vue";
import {useForm, usePage, Link} from "@inertiajs/vue3";
import {routeFromPage} from "../../routes.js";
import PaginationLinks from "../../InertiaComponents/Pagination/PaginationLinks.vue";
import Button from 'primevue/button';
import YouTracker from "../../InertiaComponents/Leaderboard/YouTracker.vue";
import UserLeaderboardTable from "../../InertiaComponents/Leaderboard/UserLeaderboardTable.vue";
import {computed} from "vue";
import {formatDistanceToNow} from "date-fns";
import {formatCurrency, getSuffixForOrdinal} from "../../Services/utils.js";
import UiButton from "../../InertiaComponents/UI/UiButton.vue";

const page = usePage();

const route = routeFromPage(page);

const props = defineProps({
    users: {
        type: Object,
        required: true
    },
    username: {
        type: String,
        required: true
    },
    activeFilter: {
        type: String,
        required: true,
    },
    activePeriodFilter: {
        type: String,
        required: true,
    },
    showRank: {
        type: Boolean,
        required: true,
    },
    yourRank: {
        type: Object,
        required: true,
    },
    leaderboard: {
        type: Object,
        required: true,
    },
    period: {
        type: Object,
        required: true,
    },
    availableLeaderboards: {
        type: Array,
        required: true,
    },
    games: {
        type: Array,
        required: true,
    }
});

const form = useForm({
    by: props.activeFilter,
    period: props.activePeriodFilter,
    username: props.username,
});

const selectedLeaderboard = computed(() => {
    return props.availableLeaderboards.find(leaderboard => leaderboard.slug === form.by);
});

const availableLeaderboardPeriods = computed(() => {
    return selectedLeaderboard.value.periods;
});

function leaderboardSelectionChanged() {
    const leaderboard = selectedLeaderboard.value;
    form.period = leaderboard.periods[0].slug;
}

const periodPrizes = computed(() => {
    return props.period.prizes.sort((a, b) => a.ordinal - b.ordinal);
})

const authUser = page.props.auth.user;

</script>

<template>
    <div>
        <div class="container mx-auto text-white">
            <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Leaderboards'}
            ]"/>

            <PageTitle title="User Leaderboards"/>

            <div class="mt-4 mb-6 flex gap-2">
                <UiButton>
                    <i aria-hidden="true" class="text-sm fas fa-users"></i> User Leaderboards
                </UiButton>

                <UiButton :href="route('leaderboard.team-index')" ghost pulse>
                    <i aria-hidden="true" class="text-sm fas fa-users"></i> Team Leaderboards
                </UiButton>
            </div>

            <p class="m-0">
                <!--                        <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">-->
                <!--                            <NewsCard v-for="newsPost in newsPosts" :key="newsPost.id"  :news-post="newsPost"/>-->
                <!--                        </div>-->

                <form @submit.prevent="form.get(route('leaderboard.index'))" class="mb-4">

                    <div class="flex flex-wrap -mx-3 mb-2">
                        <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0 flex flex-col">
                            <label class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                                   for="region">
                                Leaderboard
                            </label>

                            <select
                                class="block appearance-none w-full bg-blue-900 border border-gray-800 text-white placeholder-gray-600 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 flex-1"
                                id="by"
                                name="by"
                                v-model="form.by"
                                @change="leaderboardSelectionChanged"
                            >
                                <option v-for="leaderboard in availableLeaderboards" :key="leaderboard.id"
                                        :value="leaderboard.slug" :selected="leaderboard.slug === activeFilter">
                                    {{ leaderboard.name }}
                                </option>
                            </select>
                        </div>

                        <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0 flex flex-col">
                            <label class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                                   for="region">
                                Period
                            </label>

                            <select
                                class="block appearance-none w-full bg-blue-900 border border-gray-800 text-white placeholder-gray-600 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 flex-1"
                                id="by"
                                name="by"
                                v-model="form.period"
                            >
                                <option v-for="period in availableLeaderboardPeriods" :key="period.id"
                                        :value="period.slug" :selected="period.slug === activePeriodFilter">
                                    {{ period.name }}
                                </option>
                            </select>
                        </div>

                        <!--                                <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0 flex flex-col">-->
                        <!--                                    <label class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"-->
                        <!--                                           for="username">-->
                        <!--                                        Username-->
                        <!--                                    </label>-->

                        <!--                                    <input-->
                        <!--                                        class="block appearance-none w-full bg-blue-900 border border-gray-800 text-white placeholder-gray-500 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 flex-1"-->
                        <!--                                        id="username"-->
                        <!--                                        name="username"-->
                        <!--                                        v-model="form.username"-->
                        <!--                                        placeholder="Find a user by username"-->
                        <!--                                    />-->
                        <!--                                </div>-->

                        <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0 flex flex-col justify-center">
                            <label
                                class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2">Search</label>

                            <Button class="flex-1 text-white font-semibold" :disabled="!form.isDirty" type="submit">
                                Update Results
                            </Button>

                            <!--                                    <button type="submit"-->
                            <!--                                            class="px-16 py-3 rounded uppercase text-xs font-semibold text-center inline-block w-full bg-blue-500 disabled:bg-blue-800"-->
                            <!--                                            :disabled="!form.isDirty"-->
                            <!--                                    >-->
                            <!--                                        Update Results-->
                            <!--                                    </button>-->
                        </div>
                    </div>
                </form>

                <div>
                    <div class="lg:sticky top-0 px-2 pb-2 bg-blue-800">
                        <div class="flex justify-between items-center">
                            <div class="mb-2">
                                <h1 class="text-3xl font-semibold">
                                    {{ leaderboard.name }} - {{ period.name }}
                                </h1>
                                <h3 class="text-lg">
                                    {{ period.description }}
                                </h3>
                            </div>

                            <div v-if="!!period.lockable">
                                <div v-if="new Date(period.end_at) > new Date()" class="mr-2">
                                    <div class="text-right">
                                        <i class="fa-solid fa-lock-open text-xl text-green-500"></i>
                                    </div>
                                    Becomes Locked in {{ formatDistanceToNow(period.end_at) }}
                                </div>
                                <div v-else class="mr-2">
                                    <div class="text-right">
                                        <i class="fa-solid fa-lock text-xl text-red-500"></i>
                                    </div>
                                    Locked
                                </div>
                            </div>
                        </div>

                        <div v-if="period.prizes.length > 0">
                            <div class="mb-4 flex items-center gap-4">
                                <div>
                                    <h2 class="text-md font-semibold text-blue-300 uppercase">
                                        Prizes
                                    </h2>
                                </div>
                                <div class="w-full flex items-center gap-4 flex-wrap">
                                    <div v-for="prize in periodPrizes"
                                         class="border-blue-500 border-2 rounded-lg p-2 flex-1">
                                        <div v-if="prize.rank === 1" class="w-16 h-16 overflow-hidden float-left mr-2">
                                            <img src="/images/trophies/gold-trophy.svg"
                                                 class="transform scale-150">
                                        </div>

                                        <div v-if="prize.rank === 2" class="w-16 h-16 overflow-hidden float-left mr-2">
                                            <img src="/images/trophies/silver-trophy.svg"
                                                 class="transform scale-150">
                                        </div>

                                        <div v-if="prize.rank === 3" class="w-16 h-16 overflow-hidden float-left mr-2">
                                            <img src="/images/trophies/bronze-trophy.svg"
                                                 class="transform scale-150">
                                        </div>

                                        <!--                                                <img v-if="prize.rank === 2" src="/images/trophies/silver-trophy.svg"-->
                                        <!--                                                     class="w-32 h-32 float-left">-->

                                        <!--                                                <img v-if="prize.rank === 3" src="/images/trophies/bronze-trophy.svg"-->
                                        <!--                                                     class="w-32 h-32 float-left">-->

                                        <div class="flex flex-col justify-around flex-1 h-full mt-2">
                                            <p class="text-xl font-semibold">

                                                <span class="relative mr-4">{{ prize.rank }}<span
                                                    class="absolute text-sm top-0">{{
                                                        getSuffixForOrdinal(prize.rank)
                                                    }}</span></span>
                                                -
                                                {{ prize.name }}
                                            </p>
                                            <!--                                                <div class="text-sm font-semibold mb-2">-->
                                            <!--                                                    {{ prize.description }}-->
                                            <!--                                                </div>-->
                                            <div class="flex gap-1">
                                                <p v-if="!!prize.balance_amount" class="text-md font-semibold">
                                                    ${{ formatCurrency(prize.balance_amount) }}
                                                </p>
                                                <p v-if="!!prize.balance_amount && !!prize.credits" class="font-light">
                                                    and
                                                </p>
                                                <p v-if="!!prize.credits" class="text-md font-semibold">
                                                    {{ prize.credits }} Credits
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <YouTracker :your-rank="yourRank" :leaderboard-title="leaderboard.name"/>
                    </div>

                    <UserLeaderboardTable :users="users"
                                          :leaderboard="leaderboard"
                                          :show-rank="showRank"
                                          :auth-user="authUser"/>

                    <div class="my-4">
                        <PaginationLinks :links="users.links"/>
                    </div>
                </div>

                <div v-if="users.total === 0" class="text-lg text-center my-4">
                    <p class="mb-4">
                        This Leaderboard doesn't have any users yet. Compete in matches to earn XP and climb the ranks!
                    </p>

                    <p class="mb-4">
                        The sooner you start, the sooner you can start climbing the ranks!
                    </p>

                    <p class="mb-4">
                        <Link :href="route('matchfinder.index')"
                              class="btn-gradient-blue px-6 py-3 rounded-lg text-xs font-semibold">
                            <i aria-hidden="true" class="text-sm fas fa-fist-raised"></i> Find Matches
                        </Link>
                    </p>
                </div>
            </p>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>

defineProps(['tournament', 'classes']);

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

</script>

<template>
    <div
        class="bg-blue-800 px-2 py-1 rounded-md shadow-xl text-center bg-opacity-90"
        :class="classes"
    >
        <div v-if="tournament.prize_amount > 0">
            <div class="text-xl sm:text-3xl md:text-2xl lg:text-xl font-semibold text-yellow-400">
                ${{ numberWithCommas(tournament.prize_amount) }}
            </div>
            <div class="text-xs sm:text-md sm:text-xl md:text-sm lg:text-xs font-light uppercase">
                Top Prize
            </div>
        </div>
        <div v-else>
            <div class="text-xl sm:text-3xl md:text-2xl lg:text-xl font-semibold text-yellow-400">
                Free Entry
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>

import {Link, router} from "@inertiajs/vue3";
import Button from 'primevue/button'

defineProps({
    'challenge': {
        type: Object,
        required: true
    }
})

const onGoToMatchFinder = () => {
    const r = route('matchfinder.index');
    router.visit(r);
}

</script>

<template>
    <div class="flex justify-center items-center w-full h-full">
        <div class="w-full lg:w-6/12 px-4 mb-6">
            <div class="bg-blue-600 text-white rounded-lg border border-gray-800 px-8 sm:px-16 py-16">
                <div id="inner" class="border-gray-800">
                    <div class="mb-6">
                        <div class="text-3xl font-extrabold mb-1">Game On!</div>
                        <div class="text-gray-400 text-sm">
                            Your challenge is ready to go!
                        </div>
                    </div>

                    <p class="my-4">
                        Your match has been created and is now ready to be accepted by your opponent.
                    </p>

                    <p class="my-4">
                        You will be notified when your opponent accepts the match.
                    </p>

                    <p class="my-4">
                        Good luck!
                    </p>

                    <Button @click="onGoToMatchFinder">
                        Go to Match Finder
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

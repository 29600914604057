<script setup>

import ProgressMarker from "./ProgressMarker.vue";
import {computed} from "vue";

const props = defineProps({
    'pageCount': {
        type: Number,
        default: 1
    },
    'pages': {
        type: Array,
        default: [{
            label: "Page 1",
            id: 'page-one'
        }]
    },
    'currentPage': {
        type: String,
        default: "page-one"
    },
    'isLoadingMarkerOptions': {
        type: Boolean,
        default: false
    }
})

const progressMarkerOptions = computed(() => props.pages.map((page, index) => {
    return {
        label: page.label,
        stepNumber: index + 1,
        id: page.id,
    }
}));

const handleNextPage = () => {
    console.log("Next clicked");

    if (props.currentPage.value < parseInt(props.pageCount)) {
        props.currentPage.value++;
    }
};

const handlePreviousPage = () => {
    console.log("Previous clicked")

    if (props.currentPage.value - 1 > 0) {
        props.currentPage.value--;
    }
};

</script>

<template>
    <div class="text-white">
        <ProgressMarker :step="currentPage" :options="progressMarkerOptions"></ProgressMarker>

<!--        <div v-if="showNextAndPrevious" class="flex justify-between">-->
<!--            <button @click="handlePreviousPage">Previous</button>-->
<!--            <button @click="handleNextPage">Next</button>-->
<!--        </div>-->

        <div>
            <div>

                <transition name="fade" mode="out-in" appear>
                    <slot :name="currentPage"></slot>
                </transition>
            </div>
        </div>
    </div>
</template>

<style scoped>

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
    transform: translateY(-3px);
}
</style>

<script setup>
import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';
import {Link} from '@inertiajs/vue3';
import NavMobileMenuDropdown from '../Navigation/NavMobileMenuDropdown.vue';

const page = usePage();
const club = computed(() => page.props.club);

</script>

<style scoped>
.club-navbar {
    @apply flex justify-center gap-4 py-2 relative;
}

.club-navbar-bg {
    @apply absolute inset-0 bg-white opacity-70 backdrop-blur-lg;
}

.club-navbar-links {
    @apply flex items-center gap-6;
}

.club-navbar-link {
    @apply lg:text-base xl:text-lg font-semibold tracking-wide text-black uppercase transition duration-200 ease-in-out transform scale-100 hover:scale-110;
}

.logo-container {
    @apply flex items-center justify-center mx-4;
}

</style>

<template>
    <transition name="slide-fade">
        <div class="relative z-0">
            <div class="club-navbar-bg"></div>
                <div class="w-full">
                    <div class="club-navbar">
                        <NavMobileMenuDropdown class="-mr-2"/>
                        <div class="club-navbar-links">
                            <Link :href="route('game.index', { 'status': 'upcoming' })" class="club-navbar-link">Games
                            </Link>
                            <Link :href="route('club-tournaments.index', club.slug)" class="club-navbar-link">
                                Tournaments
                            </Link>
                            <Link :href="route('clubleagues.index', club.slug )" class="club-navbar-link">Leagues</Link>
                        </div>
                        <div class="logo-container">
                            <Link :href="route('clubs.show', club.slug)">
                                <img class="h-12 md:h-16 object-contain rounded-full" :src="club.logo_url"
                                     :alt="`${club.logo_url} logo`">
                            </Link>
                        </div>
                        <div class="club-navbar-links">
                            <Link :href="route('matchfinder.index')" class="club-navbar-link">Galleries</Link>
                            <Link href="/" class="club-navbar-link">News</Link>
                            <Link :href="route('store.index')" class="club-navbar-link">Store</Link>
                        </div>
                    </div>
                </div>
            </div>
    </transition>
</template>

<template>
    <div class="w-full md:w-2/12">
        <ul>
            <li>
                <a
                    class="py-4 px-3 text-xs font-semibold hover:bg-blue-600 w-full inline-block"
                    :class="{ 'bg-blue-700': isActiveRoute('user.settings') }"
                    :href="route('user.settings')"
                >
                    Personal Information
                </a>
            </li>
            <li>
                <a
                    class="py-4 px-3 hover:bg-blue-700 text-xs font-semibold w-full inline-block"
                    :class="{ 'bg-blue-700': isActiveRoute('settings.social.index') }"
                    :href="route('settings.social.index')"
                >
                    Social Information
                </a>
            </li>
            <li>
                <a
                    class="py-4 px-3 hover:bg-blue-700 text-xs font-semibold w-full inline-block"
                    :class="{ 'bg-blue-700': isActiveRoute('settings.gaming.index') }"
                    :href="route('settings.gaming.index')"
                >
                    Gaming Information
                </a>
            </li>
            <li>
                <a
                    class="py-4 px-3 hover:bg-blue-700 text-xs font-semibold w-full inline-block"
                    :class="{ 'bg-blue-700': isActiveRoute('settings.preferences.index') }"
                    :href="route('settings.preferences.index')"
                >
                    Challenge Settings
                </a>
            </li>
            <li>
                <a
                    class="py-4 px-3 hover:bg-blue-700 text-xs font-semibold w-full inline-block"
                    :class="{ 'bg-blue-700': isActiveRoute('settings.account.index') }"
                    :href="route('settings.account.index')"
                >
                    Email / Password settings
                </a>
            </li>
            <li>
                <a
                    class="py-4 px-3 hover:bg-blue-700 text-xs font-semibold w-full inline-block"
                    :class="{ 'bg-blue-700': isActiveRoute('settings.security.index') }"
                    :href="route('settings.security.index')"
                >
                    Security settings
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";

const page = usePage();

const isActiveRoute = (routeName) => {
    return page.url.includes(route(routeName));
};
</script>

<script setup>
import { ref, computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import { format } from 'date-fns';
import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import MatchTeamInfo from "../../InertiaComponents/Leagues/MatchTeamInfo.vue";

const { props } = usePage();
const match = computed(() => props.match);
const league = computed(() => props.league);

const teamOneLineup = computed(() => match.value.lineups.filter(lineup => lineup.team_id === match.value.team_one.id));
const teamTwoLineup = computed(() => match.value.lineups.filter(lineup => lineup.team_id === match.value.team_two.id));

const formattedDate = computed(() => format(new Date(match.value.match_start), 'dd/MM/yyyy HH:mm'));

const matchResult = computed(() => {
    if (match.value.winning_team_id === null) {
        return 'Pending';
    }
    return {
        teamOneResult: match.value.winning_team_id === match.value.team_one.id ? 'W' : 'L',
        teamTwoResult: match.value.winning_team_id === match.value.team_two.id ? 'W' : 'L',
        teamOneClass: match.value.winning_team_id === match.value.team_one.id ? 'text-green-500' : 'text-red-500',
        teamTwoClass: match.value.winning_team_id === match.value.team_two.id ? 'text-green-500' : 'text-red-500'
    };
});
</script>


<template>
    <div>
        <div id="bg" class="game-header-img z-1" :style="{
                background:
                    'url(\'' + league.game.storage_header_url + '\')'
            }"></div>
        <div id="main" class="py-2 text-white">
            <div class="container mx-auto">
                <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Leagues', link: route('leagues.index', league.id)},
                {name: league.name, link: route('leagues.show', league.id)},
                {name: 'Match'},
            ]"/>
                <div id="heading-area"
                     class="flex flex-col items-center justify-between mt-8 sm:flex-row sm:mx-0">
                    <div class="flex md:w-3/4">
                        <div
                            class="items-center self-center hidden px-2 py-2 mr-2 rounded-full sm:block">
                            <img :src="league.game.storage_icon_url" class="w-8 h-8" alt="game_icon"/>
                        </div>
                        <div class="flex flex-col text-4xl font-extrabold lg:flex-row lg:items-center">
                            <div class="inline-block">
                                {{ league.name }}
                                <span class="text-gray-300">{{ league.max_team_size }} v
                                        {{ league.max_team_size }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mx-auto px-4 py-8">
            <div class="match-details flex flex-col md:flex-row items-center justify-between mb-8">
                <MatchTeamInfo :team="match.team_one" :lineups="teamOneLineup"/>

                <div class="central-section">
                    <div class="text-xl font-semibold">{{ match.league_group.name }}</div>
                    <div class="text-6xl font-bold">
                        <span v-if="matchResult === 'Pending'" class="text-white">{{ matchResult }}</span>
                        <template v-else>
                            <span :class="matchResult.teamOneClass">{{ matchResult.teamOneResult }}</span>
                            :
                            <span :class="matchResult.teamTwoClass">{{ matchResult.teamTwoResult }}</span>
                        </template>
                    </div>
                    <div id="start-date-stat-box"
                         class="flex items-center px-6 py-3 mx-2 leading-tight">
                        <i class="mr-4 text-blue-400 fas fa-clock text-2xl"></i>
                        <div>
                            <div class="font-bold">
                                Match Date: {{ formattedDate }}
                            </div>
                            <div class="text-xs text-gray-400">
                                GMT
                            </div>
                        </div>
                    </div>
                    <div v-if="match.most_valuable_player">
                        <div class="mvp">
                            <h3 class="text-xl font-bold">MVP <i class="fa-solid fa-crown text-yellow-500"></i></h3>
                            <Link :href="route('user.show', {user: match.most_valuable_player})">
                                <img :src="match.most_valuable_player.storage_avatar" alt="MVP Avatar"
                                     class="w-16 h-16 rounded-full mb-2 mx-auto">
                                <p class="text-lg font-bold">{{ match.most_valuable_player.username }}</p>
                            </Link>

                        </div>
                    </div>
                </div>
                <MatchTeamInfo :team="match.team_two" :lineups="teamTwoLineup"/>
            </div>
        </div>
    </div>
</template>


<style scoped>
#bg {
    position: absolute;
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
}

.central-section {
    @apply flex flex-col items-center w-full mb-4 md:mb-0 m-6;
}

.mvp {
    @apply flex flex-col items-center gap-2 m-6 bg-blue-600 p-6 border border-blue-700 rounded-2xl;
}
</style>

<script setup>

// const props = defineProps(['options', 'labelAttr', 'valueAttr', 'name', 'label', 'onChange', 'labelDirection']);
const props = defineProps({
    'options': {
        type: Array,
        default: [{
            label: 'Example Option 1',
            value: 'exampleOption1'
        }, {
            label: 'Example Option 2',
            value: 'exampleOption2'
        }]
    },
    'labelAttr': {
        type: String,
        default: 'label'
    },
    'valueAttr': {
        type: String,
        default: 'value'
    },
    'name': {
        type: String,
        default: 'exampleSelect'
    },
    'label': {
        type: String,
        default: 'Example Select'
    },
    'labelDirection': {
        type: String,
        default: 'above'
    },
    'classes': {
        type: String,
        default: ''
    },
    'boxSize': {
        type: String,
        default: 'lg'
    }
});

const model = defineModel();

const isSelected = (option) => {
    if (!props.selected) {
        return false;
    }

    if (typeof(props.selected) === 'string') {
        return option[props.valueAttr] === props.selected;
    }

    return option[props.valueAttr] === props.selected[props.valueAttr];
}

</script>

<template>
    <div :class="[ labelDirection === 'left' ? 'flex items-center gap-1' : '', classes ]">
        <label
            :for="name"
            class="text-xs uppercase flex-1 whitespace-nowrap"
            :class="{ 'text-right': labelDirection === 'left' }"
        >
            {{ label }}
        </label>
        <select
            v-model="model"
            @change="$emit('selectionChange', $event.target.value)"
            :name="name"
            class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold px-4 pr-8 rounded leading-tight focus:outline-none flex-1"
            :class="{ 'py-4': boxSize === 'lg', 'py-3': boxSize === 'md', 'py-2': boxSize === 'sm', 'py-1': boxSize === 'xs'}"
        >
            <option
                v-for="option in options"
                :key="option[valueAttr]"
                :value="option[valueAttr]"
            >
                {{ option[labelAttr] }}
            </option>
        </select>
    </div>
</template>

<style scoped>

</style>

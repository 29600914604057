<script setup>

import {computed, defineProps} from 'vue';

const props = defineProps({
    'count': {
        type: Number,
        required: true
    },
    'type': {
        type: String,
        required: true
    }
});

const trophyImage = computed(() => {
    return `/images/trophies/${props.type}-trophy.svg`;
});

const trophyImageAltText = computed(() => {
    return `${props.type} trophy`;
});

const bgClass = computed(() => {
    if (props.type === 'diamond') {
        return 'bg-turqouise';
    }

    if (props.type === 'gold') {
        return 'bg-gold';
    }

    if (props.type === 'silver') {
        return 'bg-silver';
    }

    return 'bg-bronze';
});

const borderClass = computed(() => {
    if (props.type === 'diamond') {
        return 'border-turqouise';
    }

    if (props.type === 'gold') {
        return 'border-gold';
    }

    if (props.type === 'silver') {
        return 'border-silver';
    }

    return 'border-bronze';
});

</script>

<template>
    <div class="relative grid w-16 h-16 bg-blue-900 rounded-full place-items-center">
        <div :class="`relative grid rounded-full w-14 h-14 place-items-center ${bgClass}`">
            <div :class="`relative rounded-full ${borderClass}`">
                <img :src="trophyImage" :alt="trophyImageAltText" class="relative w-12 h-12">
            </div>
            <div class="absolute -bottom-4 right-1/2 transform translate-x-1/2 z-0 px-2 py-0 aspect-square rounded-full trophy-number flex items-center justify-center">
                {{ count }}
            </div>
        </div>
    </div>
</template>

<style scoped>

.trophy-number {
    background: #2d405d;
    font-size: 20px;
    font-weight: 700;
}

</style>

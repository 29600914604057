<script setup>

import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3';
import {roundToXDecimalPlaces} from "../Services/utils.js";

const props = defineProps({
    teamId: String,
    winRatio: Number,
    totalWins: Number,
    totalLosses: Number,
    totalCashEarned: Number,
    teamViews: Number,
    isCaptain: Boolean,
    userBelongsToTeam: Boolean,
    isOwner: Boolean,
    showElo: {
        type: Boolean,
        default: false
    },
    team: {
        type: Object,
        default: () => ({})
    }
});

const { props: pageProps } = usePage();
const csrf = ref(pageProps.csrf);

const confirmLeaveAndSubmit = () => {
    if (confirm('Are you sure you want to leave this team?')) {
        const form = useForm({});
        form.post(route('team.leave.store', props.teamId));
    }
};

</script>

<template>
    <div class="flex px-4 py-4 mt-10 bg-blue-800 border border-gray-800 lg:mt-5 sm:px-0">
        <div class="container flex flex-col mx-auto sm:flex-row sm:justify-between">
            <div class="flex flex-col sm:flex-row">
                <div id="stat-box"
                     class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                    <i class="mr-4 text-orange-500 fas fa-chart-pie text-2xl"></i>
                    <div>
                        <div class="text-xs text-gray-300">Win Ratio</div>
                        <div class="text-md font-bold text-white">{{ winRatio.toFixed(2) }}%</div>
                    </div>
                </div>
                <div id="stat-box"
                     class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                    <i class="mr-4 text-blue-500 fa-solid fa-trophy text-2xl"></i>
                    <div>
                        <div class="text-xs text-gray-300">Win Loss Record</div>
                        <div class="text-md font-bold text-white">{{ totalWins }}W / {{ totalLosses }}L</div>
                    </div>
                </div>
                <div id="stat-box"
                     v-if="showElo"
                     class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                    <i class="mr-4 text-blue-500 fa-solid fa-gauge text-2xl"></i>
                    <div>
                        <div class="text-xs text-gray-300">Score (Elo)</div>
                        <div class="text-md font-bold text-white">{{ roundToXDecimalPlaces(team.competitive_score, 2) }}</div>
                    </div>
                </div>
                <div id="stat-box"
                     v-if="showElo"
                     class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                    <i class="mr-4 text-orange-500 fa-solid fa-fire text-2xl"></i>
                    <div>
                        <div class="text-xs text-gray-300">Weekly Streak</div>
                        <div class="text-md font-bold text-white">{{ team.streak }}</div>
                    </div>
                </div>
                <div id="stat-box"
                     class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                    <i class="mr-4 text-green-500 fas fa-coins text-2xl"></i>
                    <div>
                        <div class="text-xs text-gray-300">Total Earnings</div>
                        <div class="text-md font-bold text-white">${{ totalCashEarned }}</div>
                    </div>
                </div>
                <div id="stat-box"
                     class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                    <i class="mr-4 text-white fas fa-eye text-2xl"></i>
                    <div>
                        <div class="text-xs text-gray-300">Views</div>
                        <div class="text-md font-bold text-white">{{ teamViews }}</div>
                    </div>
                </div>
            </div>
            <div class="ml-auto flex items-center">
                <div v-if="isCaptain" class="flex py-3 mr-2 leading-tight">
                    <a :href="route('team.settings.info.edit', teamId)">
                        <div class="px-4 py-3 text-xs font-medium border border-gray-700 rounded-lg">
                            <i class="mr-4 text-sm text-blue-500 fas fa-cog"></i>Edit Team
                        </div>
                    </a>
                </div>
                <div class="flex py-3 mr-2 leading-tight">
                    <form v-if="userBelongsToTeam && !isOwner" :action="route('team.leave.store', teamId)" method="POST" @submit.prevent="confirmLeaveAndSubmit">
                        <input type="hidden" name="_token" :value="csrf">
                        <button type="submit" class="px-4 py-3 text-xs font-medium bg-red-500 border border-gray-700 rounded-lg">
                            <i class="mr-2 text-sm fas fa-times-circle"></i>Leave Team
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

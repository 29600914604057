<template>
    <div id="main" class="text-white py-4">
        <div class="container mx-auto">
            <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Store'}
            ]"/>

            <div class="flex items-center mb-4 w-full justify-between px-2">
                <div class="text-4xl font-bold">Store</div>
                <Link :href="route('bank.deposit.create')">
                    <div class="btn-gradient-blue px-6 py-3 rounded-lg text-xs font-semibold">
                        <i aria-hidden="true" class="text-sm mr-2 fas fa-coins"></i>Add Money
                    </div>
                </Link>
            </div>

            <Tabs>
                <Tab icon="fas fa-coins mr-1" name="Credits" :selected="true">
                    <div class="px-0 py-4">
                        <div
                            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-12 max-w-7xl mx-auto">
                            <CreditPack
                                v-for="creditPack in creditPacks"
                                :key="creditPack.id"
                                :id="creditPack.id"
                                :image_url="creditPack.image_url.toString()"
                                :storage_image_url="creditPack.storage_image_url.toString()"
                                :credit_amount="creditPack.credit_amount.toString()"
                                :price="creditPack.price"
                            />
                        </div>
                    </div>
                </Tab>

                <Tab icon="fas fa-crown mr-1" name="Membership">
                    <div v-if="eliteMembership"
                         class="bg-blue-300 border-t-4 border-gray-900 rounded-b text-teal-darkest px-4 py-3 shadow-md my-4"
                         role="alert">
                        <div class="flex">
                            <svg class="h-6 w-6 text-white mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path
                                    d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                            </svg>
                            <div>
                                <p class="font-bold">Information</p>
                                <p class="text-sm">
                                    You have Elite membership until {{ eliteMembership.expiry_date }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="px-0 py-4">
                        <div class="flex flex-wrap justify-center">
                            <MembershipCard
                                v-for="(membership, index) in memberships"
                                :key="index"
                                :membership="membership"
                                :eliteMembership="eliteMembership"
                                :index="index"
                            />
                        </div>
                    </div>
                </Tab>
                <Tab icon="fas fa-store mr-1" name="Other">
                    <div class="px-0 py-4">
                        <div class="flex flex-wrap">
                            <div
                                v-for="item in storeItems"
                                :key="item.name"
                                class="w-full md:w-1/4 transition duration-300 ease-in-out transform hover:-translate-y-1 px-2 mb-6 shadow-lg"
                            >
                                <div
                                    class="bg-blue-800 text-white rounded border border-blue-700 py-4 px-4 flex flex-col items-center">
                                    <div class="text-center">
                                        <i :class="item.icon" class="text-2xl"></i>
                                    </div>
                                    <div class="text-center text-xs font-semibold py-1 mb-3">
                                        {{ item.name }}
                                    </div>
                                    <Link
                                        v-if="item.name !== 'Claim inactive username'"
                                        :href="isAuthenticated ? route(item.authRoute) : route(loginRoute)"
                                        class="btn-gradient-blue py-2 w-full rounded-lg text-sm font-semibold text-center"
                                    >
                                        {{ isAuthenticated ? `Buy with Balance ($${item.price})` : `$${item.price}` }}
                                    </Link>
                                    <a
                                        v-else
                                        :href="route('tickets.index')"
                                        class="btn-gradient-blue py-2 w-full rounded-lg text-sm font-semibold text-center">
                                        {{ isAuthenticated ? `Contact Live Support ($${item.price})` : `$${item.price}` }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue';
import {usePage} from '@inertiajs/vue3';
import Tabs from '../../components/partials/Tabs.vue';
import Tab from '../../components/partials/Tab.vue';
import CreditPack from '../../InertiaComponents/Store/CreditPack.vue';
import MembershipCard from '../../InertiaComponents/Store/MembershipCard.vue';
import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import {Link} from '@inertiajs/vue3';

const {props} = usePage();
const isAuthenticated = ref(props.auth.user !== null);

// TODO: These ideally should be fetched from the backend
const storeItems = ref([
    {
        name: 'Username change',
        icon: 'fas fa-exchange-alt',
        price: props.usernameChangePrice,
        authRoute: 'store.username-change'
    },
    {
        name: 'Game record reset',
        icon: 'fas fa-undo',
        price: props.gameRecordPrice,
        authRoute: 'store.game-record-reset'
    },
    {
        name: 'All stats reset',
        icon: 'fas fa-recycle',
        price: props.allStatsResetPrice,
        authRoute: 'store.all-stats-reset'
    },
    {
        name: 'Claim inactive username',
        icon: 'fas fa-undo',
        price: props.inactiveUsernameClaim,
        authRoute: '#' // TODO: This needs to be updated with a route
    }
]);

const loginRoute = 'login';
const creditPacks = ref(props.creditPacks || []);
const memberships = ref(props.memberships);
const eliteMembership = ref(props.eliteMembership || null);

</script>

<template>
    <div class="w-full my-4 overflow-x-auto rounded-lg">
        <div class="text-white bg-blue-600 border border-gray-800 w-max lg:w-full">
            <div id="wager-header" class="flex items-center justify-between px-6 text-xs">
                <div class="flex">
                    <div class="px-1 py-4 mr-4 border-b-2 border-blue-500">
                        <h2 class="font-semibold">Activity Log</h2>
                    </div>
                </div>
            </div>

            <div class="flex pt-3 pb-1 font-extrabold text-gray-600 bg-blue-800 text-xxs">
                <div class="flex items-center w-1/12 px-6">ID</div>
                <div class="flex items-center justify-center w-2/12 px-6">Type</div>
                <div class="flex items-center justify-center w-4/12 px-6">Message</div>
                <div class="flex items-center justify-center w-2/12 px-6">Starting Balance</div>
                <div class="flex items-center justify-center w-2/12 px-6">Change</div>
                <div class="flex items-center w-2/12 px-6">Final Balance</div>
                <div class="flex items-center w-2/12 px-6 text-right">Date</div>
            </div>

            <div v-for="(activityLog, key) in logs.data" :key="activityLog.id"
                 :class="[key % 2 == 0 ? 'bg-blue-700' : 'bg-blue-800', 'flex']">
                <div class="flex items-center w-1/12 px-6 font-semibold text-xxs">#{{ activityLog.id }}</div>
                <div class="flex items-center justify-center w-2/12 px-6 py-4 font-semibold text-xxs">
                    {{ activityLog.log_type }}
                </div>
                <div class="flex items-center justify-center w-4/12 px-6 py-4 font-semibold text-xxs">
                    {{ activityLog.log_message }}
                </div>
                <div class="flex items-center w-2/12 px-6 py-4 font-semibold lg:justify-center text-xxs">
                    ${{ activityLog.starting_balance }}
                </div>
                <div class="flex items-center justify-center w-2/12 px-6 py-4 font-semibold text-xxs">
                    {{ activityLog.change < 0 ? '-' : '+' }} ${{ Math.abs(change(activityLog.starting_balance, activityLog.end_balance)) }}
                </div>
                <div class="flex items-center w-2/12 px-6 py-4">
          <span class="font-semibold text-xxs">
            <span :class="activityLog.end_balance > activityLog.starting_balance ? 'text-green-500' : 'text-red-500'">
              ${{ activityLog.end_balance }}
            </span>
          </span>
                </div>
                <div class="flex w-2/12 px-6 py-4 text-left">
                    <span class="font-semibold text-xxs">{{ format(activityLog.created_at, 'yyyy-MM-dd HH:mm:ss') }}</span>
                </div>
            </div>

            <div v-if="!logs.data.length" class="flex">
                <div class="flex items-center px-6 py-4 w-12/12">
                    <div class="font-semibold text-white text-xxs">No activity logs available</div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <PaginationLinks :links="logs.links"></PaginationLinks>
        </div>
    </div>
</template>

<script setup>
import {computed} from 'vue';
import PaginationLinks from "../Pagination/PaginationLinks.vue";
import {format} from "date-fns";

const props = defineProps({
    logs: {
        type: Object,
        required: true,
    },
});

const logs = computed(() => props.logs);

const change = (starting_balance, end_balance) => {
    return (end_balance - starting_balance).toFixed(2);
};


</script>

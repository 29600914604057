<script setup>

import {usePage} from "@inertiajs/vue3";
import PlatformIcon from "../../../InertiaComponents/Platform/PlatformIcon.vue";

const page = usePage();
const props = defineProps({
    recentlyPlayedMatches: Array,
    classes: String,
    buttons: Object
});

</script>

<template>
    <div class="w-full overflow-x-auto rounded-lg">
        <div class="bg-blue-600 text-white border border-gray-800 w-max lg:w-full"
             :class="`${!!classes ? classes : ''}`"
        >
            <div id="wager-header" class="flex items-center justify-between px-6 text-xs">
                <a
                    v-if="!!buttons"
                    v-for="button in buttons"
                    :href="button.link"
                   class="px-3 py-1 font-semibold rounded-lg btn-gradient-blue text-xxs">
                    {{ button.label }}
                </a>
            </div>

            <div class="p-4">
                <table id="recently-played-match-table">
                    <thead>
                    <tr>
                        <th class="dimension-title">Game</th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Game Mode
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Region
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Best Of
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Team Size
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Wager
                            </p>
                        </th>
                        <th class="dimension-title">
                            <p class="text-center">
                                Winner
                            </p>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="match in recentlyPlayedMatches"
                            :key="match.id"
                        >
                            <td class="flex items-center gap-4 pl-3 py-2">
                                <div class="flex items-center gap-2">
                                    <img
                                        :src="match.game.storage_icon_url"
                                        alt=""
                                        class="w-8 h-8 object-contain rounded-md"
                                    >
                                    <PlatformIcon class="w-8 h-8 rounded-md" :platform="match.platform" />
                                </div>
                                <div class="font-semibold text-gray-300 text-sm">{{ match.game.name }}</div>
                            </td>

                            <td class="text-gray-400 text-xs">
                                <p class="text-center">
                                    {{ match.game_mode.name }}
                                </p>
                            </td>

                            <td class="text-gray-400 text-xs">
                                <p class="text-center">
                                    {{ match.region.name }}
                                </p>
                            </td>

                            <td class="text-gray-400 text-xs">
                                <p class="font-semibold text-center">
                                    {{ match.best_of }}
                                </p>
                            </td>

                            <td class="text-gray-400 text-xs">
                                <p class="font-semibold text-center">{{ match.team_size }} v {{ match.team_size }}</p>
                            </td>

                            <td class="text-gray-400">
                                <div class="text-center">
                                    <p class="inline-block px-4 py-2 font-semibold text-center uppercase bg-gray-800 rounded-full text-xs">
                                        <i class="mr-1 text-orange-500 fa-solid fa-trophy" aria-hidden="true"></i>
                                        $ {{ match.wager_amount }}
                                    </p>
                                </div>
                            </td>

                            <td class="text-gray-400 text-xs">
                                <div class="text-center">
                                    <a
                                        v-if="!!match.winner"
                                        :href="route('teams.show', match.winner.id)"
                                        class="flex items-center text-blue-400">
                                        <img
                                            :src="match.winner.storage_avatar"
                                            class="w-8 h-8 mr-2 rounded-full"
                                            alt="">
                                        {{ match.winner.name }}
                                    </a>
                                    <div v-else>
                                        Disbanded
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <div class="flex" v-if="!recentlyPlayedMatches || recentlyPlayedMatches.length <= 0">
                <div class="flex items-center px-6 py-4 w-12/12">
                    <div class="font-semibold text-white text-xs">There are no matches available.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
#recently-played-match-table {
    @apply w-full p-2;
}

#recently-played-match-table th {
    @apply bg-blue-800 text-gray-400 text-left text-xs pl-4 py-2 uppercase font-medium;
}

#recently-played-match-table th:first-of-type {
    @apply rounded-l-lg;
}

#recently-played-match-table th:last-of-type {
    @apply rounded-r-lg;
}

#recently-played-match-table td {
    @apply text-left pl-4;
}
</style>

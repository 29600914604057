<template>
    <div class="container mx-auto">
        <div class="flex items-center justify-center w-full h-full">
            <div class="w-full px-4 mb-6 lg:w-6/12">
                <form @submit.prevent="submitForm" class="w-full">
                    <div class="p-12 text-white bg-blue-600 border border-gray-800 rounded-lg lg:p-16">
                        <div id="inner" class="pb-6 mb-4 border-gray-800">
                            <div class="mb-6">
                                <div class="mb-1 text-4xl font-extrabold">Username change</div>
                                <div class="text-sm text-gray-600">
                                    Change your username on {{ appName }} platform
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="py-1 text-xs text-gray-600">Current username</div>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <input disabled type="text" name="current_username"
                                           class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500"
                                           :value="user.username" />
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="py-1 text-xs text-gray-600">New username</div>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <input v-model="form.username" type="text" name="username"
                                           class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500" />
                                </div>
                            </div>

                            <button v-if="parseFloat(user.balance) >= parseFloat(price)"
                                    type="submit"
                                    @click="confirmChange"
                                    class="w-full p-4 mt-3 text-xs font-semibold text-center uppercase bg-red-500 rounded">
                                Change username (${{ price }})
                            </button>
                            <div v-else>
                                <p class="mb-2 text-red-500 font-semibold">
                                    You do not have sufficient funds to buy - add money?
                                </p>
                                <a :href="route('bank.deposit.create', { m: 'dc' })"
                                              class="btn-gradient-blue px-16 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block">
                                    Add Money
                                </a>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { usePage, useForm } from '@inertiajs/vue3';

const { props } = usePage();
const user = props.auth.user;
const appName = props.appName;
const price = ref(props.price || 0);

const form = useForm({
    username: ''
});

const confirmChange = () => {
    return confirm('Are you sure you want to change your username?');
};

const successMessage = ref('');
const errorMessage = ref('');

const submitForm = () => {
    form.post(route('store.username-change.store'), {
        onSuccess: (page) => {
            successMessage.value = 'Successfully changed the username';
            errorMessage.value = '';
        },
        onError: (err) => {
            errorMessage.value = 'Failed to change the username';
            successMessage.value = '';
        }
    });
};
</script>

<template>
    <div class="w-full transition duration-300 ease-in-out transform hover:-translate-y-1 shadow-lg">
        <div class="bg-blue-800 text-white rounded border border-blue-700 py-4 px-4 flex flex-col items-center">
            <div class="text-center">
                <img :src="creditPack.storage_image_url" :alt="`Credit pack ${creditPack.credit_amount}`" class="rounded-lg">
            </div>
            <div class="text-center text-xs font-semibold pb-1 pt-4 mb-3">
                {{ creditPack.credit_amount }} {{ creditPack.credit_amount === 1 ? 'Credit' : 'Credits' }}
            </div>
            <template v-if="!isAuthenticated">
                <Link :href="route('login')" class="btn-gradient-blue py-2 w-full rounded-lg text-sm font-semibold text-center">
                    ${{ creditPack.price }}
                </Link>
            </template>
            <template v-else>
                <form :action="route('credits.checkout')" method="GET" class="w-full">
                    <input type="hidden" name="credit_pack_id" :value="creditPack.id">
                    <button type="submit" class="btn-gradient-blue py-2 px-6 w-full rounded-lg text-sm font-semibold text-center">
                        Buy with Balance (${{ creditPack.price }})
                    </button>
                </form>
            </template>
        </div>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import {defineProps} from 'vue';
import {Link} from '@inertiajs/vue3';

const { props } = usePage();
const isAuthenticated = props.auth.user !== null;

const creditPack = defineProps({
    id: Number,
    image_url: String,
    storage_image_url: String,
    credit_amount: String,
    price: Number
});
</script>

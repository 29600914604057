<script setup>

import {useForm} from "@inertiajs/vue3";
import RadioButton from "primevue/radiobutton";
import { ref } from 'vue';
import SoloMatchForm from "./SoloMatchForm.vue";
import DuoMatchForm from "./DuoMatchForm.vue";
import CustomMatchForm from "./CustomMatchForm.vue";

const selectedMatchType = ref('solo');

const props = defineProps({
    'game': Object,
    'teams': Array,
    'regions': Array,
    gamingAccount: {
        type: Object
    }
});

const emits = defineEmits(['challengeCreated']);

const form = useForm({
    startTime: 'now',
    team: '',
    game: props.game,
    game_mode: '',
    players_per_team: '',
    best_of: '',
    platform: '',
    region: '',
    wager: '',
});

const onChallengeCreated = (challengeId) => {
    emits('challengeCreated', challengeId);
}

</script>

<template>
    <div class="flex justify-center items-center w-full h-full">
        <div class="w-full lg:w-6/12 px-4 mb-6">
            <div class="bg-blue-600 text-white rounded-lg border border-gray-800 px-8 sm:px-16 py-16">
                <div id="inner" class="border-gray-800">
                    <div class="mb-6">
                        <div class="text-3xl font-extrabold mb-1">Match Options</div>
                        <div class="text-gray-400 text-sm">
                            Challenge your competitors
                        </div>
                    </div>

                    <h5 class="mb-4">
                        What type of match are you creating?
                    </h5>

                    <div class="flex flex-col flex-wrap gap-3 ml-4 mb-8">
<!--                        <div class="flex align-items-center">-->
<!--                            <RadioButton v-model="ingredient" inputId="ingredient1" name="pizza" value="Cheese" />-->
<!--                            <label for="ingredient1" class="ml-2">Scrim</label>-->
<!--                        </div>-->
<!--                        <div class="flex align-items-center">-->
<!--                            <RadioButton v-model="ingredient" inputId="ingredient2" name="pizza" value="Mushroom" />-->
<!--                            <label for="ingredient2" class="ml-2">8s</label>-->
<!--                        </div>-->
                        <div class="flex items-center">
                            <RadioButton v-model="selectedMatchType" inputId="match-type-solo" name="selectedMatchType" value="solo" />
                            <label for="match-type-solo" class="ml-2">
                                Solo

                                <span class="text-sm text-gray-400"> - 1v1 (best of 3) to test your skill individually</span>
                            </label>
                        </div>
                        <div class="flex items-center">
                            <RadioButton v-model="selectedMatchType" inputId="match-type-duo" name="selectedMatchType" value="duo" />
                            <label for="match-type-duo" class="ml-2">
                                Duo

                                <span class="text-sm text-gray-400"> - 2v2 (best of 3), you're not in it alone</span>
                            </label>
                        </div>
                        <div class="flex items-center">
                            <RadioButton v-model="selectedMatchType" inputId="match-type-custom" name="selectedMatchType" value="custom" />
                            <label for="match-type-custom" class="ml-2">
                                Custom

                                <span class="text-sm text-gray-400"> - Customised to your specifications</span>
                            </label>
                        </div>
                    </div>

                    <div v-if="selectedMatchType === 'solo'">
                        <SoloMatchForm :game="game" :teams="teams" :regions="regions" :gaming-account="gamingAccount" @challenge-created="onChallengeCreated" />
                    </div>

                    <div v-if="selectedMatchType === 'duo'">
                        <DuoMatchForm :game="game" :teams="teams" :regions="regions" :gaming-account="gamingAccount" @challenge-created="onChallengeCreated" />
                    </div>

                    <div v-if="selectedMatchType === 'custom'">
                        <CustomMatchForm :game="game" :teams="teams" :regions="regions" :gaming-account="gamingAccount" @challenge-created="onChallengeCreated" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>

import Select from "../../Forms/Select.vue";
import {useForm} from "@inertiajs/vue3";
import {computed, onMounted, ref} from 'vue';
import Button from "primevue/button";
import TeamPlayersChooser from "./TeamPlayersChooser.vue";
import {Api} from "../../../Services/api.js";
import {useToast} from "primevue/usetoast";
import InputNumber from "primevue/inputnumber";
import {add} from "date-fns";

const props = defineProps({
    game: {
        type: Object
    },
    teams: {
        type: Array
    },
    wagerAmount: {
        type: Number
    },
    regions: {
        type: Array
    },
});

const emit = defineEmits(['challengeCreated']);

if (!props.game) {
    throw new Error('Game prop is required');
}

if (!props.teams) {
    throw new Error('Teams prop is required');
}

if (!props.regions) {
    throw new Error('Regions prop is required');
}

if (!props.game.game_modes.length) {
    throw new Error('Game must have at least one game mode');
}

if (!props.game.platforms.length) {
    throw new Error('Game must have at least one platform');
}

if (!props.regions.length) {
    throw new Error('Regions must have at least one region');
}

const toast = useToast();

const form = useForm({
    start_time: 'now',
    team_id: props.teams[0].id,
    game_id: props.game.id,
    game_mode_id: props.game.game_modes[0].id,
    team_size: 1,
    best_of: 3,
    platform_id: props.game.platforms[0].id,
    region_id: props.regions[0].id,
    challenge_amount: props.wagerAmount || 0,
    selectedRoster: []
});

onMounted(() => {
    onTeamChange(form.team_id);
});

const whenOptions = [{
    label: 'Now',
    value: 'now',
}, {
    label: '5 Minutes',
    value: add(new Date(), {minutes: 5}),
}, {
    label: '10 Minutes',
    value: add(new Date(), {minutes: 10}),
}, {
    label: '15 Minutes',
    value: add(new Date(), {minutes: 15}),
}, {
    label: '30 Minutes',
    value: add(new Date(), {minutes: 30}),
}, {
    label: '40 Minutes',
    value: add(new Date(), {minutes: 40}),
}, {
    label: '60 Minutes',
    value: add(new Date(), {minutes: 60}),
}];

const platformOptions = computed(() => {
    return props.game.platforms.map(platform => {
        return {
            label: platform.name,
            value: platform.id
        };
    });
});

const teamOptions = computed(() => {
    return props.teams.map(team => {
        return {
            label: team.name,
            value: team.id
        };
    });
});

const gameModeOptions = computed(() => {
    return props.game.game_modes.map(gameMode => {
        return {
            label: gameMode.name,
            value: gameMode.id
        };
    });
});

const regionOptions = computed(() => {
    return props.regions.map(region => {
        return {
            label: region.name,
            value: region.id
        };
    });
});

const playerOptions = computed(() => {
    return Array.from({length: 11}, (_, i) => i + 1).map(i => {
        return {
            label: `${i}v${i}`,
            value: i
        };
    });
});

const selectedTeam = computed(() => {
    return props.teams.find(team => team.id === form.team_id);
});

const selectedPlatform = computed(() => {
    return props.game.platforms.find(platform => platform.id === form.platform_id);
});

const selectablePlayers = ref([]);

const onSelectedPlayersChanged = (players) => {
    form.selectedRoster = players;
    console.log("Selected players", players);
};

const onTeamChange = async (teamId) => {
    try {
        const resp = await Api.teams().get(teamId);
        const teamDetails = resp.data.data;
        console.log("Team changed", teamDetails);

        selectablePlayers.value = teamDetails.members;
    } catch (e) {
        console.error(e);
        toast.add({severity: 'error', summary: 'Error', detail: 'An error occurred while fetching team details', life: 5000});
    }
};

const canSubmitForm = computed(() => {
    return form.selectedRoster.length === form.team_size;
});

const onFormSubmit = async () => {
    try {
        const resp = await Api.wagerChallenges().create(form);
        const challengeId = resp.data.id;

        emit('challengeCreated', challengeId);
        toast.add({severity: 'success', summary: 'Success', detail: 'Challenge created successfully', life: 5000});
    } catch (e) {
        console.error(e);
        toast.add({severity: 'error', summary: 'Error', detail: 'An error occurred while creating challenge', life: 5000});
    }
};

</script>

<template>
    <form @submit.prevent="onFormSubmit">
        <h6 class="text-lg font-semibold mt-4">
            Custom Match Options
        </h6>
        <p class="text-sm text-gray-400 mb-4">
            Customise your {{form.team_size}}v{{form.team_size}} match!
        </p>

        <div class="mb-4">
            <Select
                label="When"
                :options="whenOptions"
                v-model="form.start_time"
            />

            <div v-if="form.start_time === 'now'" class="text-xs mt-2 text-gray-300">
                <span class="text-white font-semibold">Available Now</span> challenges remain active for
                2 hours or until the match is accepted or cancelled.
                <br>
                Once accepted it will be scheduled in 10 minutes.
            </div>
        </div>

        <div class="mb-4">
            <Select
                label="Team"
                :options="teamOptions"
                v-model="form.team_id"
                @selection-change="onTeamChange"
            />
        </div>

        <div class="mb-4">
            <Select
                label="Players"
                :options="playerOptions"
                v-model="form.team_size"
            />
        </div>

        <div class="mb-4">
            <Select
                label="Game Mode"
                :options="gameModeOptions"
                v-model="form.game_mode_id"
            />
        </div>

        <div class="mb-4">
            <Select
                label="Best Of"
                :options="[
                    { label: '1', value: 1 },
                    { label: '3', value: 3 },
                    { label: '5', value: 5 },
                    { label: '7', value: 7 },
                ]"
                v-model="form.best_of"
            />
        </div>

        <div class="mb-4">
            <Select
                label="Platform"
                :options="platformOptions"
                v-model="form.platform_id"
            />
        </div>

        <div class="mb-4">
            <Select
                label="Region"
                :options="regionOptions"
                v-model="form.region_id"
            />
        </div>

        <TeamPlayersChooser :game="game" :team="selectedTeam" :how-many-players="form.team_size" :players="selectablePlayers" :platform="selectedPlatform" @players-selected="onSelectedPlayersChanged" />

        <div class="my-4">
            <label for="wager" class="text-xs uppercase flex-1 whitespace-nowrap">Wager</label>
            <InputNumber
                v-model="form.challenge_amount"
                inputId="wager"
                mode="currency"
                currency="USD"
                locale="en-US"
                class="w-full"
                min="0"
                show-buttons
            >
                <template #incrementbuttonicon>
                    <i class="fa-solid fa-plus text-white"></i>
                </template>
                <template #decrementbuttonicon>
                    <i class="fa-solid fa-minus text-white"></i>
                </template>
            </InputNumber>
        </div>

        <Button label="Create Custom Match" class="w-full mt-4" :disabled="!canSubmitForm" type="submit" />
    </form>
</template>

<style scoped>

</style>

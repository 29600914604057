<script setup>

import {computed, defineProps} from 'vue';

const props = defineProps({
    'type': {
        type: String,
        required: true
    },
    'size': {
        type: String,
        required: false,
        default: 'md',
    }
});

const trophyImage = computed(() => {
    return `/images/trophies/${props.type}-trophy.svg`;
});

const trophyImageAltText = computed(() => {
    return `${props.type} trophy`;
});

const bgClass = computed(() => {
    if (props.type === 'diamond') {
        return 'bg-turqouise';
    }

    if (props.type === 'gold') {
        return 'bg-gold';
    }

    if (props.type === 'silver') {
        return 'bg-silver';
    }

    return 'bg-bronze';
});

const borderClass = computed(() => {
    if (props.type === 'diamond') {
        return 'border-turqouise';
    }

    if (props.type === 'gold') {
        return 'border-gold';
    }

    if (props.type === 'silver') {
        return 'border-silver';
    }

    return 'border-bronze';
});

// function
//
const containerSize = computed(() => {
    if (props.size === 'xs') {
        return 12;
    }

    if (props.size === 'sm') {
        return 16;
    }

    if (props.size === 'md') {
        return 20;
    }

    if (props.size === 'lg') {
        return 24;
    }

    if (props.size === 'xl') {
        return 28;
    }

    if (props.size === '2xl') {
        return 32;
    }

    if (props.size === '3xl') {
        return 36;
    }

    return 16;
});

const secondaryContainerSizeClasses = computed(() => {
    return `w-${containerSize.value} h-${containerSize.value}`;
})

const imageSize = computed(() => {
    return containerSize.value - 4;
})

</script>

<template>
    <div :class="`grid w-${containerSize} h-${containerSize} bg-blue-900 rounded-full place-items-center`">
        <div :class="`grid rounded-full ${secondaryContainerSizeClasses} place-items-center ${bgClass}`">
            <div :class="`rounded-full ${borderClass}`">
                <img
                    :src="trophyImage" :alt="trophyImageAltText"
                    :class="`w-${imageSize} h-${imageSize}`"
                >
            </div>
        </div>
    </div>
</template>

<style scoped>

.trophy-number {
    background: #2d405d;
    font-size: 20px;
    font-weight: 700;
}

</style>

<script setup>
import {computed} from 'vue';
import {Link} from '@inertiajs/vue3';

const props = defineProps({
    league: Object,
    club: {
        type: Object,
        required: false,
    },
});

const status = computed(() => {
    let now = new Date();
    let startDate = new Date(props.league.start_date);
    let endDate = new Date(props.league.end_date);

    let daysUntilStart = Math.floor((startDate - now) / (1000 * 60 * 60 * 24));

    if (now < startDate) {
        return 'Starts in ' + daysUntilStart + ' days';
    }

    if (now > endDate) {
        return 'Ended';
    }

    return 'In Progress';
});

</script>

<style scoped>
.club-league-card {
    @apply bg-gray-800 rounded-lg overflow-hidden shadow-md text-white transition-transform transform hover:-translate-y-1 min-h-full;
}

.league-name {
    @apply flex items-center bg-blue-900 text-gray-50 p-2 font-semibold text-base;
}

.league-image {
    @apply w-full h-48 object-cover;
}

.team-size, .region-name, .prize-amount, .tournament-status {
    @apply absolute p-2 text-xs font-semibold;
}

.team-size {
    @apply bottom-0 left-0 bg-black bg-opacity-50;
}

.region-name {
    @apply bottom-0 right-0 bg-black bg-opacity-50;
}

.prize-amount {
    @apply top-0 right-0 bg-red-600 text-xl font-bold text-yellow-400 rounded-bl-lg;
}

.tournament-status {
    @apply top-0 left-0 text-lg font-semibold rounded-br-lg;
}

.completed-tournament {
    @apply bg-red-600 text-yellow-400;
}

.in-progress-tournament {
    @apply bg-black;
}

.upcoming-tournament {
    @apply bg-green-700 text-yellow-400;
}

.details {
    @apply p-4;
}

.title {
    @apply text-base font-semibold mb-2;
}

.tags {
    @apply flex space-x-2 mb-2;
}

.tag {
    @apply px-2 py-1 bg-gray-700 rounded text-xs;
}

.info {
    @apply flex flex-col items-start mb-2;
}

.info-item {
    @apply text-xs;
}

.organizer {
    @apply bg-gray-900 text-center p-2 text-xs flex justify-between items-center;
}

.organizer-avatar {
    @apply w-12 h-12 rounded-full mr-2;
}
</style>

<template>
    <Link :href="route('clubleagues.show', {club: club.slug, league: league.slug})" class="text-blue-500 hover:underline text-sm">
        <div class="club-league-card">
            <div class="league-name">
                {{ league.name }}
            </div>
            <div class="relative">
                <img :src="league.game.storage_background_url" alt="League Image" class="league-image">
                <div class="team-size">
                    {{ league.max_team_size }} v {{ league.min_team_size }}
                </div>
                <div class="region-name">
                    {{ league.region.name }}
                </div>
                <div class="prize-amount">
                    <i aria-hidden="true" class="mr-1 fa-solid fa-trophy"></i>${{ league.prize_amount }}
                </div>
                <div class="tournament-status"
                     :class="status === 'Ended' ? 'completed-tournament' : (status === 'In Progress' ? 'in-progress-tournament' : 'upcoming-tournament')">
                    {{ status }}
                </div>
            </div>
            <div class="details">
                <h2 class="title">{{ league.name }} | {{ league.game.name }}</h2>
                <div class="tags">
                    <span class="tag">{{ league.game.name }}</span>
                    <span class="tag">{{ league.platform.name }}</span>
                </div>
                <div class="info">
                    <div class="info-item">
                        <span class="font-semibold">1st Prize: </span><i aria-hidden="true"
                                                                         class="mr-1 text-yellow-500 fa-solid fa-trophy"></i>${{
                            league.prize_amount
                        }}
                    </div>
                    <div class="info-item">
                        <span class="font-semibold">Team Size: </span>
                        <i aria-hidden="true" class="mr-1 text-indigo-600 fa-solid fa-dice"></i>{{
                            league.max_team_size
                        }} v {{ league.min_team_size }}
                    </div>
                    <div class="info-item">
                        <span class="font-semibold">Entry Fee: </span>
                        <i aria-hidden="true" class="mr-1 text-green-500 fa-solid fa-coins"></i>{{ league.entry_fee }}
                        Credits
                    </div>
                </div>
            </div>
        </div>
    </Link>
</template>



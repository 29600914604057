<script setup>
import {ref, watch, computed} from "vue";
import {useForm, usePage} from '@inertiajs/vue3';
import Editor from 'primevue/editor';
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';

const props = usePage().props;
const games = props.games;
const platforms = props.platforms;
const regions = props.regions;
const fileUploadRef = ref(null);
const files = ref([]);
const thumbnailUploadRef = ref(null);
const thumbnailFiles = ref([]);

// Define acceptable bracket sizes
const bracketSizes = [4, 8, 16, 32, 64];
const bracketSizeIndex = ref(0);

watch(bracketSizeIndex, (newIndex) => {
    form.bracket_size = bracketSizes[newIndex];
});

const form = useForm({
    name: '',
    description: '',
    game_id: null,
    platform_id: null,
    team_size: 1,
    game_mode_id: null,
    best_of: 1,
    tournament_type: 'single',
    bracket_size: 16,
    price_per_player: 10,
    commission: 0.10,
    region_id: null,
    ruleset_id: null,
    registration_start: '',
    registration_end: '',
    start_time: '',
    first_place: null,
    second_place: null,
    third_place: null,
    custom_ruleset_content: '',
    custom_tournament_banner: null,
    closed: null,
    twitch_channel: null,
    host_details: null,
    youtube: '',
    facebook: '',
    x_social: '',
    discord: '',
    is_published: false,
    thumbnail_image: null,
    pc_stream_required: false,
    pc_players_allowed: false,
    input_option: '',
    moss_required: false,
});

form.bracket_size = bracketSizes[bracketSizeIndex.value];

const firstPlacePercentage = ref(70);
const secondPlacePercentage = ref(30);
const thirdPlacePercentage = ref(0);
const filteredGameModes = ref([]);
const filteredRulesets = ref([]);

const prizePool = computed(() => form.team_size * form.bracket_size * form.price_per_player);
const adjustedPrizePool = computed(() => (prizePool.value * (1 - form.commission)).toFixed(2));
const commissionAmount = computed(() => (prizePool.value * form.commission).toFixed(2));
const firstPlaceAmount = computed(() => ((firstPlacePercentage.value / 100) * adjustedPrizePool.value).toFixed(2));
const secondPlaceAmount = computed(() => ((secondPlacePercentage.value / 100) * adjustedPrizePool.value).toFixed(2));
const thirdPlaceAmount = computed(() => 0);

const totalPrizeDistribution = computed(() => {
    return parseFloat(firstPlacePercentage.value) + parseFloat(secondPlacePercentage.value) + parseFloat(thirdPlacePercentage.value);
});

const selectedSocial = ref('discord');

const selectSocial = (social) => {
    selectedSocial.value = social;
};

let isAdjustingFirst = false;
let isAdjustingSecond = false;

watch(firstPlacePercentage, (newVal) => {
    if (isAdjustingFirst) return;
    isAdjustingSecond = true;

    if (newVal < 50) {
        firstPlacePercentage.value = 50;
        secondPlacePercentage.value = 50;
    } else if (newVal > 100) {
        firstPlacePercentage.value = 100;
        secondPlacePercentage.value = 0;
    } else {
        secondPlacePercentage.value = 100 - newVal;
    }

    isAdjustingSecond = false;
});

watch(secondPlacePercentage, (newVal) => {
    if (isAdjustingSecond) return;
    isAdjustingFirst = true;

    // Enforce second place to be at most 50%
    if (newVal > 50) {
        secondPlacePercentage.value = 50;
        firstPlacePercentage.value = 50;
    } else if (newVal < 0) {
        secondPlacePercentage.value = 0;
        firstPlacePercentage.value = 100;
    } else {
        firstPlacePercentage.value = 100 - newVal;
    }

    isAdjustingFirst = false;
});

watch(() => form.game_id, (newGameId) => {
    const selectedGame = games.find(game => game.id === newGameId);
    if (selectedGame) {
        filteredGameModes.value = selectedGame.game_modes;
        filteredRulesets.value = selectedGame.rulesets.map(ruleset => {
            if (Array.isArray(ruleset)) {
                return ruleset.map(r => ({id: r.id, name: r.name}));
            } else {
                return {id: ruleset.id, name: ruleset.name};
            }
        });

        form.game_mode_id = null;

        if (selectedGame.rulesets && selectedGame.rulesets.length > 0) {
            form.ruleset_id = null; // User needs to select a ruleset
        } else {
            form.ruleset_id = 'custom'; // No rulesets, set to 'custom'
        }
    } else {
        filteredGameModes.value = [];
        filteredRulesets.value = [];
        form.ruleset_id = null;
        form.game_mode_id = null;
    }
});

watch(() => form.ruleset_id, (newRulesetId) => {
    if (newRulesetId !== null && newRulesetId !== 'custom') {
        form.custom_ruleset_content = '';
    }
});

// Watcher to update form data when files change
watch(files, (newFiles) => {
    if (newFiles.length > 0) {
        form.custom_tournament_banner = newFiles[0];
    } else {
        form.custom_tournament_banner = null;
    }
});

watch(thumbnailFiles, (newFiles) => {
    if (newFiles.length > 0) {
        form.thumbnail_image = newFiles[0];
    } else {
        form.thumbnail_image = null;
    }
});

// Handle thumbnail file selection
const onThumbnailSelect = (event) => {
    const selectedFiles = event.files;
    console.log(event.files)

    if (selectedFiles && selectedFiles.length > 0) {
        const file = selectedFiles[selectedFiles.length - 1];

        if (thumbnailUploadRef.value && thumbnailUploadRef.value.files) {
            thumbnailUploadRef.value.files = [];
        }

        thumbnailUploadRef.value.files.push(file);

        // Update the form data
        form.thumbnail_image = file;
    }
};

// Handle thumbnail file removal
const onThumbnailRemove = () => {
    thumbnailFiles.value = [];
    form.thumbnail_image = null;
};

// Handle file selection
const onFileSelect = (event) => {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
        const file = selectedFiles[selectedFiles.length - 1];

        if (fileUploadRef.value && fileUploadRef.value.files) {
            fileUploadRef.value.files = [];
        }

        fileUploadRef.value.files.push(file);

        // Update the form data
        form.custom_tournament_banner = file;
    }
};

// Handle file removal
const onFileRemove = () => {
    files.value = [];
    form.custom_tournament_banner = null;
};

const formatSize = (bytes) => {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

watch(() => form.price_per_player, (newValue) => {
    if (newValue == 0) {
        form.commission = 0;
        firstPlacePercentage.value = 0;
        secondPlacePercentage.value = 0;
        thirdPlacePercentage.value = 0;
    }
});

const submit = (isPublished) => {
    if (isPublished && !confirm("Are you sure you want to publish this tournament?")) {
        return;
    }

    if (prizePool.value > 0 && totalPrizeDistribution.value !== 100) {
        alert("Total prize distribution must equal 100%");
        return;
    }

    if (!form.ruleset_id) {
        alert("Please select a ruleset.");
        return;
    }

    if (form.ruleset_id === 'custom' && (!form.custom_ruleset_content || form.custom_ruleset_content.trim() === '')) {
        alert("Please provide a custom ruleset.");
        return;
    }

    form.is_published = isPublished;
    form.prize_amount = prizePool.value;
    form.first_place = firstPlaceAmount.value;
    form.second_place = secondPlaceAmount.value;
    form.third_place = thirdPlaceAmount.value;

    form.submit('post', '/tournaments', {
        forceFormData: true,
    });
};
</script>


<template>
    <div class="container mx-auto">
        <div class="flex justify-center items-center w-full">
            <div class="mx-auto px-4 mb-6">
                <!-- Tournament Creation Form -->
                <form @submit.prevent="submit" class="w-full flex flex-col sm:flex-row justify-center">
                    <!-- Main Form -->
                    <div class="bg-blue-600 text-white rounded-lg border border-gray-800 px-8 py-16 w-full">
                        <div class="mb-6">
                            <h2 class="text-3xl font-extrabold mb-1">Create Tournament</h2>
                            <p class="info-text">Fill in the tournament details below.</p>
                        </div>

                        <!-- Tournament Name -->
                        <div class="form-group mb-4">
                            <label for="name" class="label-text">Tournament Name</label>
                            <input v-model="form.name" type="text" name="name" id="name"
                                   class="input-field"
                                   required>
                        </div>

                        <!-- Description -->
                        <div class="form-group mb-4 bg-blue-600">
                            <label for="description" class="label-text mb-2">Tournament Description
                            </label>
                            <Editor v-model="form.description"
                                    class="input-field w-full"
                                    editorStyle="height: 320px">
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Header 1'" class="ql-header" value="1"></button>
                                    <button v-tooltip.bottom="'Header 2'" class="ql-header" value="2"></button>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                                    <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                                    <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                                </span>
                                    <span class="ql-formats">
                                    <select v-tooltip.bottom="'Text Color'" class="ql-color">
                                        <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)" selected></option>
                                        <option value="rgb(230, 0, 0)" label="rgb(230, 0, 0)"></option>
                                        <option value="rgb(255, 153, 0)" label="rgb(255, 153, 0)"></option>
                                        <option value="rgb(255, 255, 0)" label="rgb(255, 255, 0)"></option>
                                        <option value="rgb(0, 138, 0)" label="rgb(0, 138, 0)"></option>
                                        <option value="rgb(0, 102, 204)" label="rgb(0, 102, 204)"></option>
                                        <option value="rgb(153, 51, 255)" label="rgb(153, 51, 255)"></option>
                                        <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)"></option>
                                        <option value="rgb(250, 204, 204)" label="rgb(250, 204, 204)"></option>
                                        <option value="rgb(255, 235, 204)" label="rgb(255, 235, 204)"></option>
                                        <option value="rgb(255, 255, 204)" label="rgb(255, 255, 204)"></option>
                                        <option value="rgb(204, 232, 204)" label="rgb(204, 232, 204)"></option>
                                        <option value="rgb(204, 224, 245)" label="rgb(204, 224, 245)"></option>
                                        <option value="rgb(235, 214, 255)" label="rgb(235, 214, 255)"></option>
                                        <option value="rgb(187, 187, 187)" label="rgb(187, 187, 187)"></option>
                                        <option value="rgb(240, 102, 102)" label="rgb(240, 102, 102)"></option>
                                        <option value="rgb(255, 194, 102)" label="rgb(255, 194, 102)"></option>
                                        <option value="rgb(255, 255, 102)" label="rgb(255, 255, 102)"></option>
                                        <option value="rgb(102, 185, 102)" label="rgb(102, 185, 102)"></option>
                                        <option value="rgb(102, 163, 224)" label="rgb(102, 163, 224)"></option>
                                        <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)"></option>
                                    </select>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                                    <button v-tooltip.bottom="'Bullet List'" class="ql-list" value="bullet"></button>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                                </span>
                                </template>
                            </Editor>
                        </div>

                        <!-- Custom Tournament Banner Upload -->
                        <div class="form-group mb-4">
                            <label for="custom_tournament_banner" class="text-xl font-semibold">Custom Tournament Banner</label>
                            <FileUpload
                                ref="fileUploadRef"
                                name="custom_tournament_banner"
                                accept="image/*"
                                :multiple="false"
                                :fileLimit="1"
                                mode="advanced"
                                :auto="false"
                                :customUpload="true"
                                :maxFileSize="2097152"
                                invalidFileLimitMessage="Only one file can be uploaded at a time."
                                :showUploadButton="false"
                                :showCancelButton="false"
                                :choose-label="files.length > 0 ? 'Change File' : 'Choose File'"
                                @select="onFileSelect"
                                @remove="onFileRemove"
                                class="w-full"
                            >
                                <!-- Custom Content Template -->
                                <template #content="{ chooseCallback, files, removeFile }">
                                    <div class="flex flex-col items-center justify-center drag-drop-area"
                                         @click="chooseCallback">
                                        <i class="pi pi-cloud-upload"></i>
                                        <p>Drag and drop a file here to upload</p>
                                        <p class="text-gray-500 text-sm mt-2">(Max file size: 2MB)</p>
                                        <p class="text-gray-500 text-xs mt-2">Preferred dimensions 1900 x 510</p>
                                    </div>

                                    <!-- Display the selected file -->
                                    <div v-if="files.length > 0" class="flex flex-col gap-4 mt-4 w-full">
                                        <div class="uploaded-file">
                                            <div class="flex items-center">
                                                <img :src="files[0].objectURL" alt="Banner Preview"
                                                     class="w-16 h-16 object-cover mr-4"/>
                                                <div class="file-details">
                                                    <p class="file-name">{{ files[0].name }}</p>
                                                    <p class="file-size">{{ formatSize(files[0].size) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </FileUpload>
                            <p class="info-text mt-2">Upload a custom banner for your tournament (optional).</p>
                        </div>

                        <!-- Custom Tournament Thumbnail Upload -->
                        <div class="form-group mb-4">
                            <label for="custom_tournament_thumbnail" class="text-xl font-semibold">Tournament Thumbnail</label>
                            <p class="text-xs text-gray-400">(this will appear in the info card)</p>
                            <FileUpload
                                ref="thumbnailUploadRef"
                                name="thumbnail_image"
                                accept="image/*"
                                :multiple="false"
                                :fileLimit="1"
                                mode="advanced"
                                :auto="false"
                                :customUpload="true"
                                :maxFileSize="2097152"
                                invalidFileLimitMessage="Only one file can be uploaded at a time."
                                :showUploadButton="false"
                                :showCancelButton="false"
                                :choose-label="thumbnailFiles.length > 0 ? 'Change File' : 'Choose File'"
                                @select="onThumbnailSelect"
                                @remove="onThumbnailRemove"
                                class="w-full"
                            >
                                <template #content="{ chooseCallback, files, removeFile }">
                                    <div class="flex flex-col items-center justify-center drag-drop-area" @click="chooseCallback">
                                        <i class="pi pi-cloud-upload"></i>
                                        <p>Drag and drop a file here to upload</p>
                                        <p class="text-gray-500 text-sm mt-2">(Max file size: 2MB)</p>
                                        <p class="text-gray-500 text-xs mt-2">Preferred dimensions 480 x 270</p>
                                    </div>
                                    <div v-if="thumbnailFiles.length > 0" class="flex flex-col gap-4 mt-4 w-full">
                                        <div class="uploaded-file">
                                            <div class="flex items-center">
                                                <img :src="thumbnailFiles[0].objectURL" alt="Thumbnail Preview"
                                                     class="w-16 h-16 object-cover mr-4"/>
                                                <div class="file-details">
                                                    <p class="file-name">{{ thumbnailFiles[0].name }}</p>
                                                    <p class="file-size">{{ formatSize(thumbnailFiles[0].size) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </FileUpload>
                            <p class="info-text mt-2">Upload a thumbnail image for your tournament (optional).</p>
                        </div>

                        <!-- Region -->
                        <div class="form-group mb-4">
                            <label for="region_id" class="label-text">Region</label>
                            <select v-model="form.region_id" name="region_id" id="region_id"
                                    class="selection">
                                <option v-for="region in regions" :key="region.id" :value="region.id">{{
                                        region.name
                                    }}
                                </option>
                            </select>
                        </div>

                        <!-- Tournament Type (Single Elimination, Double Elimination, Warzone Custom) -->
                        <div class="form-group mb-4">
                            <label for="tournament_type" class="label-text">Tournament Type</label>
                            <select v-model="form.tournament_type" name="tournament_type" id="tournament_type"
                                    class="selection">
                                <option value="single">Single Elimination</option>
                                <option value="double" disabled>Double Elimination (Coming Soon)</option>
                                <option value="warzone" disabled>Warzone Custom (Coming Soon)</option>
                            </select>
                        </div>

                        <!-- Best of Select -->
                        <div class="form-group mb-4">
                            <label for="best_of" class="label-text">Best Of</label>
                            <select v-model="form.best_of" name="best_of" id="best_of"
                                    class="selection">
                                <option value="1">1</option>
                                <option value="3">3</option>
                                <option value="5">5</option>
                                <option value="7">7</option>
                            </select>
                        </div>

                        <!-- Team Size (Format) Slider -->
                        <div class="form-group mb-4">
                            <label for="team_size" class="label-text">Format ({{
                                    form.team_size
                                }}v{{ form.team_size }})</label>
                            <input type="range" v-model="form.team_size" min="1" max="11" step="1"
                                   class="range-field">
                        </div>

                        <!-- Open/Closed Checkbox -->
                        <div class="form-group mb-4">
                            <label for="closed" class="text-gray-400 font-semibold">Closed Tournament (Invite
                                Only)</label>
                            <input v-model="form.closed" type="checkbox" name="closed" id="closed"
                                   class="form-checkbox ml-2 w-6 h-6 text-blue-500 rounded">
                        </div>

                        <!-- Host Details -->
                        <div class="form-group mb-4 bg-blue-600 my-8">
                            <h2 class="text-3xl font-extrabold mb-1">Host Contact Details</h2>
                            <label for="host_details" class="label-text mb-2">How To Contact You
                            </label>
                            <Editor v-model="form.host_details"
                                    class="input-field w-full"
                                    editorStyle="height: 320px">
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Header 1'" class="ql-header" value="1"></button>
                                    <button v-tooltip.bottom="'Header 2'" class="ql-header" value="2"></button>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                                    <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                                    <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                                </span>
                                    <span class="ql-formats">
                                    <select v-tooltip.bottom="'Text Color'" class="ql-color">
                                        <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)" selected></option>
                                        <option value="rgb(230, 0, 0)" label="rgb(230, 0, 0)"></option>
                                        <option value="rgb(255, 153, 0)" label="rgb(255, 153, 0)"></option>
                                        <option value="rgb(255, 255, 0)" label="rgb(255, 255, 0)"></option>
                                        <option value="rgb(0, 138, 0)" label="rgb(0, 138, 0)"></option>
                                        <option value="rgb(0, 102, 204)" label="rgb(0, 102, 204)"></option>
                                        <option value="rgb(153, 51, 255)" label="rgb(153, 51, 255)"></option>
                                        <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)"></option>
                                        <option value="rgb(250, 204, 204)" label="rgb(250, 204, 204)"></option>
                                        <option value="rgb(255, 235, 204)" label="rgb(255, 235, 204)"></option>
                                        <option value="rgb(255, 255, 204)" label="rgb(255, 255, 204)"></option>
                                        <option value="rgb(204, 232, 204)" label="rgb(204, 232, 204)"></option>
                                        <option value="rgb(204, 224, 245)" label="rgb(204, 224, 245)"></option>
                                        <option value="rgb(235, 214, 255)" label="rgb(235, 214, 255)"></option>
                                        <option value="rgb(187, 187, 187)" label="rgb(187, 187, 187)"></option>
                                        <option value="rgb(240, 102, 102)" label="rgb(240, 102, 102)"></option>
                                        <option value="rgb(255, 194, 102)" label="rgb(255, 194, 102)"></option>
                                        <option value="rgb(255, 255, 102)" label="rgb(255, 255, 102)"></option>
                                        <option value="rgb(102, 185, 102)" label="rgb(102, 185, 102)"></option>
                                        <option value="rgb(102, 163, 224)" label="rgb(102, 163, 224)"></option>
                                        <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)"></option>
                                    </select>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                                    <button v-tooltip.bottom="'Bullet List'" class="ql-list" value="bullet"></button>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                                </span>
                                </template>
                            </Editor>
                        </div>

                        <!-- Social Media Section -->
                        <div class="socials-container my-8">
                            <h2 class="text-2xl font-semibold mb-2">Social Link URLs (Optional)</h2>
                            <p class="info-text">Add your social links below.</p>

                            <div class="social-icons flex justify-between space-x-4 my-4">
                                <div
                                    :class="{'selected-icon': selectedSocial === 'twitch'}"
                                    @click="selectSocial('twitch')">
                                    <i class="fab fa-twitch"></i>
                                </div>
                                <div
                                    :class="{'selected-icon': selectedSocial === 'discord'}"
                                    @click="selectSocial('discord')">
                                    <i class="fab fa-discord"></i>
                                </div>
                                <div
                                    :class="{'selected-icon': selectedSocial === 'youtube'}"
                                    @click="selectSocial('youtube')">
                                    <i class="fab fa-youtube"></i>
                                </div>
                                <div
                                    :class="{'selected-icon': selectedSocial === 'facebook'}"
                                    @click="selectSocial('facebook')">
                                    <i class="fab fa-facebook"></i>
                                </div>
                                <div
                                    :class="{'selected-icon': selectedSocial === 'x'}"
                                    @click="selectSocial('x')">
                                    <i class="fab fa-x-twitter"></i>
                                </div>
                            </div>

                            <!-- Social Input Boxes with Flash and Bounce Effect -->
                            <transition name="bounce-flash" mode="out-in">
                                <div key="twitch" v-if="selectedSocial === 'twitch'" class="mt-4 animated-input">
                                    <label for="twitch_channel" class="social-text">Add Your Twitch Channel</label>
                                    <input v-model="form.twitch_channel" type="url" name="twitch_channel"
                                           id="twitch_channel" class="input-field">
                                </div>
                            </transition>

                            <transition name="bounce-flash" mode="out-in">
                                <div key="discord" v-if="selectedSocial === 'discord'" class="mt-4 animated-input">
                                    <label for="discord" class="social-text">Add Your Discord Server</label>
                                    <input v-model="form.discord" type="url" name="discord" id="discord"
                                           class="input-field">
                                </div>
                            </transition>

                            <transition name="bounce-flash" mode="out-in">
                                <div key="youtube" v-if="selectedSocial === 'youtube'" class="mt-4 animated-input">
                                    <label for="youtube" class="social-text">Add Your YouTube Channel</label>
                                    <input v-model="form.youtube" type="url" name="youtube" id="youtube"
                                           class="input-field">
                                </div>
                            </transition>

                            <transition name="bounce-flash" mode="out-in">
                                <div key="facebook" v-if="selectedSocial === 'facebook'" class="mt-4 animated-input">
                                    <label for="facebook" class="social-text">Add Your Facebook Profile</label>
                                    <input v-model="form.facebook" type="url" name="facebook" id="facebook"
                                           class="input-field">
                                </div>
                            </transition>

                            <transition name="bounce-flash" mode="out-in">
                                <div key="x" v-if="selectedSocial === 'x'" class="mt-4 animated-input">
                                    <label for="x_social" class="social-text">X Social Profile</label>
                                    <input v-model="form.x_social" type="url" name="x_social" id="x_social"
                                           class="input-field">
                                </div>
                            </transition>
                        </div>

                        <div class="mb-6">
                            <h2 class="text-3xl font-extrabold mb-1">Game Details</h2>
                            <p class="info-text">Fill in the game details below.</p>
                        </div>

                        <!-- Game Selection -->
                        <div class="form-group mb-4">
                            <label for="game_id" class="label-text">Game</label>
                            <select v-model="form.game_id" name="game_id" id="game_id"
                                    class="selection">
                                <option v-for="game in games" :key="game.id" :value="game.id">{{ game.name }}</option>
                            </select>
                        </div>

                        <!-- Platform Select -->
                        <div class="form-group mb-4">
                            <label for="platform_id" class="label-text">Platform</label>
                            <select v-model="form.platform_id" name="platform_id"
                                    class="selection">
                                <option v-for="platform in platforms" :key="platform.id" :value="platform.id">
                                    {{ platform.name }}
                                </option>
                            </select>
                        </div>

                        <!-- Game Mode Select -->
                        <div class="form-group mb-4">
                            <label for="game_mode_id" class="label-text">Game Mode</label>
                            <select v-if="filteredGameModes.length > 0" v-model="form.game_mode_id" name="game_mode_id"
                                    class="selection">
                                <option v-for="gameMode in filteredGameModes" :key="gameMode.id" :value="gameMode.id">
                                    {{ gameMode.name }}
                                </option>
                            </select>
                            <p v-else class="text-red-400">Select a game to view game modes.</p>
                        </div>

                        <!-- Ruleset Selection -->
                        <div class="form-group mb-4">
                            <label for="ruleset_id" class="label-text mb-2">Ruleset</label>

                            <!-- If there are rulesets -->
                            <div v-if="filteredRulesets">
                                <select v-model="form.ruleset_id" name="ruleset_id"
                                        class="selection">
                                    <option v-for="ruleset in filteredRulesets" :key="ruleset.id" :value="ruleset.id">
                                        {{ ruleset.name }}
                                    </option>
                                    <option value="custom">Custom</option>
                                </select>
                            </div>

                            <!-- If there are no rulesets -->
                            <div v-else>
                                <p class="text-red-400">This game has no predefined rulesets. Please provide a custom
                                    ruleset.</p>
                            </div>
                        </div>

                        <!-- Custom Ruleset Content -->
                        <div v-if="form.ruleset_id === 'custom'" class="form-group mb-4 bg-blue-600">
                            <label for="custom_ruleset_content" class="label-text mb-2">Custom Ruleset</label>
                            <Editor v-model="form.custom_ruleset_content"
                                    class="input-field w-full"
                                    editorStyle="height: 320px">
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Header 1'" class="ql-header" value="1"></button>
                                    <button v-tooltip.bottom="'Header 2'" class="ql-header" value="2"></button>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                                    <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                                    <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                                </span>
                                    <span class="ql-formats">
                                    <select v-tooltip.bottom="'Text Color'" class="ql-color">
                                        <option value="rgb(0, 0, 0)" label="rgb(0, 0, 0)" selected></option>
                                        <option value="rgb(230, 0, 0)" label="rgb(230, 0, 0)"></option>
                                        <option value="rgb(255, 153, 0)" label="rgb(255, 153, 0)"></option>
                                        <option value="rgb(255, 255, 0)" label="rgb(255, 255, 0)"></option>
                                        <option value="rgb(0, 138, 0)" label="rgb(0, 138, 0)"></option>
                                        <option value="rgb(0, 102, 204)" label="rgb(0, 102, 204)"></option>
                                        <option value="rgb(153, 51, 255)" label="rgb(153, 51, 255)"></option>
                                        <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)"></option>
                                        <option value="rgb(250, 204, 204)" label="rgb(250, 204, 204)"></option>
                                        <option value="rgb(255, 235, 204)" label="rgb(255, 235, 204)"></option>
                                        <option value="rgb(255, 255, 204)" label="rgb(255, 255, 204)"></option>
                                        <option value="rgb(204, 232, 204)" label="rgb(204, 232, 204)"></option>
                                        <option value="rgb(204, 224, 245)" label="rgb(204, 224, 245)"></option>
                                        <option value="rgb(235, 214, 255)" label="rgb(235, 214, 255)"></option>
                                        <option value="rgb(187, 187, 187)" label="rgb(187, 187, 187)"></option>
                                        <option value="rgb(240, 102, 102)" label="rgb(240, 102, 102)"></option>
                                        <option value="rgb(255, 194, 102)" label="rgb(255, 194, 102)"></option>
                                        <option value="rgb(255, 255, 102)" label="rgb(255, 255, 102)"></option>
                                        <option value="rgb(102, 185, 102)" label="rgb(102, 185, 102)"></option>
                                        <option value="rgb(102, 163, 224)" label="rgb(102, 163, 224)"></option>
                                        <option value="rgb(255, 255, 255)" label="rgb(255, 255, 255)"></option>
                                    </select>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'List'" class="ql-list" value="ordered"></button>
                                    <button v-tooltip.bottom="'Bullet List'" class="ql-list" value="bullet"></button>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Link'" class="ql-link"></button>
                                </span>
                                    <span class="ql-formats">
                                    <button v-tooltip.bottom="'Clean'" class="ql-clean"></button>
                                </span>
                                </template>
                            </Editor>
                        </div>

                        <!-- Additional Options -->
                        <div class="mb-6">
                            <h2 class="text-3xl font-extrabold mb-1">Additional Options</h2>
                        </div>
                        <!-- PC Players Allowed Checkbox -->
                        <div class="flex flex-row gap-3">
                            <div class="form-group mb-4">
                                <label for="pc_players_allowed" class="text-gray-400 font-semibold">PC Players Allowed</label>
                                <input v-model="form.pc_players_allowed" type="checkbox" name="pc_players_allowed" id="pc_players_allowed"
                                       class="form-checkbox ml-2 w-6 h-6 text-blue-500 rounded">
                            </div>

                            <!-- Stream Required Checkbox -->
                            <div class="form-group mb-4">
                                <label for="pc_stream_required" class="text-gray-400 font-semibold">Stream Required</label>
                                <input v-model="form.pc_stream_required" type="checkbox" name="pc_stream_required" id="pc_stream_required"
                                       class="form-checkbox ml-2 w-6 h-6 text-blue-500 rounded">
                            </div>

                            <!-- Mosscam (MonitorCam Required) Checkbox -->
                            <div class="form-group mb-4">
                                <label for="moss_required" class="text-gray-400 font-semibold">MonitorCam Required</label>
                                <input v-model="form.moss_required" type="checkbox" name="moss_required" id="moss_required"
                                       class="form-checkbox ml-2 w-6 h-6 text-blue-500 rounded">
                            </div>
                        </div>

                         <!-- Text Field for Input Type (controller type) -->
                        <div class="form-group mb-4">
                            <label for="input_option" class="label-text">Input Type (Controller)</label>
                            <select v-model="form.input_option" name="input_option"
                                    class="selection">
                                <option value="Controller">
                                    Controller
                                </option>
                                <option value="Keyboard & Mouse">
                                    Keyboard & Mouse
                                </option>
                                <option value="All">
                                    All
                                </option>
                            </select>
                        </div>

                        <!-- Bracket Size Slider -->
                        <div class="form-group mb-4">
                            <label for="bracket_size" class="label-text">
                                Bracket Size <strong>{{ form.bracket_size }}</strong>
                            </label>
                            <input
                                type="range"
                                v-model="bracketSizeIndex"
                                :min="0"
                                :max="bracketSizes.length - 1"
                                step="1"
                                class="range-field"
                            >
                        </div>

                        <!-- Price Per Player Slider -->
                        <div class="form-group mb-4">
                            <label for="price_per_player" class="label-text">Price Per Player (${{
                                    form.price_per_player
                                }})</label>
                            <input type="range" v-model="form.price_per_player" min="0" max="100" step="1"
                                   class="range-field">
                        </div>

                        <!-- Commission -->
                        <div class="form-group mb-4">
                            <label for="commission" class="label-text">Host Commission
                                ({{ (form.commission * 100).toFixed(2) }}%)</label>
                            <input type="range" v-model="form.commission" min="0" max="0.30" step="0.01"
                                   class="range-field" :disabled="form.price_per_player == 0">
                            <p v-if="form.price_per_player == 0" class="text-gray-500 text-sm mt-2">Commission is
                                disabled when entry fee is zero.</p>
                        </div>

                        <!-- Prize Pool Display -->
                        <div class="my-2">
                            <h3>Totals</h3>
                            <p class="info-text">Review the totals below.</p>
                            <div class="form-group mb-4">
                                <label for="prize_pool" class="label-text">Total Prize Pool</label>
                                <p class="prize-info">${{ prizePool }}</p>

                                <label for="adjusted_prize_pool" class="label-text">Prize Pool After Host
                                    Commission</label>
                                <p class="prize-info">${{ adjustedPrizePool }}</p>

                                <label for="commission" class="label-text">Total Host Commission</label>
                                <p class="prize-info">${{ commissionAmount }}</p>
                            </div>
                        </div>

                        <!-- Prize Distribution Section -->
                        <div class="my-8">
                            <div class="mb-6">
                                <h2 class="text-3xl font-extrabold mb-1">Prize Distribution</h2>
                                <p class="info-text">Adjust the prize distribution below.</p>
                            </div>

                            <p v-if="prizePool == 0" class="info-text">No prize pool as the entry fee is zero.</p>

                            <!-- Prize Distribution Sliders -->
                            <div class="my-8">
                                <p class="info-text">Adjust the prize distribution sliders below. The total <span
                                    class="text-red-500 font-semibold">must equal</span> the adjusted prize pool.</p>

                                <div class="form-group mb-4">
                                    <label for="first_place" class="prize-label">1st Place ({{
                                            firstPlacePercentage
                                        }}%)</label>
                                    <input type="range" v-model="firstPlacePercentage" min="0" max="100" step="1"
                                           class="range-field" :disabled="prizePool == 0">
                                    <p class="prize-info">${{ firstPlaceAmount }}</p>
                                </div>

                                <div class="form-group mb-4">
                                    <label for="second_place" class="prize-label">2nd Place ({{
                                            secondPlacePercentage
                                        }}%)</label>
                                    <input type="range" v-model="secondPlacePercentage" min="0" max="100" step="1"
                                           class="range-field" :disabled="prizePool == 0">
                                    <p class="prize-info">${{ secondPlaceAmount }}</p>
                                </div>

<!--                                <div class="form-group mb-4">-->
<!--                                    <label for="third_place" class="prize-label">3rd Place (0%)</label>-->
<!--                                    <p class="prize-info">$0.00</p>-->
<!--                                </div>-->
                            </div>
                        </div>

                        <!-- Registration and Tournament Dates -->
                        <div class="my-8">
                            <h3>Dates</h3>
                            <p class="info-text">Set the registration and tournament start dates below.</p>

                            <div class="form-group mb-4">
                                <label for="registration_start" class="label-text">Registration Start</label>
                                <input type="datetime-local" v-model="form.registration_start"
                                       class="input-field" required>
                            </div>

                            <div class="form-group mb-4">
                                <label for="registration_end" class="label-text">Registration End</label>
                                <input type="datetime-local" v-model="form.registration_end"
                                       class="input-field" required>
                            </div>

                            <div class="form-group mb-4">
                                <label for="start_time" class="label-text">Tournament Start</label>
                                <input type="datetime-local" v-model="form.start_time"
                                       class="input-field" required>
                            </div>
                        </div>

                        <div>
                            <h3>Actions</h3>
                            <p class="info-text">Choose an action below. <strong>Note that once sent live, bracket sizes,
                                entry fees, commission etc...tournament details cannot be amended.</strong></p>
                            <div class="form-group flex space-x-4 my-2">
                                <button type="button" @click="submit(true)" class="submit-button">
                                    Save & Send Live
                                </button>
                                <button type="button" @click="submit(false)" class="draft-button">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>
h3 {
    @apply text-2xl font-extrabold mb-1;
}

.input-field {
    @apply w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 rounded;
}

.input-field::-webkit-calendar-picker-indicator {
    filter: invert(1);
    opacity: 0.8;
}

.range-field {
    @apply w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 rounded;
}

.selection {
    @apply w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 rounded;
}

.info-text {
    @apply text-gray-400 text-sm;
}

.prize-info {
    @apply font-bold text-xl;
}

.label-text {
    @apply text-xs text-gray-400;
}

.social-text {
    @apply text-xl;
}

.prize-label {
    @apply font-bold text-gray-400;
}

.submit-button {
    @apply bg-green-500 text-white rounded px-4 py-3 w-full uppercase font-semibold text-xs hover:bg-green-700;
}

.draft-button {
    @apply bg-blue-300 text-white rounded px-4 py-3 w-full uppercase font-semibold text-xs hover:bg-blue-500;
}

.p-fileupload-content .drag-drop-area {
    border: 2px dashed #4B5563; /* Tailwind color gray-600 */
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: #1F2937; /* Tailwind color gray-800 */
    cursor: pointer;
}

.p-fileupload-content .drag-drop-area:hover {
    background-color: #374151; /* Tailwind color gray-700 */
}

.p-fileupload-content .drag-drop-area i {
    font-size: 2rem;
    color: #9CA3AF; /* Tailwind color gray-400 */
}

.p-fileupload-content .drag-drop-area p {
    color: #9CA3AF; /* Tailwind color gray-400 */
    margin: 0.5rem 0 0;
}

/* Style for the uploaded file preview */
.uploaded-file {
    background-color: #1F2937; /* Tailwind color gray-800 */
    border: 1px solid #4B5563; /* Tailwind color gray-600 */
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uploaded-file img {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    margin-right: 1rem;
}

.uploaded-file .file-details {
    flex: 1;
}

.uploaded-file .file-details p {
    margin: 0;
    color: #D1D5DB; /* Tailwind color gray-300 */
}

.uploaded-file .file-details .file-name {
    font-weight: 600;
}

.uploaded-file .file-details .file-size {
    font-size: 0.875rem;
    color: #9CA3AF; /* Tailwind color gray-400 */
}

.uploaded-file .remove-button {
    margin-left: 1rem;
}

.social-icons {
    @apply flex justify-between space-x-4;
}

i {
    @apply text-2xl p-2;
}

.selected-icon i {
    @apply border-2 text-3xl border-white p-2;
}

/* Bounce and flash animation */
.bounce-flash-enter-active {
    animation: bounce-flash 1s ease;
}

.bounce-flash-leave-active {
    animation: bounce-flash 0s;
}

@keyframes bounce-flash {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.4); /* Tailwind blue-500 */
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 0 25px 10px rgba(59, 130, 246, 0.7); /* Stronger blue halo */
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(59, 130, 246, 0); /* Fade halo */
    }
}

.selected-icon i {
    @apply text-blue-500;
}

/* Style the editor toolbar */
:deep(.p-editor) {
    @apply bg-blue-900 border-none;
}

:deep(.p-editor-toolbar) {
    @apply bg-blue-900 border-none;
}

/* Style the editor content area */
:deep(.p-editor-content) {
    @apply bg-blue-900 border-none;
}

/* Style the file upload component */
:deep(.p-fileupload) {
    @apply bg-blue-900 border-none;
}

/* Style the file upload content area */
:deep(.p-fileupload-content) {
    @apply bg-blue-900 border-none;
}

</style>

<script setup>
import {computed} from 'vue';

const props = defineProps({
    'socialMediaAddress': {
        type: String,
        required: true
    },
    'socialMediaName': {
        type: String,
        required: true
    },
    'socialMediaIcon': {
        type: String,
        required: true
    },
    'socialMediaColor': {
        type: String,
        required: true
    },
    'socialUrlPrefix': {
        type: String,
        required: true,
        default: '' // Set to your desired prefix if needed, e.g., 'https://facebook.com/'
    }
});

// Computed property to determine the full URL
const fullUrl = computed(() => {
    // Check if the address is already a URL (e.g., starts with "http")
    return props.socialMediaAddress.startsWith('http') 
        ? props.socialMediaAddress 
        : `${props.socialUrlPrefix}/${props.socialMediaAddress}`;
});
</script>

<template>
    <a
    :href="fullUrl"
    target="_blank"
    rel="noreferrer nofollow"
    class="my-4 rounded-r-lg bg-blue-600 flex items-center"
    >
        <span :class="`p-2 bg-${socialMediaColor} mr-2 rounded-l-lg flex items-center`"><i :class="`${socialMediaIcon} text-2xl text-black`"></i></span> {{ socialMediaName }}
    </a>
</template>

<style scoped>

</style>

<script setup>

import Tabs from "../../../InertiaComponents/UI/Tabs.vue";
import {Link, usePage} from '@inertiajs/vue3';
import UserLeaderboardTable from "../../../InertiaComponents/Leaderboard/UserLeaderboardTable.vue";

const page = usePage();

const props = defineProps({
    xpLeaderboard: {
        type: Object,
        required: true,
    },
    earningsLeaderboard: {
        type: Object,
        required: true,
    },
    userXpAllTimeRanks: {
        type: Object,
        required: true,
    },
    userEarningsAllTimeRanks: {
        type: Object,
        required: true,
    }
});

const tabs = [
    {name: 'XP - All Time', slotName: 'xp-all-time'},
    {name: 'Earnings - All Time', slotName: 'earnings-all-time'},
];

const authUser = page.props.auth.user;

</script>

<template>
    <div class="bg-blue-600 rounded-lg overflow-hidden text-white">

        <section id="hero" class="py-10 sm:py-20 text-center sm:text-left sm:px-10 relative">
            <div class="img-container absolute inset-0 z-0">
            </div>

            <div class="hero-content relative z-10">
                <h2 class="text-3xl font-semibold mb-4">
                    Top Performers
                </h2>

                <p class="text-gray-400 text-sm mb-6 font-light">
                    Leaderboard of the top performers with the most
                    <br>
                    matches won across tournaments
                </p>

                <div class="mt-4 mb-8">
                    <Link :href="route('leaderboard.index')">
                        <button class="btn-gradient-blue px-4 py-3 rounded-full text-xs">
                            <i class="fas fa-crown text-sm mr-1">
                            </i>
                            View all Leaderboards
                        </button>
                    </Link>
                </div>
            </div>
        </section>

        <Tabs :tabs="tabs">
            <template v-slot:xp-all-time>
<!--                XP - All Time-->
                <UserLeaderboardTable
                    :auth-user="authUser"
                    :leaderboard="xpLeaderboard"
                    :users="userXpAllTimeRanks"
                />
            </template>

            <template v-slot:earnings-all-time>
<!--                Earnings - All Time-->
                <UserLeaderboardTable
                    :auth-user="authUser"
                    :leaderboard="earningsLeaderboard"
                    :users="userEarningsAllTimeRanks"
                />
            </template>
        </Tabs>
    </div>
</template>

<style scoped>

#hero > .img-container {
    background: url("/public/images/leaderboard/top-performers/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    opacity: 0.6;
}

</style>


<script setup>

import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";
import TournamentCard from "../../InertiaComponents/Tournament/TournamentCard.vue";
import NewsCard from "../../InertiaComponents/News/NewsCard.vue";
import AiHero from "../../InertiaComponents/Home/AiHero.vue";
import OpenChallengesAndRecentMatches from "./Partials/OpenChallengesAndRecentMatches.vue";
import TopPerformers from "./Partials/TopPerformers.vue";
import Carousel from "./Partials/Carousel.vue";
import LeagueSchedule from "./Partials/LeagueSchedule.vue";

const props = defineProps({
    userCount: {
        type: Number,
        required: true
    },
    // auth: {
    //     type: Object,
    //     required: true
    // },
    eliteTournaments: {
        type: Array,
        required: true
    },
    nonEliteTournaments: {
        type: Array,
        required: true
    },
    matches: {
        type: Array,
        required: true
    },
    recentlyPlayedMatches: {
        type: Array,
        required: true
    },
    recentNews: {
        type: Array,
        required: true
    },
    xpLeaderboard: {
        type: Object,
        required: true,
    },
    earningsLeaderboard: {
        type: Object,
        required: true,
    },
    userXpAllTimeRanks: {
        type: Object,
        required: true,
    },
    userEarningsAllTimeRanks: {
        type: Object,
        required: true,
    },
    slidingBanners: {
        type: Array,
        required: true,
    },  
    leagueSchedule: {
        type: Array,
        required: true,
    }
});

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const page = usePage();
// const userCount = computed(() => page.props.userCount);

const appName = computed(() => page.props.appName);

// const userCount = computed(() => 10000000);
const formattedUserCount = computed(() => numberWithCommas(props.userCount));
const user = computed(() => page.props.auth.user);

// const eliteTournaments = computed(() => page.props.eliteTournaments);
// const nonEliteTournaments = computed(() => page.props.nonEliteTournaments);

// const matches = computed(() => page.props.matches);
// const recentlyPlayedMatches = computed(() => page.props.recentlyPlayedMatches);

// const recentNews = computed(() => page.props.recentNews);

</script>

<template>
    <div class="space-y-6">
        <section class="sm:pb-20 bg-auto sm:bg-contain relative" :class="[leagueSchedule.length ? '-mt-12' : '']">
            <LeagueSchedule :leagueSchedule="leagueSchedule" />
            
            <Carousel v-if="slidingBanners.length > 2" :user="user" :banners="slidingBanners" />

            <div id="ai-hero" v-else class="container mx-auto flex text-center justify-center">
                <div class="-mt-12 img-container absolute inset-0"></div>
                <AiHero :userCount="userCount" :appName="page.props.appName" />
            </div>
        </section>

        <section id="tournaments" v-if="!!eliteTournaments && Object.keys(eliteTournaments).length > 0">
            <div class="container mx-auto">
                <h2 class="section-heading">
                    Elite Tournaments
                </h2>

                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    <TournamentCard v-for="tournament in eliteTournaments" :tournament="tournament" />
                </div>
            </div>
        </section>
        <section id="tournaments" v-if="!!nonEliteTournaments && Object.keys(nonEliteTournaments).length > 0">
            <div class="container mx-auto">
                <h3 class="section-heading">
                    Upcoming Tournaments
                </h3>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    <TournamentCard v-for="tournament in nonEliteTournaments" :tournament="tournament" />
                </div>
            </div>
        </section>
<!--        <section id="wagers">-->
<!--            <div class="container mx-auto  px-4 sm:px-0">-->
<!--                <ChallengesTable :matches="matches" />-->

<!--&lt;!&ndash;                <div>&ndash;&gt;-->
<!--&lt;!&ndash;                    {{ $matches->onEachSide(2)->links()  }}&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--            </div>-->
<!--        </section>-->
<!--        <section id="recent-wagers" class="mt-4">-->
<!--            <div class="container mx-auto  px-4 sm:px-0">-->
<!--&lt;!&ndash;                @include('components.challenges.recent-matches-table')&ndash;&gt;-->
<!--                <RecentMatchesTable :recently-played-matches="recentlyPlayedMatches"/>-->
<!--            </div>-->
<!--        </section>-->

        <section id="top-performers"
             v-if="!!xpLeaderboard && !!earningsLeaderboard && !!userXpAllTimeRanks && !!userEarningsAllTimeRanks && !!userXpAllTimeRanks.total && !!userEarningsAllTimeRanks.total"
        >
            <div class="container mx-auto px-4 sm:px-0">
                <TopPerformers
                    :earnings-leaderboard="earningsLeaderboard"
                    :user-xp-all-time-ranks="userXpAllTimeRanks"
                    :user-earnings-all-time-ranks="userEarningsAllTimeRanks"
                    :xp-leaderboard="xpLeaderboard"
                />
            </div>
        </section>

        <section id="open-wagers-and-recent-matches">
            <div class="container mx-auto px-4 sm:px-0">
                <OpenChallengesAndRecentMatches :recently-played-matches="recentlyPlayedMatches" :matches="matches"/>
            </div>
        </section>

        <section id="recent-news" v-if="!!recentNews && recentNews.length > 0">
            <div class="container mx-auto">
                <h2 class="section-heading">
                    Recent News
                </h2>

                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                    <NewsCard
                        v-for="newsPost in recentNews"
                        :key="newsPost.id"
                        :news-post="newsPost"
                    />
                </div>
            </div>
        </section>
    </div>
</template>


<style scoped>

.section-heading {
    @apply text-blue-400 inline-block text-xs font-semibold mb-4 uppercase;
}

</style>

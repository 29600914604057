

export function joinWithAnd(array) {
    if (array.length === 0) {
        return '';
    }

    if (array.length === 1) {
        return array[0];
    }

    if (array.length === 2) {
        return array.join(' and ');
    }

    let last = array.pop();
    return array.join(', ') + ', and ' + last;
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function roundToTwoDecimalPlaces(number) {
    return +parseFloat(number).toFixed(2);
}

export function numberWithCommasNoDecimals(x) {
    return parseFloat(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function roundToXDecimalPlaces(number, places) {
    return +parseFloat(number).toFixed(places);
}

export function formatCurrency(amount, currency = 'USD') {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency, currencyDisplay: 'code' })
        .format(parseFloat(amount))
        .replace("USD", "")
        .trim();
}

export function createExcerpt(content, maxNumberOfWords, trailingIndicator = '...') {
    const listOfWords = content.trim().split(' ');
    const truncatedContent = listOfWords.slice(0, maxNumberOfWords).join(' ');
    const excerpt = truncatedContent + trailingIndicator;
    const output = listOfWords.length > maxNumberOfWords ? excerpt : content;

    return output;
}

export function formatOrdinalSuffix(number) {
    const j = number % 10,
        k = number % 100;

    if (j === 1 && k !== 11) {
        return number + "st";
    }

    if (j === 2 && k !== 12) {
        return number + "nd";
    }

    if (j === 3 && k !== 13) {
        return number + "rd";
    }

    return number + "th";
}

export function getSuffixForOrdinal(number) {
    const j = number % 10,
        k = number % 100;

    if (j === 1 && k !== 11) {
        return "st";
    }

    if (j === 2 && k !== 12) {
        return "nd";
    }

    if (j === 3 && k !== 13) {
        return "rd";
    }

    return "th";
}

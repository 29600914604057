<script setup>

</script>

<template>
    <div
        class="px-4 py-2 flex items-center justify-between border-b border-gray-800"
    >
        <div class="flex items-center">
            <div class="bg-blue-900 px-1 py-1 rounded-lg mr-3">
                <p class="h-6 w-6 rounded-lg flex items-center justify-center text-blue-400 text-lg">
                    ?
                </p>
            </div>
            <div class="text-white font-semibold">TBD</div>
        </div>
    </div>
</template>

<style scoped>

</style>

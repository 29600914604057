<script setup>

import {computed, defineProps, onMounted, onUnmounted, ref, watch} from "vue";
import {format} from "date-fns/format";
import {formatDistance, formatDistanceStrict, formatDistanceToNow} from "date-fns";
import {usePage} from "@inertiajs/vue3";
import Tooltip from "../../UI/Tooltip.vue";
import ValueWithLabel from "../../UI/ValueWithLabel.vue";
import AcceptMatchButton from "./AcceptMatchButton.vue";

const page = usePage();

const user = computed(() => page.props.auth?.user);

const props = defineProps([
    // 'tournament',
    'match'
]);

// const tournament = ref(props.tournament);

const now = ref(new Date());
const matchExpiryInWords = computed(() => {
    return formatDistanceStrict(props.match.expiry_date, now.value, {addSuffix: true});
});

const matchStartInWords = computed(() => {
    return formatDistanceStrict(props.match.match_start_date, now.value, {addSuffix: true});
});

const longMatchExpiryInWords = computed(() => {
    return formatDistance(props.match.expiry_date, now.value, {addSuffix: true});
});

const longMatchStartInWords = computed(() => {
    return formatDistance(props.match.match_start_date, now.value, {addSuffix: true});
});

const wagerAmount = computed(() => {
    return parseFloat(props.match.wager_amount);
});

const userBalance = computed(() => {
    return parseFloat(user.value.balance);
});

const interval = ref(null);
onMounted(() => {
    interval.value = setInterval(() => {
        now.value = new Date();
    }, 1000);
});

onUnmounted(() => {
    clearInterval(interval.value);
});

const fontSizeForMatchExpiry = computed(() => {
    if (matchExpiryInWords.value.length > 10) {
        return 'text-md';
    }

    if (matchExpiryInWords.value.length <= 10 && matchExpiryInWords.value.length > 5) {
        return 'text-lg';
    }

    return 'text-xl';
});

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const showCrossplayInfoTooltip = ref(false);

</script>

<template>
    <div class="relative bg-blue-600 pb-4 rounded-lg flex flex-col justify-between">
        <div class="relative">
            <img
                :src="match.game.storage_background_url"
                class="rounded-t-lg w-full object-cover"
                :alt="`${match.game.name}'s background`">

            <div
                class="absolute font-semibold text-right top-2 right-2"
            >
                <div
                    class="inline-flex items-center px-2 py-1 rounded-xl shadow-lg mb-3 sm:mb-4"
                    :class="`${match.platform.color}`"
                >
                    <i :class="`text-lg sm:text-2xl ${match.platform.icon}`"></i>
                </div>
            </div>

            <div
                class="absolute font-semibold text-right top-2 left-2 bg-blue-600 bg-opacity-95 rounded-xl"
            >
                <img
                    :src="match.game.storage_icon_url"
                    class="rounded-lg object-contain w-11 h-11"
                    :alt="`${match.game.storage_icon_url}'s background`">
            </div>

            <div
                class="absolute font-semibold text-left bottom-2 left-2 bg-blue-600 bg-opacity-95 rounded-xl px-2 py-1 text-xs md:hidden"
            >
                <div class="flex">
                    <div class="text-xs sm:text-sm font-light uppercase">
                        <i class="mr-1 text-blue-400 fas fa-globe w-4 text-right"></i>
                        {{ match.region.name }}
                    </div>
                </div>

                <div>
                    <div class="text-xs sm:text-sm font-light uppercase">
                        <i class="mr-1 text-blue-400 fas fa-gamepad w-4 text-right"></i>
                        {{ match.team_size }} v {{ match.team_size }}
                    </div>

                    <div class="text-xs sm:text-sm font-light uppercase">
                        <i class="mr-1 text-blue-400 fas fa-hand-rock w-4 text-right"></i>
                        Best Of {{ match.best_of }}
                    </div>
                </div>
            </div>

            <div
                class="absolute font-semibold text-center bottom-2 right-2 bg-blue-600 bg-opacity-95 rounded-xl px-3 py-2 leading-3"
            >
                <div v-if="match.wager_amount > 0">
                    <p class="text-yellow-400 text-xl animate-subtle-pulse">
                        ${{ match.wager_amount }}
                    </p>
                    <p class="text-xs uppercase text-white font-light">
                        Wager
                    </p>
                </div>
                <div v-else>
                    <p class="text-yellow-400 text-xl">
                        XP
                    </p>
                    <p class="text-xs uppercase text-white font-light">
                        Match
                    </p>
                </div>
            </div>

            <div
                v-if="match.available_now"
                class="absolute top-0 left-1/2 transform -translate-x-1/2"
            >
                <div
                    class="text-xs font-semibold text-yellow-400 uppercase bg-blue-800 rounded-b-xl px-2 pb-1 shadow-md bg-opacity-90 animate-heartbeat">
                    Available Now
                </div>
            </div>

            <div
                class="absolute text-center top-2 left-1/2 transform -translate-x-1/2 bg-blue-600 bg-opacity-95 rounded-xl px-2 py-1 text-xs md:bottom-2 md:top-auto md:left-2 md:transform-none"
                :class="{ 'top-7': match.available_now }"
            >
                <div
                    class="flex justify-center flex-col items-center bg-blue-800 px-3 py-2 rounded-md bg-opacity-90 shadow-md text-center leading-3">
                    <div v-if="!match.available_now">
                        <div class="text-xxs font-semibold text-white uppercase">
                            Starts {{ matchStartInWords.startsWith("in") ? "In" : "" }}
                        </div>
                        <div
                            class="font-semibold text-blue-400 uppercase"
                            :class="fontSizeForMatchExpiry"
                        >
                            {{ matchStartInWords.replace(/^in/, "") }}
                        </div>
                    </div>

                    <div v-else>
                        <div class="text-xxs font-semibold text-white uppercase">
                            Expires {{ matchExpiryInWords.startsWith("in") ? "In" : "" }}
                        </div>
                        <div
                            class="font-semibold text-blue-400 uppercase"
                            :class="fontSizeForMatchExpiry"
                        >
                            {{ matchExpiryInWords.replace(/^in/, "") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="px-4 mt-2 flex flex-col justify-between">
            <div>
                <div class="flex justify-between mb-1 font-bold text-gray-400 uppercase text-xxs">
                    <div>
                        <div v-if="match.finished">
                            {{ format(match.expiry_date, 'MMM d, h:mm bbb') }} -
                            <span class="text-blue-300">
                                Complete
                            </span>
                        </div>

                        <div v-if="!match.finished && !!match.team_two">
                            {{ format(match.expiry_date, 'MMM d, h:mm bbb') }} -
                            <span class="text-blue-300">
                                In Progress
                            </span>
                        </div>
                        <div v-if="!match.finished && !match.team_two">
                            {{ format(match.expiry_date, 'MMM d, h:mm bbb') }} -
                            <span class="text-blue-300">
                                {{ longMatchExpiryInWords }}
                            </span>
                        </div>
                    </div>
                    <div class="flex">
                        <span class="ml-3">{{ match.region.name }}</span>
                    <!--                            <img class="ml-1 -mt-1" :src="tournament.region.icon" alt="">-->
                    </div>
                </div>
                <div class="md:text-left leading-3 my-1">
                    <div class="mb-1 font-bold text-gray-400 text-xxs ">Game</div>
                    <div class="text-md font-semibold text-white"> {{ match.game.name }}</div>
                </div>
                <div class="grid grid-cols-2 gap-2 leading-3 my-2 gap-y-2">

                    <div v-if="match.wager_amount > 0">
                        <div class="mb-1 font-bold text-gray-400 text-xxs">Wager</div>
                        <div class="text-sm font-bold text-yellow-400">
                            <span>
                                ${{ match.wager_amount }}
                            </span>
                        </div>
                    </div>
                    <div v-else>
                        <div class="mb-1 font-bold text-gray-400 text-xxs">Wager</div>
                        <div class="text-sm font-bold text-yellow-400">
                            <span>
                                XP Match
                            </span>
                        </div>
                    </div>

                    <div>
                        <div class="mb-1 font-bold text-gray-400 text-xxs">Team Size</div>
                        <div class="text-sm font-bold"><i class="mr-1 text-indigo-600 fas fa-dice"></i>
                            {{ match.team_size }} v {{ match.team_size }}
                        </div>
                    </div>
                    <div>
                        <div class="mb-1 font-bold text-gray-400 text-xxs">Best Of</div>
                        <div class="text-sm font-bold">
                            <i class="mr-1 text-orange-500 fas fa-hand-rock"></i>
                            {{ match.best_of }}
                        </div>
                    </div>
                    <div>
                        <div class="mb-1 font-bold text-gray-400 text-xxs">Game Mode</div>
                        <div class="text-sm font-bold">
                            <i class="mr-1 text-orange-500 fas fa-hand-rock"></i>
                            {{ match.game_mode.name }}
                        </div>
                    </div>

                    <div>
                        <div class="mb-1 font-bold text-gray-400 text-xxs">Platform</div>
                        <div class="text-sm font-bold">
                            <i
                                class="mr-1 fas fa-hand-rock"
                                :class="`${match.platform.icon}`"
                            >
                            </i>
                            {{ match.platform.name }}
                        </div>
                    </div>


                    <div v-if="match.game.crossplay_options" @mouseover="showCrossplayInfoTooltip = true"
                         @mouseleave="showCrossplayInfoTooltip = false">
                        <div class="mb-1 font-bold text-gray-400 text-xxs">Info</div>
                        <div class="text-sm font-bold">
                            <i
                                class="mr-1 fas fa-info-circle text-blue-200 text-sm"
                            >
                            </i>
                            Crossplay

                            <Tooltip
                                :show="showCrossplayInfoTooltip"
                                title="Crossplay Info"
                            >
                                <div v-if="match.game.name === 'Call of Duty: Cold War'">
                                    <ValueWithLabel label="Input Type:" hide-if-no-content>
                                        {{ match.input_option }}
                                    </ValueWithLabel>

                                    <ValueWithLabel label="PC Allowed:">
                                        {{ match.pc_players ? 'Yes' : 'No' }}
                                    </ValueWithLabel>

                                    <ValueWithLabel label="Stream Required:">
                                        {{ match.pc_stream_required ? 'Yes' : 'No' }}
                                    </ValueWithLabel>

                                    <ValueWithLabel label="Radar Always On:">
                                        {{ match.radars ? 'Enabled' : 'Disabled' }}
                                    </ValueWithLabel>

                                    <ValueWithLabel label="Snipers:">
                                        {{ match.snipers ? 'Allowed' : 'Disallowed' }}
                                    </ValueWithLabel>

                                    <ValueWithLabel label="Monitorcam:">
                                        {{ match.moss_required ? 'Yes' : 'No' }}
                                    </ValueWithLabel>
                                </div>
                                <div v-else>
                                    <ValueWithLabel label="Input Type:" hide-if-no-content>
                                        {{ match.input_option }}
                                    </ValueWithLabel>
                                    <ValueWithLabel label="PC Allowed:">
                                        {{ match.pc_players ? 'Yes' : 'No' }}
                                    </ValueWithLabel>
                                    <ValueWithLabel label="Stream Required:">
                                        {{ match.pc_stream_required ? 'Yes' : 'No' }}
                                    </ValueWithLabel>
                                    <ValueWithLabel label="Monitorcam:">
                                        {{ match.moss_required ? 'Yes' : 'No' }}
                                    </ValueWithLabel>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <AcceptMatchButton :match="match"/>
        </div>
    </div>
</template>

<style scoped>

@keyframes heartbeat
{
    0%
    {
        transform: scale( 1 );
    }
    20%
    {
        transform: scale( 1.05 );
    }
    40%
    {
        transform: scale( 1 );
    }
    60%
    {
        transform: scale( 1.05 );
    }
    80%
    {
        transform: scale( 1 );
    }
    100%
    {
        transform: scale( 1 );
    }
}

.animate-heartbeat {
    animation: heartbeat 1.2s infinite;
}

@keyframes subtle-pulse
{
    0%
    {
        transform: scale( 1 );
    }
    50%
    {
        transform: scale( 1.04 );
    }
    100%
    {
        transform: scale( 1 );
    }
}

.animate-heartbeat {
    animation: heartbeat 1.2s infinite;
}

.animate-subtle-pulse {
    animation: subtle-pulse 2s infinite;
}

</style>

<script setup>

import {useForm, usePage} from "@inertiajs/vue3";
import { computed, ref } from "vue";
import Checkbox from "primevue/checkbox";
import pluralize from "pluralize";
import {getTagsByGameAndPlatform} from "../../../Services/platformGamingAccount.js";
import {joinWithAnd} from "../../../Services/utils.js";

const page = usePage();
const user = computed(() => page.props.auth.user);

const props = defineProps({
    game: {
        type: Object
    },
    team: {
        type: Object
    },
    howManyPlayers: {
        type: Number,
        required: true
    },
    players: {
        type: Array
    },
    platform: {
        type: Object
    }
});

const selectedPlayers = ref([]);

const form = useForm({
    team: {
        players: []
    }
});

function playerHasGamingAccountsForPlatform(player) {
    let gamerTags = getTagsByGameAndPlatform(props.game, props.platform);

    return gamerTags.filter(tag => {
        return !!player.gamingAccounts && !!player.gamingAccounts[tag.accountFieldName]
    }).length > 0;
}

const selectedPlatformGamerTags = computed(() => {
    return getTagsByGameAndPlatform(props.game, props.platform);
});

const selectedPlatformGamerTagsHumanReadable = computed(() => {
    return joinWithAnd(selectedPlatformGamerTags.value.map(tag => {
        return tag.name;
    }));
});

</script>

<template>
    <div>
        <h6 class="text-md font-medium mt-4">
            Choose Your {{ pluralize('Player', howManyPlayers) }}
        </h6>
        <p class="text-sm text-gray-400 mb-4">
            Select the {{ pluralize('player', howManyPlayers) }} for your team.
        </p>

        <div v-for="player of players" :key="player.id" class="flex items-center my-1">
            <Checkbox
                v-model="selectedPlayers"
                :inputId="player.id"
                name="players"
                :value="player.id"
                class="ml-4"
                :disabled="!playerHasGamingAccountsForPlatform(player)"
                @change="$emit('playersSelected', selectedPlayers)"
            />
            <label :for="player.id" class="ml-2 font-semibold">
                {{ player.username }}
                <span v-if="player.username === user.username" class="font-light">
                    (You)
                </span>
            </label>

            <div v-if="!playerHasGamingAccountsForPlatform(player)" class="text-xs text-red-500 ml-2 flex items-center gap-1">
                <i class="fa-solid fa-circle-exclamation text-lg"></i> Missing {{ selectedPlatformGamerTagsHumanReadable }} account details
                <span v-if="player.username === user.username">
                    - add <a :href="route('settings.gaming.index')" class="font-semibold underline">here</a>
                </span>
            </div>
        </div>

        <div v-if="players.length === 0">
            <p class="text-sm text-red-500 font-semibold my-2">No players found for Team {{ team.name }}</p>
        </div>

        <div v-else>
            <div v-if="selectedPlayers.length > howManyPlayers">
                <p class="text-xs text-red-500">You can only select {{ howManyPlayers }} {{ pluralize('player', howManyPlayers) }}</p>
            </div>

            <div v-if="selectedPlayers.length < howManyPlayers">
                <p class="text-xs text-yellow-500">You must select {{ howManyPlayers }} {{ pluralize('player', howManyPlayers) }}</p>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

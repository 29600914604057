<script setup>

import {useForm, usePage} from "@inertiajs/vue3";
import {computed} from "vue";

const page = usePage();
const user = computed(() => page.props.auth?.user)
const userOnboarding = computed(() => page.props.auth?.user?.user_onboarding)
const appName = computed(() => page.props.appName)

const success = computed(() => page.props.flash.success)
const error = computed(() => page.props.flash.error)
const errors = computed(() => page.props.errors)
const emailVerify = computed(() => page.props.flash.emailVerify)

const skipOnboardingForm = useForm({});

</script>

<template>
    <div>
        <div v-if="!!user">
            <div
                v-if="!user.has_verified_email"
                class="border-l-4 border-red-400 bg-red-50 p-4"
            >
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </div>
                    <div class="ml-3">
                        <p class="text-sm text-red-700">
                            Please verify your email to enjoy the full gaming experience.
                            <a
                                :href="route('user.request-verification-email')"
                                class="font-medium text-red-700 underline hover:text-red-600"
                            >
                                Didn't receive an e-mail?
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div
                v-if="user.has_verified_email && !!user.user_onboarding && !user.user_onboarding.is_complete"
            >
                <div class="border-l-4 border-blue-400 bg-blue-50 p-3">
                    <div class="flex items-center">
                        <div class="flex-shrink-0">
                            <i class="fa-solid fa-circle-info text-blue-400 text-2xl"></i>
                        </div>
                        <div class="ml-3">
                            <p class="text-md text-blue-700 inline">
                                Complete your profile,
                                <a :href="route('user.onboarding.index')" class="font-medium">
                                    {{ userOnboarding.current_step_description }}
                                </a> -
                                <a :href="route('user.onboarding.index')"
                                   class="font-medium text-blue-700 underline hover:text-blue-500"
                                >
                                    complete step
                                </a>
                            </p>

                            <form
                                v-if="userOnboarding.current_step_index > 1"
                                @submit.prevent="skipOnboardingForm.post(route('user.onboarding.skip'))"
                                class="inline text-md text-blue-700">

                                or

                                <button type="submit"
                                        class="font-medium text-blue-700 underline hover:text-blue-500 inline text-md"
                                >
                                    skip
                                </button>
                            </form>

                            <p class="inline text-md text-blue-700">
                                ({{ userOnboarding.current_step_index }} / {{ userOnboarding.total_steps }})
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="!!success"
            class="border-l-8 border-green-500 bg-green-50 p-3">
            <div class="flex items-center text-center">
                <div class="flex-shrink-0">
                    <i class="fa-solid fa-circle-info text-green-500 text-2xl"></i>
                </div>
                <div class="ml-3">
                    <p class="text-md text-blue-700 inline">
                        {{ success }}
                    </p>
                </div>
            </div>
        </div>

        <div
            v-if="!!error"
            class="border-l-8 border-red-500 bg-red-50 p-3">
            <div class="flex items
            -center text-center">
                <div class="flex-shrink-0">
                    <i class="fa-solid fa-circle-info text-red-500 text-2xl"></i>
                </div>
                <div class="ml-3">
                    <p class="text-md text-blue-700 inline">
                        {{ error }}
                    </p>
                </div>
            </div>
        </div>
        <div v-if="!!errors">
            <div
                v-for="(error, key) in errors"
                :key="key"
                class="border-l-8 border-red-500 bg-red-50 p-3">
                <div class="flex items
                -center text-center">
                    <div class="flex-shrink-0">
                        <i class="fa-solid fa-circle-info text-red-500 text-2xl"></i>
                    </div>
                    <div class="ml-3">
                        <p class="text-md text-blue-700 inline">
                            {{ error }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="!!emailVerify"
            class="bg-blue-300 border-t-4 border-gray-900 rounded-b text-teal-darkest px-4 py-3 shadow-md my-2"
            role="alert">
            <div class="flex">
                <svg class="h-6 w-6 text-white mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                        d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                </svg>
                <div>
                    <p class="text-sm">{{ emailVerify }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>
import {ref} from "vue";
import {Link} from "@inertiajs/vue3";

const dropdownOpen = ref(false);

const toggleDropdown = () => {
    dropdownOpen.value = !dropdownOpen.value;
}

</script>

<template>
    <div class="relative">
        <div class="flex mr-2 lg:hidden">
            <!-- Mobile menu button -->
            <button @click="toggleDropdown"
                    class="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
                    aria-label="Main menu" aria-expanded="false">

                <svg :class="{ hidden: dropdownOpen }" class="block w-6 h-6" xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
                </svg>

                <svg :class="{ hidden: !dropdownOpen }" class="block w-6 h-6" xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                </svg>
            </button>
        </div>
        <transition enter-active-class="transition duration-100 ease-out" enter-class="opacity-0"
                    enter-to-class="opacity-100" leave-active-class="transition duration-75 ease-in"
                    leave-class="opacity-100" leave-to-class="opacity-0">
            <div v-show="dropdownOpen">
                <div v-show="dropdownOpen" class="fixed inset-0 bg-blue-900 opacity-90 z-20">
                </div>

                <div class="fixed inset-0 w-full lg:hidden z-30 text-center flex flex-col max-w-sm mx-auto">

                    <div class="px-4 pt-2 pb-3 flex flex-col gap-4 justify-around items-center h-full">
<!--                        <div class="relative flex items-center text-2xl font-bold text-white uppercase lg:block items h-16 md:h-24 mt-12">-->
<!--                            <a href="/" class="h-full">-->
<!--                                <img class="w-full h-full object-contain" src="/images/logo3.svg"-->
<!--                                     alt="ESHUB logo">-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="relative flex items-center text-2xl font-bold text-white uppercase lg:block items h-16 md:h-24 mt-12">-->
<!--                            <a href="/" class="h-full">-->
<!--                                <img class="w-full h-full object-contain" src="/images/logobeta.png"-->
<!--                                     alt="ESHUB logo">-->
<!--                            </a>-->
<!--                        </div>-->
                        <h3 class="text-3xl text-white uppercase underline mt-8">
                            Menu
                        </h3>
                        <div class="flex flex-col justify-center items-center h-full w-full gap-8" @click="toggleDropdown">
                            <!--                        <a href="/games"
                                                        class="block px-3 py-2 text-base font-medium text-white transition duration-150 ease-in-out bg-gray-900 rounded-md focus:outline-none focus:text-white focus:bg-gray-700 border-2 border-gray-400 w-full">
                                                        <i class="fa-solid fa-circle-xmark"></i>
                                                        GAMES
                                                    </a>-->
                            <Link href="/tournaments?status=upcoming"
                               class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out bg-gray-900 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 border-2 border-gray-400 w-full">
                                <i class="fa-solid fa-gamepad-modern"></i>
                                TOURNAMENTS
                            </Link>
                            <Link href="/match-finder"
                               class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out bg-gray-900 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 border-2 border-gray-400 w-full">
                                <i class="fa-solid fa-swords"></i>
                                CHALLENGES
                            </Link>
                            <a href="/leagues"
                               class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out bg-gray-900 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 border-2 border-gray-400 w-full">
                                <i class="fa-solid fa-swords"></i>
                                LEAGUES
                            </a>

                            <!--                <a href="/leagues" class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out bg-gray-900 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">LEAGUES</a>-->
                            <Link href="/store"
                               class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out bg-gray-900 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 border-2 border-gray-400 w-full">
                                <i class="fa-solid fa-cart-shopping"></i>
                                STORE
                            </Link>

                            <a href="/search"
                               class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out bg-gray-900 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 border-2 border-gray-400 w-full">
                                <i class="fa-solid fa-search"></i>
                                SEARCH
                            </a>
                        </div>
                        <button @click="toggleDropdown"
                                class="block px-3 py-2 mt-1 text-base font-medium text-gray-300 transition duration-150 ease-in-out bg-gray-900 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 border-2 border-gray-400 w-full">
                            <i class="fa-solid fa-circle-xmark"></i>
                            CLOSE MENU
                        </button>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<style scoped>

</style>

<script setup>

import ChallengesTable from "./ChallengesTable.vue";
import RecentMatchesTable from "./RecentMatchesTable.vue";
import Tabs from '../../../InertiaComponents/UI/Tabs.vue';
import {computed} from "vue";

const props = defineProps({
    matches: {
        type: Object,
        required: true,
    },
    recentlyPlayedMatches: {
        type: Array,
        required: true,
    }
});

const tabs = [
    {
        name: 'Open Wagers',
        slotName: 'open-wagers'
    },
    {
        name: 'Recently Played Matches',
        slotName: 'recently-played-matches'
    }
];

const defaultOpenTab = computed(() => {
    if (props.matches.total > 0) {
        return tabs[0];
    }

    return tabs[1];
})

</script>

<template>
    <div>
        <Tabs
            :tabs="tabs"
            :default-open-tab="defaultOpenTab.slotName"
        >
            <template v-slot:open-wagers>
                <ChallengesTable :matches="matches"/>
            </template>
            <template v-slot:recently-played-matches>
                <RecentMatchesTable :recently-played-matches="recentlyPlayedMatches"/>
            </template>
        </Tabs>
    </div>
</template>

<style scoped>

</style>

<script setup>

import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import {formatCurrency, numberWithCommas, roundToXDecimalPlaces} from "../../Services/utils.js";
import TournamentCard from "../../InertiaComponents/Tournament/TournamentCard.vue";
import GamePlatformFilter from "../../components/GamePlatformFilter.vue";
import {Link} from "@inertiajs/vue3";
import MatchCardWithUnknownTeams from "../../InertiaComponents/WagerChallenges/Matches/MatchCardWithUnknownTeams.vue";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {computed} from 'vue';
import NewsCard from "../../InertiaComponents/News/NewsCard.vue";
import StartChallengeButton from "../../InertiaComponents/WagerChallenges/Matches/StartChallengeButton.vue";
import UiButton from "../../InertiaComponents/UI/UiButton.vue";
import Tabs from "../../InertiaComponents/UI/Tabs.vue";

const props = defineProps({
    game: {
        type: Object,
        required: true
    },
    matches: {
        type: Object,
        required: true
    },
    openTournaments: {
        type: Array,
        required: true
    },
    openChallenges: {
        type: Array,
        required: true
    },
    totalCashAwarded: {
        type: Number,
        required: true
    },
    totalMatches: {
        type: Number,
        required: true
    },
    totalTournaments: {
        type: Number,
        required: true
    },
    leaderboard: {
        type: Array,
        required: true
    },
    leaderboardForPlatform: {
        type: Array,
        required: true
    },
    playerLeaderboard: {
        type: Array,
        required: true
    },
    playerLeaderboardForPlatform: {
        type: Array,
        required: true
    },
    newsPosts: {
        type: Array,
        required: true
    },
    showLeaderboard: {
        type: Boolean,
        required: true
    }
});

const leaderboardWithRank = computed(() => props.leaderboard.map((team, index) => {
    return {
        ...team,
        rank: `#${index + 1}`,
        competitive_score: roundToXDecimalPlaces(team.competitive_score, 2),
    }
}));

const leaderboardForPlatformWithRank = computed(() => props.leaderboardForPlatform.map((teamPlatform, index) => {
    return {
        ...teamPlatform.team,
        rank: `#${index + 1}`,
        competitive_score: roundToXDecimalPlaces(teamPlatform.competitive_score, 2),
    }
}));

const playerLeaderboardWithRank = computed(() => props.playerLeaderboard.map((player, index) => {
    return {
        ...player,
        name: player.user.username,
        rank: `#${index + 1}`,
        competitive_score: roundToXDecimalPlaces(player.competitive_score, 2),
    }
}));

const playerLeaderboardForPlatformWithRank = computed(() => props.playerLeaderboardForPlatform.map((playerPlatform, index) => {
    return {
        ...playerPlatform.player,
        name: playerPlatform.game_player.user.username,
        rank: `#${index + 1}`,
        competitive_score: roundToXDecimalPlaces(playerPlatform.competitive_score, 2),
    }
}));

const tabs = computed(() => {
    const result = [];

    if (props.newsPosts.length > 0) {
        result.push({
            name: 'News',
            slotName: 'news',
        });
    }

    if (!!props.openTournaments && props.openTournaments.length > 0) {
        result.push({
            name: 'Upcoming Tournaments',
            slotName: 'upcoming-tournaments',
        });
    }

    return [
        ...result,
        {
            name: 'Open Challenges',
            slotName: 'open-challenges',
        }
    ];
});

</script>

<template>
    <div>
        <div id="bg" class="-mt-12 game-header-img z-1" :style="`background-image: url('${game.storage_header_url}')`">
        </div>
        <div id="main" class="relative px-4 py-24 text-white sm:px-0">
            <div class="container z-10 mx-auto">
                <Breadcrumbs :breadcrumbs="[
                    {name: 'Home', link: '/'},
                    {name: 'Games', link: '/games'},
                    {name: `${game.name} (${game.active_platform.name})`},
                ]" />

                <div class="flex flex-col lg:items-center lg:justify-between lg:flex-row">
                    <div class="text-4xl font-extrabold">{{ game.name }} Game Hub</div>

                    <div class="flex items-center gap-3 mt-4 flex-wrap">
                        <GamePlatformFilter :game="game"></GamePlatformFilter>

                        <UiButton :href="route('leaderboard.team-index', { game: game.slug })">
                            <i class="mr-2 fas fa-users"></i> Team Leaderboards
                        </UiButton>

                        <a
                            v-if="!!game.rulesets"
                            :href="route('ruleset.show', game.rulesets.id)"
                           class="px-4 py-2 text-sm font-medium bg-blue-800 border border-gray-800 rounded-lg"
                        >
                            <i class="mr-2 fas fa-clipboard"></i>Rules
                        </a>
                    </div>
                </div>
            </div>

            <div class="flex py-4 mt-5 mb-10 bg-blue-800 border border-gray-800">
                <div class="container flex justify-between mx-auto">
                    <div class="flex flex-wrap">
                        <div id="stat-box"
                             class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i class="mr-4 text-purple-600 fa-solid fa-trophy"></i>
                            <div>
                                <div class="text-sm font-bold">
                                    ${{ formatCurrency(totalCashAwarded) }}</div>
                                <div class="text-xs text-gray-400">Cash Awarded</div>
                            </div>
                        </div>
                        <div id="stat-box"
                             class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                            <i class="mr-4 text-blue-500 fas fa-gamepad"></i>
                            <div>
                                <div class="text-sm font-bold">{{ numberWithCommas(totalMatches) }}</div>
                                <div class="text-xs text-gray-400">Matches Played</div>
                            </div>
                        </div>
                        <div id="stat-box"
                             class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">

                            <i class="mr-4 text-orange-600 fa-solid fa-trophy"></i>
                            <div>
                                <div class="text-sm font-bold">{{ numberWithCommas(totalTournaments) }}</div>
                                <div class="text-xs text-gray-400">Tournaments Held</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container mx-auto">
<!--                               <div class="flex flex-wrap mb-12 -mx-6">-->
                    <!--                    <div class="w-full px-4 mb-6 sm:w-1/4 ">-->
                    <!--&lt;!&ndash;                        @include('partials.components.elite-advertisement')&ndash;&gt;-->
                    <!--                    </div>-->

                    <!--                    <div class="w-full px-4 mb-6 sm:w-3/4">-->
                    <!--&lt;!&ndash;                        @include('components.challenges.table', [&ndash;&gt;-->
                    <!--&lt;!&ndash;                        'classes' => 'bg-blue-800 text-white rounded border border-blue-700',&ndash;&gt;-->
                    <!--&lt;!&ndash;                        'buttons' => [&ndash;&gt;-->
                    <!--&lt;!&ndash;                        'Create Challenge' => route('challenges.create', ['game' => $game->id]),&ndash;&gt;-->
                    <!--&lt;!&ndash;                        ],&ndash;&gt;-->
                    <!--&lt;!&ndash;                        ])&ndash;&gt;-->

                    <!--&lt;!&ndash;                        {{ $matches->onEachSide(2)->links() }}&ndash;&gt;-->
                    <!--                    </div>-->
                    <!--                </div>-->


                <Tabs
                    :tabs="tabs"
                >
                    <template v-slot:news>
                        <p class="m-0 p-4">
                            <div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                                <NewsCard v-for="newsPost in newsPosts" :key="newsPost.id"  :news-post="newsPost"/>
                            </div>
                        </p>
                    </template>

                    <template v-slot:upcoming-tournaments>
                        <div class="p-4">
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-6">
                                <TournamentCard v-for="tournament in openTournaments" :key="tournament.id" :tournament="tournament" />
                            </div>

                            <div class="text-center">
                                <Link
                                    :href="route('tournaments.index', {'status': 'upcoming', 'game': game.slug})"
                                    class="inline-flex items-center gap-2 btn-gradient-blue font-semibold px-4 py-2 rounded-lg transition transform scale-100 hover:scale-105 duration-200 ease-in-out"
                                >
                                    <i class="fas fa-gamepad text-lg">
                                    </i>
                                    More Tournaments
                                </Link>
                            </div>
                        </div>
                    </template>

                    <template v-slot:open-challenges>
                        <div class="m-0 p-4">
                            <div v-if="!!openChallenges && openChallenges.length > 0">
                                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-6 gap-4">
                                    <MatchCardWithUnknownTeams v-for="challenge in openChallenges" :key="challenge.id" :match="challenge" />
                                </div>
                            </div>

                            <div v-else>
                                <div
                                    class="rounded-b px-4 py-3 my-10 flex flex-col justify-center items-center"
                                >
                                    <div class="flex px-2 py-2 rounded-md items-center gap-2">
                                        <i class="fas fa-circle-info text-xl"></i>
                                        <div>
                                            <p class="font-semibold">There aren't any active matches right now, start a challenge?</p>
                                        </div>
                                    </div>
                                    <StartChallengeButton />
                                </div>
                            </div>

                            <div class="text-center">
                                <Link
                                    :href="route('matchfinder.index', {'status': 'upcoming', 'game': game.slug})"
                                    class="inline-flex items-center gap-2 btn-gradient-blue font-semibold px-4 py-2 rounded-lg transition transform scale-100 hover:scale-105 duration-200 ease-in-out"
                                >
                                    <i class="fas fa-gamepad text-lg">
                                    </i>
                                    More Challenges
                                </Link>
                            </div>
                        </div>
                    </template>
                </Tabs>
            </div>
        </div>
    </div>
</template>

<style scoped>

.p-tabview-panels {
    background-color: red !important;
    padding: 0 !important;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active {
    background-color: red;
}

</style>

<template>
    <div id="main" class="text-white px-4 md:px-0 py-4">
        <div class="mx-auto container">
            <div id="breadcrumbs" class="text-xs mb-5">
                Home <span class="ml-1 mr-1 text-gray-600 font-extrabold">></span> User <span class="ml-1 mr-1 text-gray-600 font-extrabold">></span> <span class="ml-1 mr-1 text-gray-600">Preferences</span>
            </div>

            <div class="flex flex-wrap justify-between">
                <Navigation />

                <div class="w-full md:w-10/12 px-4 md:px-6 mt-8 md:mt-0 border-l border-gray-800">

                    <h2 class="text-2xl font-bold">Challenge Settings</h2>

                    <hr class="border-b-1 border-gray-800 py-2">
                    <form @submit.prevent="submit">
                        <div id="form-group">
                            <div class="text-xs text-gray-600 py-1">Enable challenges</div>

                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <select v-model="form.challenges_enabled"
                                        class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none">
                                    <option :value="1">Yes</option>
                                    <option :value="0">No</option>
                                </select>
                            </div>

                            <p class="text-xs">When disabled, you will <u>not</u> be able to participate in Challenge matches</p>
                        </div>

                        <button type="submit" class="mt-4 btn-gradient-blue px-16 py-3 rounded uppercase text-xxs float-left font-semibold text-right inline-block">
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import {useForm, usePage} from '@inertiajs/vue3';
import Navigation from '../../../components/SettingsNav.vue';



const page = usePage();
let props = page.props;

const form = useForm({
    challenges_enabled: props.user.challenges_enabled ? 1 : 0
});

const successMessage = ref('');
const errorMessage = ref('');
const errors = ref([]);

const submit = () => {
    form.post(route('settings.preferences.update'), {
        onSuccess: () => {
            successMessage.value = 'Successfully updated your preferences settings';
            errorMessage.value = '';
            errors.value = [];
        },
        onError: (err) => {
            errorMessage.value = 'Failed to update preferences settings';
            successMessage.value = '';
        }
    });
};
</script>

<template>
    <a :href="route('teams.show', team.id)" class="w-full sm:w-3/12 transition duration-300 ease-in-out transform hover:-translate-y-1 px-2 mb-6 shadow-lg">
        <div class="bg-blue-800 text-white rounded border border-blue-700 py-12 px-8 flex">
            <div>
                <img :src="team.storage_avatar" class="rounded-lg h-12 w-12 border-2 border-blue-900 mr-3">
            </div>
            <div>
                <div class="text-blue-500 text-xxs font-bold uppercase">
                    <i v-if="team.owner_id === user.id" class="fas fa-crown mr-1"></i>
                    <span v-if="team.owner_id === user.id">Team Leader</span>
                    <span v-else>Recruit</span>
                </div>
                <div class="text-white font-sm font-bold">
                    {{ team.name }}
                </div>
                <div class="text-xxs text-gray-600">Member since Yesterday</div>
            </div>
        </div>
    </a>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const props = defineProps({
    team: Object,
});

const { props: pageProps } = usePage();
const user = pageProps.auth.user;
</script>

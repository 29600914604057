<script setup>

import {formatDistanceToNow} from "date-fns";
import {formatCurrency, getSuffixForOrdinal} from "../../Services/utils.js";
import {useForm, usePage, Link} from "@inertiajs/vue3";
import {computed} from "vue";
import YourTeamTracker from "./YourTeamTracker.vue";
import TeamLeaderboardTable from "./TeamLeaderboardTable.vue";
import PaginationLinks from "../Pagination/PaginationLinks.vue";
import Button from 'primevue/button';

import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';

const page = usePage();

const props = defineProps({
    teams: {
        type: Object,
        required: true
    },
    username: {
        type: String,
        required: true
    },
    activeFilter: {
        type: String,
        required: true,
    },
    activePeriodFilter: {
        type: String,
        required: true,
    },
    activeGameFilter: {
        type: String,
        required: true,
    },
    leaderboard: {
        type: Object,
        required: true,
    },
    period: {
        type: Object,
        required: true,
    },
    availableLeaderboards: {
        type: Array,
        required: true,
    },
    game: {
        type: Object,
        required: false,
    },
    games: {
        type: Array,
        required: true,
    },
    yourTeamRank: {
        type: Object,
        required: false,
    },
    activeTeamFilter: {
        type: String,
        required: true,
    },
    ranksForUserTeams: {
        type: Array,
        required: true,
    },
});

const form = useForm({
    by: props.activeFilter,
    period: props.activePeriodFilter,
    username: props.username,
    team: props.activeTeamFilter,
    game: props.game?.slug,
});

const selectedGame = computed(() => {
    return props.games.find(game => game.slug === form.game);
});

const availableLeaderboards = computed(() => {
    return props.availableLeaderboards.filter(leaderboard => leaderboard.game.slug === form.game);
});

function gameSelectionChanged() {
    const game = selectedGame.value;
    form.by = game.leaderboards[0].slug;

    form.team = null;

    leaderboardSelectionChanged();
}

const selectedLeaderboard = computed(() => {
    return availableLeaderboards.value.find(leaderboard => leaderboard.slug === form.by);
});

const availableLeaderboardPeriods = computed(() => {
    if (!selectedLeaderboard.value) {
        return [];
    }

    if (!selectedLeaderboard.value.periods) {
        return [];
    }

    return selectedLeaderboard.value.periods;
});

function leaderboardSelectionChanged() {
    const leaderboard = selectedLeaderboard.value;
    form.period = leaderboard.periods[0].slug;

    form.team = null;
}

const periodPrizes = computed(() => {
    return props.period.prizes.sort((a, b) => a.ordinal - b.ordinal);
})

const authUser = page.props.auth.user;

function isUserAMemberOfTeam(team) {
    return team.members.some(member => member.username === authUser.username);
}

// Returns 'owner' if the team's owner_id matches the user's ID
// and 'member' if the User is within the team's member records.
function userRoleInTeam(team) {
    if (team.owner_id === authUser.id) {
        return 'owner';
    }

    if (isUserAMemberOfTeam(team)) {
        return 'member';
    }

    return 'guest';
}
</script>

<template>
    <div class="m-0">
        <form @submit.prevent="form.get(route('leaderboard.team-index'))" class="mb-4">

            <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0 flex flex-col">
                    <label class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                           for="region">
                        Game
                    </label>

                    <select
                        class="block appearance-none w-full bg-blue-900 border border-gray-800 text-white placeholder-gray-600 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 flex-1"
                        id="game"
                        name="game"
                        v-model="form.game"
                        @change="gameSelectionChanged"
                    >
                        <option v-for="game in games" :key="game.id"
                                :value="game.slug" :selected="game.slug === activeGameFilter">
                            {{ game.name }}
                        </option>
                    </select>
                </div>

                <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0 flex flex-col">
                    <label class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                           for="region">
                        Leaderboard
                    </label>

                    <select
                        class="block appearance-none w-full bg-blue-900 border border-gray-800 text-white placeholder-gray-600 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 flex-1"
                        id="by"
                        name="by"
                        v-model="form.by"
                        @change="leaderboardSelectionChanged"
                    >
                        <option v-for="leaderboard in availableLeaderboards" :key="leaderboard.id"
                                :value="leaderboard.slug" :selected="leaderboard.slug === activeFilter">
                            {{ leaderboard.name }}
                        </option>
                    </select>
                </div>

                <div v-if="availableLeaderboardPeriods.length > 1" class="w-full md:w-1/5 px-3 mb-6 md:mb-0 flex flex-col">
                    <label class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                           for="region">
                        Period
                    </label>

                    <select
                        class="block appearance-none w-full bg-blue-900 border border-gray-800 text-white placeholder-gray-600 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 flex-1"
                        id="by"
                        name="by"
                        v-model="form.period"
                    >
                        <option v-for="period in availableLeaderboardPeriods"
                                :key="period.id"
                                :value="period.slug"
                                :selected="period.slug === activePeriodFilter"
                        >
                            {{ period.name }}
                        </option>
                    </select>
                </div>

                <div v-if="!!ranksForUserTeams && ranksForUserTeams.length > 1 && selectedGame.id === ranksForUserTeams[0].team.game_id" class="w-full md:w-1/5 px-3 mb-6 md:mb-0 flex flex-col">
                    <label class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"
                           for="region">
                        Your Team
                    </label>

                    <select
                        class="block appearance-none w-full bg-blue-900 border border-gray-800 text-white placeholder-gray-600 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 flex-1"
                        id="team"
                        name="team"
                        v-model="form.team"
                    >
                        <option v-for="teamRank in ranksForUserTeams"
                                :key="teamRank.id"
                                :value="teamRank.team.id"
                                :selected="teamRank.team.id === activeTeamFilter"
                        >
                            {{ teamRank.team.name }}
                            <span v-if="userRoleInTeam(teamRank.team) === 'owner'" class="text-blue-400 font-semibold text-sm">
                                        (Owner)
                                    </span>

                            <span v-else-if="userRoleInTeam(teamRank.team) === 'member'" class="text-green-500 font-semibold text-sm">
                                        (Member)
                                    </span>
                        </option>
                    </select>
                </div>

                <!--                                <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0 flex flex-col">-->
                <!--                                    <label class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2"-->
                <!--                                           for="username">-->
                <!--                                        Username-->
                <!--                                    </label>-->

                <!--                                    <input-->
                <!--                                        class="block appearance-none w-full bg-blue-900 border border-gray-800 text-white placeholder-gray-500 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500 flex-1"-->
                <!--                                        id="username"-->
                <!--                                        name="username"-->
                <!--                                        v-model="form.username"-->
                <!--                                        placeholder="Find a user by username"-->
                <!--                                    />-->
                <!--                                </div>-->

                <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0 flex flex-col justify-center">
                    <label
                        class="block uppercase tracking-wide text-gray-400 text-xs font-bold mb-2">Search</label>

                    <Button class="flex-1 text-white font-semibold" :disabled="!form.isDirty" type="submit">
                        Update Results
                    </Button>

                    <!--                                    <button type="submit"-->
                    <!--                                            class="px-16 py-3 rounded uppercase text-xs font-semibold text-center inline-block w-full bg-blue-500 disabled:bg-blue-800"-->
                    <!--                                            :disabled="!form.isDirty"-->
                    <!--                                    >-->
                    <!--                                        Update Results-->
                    <!--                                    </button>-->
                </div>
            </div>
        </form>

        <div>
            <div class="lg:sticky top-0 px-2 pb-2 bg-blue-800">
                <div class="flex justify-between items-center flex-wrap">
                    <div class="mb-2">
                        <h1 class="text-3xl font-semibold">
                            <span v-if="!!leaderboard.game">{{leaderboard.game.name}} -</span> {{ leaderboard.name }} - {{ period.name }}
                        </h1>
                        <h3 class="text-lg">
                            {{ period.description }}
                        </h3>
                    </div>

                    <div v-if="!!period.lockable">
                        <div v-if="new Date(period.end_at) > new Date()" class="mr-2">
                            <div class="text-right inline lg:block">
                                <i class="fa-solid fa-lock-open text-xl text-green-500"></i>
                            </div>
                            Locked in {{ formatDistanceToNow(period.end_at) }}
                        </div>
                        <div v-else class="mr-2">
                            <div class="text-right inline lg:block">
                                <i class="fa-solid fa-lock text-xl text-red-500"></i>
                            </div>
                            Locked
                        </div>
                    </div>
                </div>

                <div v-if="period.prizes.length > 0">
                    <Accordion value="0" class="mb-2">
                        <AccordionPanel value="0">
                            <AccordionHeader>
                                <h2 class="text-md font-semibold text-blue-300 uppercase">
                                    Prizes
                                </h2>
                            </AccordionHeader>
                            <AccordionContent>
                                <div class="w-full flex flex-col lg:flex-row items-center gap-4 flex-wrap">
                                    <div v-for="prize in periodPrizes"
                                         class="border-blue-500 border-2 rounded-lg p-2 flex-1 w-full lg:w-auto">
                                        <div v-if="prize.rank === 1" class="w-16 h-16 overflow-hidden float-left mr-2">
                                            <img src="/images/trophies/gold-trophy.svg"
                                                 class="transform scale-150">
                                        </div>

                                        <div v-if="prize.rank === 2" class="w-16 h-16 overflow-hidden float-left mr-2">
                                            <img src="/images/trophies/silver-trophy.svg"
                                                 class="transform scale-150">
                                        </div>

                                        <div v-if="prize.rank === 3" class="w-16 h-16 overflow-hidden float-left mr-2">
                                            <img src="/images/trophies/bronze-trophy.svg"
                                                 class="transform scale-150">
                                        </div>

                                        <!--                                                <img v-if="prize.rank === 2" src="/images/trophies/silver-trophy.svg"-->
                                        <!--                                                     class="w-32 h-32 float-left">-->

                                        <!--                                                <img v-if="prize.rank === 3" src="/images/trophies/bronze-trophy.svg"-->
                                        <!--                                                     class="w-32 h-32 float-left">-->

                                        <div class="flex flex-col justify-around flex-1 h-full mt-2">
                                            <p class="text-xl font-semibold">

                                                <span class="relative mr-4">{{ prize.rank }}<span
                                                    class="absolute text-sm top-0">{{ getSuffixForOrdinal(prize.rank) }}</span></span>
                                                -
                                                {{ prize.name }}
                                            </p>
                                            <!--                                                <div class="text-sm font-semibold mb-2">-->
                                            <!--                                                    {{ prize.description }}-->
                                            <!--                                                </div>-->
                                            <div class="flex gap-1">
                                                <p v-if="!!prize.balance_amount" class="text-md font-semibold">
                                                    ${{ formatCurrency(prize.balance_amount) }}
                                                </p>
                                                <p v-if="!!prize.balance_amount && !!prize.credits" class="font-light">
                                                    and
                                                </p>
                                                <p v-if="!!prize.credits" class="text-md font-semibold">
                                                    {{ prize.credits }} Credits
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionContent>
                        </AccordionPanel>
                    </Accordion>
                </div>

                <YourTeamTracker v-if="!!yourTeamRank" :leaderboard-title="leaderboard.name" :your-team-rank="yourTeamRank"/>
            </div>

            <TeamLeaderboardTable :teams="teams" :leaderboard="leaderboard" :active-filter="activeFilter" :auth-user="authUser"/>

            <div class="my-4">
                <PaginationLinks :links="teams.links"/>
            </div>
        </div>

        <div v-if="teams.total === 0" class="text-lg text-center my-4">
            <p class="mb-4">
                This Leaderboard doesn't have any teams yet. Compete in matches to earn XP and climb the ranks!
            </p>

            <p class="mb-4">
                The sooner you start, the sooner you can start climbing the ranks!
            </p>

            <p class="mb-4">
                <Link :href="route('matchfinder.index')"
                      class="btn-gradient-blue px-6 py-3 rounded-lg text-xs font-semibold">
                    <i aria-hidden="true" class="text-sm fas fa-fist-raised"></i> Find Matches
                </Link>
            </p>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>
import {usePage, Link} from "@inertiajs/vue3";
import Tabs from "../../components/partials/Tabs.vue";
import Tab from "../../components/partials/Tab.vue";
import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import Fixtures from "../../InertiaComponents/Leagues/Fixtures.vue";
import Standings from "../../InertiaComponents/Leagues/Standings.vue";
import LeagueRegisterTeamModal from "../../components/Leagues/LeagueRegisterTeamModal.vue";
import {computed} from "vue";
import {format} from "date-fns";

const props = usePage().props;
const league = props.league;
const groupStandings = props.groupStandings;
const leagueMatches = league.matches;

const formattedStartDate = computed(() => format(new Date(league.start_date), 'dd/MM/yyyy HH:mm'));
const formattedEndDate = computed(() => format(new Date(league.end_date), 'dd/MM/yyyy HH:mm'));

</script>

<style scoped>

tr:nth-child(even) {
    @apply bg-gray-800;
}

tr:nth-child(odd) {
    @apply bg-gray-900;
}

th {
    @apply bg-blue-600 px-6 pt-2 pb-2 text-left border-b border-gray-700;
}

.league-info-card {
    @apply w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12;
}

.league-info-card body {
    @apply pt-1 text-xl text-gray-500;
}

</style>

<template>
    <div class="w-full">
        <div id="bg" class="game-header-img z-1" :style="{
                background:
                    'url(\'' + league.game.storage_header_url + '\')'
            }"></div>
        <div id="main" class="py-12 text-white">
            <div class="container mx-auto">
                <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Leagues', link: route('leagues.index', league.id)},
            ]"/>
                <div id="heading-area"
                     class="flex flex-col items-center justify-between mt-8 sm:flex-row sm:mx-0">
                    <div class="flex md:w-3/4">
                        <div
                            class="items-center self-center hidden px-2 py-2 mr-2 rounded-full sm:block">
                            <img :src="league.game.storage_icon_url" class="w-8 h-8" alt="game_icon"/>
                        </div>
                        <div class="flex flex-col text-4xl font-extrabold lg:flex-row lg:items-center">
                            <div class="inline-block">
                                {{ league.name }}
                                <span class="text-gray-600">{{ league.max_team_size }} v
                                        {{ league.max_team_size }}</span>
                                <span
                                    class="hidden px-3 py-2 mx-4 font-semibold text-center text-white uppercase rounded-lg lg:inline text-xxs"
                                    :class="league.platform.color">
                                    <i class="mr-1" :class="[league.platform.icon]"></i>
                                    {{ league.platform.name }}
                                </span>
                            </div>
                            <div>
                                <span
                                    class="px-3 py-1 font-semibold text-center text-white uppercase rounded-lg lg:hidden text-xxs"
                                    :class="league.platform.color">
                                    <i class="mr-1" :class="[league.platform.icon]"></i>
                                    {{ league.platform.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col mb-10 border border-gray-800 md:pt-4 md:mt-5 sm:flex-row">
            <div class="container flex flex-col justify-between px-4 py-4 mx-auto lg:flex-row gap-y-4">
                <div class="flex flex-col justify-around sm:flex-row">
                    <div id="stat-box"
                         class="flex items-center px-6 py-3 leading-tight bg-blue-900 border border-gray-800 rounded-full ">
                        <i class="mr-4 text-yellow-400 fa-solid fa-trophy"></i>
                        <div>
                            <div class="text-xs font-bold">
                                ${{ league.prize_amount }}
                            </div>
                            <div class="text-xs text-gray-600">
                                Prize Awarded
                            </div>
                        </div>
                    </div>
                    <div id="start-date-stat-box"
                         class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                        <i class="mr-4 text-blue-500 fas fa-clock"></i>
                        <div>
                            <div class="text-xs font-bold">
                                {{ formattedStartDate }} GMT
                            </div>
                            <div class="text-xs text-gray-600">
                                League Start
                            </div>
                        </div>
                    </div>
                    <div id="end-date-stat-box"
                         class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                        <i class="mr-4 text-purple-800 fas fa-calendar-check"></i>
                        <div>
                            <div class="text-xs font-bold">
                                {{ formattedEndDate }} GMT
                            </div>
                            <div class="text-xs text-gray-600">
                                League End
                            </div>
                        </div>
                    </div>
                    <div id="region-stat-box"
                         class="flex items-center px-6 py-3 mr-2 leading-tight bg-blue-900 border border-gray-800 rounded-full">
                        <i class="mr-4 text-orange-500 fas fa-globe"></i>
                        <div>
                            <div class="text-xs font-bold">
                                {{ league.region.name }}
                            </div>
                            <div class="text-xs text-gray-600">
                                League Region
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center px-8 mt-4 lg:px-0 sm:mt-0">
                    <div>
                        <div class="text-xs font-bold">
                            Teams Registered
                        </div>
                        <div class="text-2xl font-semibold text-center">
                                    <span class="text-blue-500">{{
                                            league.teams.length
                                        }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Teams -->
        <div class="container mx-auto text-center mb-12">
            <h1 class="text-3xl font-extrabold mb-6">Teams</h1>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-4 w-full">
                <div v-for="team in league.teams" :key="team.id">
                    <Link :href="route('teams.show', team.id)" class="flex flex-col items-center justify-center text-center space-y-4">
                        <img :src="team.storage_avatar" class="w-24 h-24 rounded-full" alt="team_badge">
                        <h2 class="text-center mt-2">{{ team.name }}</h2>
                    </Link>
                </div>
            </div>
        </div>


        <div class="container mx-auto">
            <Tabs>
                <Tab icon="fas fa-award mr-1" name="Standings" :selected="true">
                    <Standings :groupStandings="groupStandings" />
                </Tab>

                <Tab icon="fas fa-gamepad mr-1" name="Fixtures">
                    <Fixtures :leagueMatches="leagueMatches" :league="league"/>
                </Tab>

                <Tab icon="fas fa-info-circle mr-1 " name="Info">
                    <div id="main-area" class="flex w-full px-0 py-4">
                        <div class="flex flex-wrap w-full">

                            <div
                                class="flex flex-col items-center justify-center w-full py-16 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12 gap-y-1">
                                <div class="-mb-8 text-4xl font-bold">
                                    1st
                                </div>
                                <img src="../../../assets/gold-trophy.svg" class="w-32 h-32" alt="gold-trophy">
                                <div class="-mt-8 text-xl font-bold text-gray-400">
                                    ${{ league.prize_amount }}
                                </div>
                            </div>

                            <div
                                class="flex flex-col items-center justify-center w-full py-16 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12 gap-y-1">
                                <div class="-mb-8 text-4xl font-bold">
                                    2nd
                                </div>
                                <img src="../../../assets/silver-trophy.svg" class="w-32 h-32" alt="silver-trophy">
                                <div class="-mt-8 text-xl font-bold text-gray-400">
                                    ${{ league.second_place }}
                                </div>
                            </div>

                            <div
                                class="flex flex-col items-center justify-center w-full py-16 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12 gap-y-1">
                                <div class="-mb-8 text-4xl font-bold">
                                    3rd
                                </div>
                                <img src="../../../assets/bronze-trophy.svg" class="w-32 h-32" alt="bronze-trophy">
                                <div class="-mt-8 text-xl font-bold text-gray-400">
                                    ${{ league.third_place }}
                                </div>
                            </div>


                            <div
                                class="league-info-card ">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-globe-europe"></i>
                                </div>
                                <div class="text-xxs">
                                    Region
                                </div>
                                <div class="text-lg">
                                    {{
                                        league.region
                                            .name
                                    }}
                                </div>
                            </div>
                            <div
                                class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-gamepad"></i>
                                </div>
                                <div class="text-xxs">
                                    Game
                                </div>
                                <div class="text-lg">
                                    {{
                                        league.game.name
                                    }}
                                </div>
                            </div>
                            <div
                                class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-coins"></i>
                                </div>
                                <div class="text-xxs">
                                    Prize Pool
                                </div>
                                <div class="text-lg">
                                    ${{
                                        league.prize_amount
                                    }}
                                </div>
                            </div>
                            <div
                                class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-stopwatch"></i>
                                </div>
                                <div class="text-xxs">
                                    League Start
                                </div>
                                <div class="text-lg">
                                    {{
                                        formattedStartDate
                                    }}
                                </div>
                            </div>
                            <div
                                class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-user-ninja"></i>
                                </div>
                                <div class="text-xxs">
                                    Format
                                </div>
                                <div class="text-lg">
                                    {{ league.format }}
                                    v
                                    {{ league.format }}
                                </div>
                            </div>
                            <div
                                class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-coins"></i>
                                </div>
                                <div class="text-xxs">
                                    Entry Fee
                                </div>
                                <div class="text-lg">
                                    {{
                                        league.entry_fee
                                    }}
                                    Credits
                                </div>
                            </div>
                            <div
                                class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-hand-rock"></i>
                                </div>
                                <div class="text-xxs">
                                    Best Of
                                </div>
                                <div class="text-lg">
                                    {{ league.best_of ? league.best_of : 'N/A' }}
                                </div>
                            </div>
                            <div
                                class="league-info-card">
                                <div class="body">
                                    <i :class="league
                                                                .platform
                                                                .icon
                                                                " class="text-gray-500"></i>
                                </div>
                                <div class="text-xxs">
                                    Platform
                                </div>
                                <div class="text-lg">
                                    {{
                                        league.platform
                                            .name
                                    }}
                                </div>
                            </div>

                            <div v-if="league.crossplay_options
                                                            ">
                                <div
                                    class="league-info-card">
                                    <div class="body">
                                        <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                    </div>
                                    <div class="text-xxs">
                                        PC Players
                                    </div>
                                    <div class="text-lg">
                                        {{
                                            league.pc_players
                                                ? "Allowed"
                                                : "Disallowed"
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="league-info-card">
                                    <div class="body">
                                        <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                    </div>
                                    <div class="text-xxs">
                                        Input Type
                                    </div>
                                    <div class="text-lg">
                                        {{
                                            league.input_option
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="league-info-card">
                                    <div class="body">
                                        <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                    </div>
                                    <div class="text-xxs">
                                        PC Stream
                                        Required
                                    </div>
                                    <div class="text-lg">
                                        {{
                                            league.pc_stream_required
                                                ? "Yes"
                                                : "No"
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="league-info-card">
                                    <div class="body">
                                        <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                    </div>
                                    <div class="text-xxs">
                                        Monitorcam Required
                                    </div>
                                    <div class="text-lg">
                                        {{
                                            league.moss_required
                                                ? "Yes"
                                                : "No"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>

                <Tab icon="fas fa-clipboard-list mr-1" name="Rules">
                    <div id="main" class="px-4 py-4">
                        <div id="rules">
                            <div v-html="league.ruleset.content"></div>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    </div>
</template>

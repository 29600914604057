<script setup>

import {computed, onMounted, ref} from 'vue';
import {useForm, usePage} from "@inertiajs/vue3";
import Select from "../../../InertiaComponents/Forms/Select.vue";
import ProgressSpinner from "primevue/progressspinner";

const page = usePage();

const props = defineProps({
    'games': {
        type: Array,
        required: true
    },
    'gamingAccounts': {
        type: Object,
        required: true
    }
})

const gameOptions = computed(() => {
    return props.games.map(game => {
        return {
            label: game.name,
            value: game.slug
        };
    });
});

const form = useForm({
    gameSlug: gameOptions?.value.length > 0 ? gameOptions.value[0].value : '',
});

const selectedGame = computed(() => {
    return props.games.find(game => game.slug === form.gameSlug);
});

const teamsForGame = ref([]);

const isLoadingTeams = ref(false);

onMounted(() => {
    handleGameChange(form.gameSlug);
});

const handleGameChange = async (gameSlug) => {
    const game = props.games.find(game => game.slug === gameSlug);
    isLoadingTeams.value = true;
    const result = await fetch('/api/user/teams?gameId=' + game.id);

    const jsonResult = await result.json();

    isLoadingTeams.value = false;
    teamsForGame.value = jsonResult.data;
}

const showCreateTeamLink = computed(() => {
    return teamsForGame.value.length === 0;
});

</script>

<template>
    <div class="flex justify-center items-center w-full h-full">
        <div class="w-full lg:w-6/12 px-4 mb-6">
            <div class="bg-blue-600 text-white rounded-lg border border-gray-800 px-8 sm:px-16 py-16">
                <div id="inner" class="pb-6 border-gray-800 mb-4">

                    <div class="mb-6">
                        <div class="text-3xl font-extrabold mb-1">Challenge Up</div>
                        <div class="text-gray-400 text-sm">
                            Challenge your competitors
                        </div>
                    </div>

                    <div v-if="!gamingAccounts" class="bg-red-600 border-t-4 border-red-700 rounded-b text-white px-4 py-3 shadow-md mb-4" role="alert">
                        <p class="mb-2">
                            You have not yet configured your gaming accounts.  This will mean you cannot take part in any challenges.
                        </p>
                        <p>
                            <a :href="route('settings.gaming.index')" class="underline">Click here to configure your gaming accounts</a>
                        </p>
                    </div>

                    <p class="text-md text-gray-300 my-2">
                        What game do you want to play?
                    </p>

                    <div id="form-group">
                        <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                            <Select label="Game"
                                    :options="gameOptions"
                                    v-model="form.gameSlug"
                                    classes="w-full"
                                    @selection-change="handleGameChange"/>
                        </div>
                    </div>

                    <div class="text-center">
                        <ProgressSpinner v-if="isLoadingTeams" />
                        <button
                            v-if="!isLoadingTeams && !!teamsForGame && teamsForGame.length > 0"
                            @click="$emit('game-chosen', selectedGame)"
                            class="mt-3 btn-gradient-blue px-16 py-3 rounded uppercase text-xs w-full font-semibold text-center inline-block"
                        >
                            Continue
                        </button>
                        <div v-if="!isLoadingTeams && showCreateTeamLink">
                            <button
                                @click="$emit('game-chosen', selectedGame)"
                                class="mt-3 btn-gradient-blue px-16 py-3 rounded uppercase text-xs w-full font-semibold text-center inline-block"
                            >
                                Create team for {{ selectedGame.name }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

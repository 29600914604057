<template>
    <a :href="route('user.show', member.username)"
       class="flex w-full px-6 py-6 mb-2 bg-blue-700 rounded shadow-md min-w-max lg:w-full">
        <div class="flex items-center mr-4 w-1/4">
            <img
                class="object-cover w-10 h-10 mr-3 border-2 border-blue-900 rounded-full"
                :src="member.storage_avatar"
                :alt="`${member.username}'s avatar`">
            <div>
                <div class="text-xs font-semibold text-white">{{ member.username }}</div>
            </div>
        </div>
        <div class="flex flex-grow items-center justify-between pl-4 border-l border-gray-800">
            <div class="flex-1 text-center">
                <div class="mb-1 text-xs font-bold text-white">Socials</div>
                <template v-if="member.socials">
                    <div class="flex justify-center">
                        <i v-if="member.socials.instagram !== null"
                           class="mr-1 fab fa-instagram instagram-blue"></i>
                        <i v-if="member.socials.twitter"
                           class="mr-1 fab fa-twitter twitter-color"></i>
                        <i v-if="member.socials.facebook"
                           class="mr-1 fab fa-facebook facebook-blue"></i>
                        <i v-if="member.socials.twitch"
                           class="mr-1 fab fa-twitch twitch-purple"></i>
                        <i v-if="member.socials.discord"
                           class="mr-1 fab fa-discord discord-color"></i>
                        <i v-if="member.socials.youtube"
                           class="mr-1 fab fa-youtube youtube-color"></i>
                    </div>
                </template>
                <template v-else>
                    <div class="text-xs font-bold text-white">None</div>
                </template>
            </div>
            <div class="flex-1 text-center">
                <div class="mb-1 text-xs font-bold text-white">Earnings</div>
                <div class="text-center">
                    <span class="text-xs font-bold text-white">${{ member.total_earnings }}</span>
                </div>
            </div>
            <div class="flex-1 text-center">
                <div class="mb-1 text-xs font-bold text-white">Status</div>
                <div class="text-xs font-bold text-white">
                    <span v-if="member.elite_membership">
                        <i class="mr-1 text-orange-500 fas fa-crown"></i> Elite
                    </span> Member
                </div>
            </div>
            <div class="flex-1 text-center">
                <div class="mb-1 text-xs font-bold text-white">Role</div>
                <div class="text-xs font-bold text-white">
                    {{ capitalize(member.pivot.role) }}
                </div>
            </div>
        </div>
    </a>
</template>

<script setup>

const props = defineProps({
    member: Object
});

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

</script>

<script setup>

import { computed } from 'vue';

const props = defineProps({
    'step': {
        type: String,
        required: true,
    },
    'options': {
        type: Array,
        default: () => [{
            label: 'Signed Up',
            id: 'signed-up',
            stepNumber: 1,
        }, {
            label: 'Favourite Game',
            id: 'favourite-game',
            stepNumber: 2,
        }, {
            label: 'Gamer Tags',
            id: 'gamer-tags',
            stepNumber: 3,
        }, {
            label: 'Create Team',
            id: 'create-team',
            stepNumber: 4,
        }, {
            label: 'Add Money',
            id: 'add-money',
            stepNumber: 5,
        }, {
            label: 'Ready to Play',
            id: 'ready-to-play',
            stepNumber: 6,
        }]
    },
});

const stepNum = computed(() => {
    const step = props.step;
    const option = props.options.find(option => option.id === step)
    if (!option) {
        return -1;
    }
    return option.stepNumber;
});

</script>

<template>
    <div class="flex flex-wrap gap-4 justify-center items-center mb-6 relative text-sm">
        <template
            v-for="(option, index) in options"
            :key="option.stepNumber"
        >

            <div
                class="relative text-center"
                :class="{
                    'text-green-500': stepNum > option.stepNumber,
                    'text-blue-400': stepNum === option.stepNumber,
                    'text-gray-400': stepNum < option.stepNumber,
                }"
            >
                <div
                    class="w-6 h-6 rounded-full mx-auto flex justify-center items-center mb-1"
                    :class="{
                        'bg-green-400': stepNum > option.stepNumber,
                        'bg-gray-400': stepNum < option.stepNumber,
                        'bg-blue-500': stepNum === option.stepNumber,
                    }"
                >
                    <i v-if="stepNum > option.stepNumber" class="fa-solid fa-check text-white"></i>
                    <p
                        v-else
                        class="text-white"
                        :class="{ 'animate-pulse': step === option.stepNumber }"
                    >
                        {{ option.stepNumber }}
                    </p>
                </div>

                <p class="hidden md:block">
                    {{ option.label }}
                </p>
            </div>

            <i
                v-if="index < options.length - 1"
                class="fa-solid fa-chevron-right"
                :class="{ 'text-green-500': step > option.stepNumber, 'text-gray-400': step <= option.stepNumber }"
            >
            </i>
        </template>
    </div>
</template>

<style scoped>

</style>

<script setup>
import {ref, computed} from 'vue';
import {usePage} from '@inertiajs/vue3';
import {format} from 'date-fns';
import Breadcrumbs from "../../../InertiaComponents/Navigation/Breadcrumbs.vue";
import MatchTeamInfo from "../../../InertiaComponents/Matches/TeamInfo.vue";
import TournamentMatchChatContainer from '../../../components/TournamentMatchChatContainer.vue';

const {props} = usePage();
const match = computed(() => props.match);
const tournament = computed(() => props.tournament);
const auth = computed(() => props.auth);
const mapsExpanded = ref(false);

const sortedMaps = computed(() => {
    return match.value.mapset.maps.slice().sort((a, b) => a.pivot.order - b.pivot.order);
});

const formattedDate = computed(() => format(new Date(match.value.start_date), 'dd/MM/yyyy HH:mm'));
const formattedNoShowDate = computed(() => match.value.no_show_date ? format(new Date(match.value.no_show_date), 'dd/MM/yyyy HH:mm') : null);

const matchResult = computed(() => {
    if (!match.value.winner_id) {
        return 'Pending';
    }
    return {
        teamOneResult: ((match.value.winner_id === match.value.team_one_id) && (match.value.team_one.name !== 'BYE')) ? 'W' : 'L',
        teamTwoResult: match.value.winner_id === match.value.team_two_id ? 'W' : 'L',
        teamOneClass: match.value.winner_id === match.value.team_one_id ? 'text-green-500' : 'text-red-500',
        teamTwoClass: match.value.winner_id === match.value.team_two_id ? 'text-green-500' : 'text-red-500'
    };
});


</script>

<template>
    <div>
        <!-- Background Image -->
        <div id="bg" class="game-header-img z-1" :style="{
                background:
                    'url(\'' + tournament.game.storage_header_url + '\')'
            }"></div>

        <!-- Breadcrumbs -->
        <div id="main" class="relative py-2 text-white">
            <div class="container mx-auto">
                <Breadcrumbs :breadcrumbs="[
                    {name: 'Home', link: '/'},
                    {name: 'Tournaments', link: '/tournaments'},
                    {name: tournament.name, link: route('tournaments.show', tournament.slug)},
                    {name: 'Match'},
                ]"/>
            </div>
        </div>

        <!-- Match Details -->
        <div class="container relative mx-auto px-4 py-8">
            <div class="match-details flex flex-col md:flex-row justify-between mb-8">
                <!-- Team One Info -->
                <MatchTeamInfo :team="match.team_one" :lineups="match.team_one.members"/>

                <!-- Central Section -->
                <div class="central-section mx-auto">
                    <div class="text-6xl font-bold">
                        <span v-if="matchResult === 'Pending'" class="text-white">{{ matchResult }}</span>
                        <template v-else>
                            <span :class="matchResult.teamOneClass">{{ matchResult.teamOneResult }}</span>
                            :
                            <span :class="matchResult.teamTwoClass">{{ matchResult.teamTwoResult }}</span>
                        </template>
                    </div>
                    <div id="start-date-stat-box"
                         class="flex items-center px-6 py-3 mx-2 leading-tight">
                        <i class="mr-4 text-blue-400 fas fa-clock text-2xl"></i>
                        <div>
                            <div class="font-bold">
                                Match Date: {{ formattedDate }}
                            </div>
                            <div class="text-xs text-gray-400">
                                GMT
                            </div>
                        </div>

                    </div>
                    <div class="mx-auto">
                        <div v-if="!!match.no_show_date" class="text-xs text-red-400">
                            No Show Date: {{ formattedNoShowDate }}
                        </div>
                        <div v-if="!!match.disputing_team">
                            <div class="font-bold">
                                Dispute Team: {{ match.disputing_team.name }}
                            </div>
                            <div class="text-xs text-gray-400">
                                {{ match.dispute_status }}
                            </div>
                        </div>
                        <div>
                            Match ID: {{ match.id }}
                        </div>
                        <a :href="route('ruleset.show', tournament.ruleset_id)"
                           class="flex items-center justify-center w-full px-4 py-2 mt-2 mb-2 text-xs font-semibold text-center text-white bg-gray-600 rounded"><i
                            class="mr-2 fas fa-info-circle"></i>Rules
                        </a>
                    </div>
                    <div class="flex flex-col w-full gap-3">

                        <!-- Chat Component -->
                        <div class="chat-section w-full mx-2">
                            <TournamentMatchChatContainer :match="match.id" class=""></TournamentMatchChatContainer>
                        </div>

                        <!-- Maps -->
                        <div class="w-full bg-blue-900">
                            <!-- Header with Chevron -->
                            <div class="flex justify-between items-center p-2 cursor-pointer"
                                 @click="mapsExpanded = !mapsExpanded">
                                <h2 class="m-2">Mapset:</h2>
                                <!-- Chevron Icon -->
                                <svg :class="{'transform rotate-180': mapsExpanded}" xmlns="http://www.w3.org/2000/svg"
                                     class="h-5 w-5 transition-transform duration-200" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fill-rule="evenodd"
                                          d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clip-rule="evenodd"/>
                                </svg>
                            </div>
                            <!-- Maps Section -->
                            <div v-if="mapsExpanded">
                                <div v-for="(map, index) in sortedMaps" :key="map.id"
                                     :style="{ background: `url(${map.storage_asset})`, backgroundSize: 'cover' }"
                                     class="w-full p-2">
                                    <div class="w-full">
                                        <!-- Map Number -->
                                        <div class="text-xs">MAP: {{ index + 1 }}</div>
                                        <!-- Map Name -->
                                        <div>{{ map.name }}</div>
                                        <!-- Host Information -->
                                        <div>
                                            <template v-if="match.team_two">
                                                Host:
                                                <template v-if="index === match.mapset.maps.length - 1">
                                                    <!-- Last Map Logic -->
                                                    <template v-if="match.tournament.best_of === 1">
                                                        {{ match.team_one?.name || 'Disbanded' }}
                                                    </template>
                                                    <template v-else-if="match.tournament.game_mode.last_map_tbd">
                                                        TBD
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="index % 2 === 0">
                                                            {{ match.team_one?.name || 'Disbanded' }}
                                                        </template>
                                                        <template v-else>
                                                            {{ match.team_two?.name || 'Disbanded' }}
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <!-- Regular Maps Logic -->
                                                    <template v-if="index % 2 === 0">
                                                        {{ match.team_one?.name || 'Disbanded' }}
                                                    </template>
                                                    <template v-else>
                                                        {{ match.team_two?.name || 'Disbanded' }}
                                                    </template>
                                                </template>
                                            </template>
                                            <template v-else>
                                                Host: <b>TBD</b>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- Team Two Info -->
                <MatchTeamInfo :team="match.team_two" :lineups="match.team_two.members"/>
            </div>
        </div>
    </div>
</template>

<style scoped>
.central-section {
    @apply flex flex-col items-center w-full mb-4 md:mb-0 m-6;
}
</style>

<template>
    <div class="container mx-auto">
        <div class="flex items-center justify-center w-full h-full">
            <div class="w-full px-4 mb-6 lg:w-6/12">
                <form @submit.prevent="submitForm" class="w-full">
                    <div class="p-12 text-white bg-blue-600 border border-gray-800 rounded-lg lg:p-16">
                        <div id="inner" class="pb-6 mb-4 border-gray-800">
                            <div class="mb-6">
                                <div class="mb-1 text-4xl font-extrabold">Game record reset</div>
                                <div class="text-sm text-gray-600">
                                    Reset your game records for a specific game
                                </div>
                            </div>

                            <div id="form-group">
                                <div class="py-1 text-xs text-gray-600">Game</div>
                                <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                    <select v-model="form.user_game_record_id"
                                            class="block w-full px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white bg-blue-900 border border-gray-800 rounded appearance-none form-select focus:outline-none">
                                        <option value="">Select a game record</option>
                                        <option v-for="userGameRecord in userGameRecords" :key="userGameRecord.id" :value="userGameRecord.id">
                                            {{ userGameRecord.game_name }} ({{ userGameRecord.wins }} wins, {{ userGameRecord.losses }} losses)
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <button v-if="parseFloat(user.balance) >= parseFloat(price)"
                                    type="submit"
                                    @click="confirmReset"
                                    class="inline-block w-full px-16 py-3 mt-3 text-xs font-semibold text-center uppercase bg-red-500 rounded">
                                Reset game record (${{ price }})
                            </button>
                            <div v-else>
                                <p class="mb-2 text-red-500 font-semibold">
                                    You do not have sufficient funds to buy - add money?
                                </p>
                                <a :href="route('bank.deposit.create', { m: 'dc' })"
                                              class="btn-gradient-blue px-16 py-3 rounded uppercase font-medium text-xs w-full font-semibold text-center inline-block">
                                    Add Money
                                </a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { usePage, useForm } from '@inertiajs/vue3';

const { props } = usePage();
const user = props.auth.user;
const userGameRecords = ref([...props.userGameRecords]);
const price = ref(props.price || 0);

const form = useForm({
    user_game_record_id: ''
});

const confirmReset = () => {
    return confirm('Are you sure you want to reset the game record? This can not be undone!');
};

const successMessage = ref('');
const errorMessage = ref('');

const submitForm = () => {
    form.post(route('store.game-record-reset.store'), {
        onSuccess: (page) => {
            successMessage.value = 'Successfully reset the game record';
            errorMessage.value = '';
            userGameRecords.value = page.props.userGameRecords;
        },
        onError: (err) => {
            errorMessage.value = 'Failed to reset the game record';
            successMessage.value = '';
        }
    });
};
</script>

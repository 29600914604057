<script setup>

import {ref} from "vue";
import {format} from "date-fns/format";
import {formatDistance, formatDistanceToNow} from "date-fns";
import {Link} from "@inertiajs/vue3";
import TournamentTopPrizeLabel from "./TopPrizeLabel.vue";
import TournamentCreditFeeLabel from "./CreditFeeLabel.vue";
import TournamentBasicDetailsLabel from "./BasicDetailsLabel.vue";
import pluralize from "pluralize";
import {numberWithCommas} from "../../../Services/utils.js";

const props = defineProps(['tournament', 'club']);
const tournament = ref(props.tournament);

</script>

<template>
    <Link :href="route('club-tournaments.show', {tournament: tournament.id, club: club})"
       class="px-2 py-2 overflow-hidden transition duration-200 ease-in-out transform hover:scale-105 w-full flex flex-col justify-between">

        <div class="elite-banner top-24" v-if="!!tournament.elite_only">
            Elite Only
        </div>

        <div class="relative text-white bg-blue-600 rounded-lg overflow-hidden shadow-xl flex-1 flex flex-col justify-between">
            <div class="relative flex flex-col justify-between flex-1">
                <div class="relative rounded-t-lg">
                    <img
                        :src="tournament.game.storage_background_url"
                        class="w-full object-contain"
                        :alt="`${tournament.game.name}'s avatar`">

<!--                    <div class="absolute flex items-center font-semibold text-center bg-blue-800 rounded-md w-20 overflow-hidden shadow-lg"-->
<!--                         style="top:15px;left:15px">-->
<!--                        <img class="mx-auto object-cover" :src="tournament.game.storage_icon_url" :alt="`${tournament.game.name} Icon`">-->
<!--                    </div>-->

                    <div
                        v-if="!!tournament.elite_only"
                        class="absolute top-2 left-2 bg-blue-800 text-xxs flex items-center px-1 py-1 font-semibold text-center rounded-full bg-opacity-90">
                        <img class="w-8 h-8 mx-auto object-cover" src="/images/elite.svg" alt="Elite logo">
                    </div>

                    <div
                        class="absolute font-semibold text-right"
                        :class="{'right-4': !!tournament.elite_only, 'top-3': !!tournament.elite_only, 'top-2': !tournament.elite_only, 'right-2': !tournament.elite_only}"
                    >
                        <div
                            class="inline-flex items-center px-2 py-1 rounded-lg shadow-lg mb-3 sm:mb-4"
                            :class="`${tournament.platform.color}`"
                        >
                            <i :class="`text-lg sm:text-2xl ${tournament.platform.icon}`"></i>
                        </div>

                        <TournamentTopPrizeLabel :tournament="tournament" classes="md:hidden" />
                    </div>

                    <div class="absolute bottom-2 left-2">
                        <TournamentBasicDetailsLabel :tournament="tournament" classes="md:hidden" />
                        <TournamentCreditFeeLabel :tournament="tournament" classes="hidden md:inline-flex" />
                    </div>

                    <div class="absolute bottom-2 font-semibold"
                         :class="{'right-4': !!tournament.elite_only, 'right-2': !tournament.elite_only}"
                    >
                        <TournamentCreditFeeLabel :tournament="tournament" classes="md:hidden" />
                        <TournamentTopPrizeLabel :tournament="tournament" classes="hidden md:block" />
                    </div>
                </div>

                <div class="px-4 flex flex-col justify-around flex-1">
                    <div class="flex justify-between mb-1 font-bold text-gray-400 uppercase text-xxs">
                        <div>
                            <div v-if="tournament.finished && tournament.bracket_generated">
                                {{ format(tournament.tournament_starts, 'MMM d, h:mm bbb') }} -
                                <span class="text-blue-300">
                                    Complete
                                </span>
                            </div>

                            <div v-if="!tournament.finished && tournament.bracket_generated">
                                {{ format(tournament.tournament_starts, 'MMM d, h:mm bbb') }} -
                                <span class="text-blue-300">
                                    In Progress
                                </span>
                            </div>
                            <div v-if="!tournament.finished && !tournament.bracket_generated">
                                {{ format(tournament.tournament_starts, 'MMM d, h:mm bbb') }} -
                                <span class="text-blue-300">
                                    {{ formatDistanceToNow(tournament.tournament_starts, { addSuffix: true }) }}
                                </span>
                            </div>
                        </div>
                        <div class="flex">
                            <span class="ml-3">{{ tournament.region.name }}</span>
<!--                            <img class="ml-1 -mt-1" :src="tournament.region.icon" alt="">-->
                        </div>
                    </div>
                    <div class="md:text-left m-1">
                        <div class="text-md font-semibold text-white"> {{ tournament.name }}</div>
                    </div>
                    <div class="flex justify-between">
                        <div>
                            <div class="mb-1 font-bold text-gray-400 text-xxs">Prize Pool</div>
                            <div class="text-xs font-bold"><i class="mr-1 text-orange-500 fa-solid fa-trophy"></i> $
                                {{ numberWithCommas(tournament.total_prize_pool) }}</div>
                        </div>
                        <div>
                            <div class="mb-1 font-bold text-gray-400 text-xxs">Team Size</div>
                            <div class="text-xs font-bold"><i class="mr-1 text-indigo-600 fas fa-dice"></i>
                                {{ tournament.max_team_size }} v {{ tournament.max_team_size }}</div>
                        </div>
                        <div>
                            <div class="mb-1 font-bold text-gray-400 text-xxs">Entry Fee</div>
                            <div class="text-xs font-bold">
                                <i class="mr-1 text-green-bright fas fa-coins"></i>

                                <span v-if="tournament.entry_fee > 0">
                                    {{ tournament.entry_fee }} {{ pluralize('Credit', tournament.entry_fee) }}
                                </span>
                                <span v-else>
                                    Free Entry
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-row px-4 py-2 " style="min-height: 56px;">
                <div class="flex -space-x-3">
                    <img
                        v-for="team in tournament.teams.slice(0, 5)"
                        class="w-10 h-10 border-4 border-blue-700 rounded-full object-contain"
                        :src="team.storage_avatar"
                        :alt="`${team.name}'s avatar`"
                    >
                </div>
                <div class="flex items-center ml-8 font-bold text-gray-400 text-xxs">
                    {{ tournament.total_registered_teams }} Registered</div>
            </div>
        </div>
    </Link>
</template>

<style scoped>
.organizer-avatar {
    @apply w-12 h-12 rounded-full mr-2;
}
</style>

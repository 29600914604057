<script setup>

import {formatDistanceToNowStrict} from "date-fns";
import {Link, useForm} from '@inertiajs/vue3'

const props = defineProps({
    friendRequest: {
        type: Object,
        required: true
    }
})

const form = useForm({

});

function acceptFriendRequest() {
    form.post(route('friendrequest.accept', {'user': props.friendRequest.inviter.slug}));
}

function rejectFriendRequest() {
    form.post(route('friendrequest.reject', {'user': props.friendRequest.inviter.slug}));
}

</script>

<template>
    <div class="p-4 hover:bg-blue-600 rounded-lg flex flex-col xl:flex-row gap-2 items-center justify-between">
        <Link class="flex items-center gap-4 flex-1 truncate"
              :href="route('user.show', {user: friendRequest.inviter})"
        >
            <img :src="friendRequest.inviter.storage_avatar" class="w-12 h-12 rounded-full" alt="Profile Picture">
            <div>
                <p class="text-xs uppercase text-orange-400 font-bold">Friend Request</p>
                <h3 class="font-semibold text-lg inline-block border-b-2 border-blue-500">{{
                        friendRequest.inviter.username
                    }}</h3>
<!--                <p class="text-gray-500">{{ friendRequest.inviter.email }}</p>-->
                <div class="text-xs text-gray-500 font-semibold">Account created
                    {{ formatDistanceToNowStrict(new Date(friendRequest.created_at), {addSuffix: true}) }}
                </div>
            </div>
        </Link>
        <div class="flex xl:flex-col items-center justify-center gap-2 text-sm">
            <form @submit.prevent="acceptFriendRequest" class="w-full">
                <button class="bg-blue-500 text-white p-4 rounded-lg flex-1 w-full flex items-center justify-center gap-1">
                    <i class="fas fa-handshake text-lg"></i>
                    Accept
                </button>
            </form>

            <form @submit.prevent="rejectFriendRequest" class="w-full">
                <button class="bg-red-600 text-white p-4 rounded-lg flex-1 w-full flex items-center justify-center gap-1">
                    <i class="fas fa-trash text-lg"></i>
                    Reject
                </button>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>

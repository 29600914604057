<script setup>
import { onMounted } from 'vue';
import { useForm, Link } from '@inertiajs/vue3';

// Initialize the form using Inertia's useForm
const form = useForm({
    username: '',
    password: '',
    remember: false,
});

// Autofocus the username input when the component is mounted
onMounted(() => {
    document.getElementById('username')?.focus();
});

// Handle form submission
const handleSubmit = () => {
    form.post(route('login'), {
        onFinish: () => {
            if (!form.hasErrors) {
                form.reset('password');
            }
        },
    });
};


// Access global properties
const appName = import.meta.env.VITE_APP_NAME || 'YourAppName';
</script>

<template>
    <div class="container mx-auto">
        <div class="flex justify-center">
            <div class="px-4 mb-6 w-full sm:w-8/12 md:w-6/12 lg:max-w-2xl">
                <div class="px-4 py-4 xl:px-8 xl:py-16 text-white bg-blue-600 border border-gray-800 rounded-lg">
                    <div id="inner" class="pb-6 mb-4 border-b-2 border-gray-800 text-center">
                        <form @submit.prevent="handleSubmit">
                            <div class="mb-6">
                                <h1 class="mb-2 text-3xl font-extrabold">Sign In</h1>
                            </div>

                            <!-- Username Field -->
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <div class="flex -mr-px leading-tight">
                  <span
                      class="bg-blue-800 flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-800 px-4 whitespace-no-wrap text-gray-300 text-sm"
                      :class="{ 'border-red-500': form.errors.username }"
                  >
                    <i class="fas fa-user-circle"></i>
                  </span>
                                </div>
                                <input
                                    id="username"
                                    v-model="form.username"
                                    name="username"
                                    placeholder="Email or Username"
                                    type="text"
                                    class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-sm font-semibold py-5 px-4 pr-8 border-l-0 rounded leading-tight focus:outline-none focus:border-blue-500"
                                    :class="{ 'border-red-500': form.errors.username }"
                                />
                            </div>
                            <div v-if="form.errors.username" class="-mt-2 text-red-500 text-sm mb-2">
                                {{ form.errors.username }}
                            </div>

                            <!-- Password Field -->
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <div class="flex -mr-px leading-tight">
                  <span
                      class="bg-blue-800 flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-800 px-4 whitespace-no-wrap text-gray-300 text-sm"
                      :class="{ 'border-red-500': form.errors.password }"
                  >
                    <i class="fas fa-lock"></i>
                  </span>
                                </div>
                                <input
                                    type="password"
                                    v-model="form.password"
                                    name="password"
                                    placeholder="Password"
                                    class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-sm font-semibold py-5 px-4 pr-8 border-l-0 rounded leading-tight focus:outline-none focus:border-blue-500"
                                    :class="{ 'border-red-500': form.errors.password }"
                                />
                            </div>
                            <div v-if="form.errors.password" class="-mt-2 text-red-500 text-sm mb-2">
                                {{ form.errors.password }}
                            </div>

                            <!-- Remember Me -->
                            <div class="relative flex flex-wrap items-stretch w-full mt-6 mb-0 justify-center">
                                <label class="flex items-center">
                                    <div class="flex items-center justify-center w-6 h-6 p-1 mr-2 bg-gray-200">
                                        <input type="checkbox" v-model="form.remember" class="!appearance-none" />
                                    </div>
                                    <span class="text-sm text-gray-500">Remember me?</span>
                                </label>
                            </div>

                            <!-- Submit Button -->
                            <button
                                type="submit"
                                class="inline-block w-full px-16 py-3 mt-6 text-xs font-medium font-semibold text-center uppercase rounded btn-gradient-blue"
                            >
                                <span>Sign In</span>
                            </button>
                        </form>

                        <!-- Links -->
                        <div class="mt-4 text-sm font-semibold text-center text-gray-600">
                            Don't have an account? <Link href="/register" class="text-blue-500">Sign Up</Link>
                        </div>

                        <div class="mt-4 text-sm font-semibold text-center text-gray-600">
                            Forgotten your details? <Link href="/password/reset" class="text-blue-500">Reset Password</Link>
                        </div>
                    </div>

                    <!-- Footer -->
                    <div id="footer" class="font-semibold text-center text-gray-600 text-xs">
                        By registering you agree to {{ appName }}'s
                        <Link href="#" class="italic text-blue-500">Terms & Conditions</Link> and
                        <Link href="{{ route('privacy-policy.index') }}" class="italic text-blue-500">Privacy Policy</Link>.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

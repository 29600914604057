<script setup>
import { usePage } from "@inertiajs/vue3";
import Tabs from "../../components/partials/Tabs.vue";
import Tab from "../../components/partials/Tab.vue";
import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import Fixtures from "../../InertiaComponents/Leagues/Fixtures.vue";
import Standings from "../../InertiaComponents/Leagues/Standings.vue";
import LeagueRegisterTeamModal from "../../components/Leagues/LeagueRegisterTeamModal.vue";
import { computed } from "vue";
import { format } from "date-fns";

const props = usePage().props;
const league = props.league;
const groupStandings = props.groupStandings;
const leagueMatches = league.matches;

const formattedStartDate = computed(() => format(new Date(league.start_date), 'do LLL yyyy'));
const formattedPlayoffsDate = computed(() => format(new Date(league.playoffs_date), 'do LLL yyyy'));

</script>

<style scoped>

tr:nth-child(even) {
    @apply bg-gray-800;
}

tr:nth-child(odd) {
    @apply bg-gray-900;
}

th {
    @apply bg-blue-600 px-6 pt-2 pb-2 text-left border-b border-gray-700;
}

.league-info-card {
    @apply w-full px-8 py-4 mb-1 font-semibold bg-blue-900 border border-blue-600 rounded-lg sm:w-3/12;
}

.league-info-card body {
    @apply pt-1 text-xl text-gray-500;
}

.header-image {
    aspect-ratio: 9/4;    
}


.prose * {
    @apply text-gray-200;
}


</style>

<template>

    <section class="container mx-auto px-4">
        <img :src="league.game.storage_header_url" alt="" class="w-full object-cover header-image">

        <div class="py-8 md:px-8">
            <div class="flex flex-wrap justify-between gap-8 md:gap-16 border-b border-gray-800">
                <h1 class="text-3xl md:text-4xl font-semibold md:py-8 pr-16 border-r border-gray-800 md:w-3/5">{{ league.name }}</h1>
                <div class="flex flex-wrap gap-4 flex-grow justify-between items-center">
                    <div class="flex gap-6">
                        <!-- TODO: Add ability to include social in League Generation -->
                        <!-- Socials -->
                        <!-- <a href="#info"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                            <i class="fab fa-facebook"></i>
                        </a>
                        <a href="#info"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                            <i class="fab fa-discord"></i>
                        </a>
                        <a href="#info"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue-500 text-3xl hover:scale-105 transition-transform">
                            <i class="fab fa-twitter"></i>
                        </a> -->
                    </div>  
                    <!-- <button @click.prevent=""
                        class="transition duration-300 ease-in-out transform hover:scale-105 inline-flex items-center btn-gradient-blue text-white font-semibold text-sm px-8 py-3 rounded-full cursor-pointer">
                        Register
                    </button>  -->
                </div>
            </div>

            <Tabs class="mt-8 md:mt-0">
                <Tab icon="fas fa-info-circle mr-1" name="Info" href="#info">
                    <div class="grid md:grid-cols-2 gap-8 mt-6 md:mt-12">
                        <!-- Left side -->
                        <div class="league-info prose prose-strong:text-gray-200 px-0 py-4 md:pt-0" v-html="league.info">
                        </div>
                        <!-- Right Side -->
                        <div class="flex flex-col pt-6 border-t border-gray-700 md:pt-0 md:border-0">
                        <dl class="grid sm:grid-cols-2 gap-y-8 xl:grid-cols-4 items-start font-semibold xl:divide-x divide-gray-700 md:text-center">
                            <div class="h-full justify-end align-center flex flex-col-reverse gap-2 uppercase tracking-tighter sm:px-4">
                                <dt class="text-blue-500">Group play start date</dt>
                                <dd>{{ formattedStartDate }}</dd>
                            </div>
                            <div class="h-full justify-end align-center flex flex-col-reverse gap-2 uppercase tracking-tighter sm:px-4">
                                <dt class="text-blue-500">Playoff start date</dt>
                                <dd>{{ formattedPlayoffsDate }}</dd>
                            </div>
                            <div class="h-full justify-end align-center flex flex-col-reverse gap-2 uppercase tracking-tighter sm:px-4">
                                <dt class="text-blue-500">Prize pool</dt>
                                <dd>${{ league.prize_amount }}</dd>
                            </div>
                            <div class="h-full justify-end align-center flex flex-col-reverse gap-2 uppercase tracking-tighter sm:px-4">
                                <dt class="text-blue-500">Format</dt>
                                <dd>{{ league.format }} v {{ league.format }}</dd>
                            </div>
                        </dl>

                        </div>
                    </div>
                    <div id="main-area" class="flex w-full px-0 py-4 mt-8">
                        <div class="flex flex-wrap w-full">
                            <div
                                class="flex flex-col items-center justify-center w-full py-16 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12 gap-y-1">
                                <div class="-mb-8 text-4xl font-bold">
                                    1st
                                </div>
                                <img src="../../../assets/gold-trophy.svg" class="w-32 h-32" alt="gold-trophy">
                                <div class="-mt-8 text-xl font-bold text-gray-400">
                                    ${{ league.prize_amount }}
                                </div>
                            </div>

                            <div
                                class="flex flex-col items-center justify-center w-full py-16 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12 gap-y-1">
                                <div class="-mb-8 text-4xl font-bold">
                                    2nd
                                </div>
                                <img src="../../../assets/silver-trophy.svg" class="w-32 h-32" alt="silver-trophy">
                                <div class="-mt-8 text-xl font-bold text-gray-400">
                                    ${{ league.second_place }}
                                </div>
                            </div>

                            <div
                                class="flex flex-col items-center justify-center w-full py-16 mb-2 bg-blue-900 border border-blue-600 rounded-lg sm:w-4/12 gap-y-1">
                                <div class="-mb-8 text-4xl font-bold">
                                    3rd
                                </div>
                                <img src="../../../assets/bronze-trophy.svg" class="w-32 h-32" alt="bronze-trophy">
                                <div class="-mt-8 text-xl font-bold text-gray-400">
                                    ${{ league.third_place }}
                                </div>
                            </div>

                            <div class="league-info-card ">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-globe-europe"></i>
                                </div>
                                <div class="text-xxs">
                                    Region
                                </div>
                                <div class="text-lg">
                                    {{ league.region.name }}
                                </div>
                            </div>

                            <div class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-gamepad"></i>
                                </div>
                                <div class="text-xxs">
                                    Game
                                </div>
                                <div class="text-lg">
                                    {{ league.game.name }}
                                </div>
                            </div>

                            <div class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-coins"></i>
                                </div>
                                <div class="text-xxs">
                                    Prize Pool
                                </div>
                                <div class="text-lg">
                                    ${{ league.prize_amount }}
                                </div>
                            </div>

                            <div class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-stopwatch"></i>
                                </div>
                                <div class="text-xxs">
                                    League Start
                                </div>
                                <div class="text-lg">
                                    {{ formattedStartDate }}
                                </div>
                            </div>

                            <div class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-user-ninja"></i>
                                </div>
                                <div class="text-xxs">
                                    Format
                                </div>
                                <div class="text-lg">
                                    {{ league.format }}
                                    v
                                    {{ league.format }}
                                </div>
                            </div>

                            <div class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-coins"></i>
                                </div>
                                <div class="text-xxs">
                                    Entry Fee
                                </div>
                                <div class="text-lg">
                                    {{ league.entry_fee }}
                                    Credits
                                </div>
                            </div>

                            <div class="league-info-card">
                                <div class="body">
                                    <i class="text-gray-500 fas fa-hand-rock"></i>
                                </div>
                                <div class="text-xxs">
                                    Best Of
                                </div>
                                <div class="text-lg">
                                    {{ league.best_of ? league.best_of : 'N/A' }}
                                </div>
                            </div>

                            <div class="league-info-card">
                                <div class="body">
                                    <i :class="league.platform.icon" class="text-gray-500"></i>
                                </div>
                                <div class="text-xxs">
                                    Platform
                                </div>
                                <div class="text-lg">
                                    {{ league.platform.name }}
                                </div>
                            </div>

                            <div v-if="league.crossplay_options">
                                <div class="league-info-card">
                                    <div class="body">
                                        <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                    </div>
                                    <div class="text-xxs">
                                        PC Players
                                    </div>
                                    <div class="text-lg">
                                        {{ league.pc_players ? "Allowed" : "Disallowed"
                                        }}
                                    </div>
                                </div>

                                <div class="league-info-card">
                                    <div class="body">
                                        <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                    </div>
                                    <div class="text-xxs">
                                        Input Type
                                    </div>
                                    <div class="text-lg">
                                        {{ league.input_option }}
                                    </div>
                                </div>

                                <div class="league-info-card">
                                    <div class="body">
                                        <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                    </div>
                                    <div class="text-xxs">
                                        PC Stream
                                        Required
                                    </div>
                                    <div class="text-lg">
                                        {{ league.pc_stream_required ? "Yes" : "No" }}
                                    </div>
                                </div>

                                <div class="league-info-card">
                                    <div class="body">
                                        <i class="text-gray-500 fa-solid fa-shuffle"></i>
                                    </div>
                                    <div class="text-xxs">
                                        Monitorcam Required
                                    </div>
                                    <div class="text-lg">
                                        {{ league.moss_required ? "Yes" : "No" }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab icon="fas fa-award mr-1" name="Standings" href="#standings">
                    <Standings :groupStandings="groupStandings" />
                </Tab>

                <Tab icon="fas fa-gamepad mr-1" name="Fixtures" href="#fixtures">
                    <Fixtures :leagueMatches="leagueMatches" :league="league"/>
                </Tab>

                <Tab icon="fas fa-clipboard-list mr-1" name="Rules" href="#rules">
                    <div id="main" class="mt-6 md:mt-12">
                        <div id="rules">
                            <div v-html="league.ruleset.content"></div>
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    </section>
  
</template>
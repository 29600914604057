<template>
    <div id="main" class="text-white py-4 px-4 sm:px-0">
        <div class="container mx-auto">
            <div id="breadcrumbs" class="text-xs">
                Home <span class="ml-1 mr-1 text-gray-600 font-extrabold">></span> <span class="ml-1 mr-1 text-gray-600">Teams</span>
            </div>
            <div class="flex justify-between items-center mb-4">
                <div class="text-4xl font-bold">Team Hub</div>
                <a :href="route('teams.create')" class="btn-gradient-blue px-6 py-3 rounded-lg font-medium text-xs font-semibold">
                    <i aria-hidden="true" class="text-sm mr-4 fas fa-fist-raised"></i>Create Team
                </a>
            </div>

            <div class="mx-0 sm:-mx-4 mb-12">
                <div v-if="Object.keys(games).length">
                    <div v-for="(teams, game) in games" :key="game">
                        <div class="text-2xl font-bold mb-2">{{ game }}</div>
                        <div class="flex flex-wrap">
                            <TeamCard v-for="team in teams" :key="team.id" :team="team" />
                        </div>
                    </div>
                </div>
                <div v-else class="px-5 text-center">
                    <p class="text-lg my-4">You aren't in a Team yet - create one?</p>
                    <a :href="route('teams.create')" class="btn-gradient-blue px-6 py-3 rounded-lg font-medium text-xs font-semibold">
                        <i aria-hidden="true" class="text-sm mr-4 fas fa-fist-raised"></i>Create Team
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import TeamCard from '../../components/TeamCard.vue';

const { props } = usePage();
const games = props.games || {};
</script>

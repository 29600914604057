<template>
    <div :class="['w-full', 'md:w-5/12', 'lg:w-4/12', 'px-4', 'mt-3']">
        <div class="bg-blue-800">
            <div class="bg-blue-600 rounded-t-lg px-2 pt-3 pb-2 lg:p-6">
                <div v-if="membership.image">
                    <img :src="membership.image" alt="Membership logo" class="w-32 mx-auto" />
                </div>
                <div v-else>
                    <div class="text-center w-32 h-32 mx-auto">
                        <i :class="['mx-auto', membership.icon, 'text-8xl', 'text-center', 'mt-6']"></i>
                    </div>
                </div>
                <p class="text-center text-lg leading-7 font-semibold font-display text-blue-500 uppercase tracking-widest">
                    {{ membership.name }}
                </p>
                <div class="mt-4 flex items-center justify-center font-display">
                    <span class="px-3 flex items-start text-6xl leading-none tracking-tight font-medium text-white sm:text-7xl">
                        <span class="mt-2 mr-1 text-4xl leading-none sm:text-5xl">
                            $
                        </span>
                        <span>
                            {{ membershipPrice }}
                        </span>
                    </span>
                    <span class="text-2xl leading-8 font-semibold text-gray-400 tracking-wide sm:text-3xl sm:leading-9">
                        USD
                    </span>
                </div>

                <div v-if="!membership.price">
                    <div class="text-center text-lg text-blue-400 py-1">
                        Each Month
                        <span v-if="!!selectedMembershipType && subscriptionPercentDiscount(selectedMembershipType) > 0">
                            (Save <strong>{{ subscriptionPercentDiscount(selectedMembershipType) }}%</strong>)
                        </span>
                    </div>
<!--                    <div class="text-center text-lg text-blue-400 py-1">-->
<!--                        Save <strong>{{ subscriptionPercentDiscount(selectedMembershipType) }}%</strong> with this plan-->
<!--                    </div>-->
                </div>
                <div v-else-if="membership.price === '0'">
                    <div class="text-center text-lg text-blue-400 py-1">
                        Free
                    </div>
                </div>
            </div>
            <div class="border-t-2 border-gray-100 rounded-b-lg px-5 pt-4 pb-3 lg:p-6 bg-gray-50">
                <ul class="flex flex-col justify-center gap-2">
                    <li v-for="(benefit, index) in membership.benefits" :key="index" class="flex flex-col items-start">
                        <div class="flex items-center">
                            <svg class="h-6 w-6 text-blue-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                {{ benefit }}
                            </p>
                        </div>
                        <div v-if="benefit === 'Everything from Free Membership'" class="w-full flex items-center mt-2">
                            <div class="flex-grow border-t border-gray-400"></div>
                            <span class="flex-shrink mx-4 text-gray-500 uppercase">And</span>
                            <div class="flex-grow border-t border-gray-400"></div>
                        </div>
                    </li>
                </ul>
                <div class="mt-2 lg:mt-6" v-if="membership.membershipTypes">
                    <div id="form-group">
                        <div class="text-xs text-gray-600 py-1">Membership type</div>
                        <div class="flex flex-wrap items-stretch w-full relative">
                            <form :action="route('elite.checkout')" method="GET" class="w-full">
                                <select
                                    name="membership_type"
                                    v-model="selectedMembershipTypeId"
                                    class="form-select block appearance-none w-full bg-blue-900 border border-gray-800 text-white text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none"
                                >
                                    <option v-for="type in membership.membershipTypes" :key="type.id" :value="type.id">
                                        {{ type.subscription_months }} Month Membership (${{ type.price }}) {{ subscriptionPercentDiscount(type) > 0 ? ` - Save ${subscriptionPercentDiscount(type)}%` : ''}}
                                    </option>
                                </select>

                                <div class="mt-4">
                                    <template v-if="isAuthenticated">
                                        <button v-if="eliteMembership && membership.type === 'elite'" class="btn-gradient-blue px-6 py-3 rounded-lg text-sm font-semibold w-full" disabled>
                                            You're Elite Already
                                        </button>
                                        <button v-else class="btn-gradient-blue px-6 py-3 rounded-lg text-sm font-semibold w-full">
                                            {{ membership.buttonText }}
                                        </button>
                                    </template>
                                    <template v-else>
                                        <p class="text-blue-500 font-semibold text-sm mb-2 text-center">You need an Account to register for Elite</p>
                                        <a :href="route('register')" class="btn-gradient-blue px-6 py-3 rounded-lg text-sm font-semibold w-full block text-center">
                                            Create Free Account
                                        </a>
                                    </template>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="mt-2 lg:mt-6" v-else>
                    <div class="rounded-lg shadow-md">
                        <template v-if="isAuthenticated">
                            <button v-if="eliteMembership && membership.type === 'elite'" class="block w-full text-center rounded-lg bg-gray-800 px-6 py-3 text-sm leading-6 font-semibold font-display text-white" disabled>
                                You're Elite Already
                            </button>
                            <button v-else class="btn-gradient-blue px-6 py-3 rounded-lg text-sm font-semibold w-full">
                                {{ membership.buttonText }}
                            </button>
                        </template>
                        <template v-else>
                            <a :href="route('register')" class="block w-full text-center rounded-lg btn-gradient-blue px-6 py-3 text-sm leading-6 font-semibold font-display text-white focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                                Create Free Account
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

const props = defineProps({
    membership: Object,
    eliteMembership: Object,
    index: Number,
});

const { props: pageProps } = usePage();

const isAuthenticated = computed(() => !!pageProps.auth.user);
const selectedMembershipTypeId = ref(props.membership.membershipTypes ? props.membership.membershipTypes[0].id : null);

const selectedMembershipType = computed(() => {
    if (props.membership.membershipTypes) {
        return props.membership.membershipTypes.find(type => type.id === selectedMembershipTypeId.value);
    }

    return null;
});

const membershipPrice = computed(() => {
    if (props.membership.type === 'elite' && selectedMembershipTypeId.value) {
        const selectedType = props.membership.membershipTypes.find(type => type.id === selectedMembershipTypeId.value);
        return selectedType ? selectedType.price : props.membership.price;
    }

    return props.membership.price;
});

const subscriptionPricePerMonth = function(subscription) {
    return parseFloat(subscription.price) / parseInt(subscription.subscription_months);
};

const mostExpensiveSubscription = computed(() => {
    if (props.membership.membershipTypes) {
        return props.membership.membershipTypes.reduce((a, b) => subscriptionPricePerMonth(a) > subscriptionPricePerMonth(b) ? a : b);
    }

    return null;
});

const subscriptionPercentDiscount = function(subscription) {
    return Math.round((1 - (subscriptionPricePerMonth(subscription) / subscriptionPricePerMonth(mostExpensiveSubscription.value))) * 100);
};

</script>

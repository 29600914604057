<script setup>

import {createExcerpt} from "../../Services/utils.js";
import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import PageTitle from "../../InertiaComponents/UI/PageTitle.vue";
import NewsCard from "../../InertiaComponents/News/NewsCard.vue";

const props = defineProps({
    posts: {
        type: Object,
        required: true
    },
    games: {
        type: Array,
        required: true
    },
});

function findGameForPost(post) {
    // Find based on the slug property matching with a post's tags.
    return props.games.find(game => post.tags.some(tag => tag.slug === game.slug));
}

</script>

<template>
    <div>
        <!--        <div id="bg" class="-mt-12 game-header-img z-1 bg-cover"-->
        <!--             :style="{'background': `url('${backgroundImg}')`, 'background-size': 'cover', 'background-position': 'center'}"-->
        <!--        >-->
        <!--        </div>-->

        <div class="container mx-auto">
            <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'News'},
            ]"/>

            <PageTitle title="News"/>

            <p class="my-2 text-lg">
                Stay up to date with the latest news and updates from the gaming world.
            </p>

            <p class="mb-4 text-lg">
                This is the most recent news on ESHUB right now, enjoy!
            </p>

            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                <NewsCard v-for="post in posts"
                          :key="post.id"
                          :news-post="post"
                          :game="findGameForPost(post)"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>

import {usePage} from "@inertiajs/vue3";
import {computed, ref} from "vue";
import Tooltip from "../../UI/Tooltip.vue";

const props = defineProps({
    'match': {
        type: Object,
        required: true,
    },
});

const page = usePage();
const user = computed(() => page.props.auth?.user);

const showTooltip = ref(false);

const wagerAmount = computed(() => {
    return parseFloat(props.match.wager_amount);
});

const userBalance = computed(() => {
    if (!user.value) {
        return 0;
    }

    return parseFloat(user?.value?.balance);
});

</script>

<template>
    <div @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
        <a
            v-if="userBalance >= wagerAmount"
            :href="route('challenge.accept.show', match.id)"
            class="px-6 py-2 font-semibold uppercase rounded-lg btn-gradient-blue text-xxs block text-center"
        >
            <div class="text-white">
                <i class="mr-2 fas fa-crown"></i>Accept
            </div>
        </a>
        <div
            v-else
            class="px-6 py-2 font-semibold uppercase rounded-lg bg-blue-900 text-xxs block text-center cursor-not-allowed"
        >
            <div class="text-white">
                <i class="mr-2 fas fa-crown"></i>Accept
            </div>

            <Tooltip :show="showTooltip" classes="normal-case text-left font-light" title="Insufficient Funds">
                <p>
                    You have <span class="font-semibold">${{ userBalance }}</span>.
                </p>
                <p>
                    You need <span class="font-semibold">${{ match.wager_amount }}</span> to accept this match.
                </p>
            </Tooltip>
        </div>
    </div>
</template>

<style scoped>

</style>

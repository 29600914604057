<template>
    <div id="main" class="text-white px-4 md:px-0 py-4">
        <div class="mx-auto container">
            <div id="breadcrumbs" class="text-xs mb-5">
                Home <span class="ml-1 mr-1 text-gray-600 font-extrabold">></span> User <span class="ml-1 mr-1 text-gray-600 font-extrabold">></span> <span class="ml-1 mr-1 text-gray-600">Settings</span>
            </div>

            <div class="flex flex-wrap justify-between">
                <Navigation />

                <div class="w-full md:w-10/12 px-4 md:px-6 mt-8 md:mt-0 border-l border-gray-800">
                    <h2 class="text-2xl font-bold">Social Settings</h2>
                    <h3 class="font-bold text-sm mb-2">Your Details</h3>
                    <hr class="border-b-1 border-gray-800 py-2">

                    <form @submit.prevent="submit">
                        <div v-for="field in socialFields" :key="field.name" id="form-group">
                            <div class="text-xs text-gray-600 py-1">{{ field.label }}</div>
                            <div class="flex flex-wrap items-stretch w-full mb-3 relative">
                                <input v-model="form[field.name]" :name="field.name" type="text"
                                       class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xs font-semibold py-4 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500">
                            </div>
                        </div>
                        <button type="submit" class="mt-4 btn-gradient-blue px-16 py-3 rounded uppercase font-medium text-xxs float-left font-semibold text-right inline-block">
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import {useForm, usePage} from '@inertiajs/vue3';
import Navigation from '../../../components/SettingsNav.vue';

const page = usePage();
let props = page.props

const form = useForm({
    instagram: props.user.socials?.instagram || '',
    twitter: props.user.socials?.twitter || '',
    facebook: props.user.socials?.facebook || '',
    twitch: props.user.socials?.twitch || '',
    discord: props.user.socials?.discord || '',
    youtube: props.user.socials?.youtube || ''
});

const socialFields = [
    { name: 'instagram', label: 'Instagram' },
    { name: 'twitter', label: 'Twitter' },
    { name: 'facebook', label: 'Facebook' },
    { name: 'twitch', label: 'Twitch' },
    { name: 'discord', label: 'Discord' },
    { name: 'youtube', label: 'Youtube' }
];

const successMessage = ref('');
const errorMessage = ref('');

const submit = () => {
    form.post(route('settings.social.update'), {
        onSuccess: () => {
            successMessage.value = 'Successfully updated your social settings';
            errorMessage.value = '';
        },
        onError: (err) => {
            errorMessage.value = 'There was an error updating your social settings';
            successMessage.value = '';
        }
    });
};
</script>

<script setup>

import Tooltip from "./Tooltip.vue";
import {ref} from "vue";

defineProps({
    'title': {
        type: String,
        default: ''
    },
    'content': {
        type: String,
        default: ''
    },
    'classes': {
        type: String,
        default: ''
    },
    'toolTipClasses': {
        type: String,
        default: ''
    }
});

const show = ref(false);

</script>

<template>
    <div @mouseover="show = true" @mouseleave="show = false" :class="classes">
        <slot></slot>

        <Tooltip
            :show="show"
            :title="title"
            :content="content"
            :classes="toolTipClasses"
        />
    </div>
</template>

<style scoped>

</style>

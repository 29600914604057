<script setup>

import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";
import {useForm} from "@inertiajs/vue3";

defineProps({
    friends: {
        type: Object,
        required: true,
    },
    friendRequests: {
        type: Array,
        required: true,
    }
});

const form = useForm({
    email: '',
});

function createFriendRequest() {
    form.post(route('user.friend-hub.store'));
}

</script>

<template>
    <div id="main" class="text-white py-4">
        <div class="container mx-auto">
            <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Friends', link: route('user.friend-hub.index')},
                {name: 'Invite a Friend'},
            ]"/>

            <div class="flex items-center mb-4 w-full justify-between">
                <div class="text-4xl font-bold">Invite a Friend</div>


            </div>

            <form
                class="bg-blue-600 p-6 rounded-lg max-w-4xl mx-auto space-y-4"
                @submit.prevent="createFriendRequest"
            >
                <div v-if="form.hasErrors" class="bg-red-600 p-4 rounded-lg">
                    <p>
                        There was a problem with your Invite.
                    </p>
                    <ul class="list-disc list-inside text-sm">
                        <li v-for="error in form.errors" :key="error">
                            {{ error }}
                        </li>
                    </ul>
                </div>

                <!--                <div class="font-light text-gray-400 space-y-4">-->
                <!--                    <p>-->
                <!--                        We're going to need a few details for your friend - if they're already on the Platform, they'll-->
                <!--                        receive a notification to accept your friend request.-->
                <!--                    </p>-->

                <!--                    <p>-->
                <!--                        If they're not, they'll receive an email inviting them to join!-->
                <!--                    </p>-->
                <!--                </div>-->
                <div class="text-gray-300 font-semibold text-lg space-y-4">
                    <p>
                        Friend Details
                    </p>

                    <p class="text-sm text-gray-400 font-light">
                        Earn <span class="text-blue-400 font-semibold">$5</span> credit every time you refer a friend to the platform!
                    </p>
                    <p class="text-sm text-gray-400 font-light">
                        Once they register and deposit $5 or more, you'll receive $5 in credit. You can’t withdraw this
                        $5, but it can be used for wager matches, helping you climb the ranks! The winnings from any
                        wager matches can be withdrawn.
                    </p>
                </div>

                <div>
                    <label for="email-address" class="text-sm text-gray-400">
                        Email Address
                    </label>
                    <input
                        id="email-address"
                        v-model="form.email"
                        class="w-full rounded-md  text-black"
                        type="email"
                        placeholder="Email Address"
                    >

                    <p v-if="!!form.errors.email" class="mt-1">
                        <span class="text-red-500 text-sm">
                            {{ form.errors.email }}
                        </span>
                    </p>
                </div>

                <div class="text-right">
                    <button class="bg-blue-500 px-4 py-2 rounded-xl">
                        Invite Friend
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped>

</style>

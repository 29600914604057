<script setup>
import { ref } from "vue";
import {Link, useForm, usePage} from "@inertiajs/vue3";
import Breadcrumbs from "../../InertiaComponents/Navigation/Breadcrumbs.vue";

const props = usePage().props;
const { clubs } = props;
const user = ref(props.auth.user);

const form = useForm({
    clubId: null,
});

const submit = (clubId) => {
    form.clubId = clubId;
    form.delete(route('club-members.destroy', { club: clubId, club_member: user.value.id }), {
        preserveState: false,
    });
};

</script>

<template>
    <div class="container mx-auto my-8 px-4">
        <Breadcrumbs :breadcrumbs="[
                {name: 'Home', link: '/'},
                {name: 'Clubs', link: '/clubs'},
            ]" />
        <h1 class="text-3xl font-bold my-2">My Clubs</h1>
        <p class="mb-6">Here are the clubs you are currently a member of. Click on a club to view details or leave the club.</p>
        <div class="club-list">
            <div v-if="clubs.length === 0" class="text-white text-lg font-bold">You are not a member of any clubs.</div>
            <form @submit.prevent="submit(club.id)" v-for="club in clubs" :key="club.id" class="club-item flex items-center justify-between">
                <Link :href="route('clubs.show', { club: club.slug })" class="club-link flex items-center">
                    <img :src="club.logo_url" alt="Club Logo" class="club-logo flex-none">
                    <span class="club-name flex-initial">{{ club.name }}</span>
                </Link>
                <button type="submit" class="leave-club-button">Leave Club</button>
            </form>
        </div>
    </div>
</template>

<style scoped>
.container {
    @apply max-w-3xl mx-auto my-8 px-4;
}

.club-list {
    @apply flex flex-col gap-4;
}

.club-item {
    @apply bg-gray-800 rounded-lg overflow-hidden text-sm md:text-base transition ease-in-out transform hover:-translate-y-1 hover:bg-gray-600 flex items-center justify-between p-4;
}

.club-link {
    @apply flex items-center text-white text-sm md:text-base no-underline ;
}

.club-logo {
    @apply w-12 h-12 rounded-full object-cover;
}

.club-name {
    @apply text-xl font-bold ml-4;
}

.leave-club-button {
    @apply bg-red-600 text-white border-none rounded px-4 py-2 cursor-pointer transition w-32 text-center;
}

.leave-club-button:hover {
    @apply bg-red-500;
}
</style>

<template>
    <div id="main" class="px-4 py-4 text-white md:px-0">
        <div class="container mx-auto">
            <div id="breadcrumbs" class="mb-5 text-xs">
                Home <span class="ml-1 mr-1 font-extrabold text-gray-600">></span> User <span class="ml-1 mr-1 font-extrabold text-gray-600">></span> <span class="ml-1 mr-1 text-gray-600">Settings</span>
            </div>

            <div class="flex flex-wrap justify-between">
                <Navigation />

                <div class="w-full px-4 mt-8 border-l border-gray-800 md:w-10/12 md:px-6 md:mt-0">
                    <h2 class="text-2xl font-bold">Gaming Settings</h2>
                    <h3 class="mb-2 text-sm font-bold">Your Details</h3>
                    <hr class="py-2 border-gray-800 border-b-1">
                    <form @submit.prevent="submit">
                        <div v-for="account in gamingAccounts" :key="account.name" id="form-group">
                            <div class="py-1 text-xs text-gray-600">{{ account.label }}</div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-3">
                                <input v-model="form[account.name]" :name="account.name" type="text"
                                       class="flex-1 flex-grow flex-shrink w-px px-4 py-4 pr-8 text-xs font-semibold leading-tight text-white placeholder-gray-600 bg-blue-900 border border-gray-800 rounded rounded-l-none focus:outline-none focus:border-blue-500">
                            </div>
                        </div>
                        <button type="submit"
                                class="inline-block float-left px-16 py-3 mt-4 font-semibold text-right uppercase rounded btn-gradient-blue text-xxs">
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import {useForm, usePage} from '@inertiajs/vue3';
import Navigation from '../../../components/SettingsNav.vue';


const page = usePage();
let props = page.props;

const form = useForm({
    psn: props.user.gaming_accounts?.psn || '',
    xbl: props.user.gaming_accounts?.xbl || '',
    epic: props.user.gaming_accounts?.epic || '',
    activision: props.user.gaming_accounts?.activision || '',
    rogue_id: props.user.gaming_accounts?.rogue_id || '',
    apex_legends_id: props.user.gaming_accounts?.apex_legends_id || '',
    steam: props.user.gaming_accounts?.steam || '',
    riot_id: props.user.gaming_accounts?.riot_id || '',
    ubisoft_id: props.user.gaming_accounts?.ubisoft_id || '',
    ea_id: props.user.gaming_accounts?.ea_id || '',
    ronin_wallet_address: props.user.gaming_accounts?.ronin_wallet_address || ''
});

const gamingAccounts = [
    { name: 'psn', label: 'Playstation Network' },
    { name: 'xbl', label: 'Xbox Live' },
    { name: 'epic', label: 'Epic' },
    { name: 'activision', label: 'Activision' },
    { name: 'rogue_id', label: 'Rogue ID' },
    { name: 'apex_legends_id', label: 'Apex Legends ID' },
    { name: 'steam', label: 'Steam' },
    { name: 'riot_id', label: 'Riot ID' },
    { name: 'ubisoft_id', label: 'Ubisoft ID' },
    { name: 'ea_id', label: 'EA ID' },
    { name: 'ronin_wallet_address', label: 'Ronin Wallet Address' }
];

const successMessage = ref('');
const errorMessage = ref('');

const submit = () => {
    form.post(route('settings.gaming.update'), {
        onSuccess: () => {
            successMessage.value = 'Successfully updated your gaming details settings';
            errorMessage.value = '';
        },
        onError: (err) => {
            errorMessage.value = 'Failed to update gaming details settings';
            successMessage.value = '';
        }
    });
};
</script>

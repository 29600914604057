<script setup>

import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";

const page = usePage();
const activeMatch = computed(() => page.props.activeMatch);
const activeTournamentMatch = computed(() => page.props.activeTournamentMatch);

</script>

<template>
    <div>
        <div v-if="!!activeMatch" class="bg-blue-600 px-6 py-8 border-b border-blue-500">
            <div class="container mx-auto">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                             fill="currentColor">
                            <path fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </div>
                    <div class="ml-3 flex-col flex flex-wrap sm:flex-row sm:flex-1 sm:justify-between">
                        <div>
                            <div class="flex flex-row">
                                <img
                                    v-if="!!activeMatch.game"
                                    :src="activeMatch.game.storage_image_url"
                                    :alt="`${activeMatch.game.name} avatar`"
                                    class="rounded-md border-4 border-white h-16">

                                <div class="ml-3 flex flex-col self-center">
                                    <h3 class="text-sm leading-5 font-bold text-white">
                                        Active match #{{ activeMatch.wager_challenge_id }}
                                    </h3>
                                    <div class="mt-2 text-sm leading-5 font-medium text-white">
                                        <p>
                                            Details: {{ activeMatch.game.name }} | ${{ activeMatch.wager_amount }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex sm:items-center">
                            <p class="mt-4 text-sm leading-5 sm:mt-0 sm:ml-6">
                                <a :href="route('challenge.show', [activeMatch.wager_challenge_id])"
                                   class="text-white whitespace-no-wrap font-bold transition ease-in-out duration-150">
                                    View match &rarr;
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!!activeTournamentMatch" class="bg-blue-600 px-6 py-8 border-b border-blue-500">
            <div class="container mx-auto">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                             fill="currentColor">
                            <path fill-rule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                  clip-rule="evenodd"/>
                        </svg>
                    </div>
                    <div class="ml-3 flex-col flex flex-wrap sm:flex-row sm:flex-1 sm:justify-between">
                        <div>
                            <div class="flex flex-row">
                                <img
                                    v-if="!!activeTournamentMatch.tournament.game"
                                    :src="activeTournamentMatch.tournament.game.storage_image_url"
                                    :alt="`${activeTournamentMatch.tournament.game.name} avatar`"
                                    class="rounded-md border-4 border-white h-16"
                                >

                                <div class="ml-3 flex flex-col self-center">
                                    <h3 class="text-sm leading-5 font-bold text-white">
                                        Active Tournament match #{{ activeTournamentMatch.id }}
                                    </h3>
                                    <div class="mt-2 text-sm leading-5 font-medium text-white">
                                        <p>
                                            Details: {{ activeTournamentMatch.tournament.name }} | {{
                                                activeTournamentMatch.tournament.game ? activeTournamentMatch.tournament.game.name : ''
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex sm:items-center">
                            <p class="mt-4 text-sm leading-5 sm:mt-0 sm:ml-6">
                                <a :href="route('tournament.match.show', [activeTournamentMatch.tournament.slug, activeTournamentMatch.id])"
                                   class="text-white whitespace-no-wrap font-bold transition ease-in-out duration-150">
                                    View match &rarr;
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script setup>
import { ref, onMounted } from 'vue';
import { useForm, usePage } from '@inertiajs/vue3';

// Define props received from the controller
const props = defineProps({
    userCount: {
        type: Number,
        required: true,
    },
    timezones: {
        type: Array,
        required: true,
    },
    creatorCode: {
        type: String,
        default: '',
    },
});

// Initialize the form using Inertia's useForm
const form = useForm({
    email: '',
    username: '',
    password: '',
    password_confirmation: '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    terms: false,
    affiliate_code: props.creatorCode || '',
});

// Reactive reference to keep track of username input for character count
const userInput = ref(form.username);

// Autofocus the email input when the component is mounted
onMounted(() => {
    document.getElementById('email')?.focus();
});

// Handle form submission
const handleSubmit = () => {
    form.post(route('register'), {
        onFinish: () => {
            if (!form.hasErrors) {
                form.reset('password', 'password_confirmation');
            }
        },
    });
};

// Access global properties
const { appName } = usePage().props;
</script>

<template>
    <div class="container mx-auto">
        <div class="flex justify-center">
            <div class="px-4 mb-6 w-full sm:w-8/12 md:w-6/12 lg:max-w-2xl">
                <div class="px-6 py-4 xl:px-12 xl:py-16 text-white bg-blue-600 border border-gray-800 rounded-lg">
                    <form @submit.prevent="handleSubmit">
                        <div id="inner" class="pb-6 mb-4 border-b-2 border-gray-800">
                            <div class="mb-6">
                                <div class="mb-4 text-3xl font-extrabold text-center">Sign Up</div>
                                <div class="text-sm text-gray-400 text-center">
                                    Over
                                    <span class="text-white">{{ userCount.toLocaleString() }}</span> players around
                                    the world are already playing on {{ appName }}. Join them and start winning.
                                    It's <span class="text-white">100%</span> free.
                                </div>
                            </div>

                            <!-- Email Field -->
                            <div v-if="form.errors.email" class="flex-none text-xs text-red-500" role="alert">
                                {{ form.errors.email }}
                            </div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-6">
                                <div class="flex -mr-px">
                  <span
                      class="bg-blue-800 flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-800 px-4 whitespace-no-wrap text-gray-300 text-sm"
                      :class="{ 'border-red-500': form.errors.email }"
                  >
                    <i class="fas fa-envelope-open"></i>
                  </span>
                                </div>
                                <input
                                    v-model="form.email"
                                    id="email"
                                    name="email"
                                    placeholder="Email Address"
                                    type="email"
                                    class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-sm font-semibold py-5 px-4 pr-8 border-l-0 rounded leading-tight focus:outline-none focus:border-blue-500"
                                    :class="{ 'border-red-500': form.errors.email }"
                                />
                            </div>

                            <!-- Username Field -->
                            <div v-if="form.errors.username" class="text-xs text-red-500" role="alert">
                                {{ form.errors.username }}
                            </div>
                            <div class="w-full mb-6">
                                <div class="relative flex flex-wrap items-stretch">
                                    <div class="flex -mr-px">
                    <span
                        class="bg-blue-800 flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-800 px-4 whitespace-no-wrap text-gray-300 text-sm"
                        :class="{ 'border-red-500': form.errors.username }"
                    >
                      <i class="fas fa-user-circle"></i>
                    </span>
                                    </div>
                                    <input
                                        v-model="form.username"
                                        name="username"
                                        placeholder="Username"
                                        type="text"
                                        @input="userInput = form.username"
                                        class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-sm font-semibold py-5 px-4 pr-8 border-l-0 rounded leading-tight focus:outline-none focus:border-blue-500"
                                        :class="{ 'border-red-500': form.errors.username }"
                                    />
                                    <div
                                        class="absolute right-1 top-1 text-xxs"
                                        :class="{ 'text-red-400': userInput.length > 12 }"
                                    >
                                        <span>{{ userInput.length }}</span> / 12
                                    </div>
                                </div>
                            </div>

                            <!-- Password Field -->
                            <div v-if="form.errors.password" class="text-xs text-red-500" role="alert">
                                {{ form.errors.password }}
                            </div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-6">
                                <div class="flex -mr-px">
                  <span
                      class="bg-blue-800 flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-800 px-4 whitespace-no-wrap text-gray-300 text-sm"
                      :class="{ 'border-red-500': form.errors.password }"
                  >
                    <i class="fas fa-lock"></i>
                  </span>
                                </div>
                                <input
                                    v-model="form.password"
                                    name="password"
                                    placeholder="Password"
                                    type="password"
                                    class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-sm font-semibold py-5 px-4 pr-8 border-l-0 rounded leading-tight focus:outline-none focus:border-blue-500"
                                    :class="{ 'border-red-500': form.errors.password }"
                                />
                            </div>

                            <!-- Confirm Password Field -->
                            <div
                                v-if="form.errors.password_confirmation"
                                class="text-xs text-red-500"
                                role="alert"
                            >
                                {{ form.errors.password_confirmation }}
                            </div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-6">
                                <div class="flex -mr-px">
                  <span
                      class="bg-blue-800 flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-800 px-4 whitespace-no-wrap text-gray-300 text-sm"
                      :class="{ 'border-red-500': form.errors.password_confirmation }"
                  >
                    <i class="fas fa-lock"></i>
                  </span>
                                </div>
                                <input
                                    v-model="form.password_confirmation"
                                    name="password_confirmation"
                                    placeholder="Confirm password"
                                    type="password"
                                    class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-sm font-semibold py-5 px-4 pr-8 border-l-0 rounded leading-tight focus:outline-none focus:border-blue-500"
                                    :class="{ 'border-red-500': form.errors.password_confirmation }"
                                />
                            </div>

                            <!-- Timezone Field -->
                            <div v-if="form.errors.timezone" class="text-xs text-red-500 z-10" role="alert">
                                {{ form.errors.timezone }}
                            </div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-6">
                                <div class="flex -mr-px">
                  <span
                      class="bg-blue-800 flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-800 px-4 whitespace-no-wrap text-gray-300 text-sm"
                      :class="{ 'border-red-500': form.errors.timezone }"
                  >
                    <i class="fas fa-globe"></i>
                  </span>
                                </div>
                                <select
                                    v-model="form.timezone"
                                    name="timezone"
                                    class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-sm font-semibold py-5 px-4 pr-8 border-l-0 rounded leading-tight focus:outline-none focus:border-blue-500"
                                    :class="{ 'border-red-500': form.errors.timezone }"
                                >
                                    <option v-for="timezone in timezones" :value="timezone" :key="timezone">
                                        {{ timezone }}
                                    </option>
                                </select>
                            </div>

                            <!-- Affiliate Code Field (Optional) -->
                            <!-- Uncomment if needed -->
                            <!--
                            <div v-if="form.errors.affiliate_code" class="text-xs text-red-500" role="alert">
                              {{ form.errors.affiliate_code }}
                            </div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-6">
                              <div class="flex -mr-px">
                                <span
                                  class="bg-blue-800 flex items-center leading-normal rounded rounded-r-none border border-r-0 border-gray-800 px-4 whitespace-no-wrap text-gray-300 text-sm"
                                  :class="{ 'border-red-500': form.errors.affiliate_code }"
                                >
                                  <i class="fas fa-ticket-alt"></i>
                                </span>
                              </div>
                              <input
                                v-model="form.affiliate_code"
                                name="affiliate_code"
                                placeholder="Creator Code(Optional)"
                                type="text"
                                class="rounded-l-none w-px flex-1 flex-shrink flex-grow bg-blue-900 border border-gray-800 text-white placeholder-gray-600 text-xxs font-semibold py-5 px-4 pr-8 border-l-0 rounded leading-tight focus:outline-none focus:border-blue-500"
                                :class="{ 'border-red-500': form.errors.affiliate_code }"
                              />
                            </div>
                            -->

                            <!-- Terms and Conditions -->
                            <div v-if="form.errors.terms" class="text-xs text-red-500 text-center" role="alert">
                                {{ form.errors.terms }}
                            </div>
                            <div class="relative flex flex-wrap items-stretch w-full mb-5 justify-center">
                                <label class="flex items-center">
                                    <div class="flex items-center justify-center w-6 h-6 p-1 mr-2 bg-gray-200">
                                        <input type="checkbox" v-model="form.terms" class="!appearance-none" />
                                    </div>
                                    <span class="text-sm text-gray-400">
                    Click to accept our
                    <a class="underline" :href="route('terms.index')">Terms and Conditions</a>
                  </span>
                                </label>
                            </div>

                            <!-- Submit Button -->
                            <button
                                type="submit"
                                class="inline-block w-full px-16 py-3 text-xs font-medium font-semibold text-center uppercase rounded btn-gradient-blue"
                            >
                                Register
                            </button>

                            <!-- Sign In Link -->
                            <div class="mt-4 text-sm font-semibold text-center text-gray-500">
                                Already have an account?
                                <a class="text-blue-500" :href="route('login')">Sign in</a>
                            </div>
                        </div>
                    </form>

                    <!-- Footer -->
                    <div id="footer" class="font-semibold text-center text-gray-500 text-xxs">
                        By registering you agree to {{ appName }}'s
                        <a :href="route('terms.index')" class="italic text-blue-500">Terms & Conditions</a> and
                        <a :href="route('privacy-policy.index')" class="italic text-blue-500">Privacy Policy</a>.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
